<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import SelectList from 'components/select-list.vue'
import ProcessDefinitionListItem from 'list-items/process-definition-list-item.vue'
import DossierDefinitionListItem from 'list-items/dossier-definition-list-item.vue'
import filter from 'lodash/filter'
import map from 'lodash/map'

export default {
  name: 'AddMainMenuEntryDialog',
  components: {
    ProcessDefinitionListItem,
    DossierDefinitionListItem,
    SelectList,
    CustomDialog
  },
  props: {
    selectedItems: {
      type: Array,
      required: true
    },
    parentId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      dialogOpen: false,
      requestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.dialogOpen = false
            this.$emit('request-success', data)
          }
        }
      }),
      selectedProcessDefinitionsItems: [],
      selectedDossierDefinitionsItems: [],
      helperKey: 0
    }
  },
  computed: {
    objectsToLink () {
      return map(this.selectedProcessDefinitionsItems, item => { return { id: item.id, type: 'workflow_definition' } }).concat(
        map(this.selectedDossierDefinitionsItems, item => { return { id: item.id, type: 'dossier_definition' } })
      )
    },
    hasValidChanges () {
      return this.objectsToLink.length > 0
    },
    workflowDefinitionsToExclude () {
      return map(filter(this.selectedItems, item => { return item.type === 'workflow_definition' }), item => { return { id: item.linkedId } })
    },
    dossierDefinitionsToExclude () {
      return map(filter(this.selectedItems, item => { return item.type === 'dossier_definition' }), item => { return { id: item.linkedId } })
    },
    okButtonText () {
      let res = this.$t('mainMenuEntry.dialogs.addMenuItem.buttons.ok')
      if (this.objectsToLink.length > 0) res = res + ` (${this.objectsToLink.length})`
      return res
    }
  },
  methods: {
    open () {
      this.dialogOpen = true
    },
    initData () {
      this.helperKey = this.helperKey + 1
      this.selectedProcessDefinitionsItems = []
      this.selectedDossierDefinitionsItems = []
    },
    onDialogOpened () {
      this.initData()
      this.requestable.resetRequestable()

      this.$emit('open')
    },
    onOkButtonClicked (index) {
      this.requestable.request(
        { method: Request.POST, url: this.$apiEndpoints.mainMenuEntries.create() },
        null,
        {
          parent_id: this.parentId || null,
          linked_objects: this.objectsToLink
        }
      )
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t('mainMenuEntry.dialogs.addMenuItem.title')"
    fullheight
    :close-on-button-click="false"
    :ok-btn-text="okButtonText"
    :ok-btn-disabled="!hasValidChanges"
    :loading="requestable.requestableLoading"
    :error-message="requestable.baseErrorMessage"
    content-class="add-main-menu-entry-dialog"
    @click-ok="onOkButtonClicked"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <v-tabs
      grow
      class="tabs-content-scroll"
    >
      <v-tab>Prozessvorlagen</v-tab>
      <v-tab>Dossiervorlagen</v-tab>

      <v-tab-item>
        <select-list
          :key="`process_definition_list_${helperKey}`"
          v-slot="{ item, itemKey, selected, toggle }"
          :value="workflowDefinitionsToExclude"
          :request-parameter="{ method: 'get', url: $apiEndpoints.workflowDefinitions.list() }"
          add-only
          class="fill-height"
          @change="selectedProcessDefinitionsItems = $event"
        >
          <process-definition-list-item
            :key="itemKey"
            :value="item"
            indent
            selectable
            :selected="selected"
            @click="toggle"
          />
        </select-list>
      </v-tab-item>
      <v-tab-item>
        <select-list
          :key="`dossier_definition_list_${helperKey}`"
          v-slot="{ item, itemKey, selected, toggle }"
          :value="dossierDefinitionsToExclude"
          :request-parameter="{ method: 'get', url: $apiEndpoints.dossierDefinitions.list() }"
          add-only
          class="fill-height"
          @change="selectedDossierDefinitionsItems = $event"
        >
          <dossier-definition-list-item
            :key="itemKey"
            :value="item"
            indent
            selectable
            :selected="selected"
            @click="toggle"
          />
        </select-list>
      </v-tab-item>
    </v-tabs>
  </custom-dialog>
</template>
<style scoped lang="scss">
.tabs-content-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > :deep(.v-tabs-items) {
    height: 100%;

    & > .v-window__container > .v-window-item {
      height: 100%;
    }
  }
}
</style>
