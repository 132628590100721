<script>
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import User from 'mixins/models/user'
import eventBus from 'helpers/event-bus'

export default {
  name: 'UserSettingsHeader',
  components: {
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu
  },
  mixins: [PageContentable, User],
  computed: {
    user () {
      return this.value
    }
  },
  methods: {
    onUpdated (value) {
      this.$emit('input', value)
    },
    onResendConfirmationInstructions (value) {
      eventBus.$emit('quick-message', 'Aktivierungsmail wurde gesendet.')
    }
  }
}
</script>
<template>
  <div v-if="value">
    <breadcrumbs :items="userBreadcrumbs" />

    <page-detail-header
      :state-text="userStateText"
      :state-updated-at="userStateUpdatedAtDate"
      :state-color="userStateColor.background"
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #actions="{actionRequest, loading}">
        <kebab-menu
          v-if="$config.current_user.isAdmin && !$config.isCurrentUser(user)"
          :disabled="loading"
          color="primary"
        >
          <template #items>
            <v-list-item @click="actionRequest($apiEndpoints.userSettings.updateActiveStatus(value.id), onUpdated, 'patch', null, { active: !userIsActive })">
              <v-list-item-title>{{ userIsActive ? $t('user.detailActions.deactivate') : $t('user.detailActions.activate') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!userIsConfirmed"
              @click="actionRequest($apiEndpoints.userSettings.resendConfirmationInstructions(value.id), onResendConfirmationInstructions, 'patch')"
            >
              <v-list-item-title>Aktivierungsmail erneut senden</v-list-item-title>
            </v-list-item>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <h1 class="text-h4 font-weight-light mb-4">
      {{ user.fullname }} ({{ userEmailsFor(user) }})
    </h1>
  </div>
</template>
