<script>
import Vue from 'vue'
import ObjectsControl from 'controls/objects-control/index.vue'
import ListControl from 'controls/list-control.vue'
import RichtextareaControl from 'controls/richtextarea-control.vue'
import DossierDefinitionListItem from 'list-items/dossier-definition-list-item.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import DataField from 'mixins/models/data-field'
import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import has from 'lodash/has'
import filter from 'lodash/filter'
import forOwn from 'lodash/forOwn'

export default {
  name: 'ContentTypeOptionsControl',
  components: {
    ObjectsControl,
    DossierDefinitionListItem,
    ListControl,
    RichtextareaControl
  },
  mixins: [Cacheable, Controlable, DataField],
  props: {
    value: {
      type: Object,
      required: true
    },
    contentType: {
      type: Object,
      default: () => undefined
    }
  },
  methods: {
    createCacheValue () {
      Cacheable.methods.createCacheValue.call(this)
      if (this.contentType?.options_definition) {
        Object.keys(this.contentType.options_definition).forEach(optionKey => {
          if (this.cacheValue[optionKey] === undefined) this.cacheValue[optionKey] = null
        })
      }
    },
    filterAutoIncItems (definition) {
      const result = {}

      forOwn(definition, (value, key) => {
        if (value.type !== 'auto-increment') {
          Vue.set(result, key, value)
        }
      })

      return result
    },
    errorMessagesForAttribute (attribute) {
      if (isNil(this.errorMessages)) return []
      const errors = !isArray(this.errorMessages) ? [{ base: this.errorMessages }] : this.errorMessages
      return map(filter(errors, (val) => {
        return has(val, attribute)
      }), (val) => {
        return val[attribute]
      })
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    hasRequestParameter () {
      return false
    }
  }
}
</script>
<template>
  <v-card
    v-if="contentType && contentType.options_definition"
    :id="$id('content-type-options-control')"
  >
    <v-card-text>
      <template v-for="(option_def, option_key) in contentType.options_definition">
        <v-switch
          v-if="option_def.type === 'boolean'"
          :key="option_key"
          v-model="cacheValue[option_key]"
          :label="option_def.title"
          :disabled="controlDisabled"
          :hint="option_def.required ? $t('general.field.required') : undefined"
          :persistent-hint="option_def.required"
          :error-messages="errorMessagesForAttribute(option_key)"
          :error-count="Number.MAX_VALUE"
          @change="controlOnInput"
        />
        <v-text-field
          v-else-if="option_def.type === 'string'"
          :key="option_key"
          v-model="cacheValue[option_key]"
          :label="option_def.title"
          :disabled="controlDisabled"
          :hint="option_def.required ? $t('general.field.required') : undefined"
          :persistent-hint="option_def.required"
          :error-messages="errorMessagesForAttribute(option_key)"
          :error-count="Number.MAX_VALUE"
          :placeholder="option_def.default"
          @input="controlOnInput"
        />
        <v-currency-field
          v-else-if="option_def.type === 'integer'"
          :key="option_key"
          v-model="cacheValue[option_key]"
          :label="option_def.title"
          :disabled="controlDisabled"
          :hint="option_def.required ? $t('general.field.required') : undefined"
          :persistent-hint="option_def.required"
          :error-messages="errorMessagesForAttribute(option_key)"
          :error-count="Number.MAX_VALUE"
          :placeholder="option_def.default ? String(option_def.default) : undefined"
          :locale="$i18n.locale"
          :auto-decimal-mode="false"
          value-as-integer
          :decimal-length="0"
          :default-value="null"
          @input="controlOnInput"
        />
        <richtextarea-control
          v-else-if="option_def.type === 'richtext'"
          :key="option_key"
          v-model="cacheValue[option_key]"
          :label="option_def.title"
          :disabled="controlDisabled"
          :required="option_def.required "
          :error-messages="errorMessagesForAttribute(option_key)"
          :placeholder="option_def.default"
          :mention-menu-attach="$idRef('content-type-options-control')"
          :mention-menu-nudge-bottom-difference="32"
          @input="controlOnInput"
        />
        <objects-control
          v-else-if="option_def.type === 'dossier-definition'"
          :key="option_key"
          v-model="cacheValue[option_key]"
          :label="option_def.title"
          :list-request-parameter="{method: 'get', url: $apiEndpoints.dossierDefinitions.list(), params: {filter_by_groups_only: true}}"
          single
          :disabled="controlDisabled"
          :hint="option_def.required ? $t('general.field.required') : undefined"
          :persistent-hint="option_def.required"
          :error-messages="errorMessagesForAttribute(option_key)"
          :select-card-menu-attach="$idRef('content-type-options-control')"
          :select-menu-card-nudge-bottom-difference="16"
          @change="controlOnInput"
        >
          <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
            <dossier-definition-list-item
              :key="item.id"
              :indent="indent"
              :item-link="itemLink"
              :selectable="selectable"
              :selected="selected"
              :skeleton="item.skeleton"
              :value="item"
              v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
            />
          </template>
        </objects-control>
        <list-control
          v-else-if="option_def.type === 'array'"
          :key="option_key"
          v-model="cacheValue[option_key]"
          :label="option_def.title"
          :items="filterAutoIncItems(option_def.items)"
          :default-value-item="dataFieldGenerateObjectWithDefaultValues(option_def.items)"
          :disabled="controlDisabled"
          :hint="option_def.required ? $t('general.field.required') : undefined"
          :persistent-hint="option_def.required"
          :error-messages="errorMessagesForAttribute(option_key)"
          @change="controlOnInput"
        >
          <template #field="{itemName, itemDef, itemValue}">
            <v-text-field
              v-if="itemDef.type === 'string'"
              v-model="itemValue[itemName]"
              :label="itemDef.title"
              :hint="itemDef.required ? $t('general.field.required') : undefined"
              :persistent-hint="itemDef.required"
              @input="controlOnInput"
            />
          </template>
        </list-control>
      </template>
      <div
        v-if="errorMessagesForAttribute('base').length > 0"
        class="error--text"
      >
        <div
          v-for="(errorMessage, index) in errorMessagesForAttribute('base')"
          :key="index"
        >
          {{ errorMessage }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
