<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import ResetBtn from 'components/reset-btn.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import { number2LocaleString } from 'helpers/number'

export default {
  name: 'TextareaControl',
  components: { ResetBtn, CopyToClipboardBtn },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: String,
      default: undefined
    }
  },
  methods: {
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    number2LocaleString (n) {
      return number2LocaleString(n)
    }
  }
}
</script>

<template>
  <v-textarea
    v-model="cacheValue"
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :readonly="controlReadonly"
    :class="controlClass"
    :error-count="Number.MAX_VALUE"
    :error-messages="controlErrorMessages"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
    auto-grow
    rows="1"
    :counter="controlIsFocused"
    @blur="controlOnBlur(); controlOnChange(); controlOnUnlock();"
    @input="controlOnInput"
    @focus="controlOnFocus(); controlOnLock();"
  >
    <template #append>
      <reset-btn
        v-if="cachedValueChanged && controlHasError"
        @click="controlReset"
      />
      <copy-to-clipboard-btn
        v-if="cacheValue"
        :value="{'text/plain': cacheValue}"
      />
    </template>
    <template #counter="{ props }">
      <v-counter
        v-bind="props"
        :value="`${number2LocaleString(props.value)} Zeichen`"
      />
    </template>
  </v-textarea>
</template>
