<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import draggable from 'vuedraggable'
import defaults from 'lodash/defaults'
import cloneDeep from 'lodash/cloneDeep'
import castArray from 'lodash/castArray'
import without from 'lodash/without'
import map from 'lodash/map'
import difference from 'lodash/difference'

export default {
  name: 'SelectionControl',
  components: {
    ResetBtn,
    draggable
  },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: [Number, Array],
      default: () => { return this.multiple ? [] : null }
    },
    itemText: {
      type: [String, Array, Function],
      default: 'text'
    },
    itemValue: {
      type: [String, Array, Function],
      default: 'value'
    },
    deletableChips: {
      type: Boolean,
      default: true
    },
    sortableChips: Boolean
  },
  data () {
    return {
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1
      }
    }
  },
  computed: {
    internalOptions () {
      const res = defaults({}, this.options, {
        items: [],
        multiple: false
      })

      const missingValues = difference(this.value, map(res.items, val => val[this.itemValue]))
      if (missingValues.length > 0) {
        res.items = res.items.concat(map(missingValues, val => { return { [this.itemValue]: val, [this.itemText]: `Nicht definierter Listeneintrag (id: ${val})` } }))
      }

      if (this.value?.length > 1) res.multiple = true

      return res
    }
  },
  methods: {
    createCacheValue () {
      const tmp = cloneDeep(without(castArray(this.value), null, undefined))

      if (this.internalOptions.multiple) {
        this.cacheValue = tmp
      } else {
        this.cacheValue = tmp.length === 0 ? null : tmp[0]
      }
    },
    onDraggableMove (event) {
      this.dragged = {
        from: parseInt(event.from.id),
        to: parseInt(event.to.id),
        newIndex: event.draggedContext.futureIndex
      }
    },
    onDraggableChange (event) {
      if (event.removed) {
        // insert
        this.cacheValue.splice(this.dragged.to + this.dragged.newIndex, 0, this.cacheValue[this.dragged.from])
        // delete
        if (this.dragged.from < this.dragged.to) {
          this.cacheValue.splice(this.dragged.from, 1)
        } else {
          this.cacheValue.splice(this.dragged.from + 1, 1)
        }

        this.controlOnInput()
        this.controlOnChange()
      }
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>

<template>
  <v-autocomplete
    v-model="cacheValue"
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :readonly="controlReadonly"
    :class="controlClass"
    :error-count="Number.MAX_VALUE"
    :error-messages="controlErrorMessages"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
    :items="internalOptions.items"
    :item-text="itemText"
    :item-value="itemValue"
    :multiple="internalOptions.multiple"
    chips
    :deletable-chips="deletableChips"
    @input="controlOnInput"
    @blur="controlOnChange(cacheValue); controlOnUnlock();"
    @focus="controlOnLock();"
  >
    <template
      v-if="sortableChips"
      #selection="data"
    >
      <draggable
        :id="data.index"
        :list="cacheValue"
        :force-fallback="true"
        :animation="200"
        group="group"
        :move="onDraggableMove"
        @change="onDraggableChange"
      >
        <v-chip
          :key="JSON.stringify(data.parent.getValue(data.item))"
          :input-value="data.index === data.parent.selectedIndex"
          :disabled="data.disabled"
          :close="deletableChips"
          class="v-chip--select cursor-drag-drop"
          tabindex="-1"
          @click:close="data.parent.onChipInput(data.item)"
          @mousedown.stop
          @click.stop
        >
          {{ data.parent.getText(data.item) }}
        </v-chip>
      </draggable>
    </template>
    <template #append>
      <reset-btn
        v-if="cachedValueChanged && controlHasError"
        @click="controlReset"
      />
    </template>
  </v-autocomplete>
</template>
