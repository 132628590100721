import Page from './page'
import forEach from 'lodash/forEach'
import flattenDeep from 'lodash/flattenDeep'
import has from 'lodash/has'
import parseInt from 'lodash/parseInt'

export const ListPageContentable = {
  props: {
    filters: {
      type: Object,
      required: true
    },
    pages: {
      type: Object,
      required: true
    }
  }
}

export default {
  name: 'ListPage',
  mixins: [Page],
  props: {
    query: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      filters: this.createFilters({
        tabs: this.filterTabs(),
        fields: this.filterFields()
      }),
      pages: {
        current: 1,
        total: this.totalPages()
      }
    }
  },
  computed: {
    pageComponentProps () {
      return { filters: this.filters, pages: this.pages }
    }
  },
  methods: {
    onPropUpdated (prop, value, info) {
      switch (prop) {
        case 'filters':
          this.filters.values[info] = value
          this.pages.current = 1
          break
        case 'pages':
          this.pages = value
          break
        default:
      }
      this.$router.replace({ name: this.$route.name, query: this.createQuery() })
    },
    onBeforePropsChanged () {
      this.updateFilterValuesFromQuery()
      this.updateCurrentPageFromQuery()
    },
    onValueChanged () {
      if ((this.pages.total() < this.pages.current) && (this.pages.total() > 0)) {
        this.pages.current = (this.pages.total() < 1) ? 1 : this.pages.total()
        this.onPropUpdated('pages', this.pages, null)
      }
    },
    filterTabs () {
      return undefined
    },
    filterFields () {
      return undefined
    },
    totalPages () {
      return this.valueAttributeOrDefault('total_pages', 1)
    },
    valueAttributeOrDefault (attribute, defaultValue = []) {
      return () => { return this.value ? this.value[attribute] : defaultValue }
    },
    addNoneUser (itemFunction) {
      return this.prependItem(itemFunction, { id: null, fullname: '- keiner -' })
    },
    prependItem (itemFunction, item) {
      return () => { return [item].concat(itemFunction()) }
    },
    createFilters ({ tabs = undefined, fields = undefined }) {
      const result = {}
      const values = {}

      if (tabs) {
        result.tabs = tabs
        values[tabs.name] = tabs.default
      }

      if (fields) {
        result.fields = fields
        forEach(flattenDeep(fields), (field) => {
          values[field.name] = field.default
        })
      }

      result.values = values

      return result
    },
    updateFilterValuesFromQuery () {
      if (this.filters.tabs) {
        this.updateFilterFieldValueFromQuery(this.filters.tabs, this.filters.values, this.query)
      }

      if (this.filters.fields) {
        forEach(flattenDeep(this.filters.fields), (field) => {
          this.updateFilterFieldValueFromQuery(field, this.filters.values, this.query)
        })
      }
    },
    updateFilterFieldValueFromQuery (field, currentValues, queryValues) {
      if (has(queryValues, field.name)) {
        currentValues[field.name] = queryValues[field.name]
        if (has(field, 'cast')) currentValues[field.name] = field.cast(currentValues[field.name])
      } else {
        currentValues[field.name] = field.default
      }
    },
    updateCurrentPageFromQuery () {
      if (has(this.query, 'page')) {
        this.pages.current = parseInt(this.query.page)
      } else {
        this.pages.current = 1
      }
    },
    createQuery () {
      const result = {}

      if (this.filters.tabs) {
        this.createQueryField(this.filters.tabs, this.filters.values, result)
      }

      if (this.filters.fields) {
        forEach(flattenDeep(this.filters.fields), (field) => {
          this.createQueryField(field, this.filters.values, result)
        })
      }

      if (this.pages && this.pages.current !== 1) {
        result.page = this.pages.current
      }

      return result
    },
    createQueryField (field, currentValues, query) {
      if (currentValues[field.name] !== field.default) {
        query[field.name] = currentValues[field.name]
      }
    }
  }
}
