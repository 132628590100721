import ListPage from '../list-page'
import ListContent from './list-content.vue'
import map from 'lodash/map'
import parseInt from 'lodash/parseInt'

export default {
  name: 'TaskListPage',
  mixins: [ListPage],
  computed: {
    pageTitleParts () {
      return ['Aufgaben']
    },
    pageContentComponents () {
      return ListContent
    }
  },
  methods: {
    prependSpecialWorkflowDefinitionItems (itemFunction) {
      let result = itemFunction

      result = this.prependItem(result, { value: 'any', text: '- mit Vorlage -' })
      result = this.prependItem(result, { value: 'none', text: '- ohne Vorlage -' })
      result = this.prependItem(result, { value: 'singletask', text: '- nur Einzelaufgaben -' })
      return result
    },
    castWorkflowDefinitionsFilterFunction (castFunction) {
      return (value) => map([value].flat(), (value) => {
        if (value !== 'none' && value !== 'any' && value !== 'singletask') return castFunction(value)
        return value
      })
    },
    initPageRequestUrl () {
      return this.$apiEndpoints.tasks.index()
    },
    initPageRequestParams () {
      return {
        page: this.pages.current,
        order: this.filters.values.order,
        query: this.filters.values.query,
        tab_category: this.filters.values.tab_category,
        assignee_ids: this.filters.values.assignee_ids,
        contributor_ids: this.filters.values.contributor_ids,
        workflow_assignee_ids: this.filters.values.workflow_assignee_ids,
        workflow_definition_ids: this.filters.values.workflow_definition_ids
      }
    },
    filterTabs () {
      return {
        name: 'tab_category',
        items: [
          { text: 'AKTIV', value: 'ACTIVE' },
          { text: 'Fällig', value: 'DUE' },
          { text: 'Zurückgestellt', value: 'SNOOZED' },
          { text: 'Meine Merkliste', value: 'MARKED' },
          { text: 'Alle', value: 'ALL' }
        ],
        counts: this.valueAttributeOrDefault('tab_categories', {}),
        default: 'ACTIVE'
      }
    },
    filterFields () {
      return [
        [
          {
            name: 'query',
            type: 'text',
            label: 'Titel',
            default: ''
          },
          {
            name: 'order',
            type: 'single-select',
            label: 'Sortierung',
            items: [
              { text: 'Erstellungsdatum – neueste zuerst', value: 'created_at_desc' },
              { text: 'Erstellungsdatum – älteste zuerst', value: 'created_at_asc' },
              { text: 'Titel – aufsteigend', value: 'title_asc' },
              { text: 'Titel – absteigend', value: 'title_desc' },
              { text: 'Fälligkeit – nach Dringlichkeit', value: 'by_due_date' }
            ],
            default: 'created_at_desc',
            cols: 4
          }],
        [
          {
            name: 'assignee_ids',
            type: 'user-multi-select',
            label: 'Verantwortlich Aufgabe',
            items: this.addNoneUser(this.valueAttributeOrDefault('users')),
            default: [],
            cast: (value) => map([value].flat(), (value) => { return value === null ? null : parseInt(value) })
          },
          {
            name: 'contributor_ids',
            type: 'user-multi-select',
            label: 'Teilnehmer:innen Aufgabe',
            items: this.addNoneUser(this.valueAttributeOrDefault('users')),
            default: [],
            cast: (value) => map([value].flat(), (value) => { return value === null ? null : parseInt(value) })
          }
        ],
        [
          {
            name: 'workflow_assignee_ids',
            type: 'user-multi-select',
            label: 'Verantwortlich Prozess',
            items: this.addNoneUser(this.valueAttributeOrDefault('users')),
            default: [],
            cast: (value) => map([value].flat(), (value) => { return value === null ? null : parseInt(value) })
          },
          {
            name: 'workflow_definition_ids',
            type: 'multi-select',
            label: 'Prozessvorlage',
            items: this.prependSpecialWorkflowDefinitionItems(this.valueAttributeOrDefault('workflow_definitions')),
            default: [],
            cast: this.castWorkflowDefinitionsFilterFunction(parseInt)
          }
        ]
      ]
    }
  }
}
