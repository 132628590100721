<script>
export default {
  name: 'DesignModeBtn',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>
<template>
  <v-btn
    id="DesignModeBtn"
    color="purple lighten-2"
    class="mb-11"
    dark
    fab
    absolute
    right
    bottom
    @click="$emit('input', !value)"
  >
    <v-icon v-if="value">
      mdi-close
    </v-icon>
    <v-icon v-else>
      mdi-pencil
    </v-icon>
  </v-btn>
</template>
