<script>
import pick from 'lodash/pick'
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import Controlable from 'mixins/controlable'
import BaseControl from 'controls/base-control'
import Avatar from 'components/avatar.vue'

export default {
  name: 'AvatarControl',
  components: {
    BaseControl,
    Avatar
  },
  mixins: [Requestable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasImage () {
      return !!this.value.url
    },
    attributeName () {
      return this.requestParameter.mapping
    },
    internalRequestParameter () {
      return pick(this.requestParameter, ['method', 'url'])
    }
  },
  methods: {
    onFileChange () {
      this.$refs.fileinput.value = null
      this.$refs.fileinput.click()
    },
    onFileDelete () {
      this.resetRequestable()
      this.request(this.internalRequestParameter, null, { [`remove_${this.attributeName}`]: '1' })
    },
    onFileInputChanged (e) {
      const file = e.target.files[0]
      if (file) {
        const formData = new FormData()
        formData.append(this.attributeName, file)

        this.resetRequestable()
        this.request(this.internalRequestParameter, null, formData)
      }
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>

<template>
  <base-control
    :value="value"
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :class="controlClass"
    :error-messages="controlErrorMessages"
    :error-count="Number.MAX_VALUE"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
  >
    <template #actions>
      <input
        ref="fileinput"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileInputChanged"
      >
      <v-btn
        color="primary"
        small
        text
        :disabled="controlDisabled"
        @click="onFileChange"
      >
        Ändern
      </v-btn>
      <v-btn
        color="error"
        small
        text
        :disabled="!hasImage || controlDisabled"
        @click="onFileDelete"
      >
        Löschen
      </v-btn>
    </template>
    <avatar
      v-if="value"
      :text="value.label"
      :image="value.url"
      :size="100"
      class="mb-2"
    />
  </base-control>
</template>
