<script>
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import Avatar from 'components/avatar.vue'
import CreateWorkflowDialog from 'dialogs/create-workflow-dialog.vue'
import ProcessDefinition from 'mixins/models/process-definition'
import { stateColors } from 'helpers/definitions'
import { routeFor } from 'helpers/route'
import map from 'lodash/map'

export default {
  name: 'ProcessesContent',
  components: { Avatar, CreateWorkflowDialog },
  mixins: [Requestable, ProcessDefinition],
  data () {
    return {
      workflowDefinitions: [],
      totalCount: 0
    }
  },
  computed: {
    requestParameter () {
      return { method: Request.GET, url: this.$apiEndpoints.dashboard.processes() }
    },
    badgeColor () {
      return stateColors.active.background
    }
  },
  beforeMount () {
    this.workflowDefinitions = []
    this.request(this.requestParameter, { offset: this.workflowDefinitions.length, limit: 4 })
  },
  methods: {
    ...{ routeFor },
    onRequestSuccess (data) {
      this.totalCount = data.total_count
      this.workflowDefinitions.splice(this.workflowDefinitions.length, 0, ...data.result)
    },
    loadMore () {
      this.request(this.requestParameter, { offset: this.workflowDefinitions.length })
    },
    onCreated (workflow) {
      if (workflow.nextTaskId) {
        this.$router.push(routeFor('task', workflow.nextTaskId))
      } else {
        this.$router.push({ name: 'tasks' })
      }
    },
    assigneeForPreviewCount (workflowDefinition) {
      return workflowDefinition.assignees_with_count.length > 4 ? 3 : workflowDefinition.assignees_with_count.length
    },
    assigneeForPreview (workflowDefinition) {
      return workflowDefinition.assignees_with_count.slice(0, this.assigneeForPreviewCount(workflowDefinition))
    },
    assigneeIdsNotPreviewed (workflowDefinition) {
      return map(workflowDefinition.assignees_with_count.slice(this.assigneeForPreviewCount(workflowDefinition)), value => value.user.id)
    }
  }
}
</script>
<template>
  <div>
    <h1 class="text-h4 font-weight-light mt-4">
      {{ $t('dashboard.widget.processes.title') }}
    </h1>
    <v-container>
      <v-row v-if="workflowDefinitions && workflowDefinitions.length > 0">
        <v-col
          v-for="wd in workflowDefinitions"
          :key="wd.id"
          cols="12"
          sm="6"
          md="3"
          class="mb-5 d-flex"
        >
          <v-card
            :to="routeFor('tasks_by_definition', wd.id)"
            class="d-flex flex-column flex-grow-1"
          >
            <v-card-title
              class="text-subtitle-1"
              style="word-break: unset; line-height: 1.375rem;"
            >
              {{ wd.name }}
            </v-card-title>
            <v-card-subtitle class="flex-grow-1">
              {{ wd.description }}
            </v-card-subtitle>
            <v-card-text class="pb-6">
              <div class="d-flex flex-wrap">
                <v-btn
                  v-for="(element, index) in assigneeForPreview(wd)"
                  :key="element.id"
                  icon
                  :to="routeFor('tasks_by_definition', wd.id, { query: { assignee_ids: [element.user.id] }})"
                  class="mb-2"
                  :style="index < 3 ? 'margin-right: 10px;': ''"
                >
                  <v-badge
                    :color="badgeColor"
                    :content="element.count"
                    overlap
                  >
                    <avatar
                      :text="element.user.avatar.label"
                      :image="element.user.avatar.url"
                      :tooltip-title="element.user.fullname"
                      :tooltip-subtitle="element.user.email"
                    />
                  </v-badge>
                </v-btn>

                <v-btn
                  v-if="assigneeForPreviewCount(wd) < wd.assignees_with_count.length"
                  icon
                  class="mb-2"
                  :to="routeFor('tasks_by_definition', wd.id, {query: { assignee_ids: assigneeIdsNotPreviewed(wd) }})"
                >
                  <avatar :text="`+${wd.assignees_with_count.length - assigneeForPreviewCount(wd)}`" />
                </v-btn>
              </div>

              <create-workflow-dialog
                :definition="wd"
                @success="onCreated"
              >
                <template #activator="{ on }">
                  <v-btn
                    color="grey lighten-5"
                    style="left: 0; right: 0; margin: 0 auto;"
                    small
                    absolute
                    bottom
                    fab
                    v-on="on"
                    @click.prevent
                  >
                    <v-tooltip
                      bottom
                      nudge-bottom="4"
                    >
                      <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                        <v-icon
                          v-bind="tooltipAttrs"
                          v-on="tooltipOn"
                        >
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>{{ $t('dashboard.widget.processes.buttons.start') }}</span>
                    </v-tooltip>
                  </v-btn>
                </template>
              </create-workflow-dialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="!requestableLoading">
        <v-col class="px-0">
          {{ $t('dashboard.widget.processes.emptyMessage') }}
        </v-col>
      </v-row>
      <v-row v-if="requestableLoadingDelayed">
        <v-col
          v-for="i in 4"
          :key="`skeleton-loader-${i}`"
          cols="6"
          md="3"
        >
          <v-skeleton-loader
            type="card-heading, text@2, table-tfoot"
            :types="{ 'table-tfoot': 'avatar@4' }"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      v-if="!requestableLoading && (workflowDefinitions.length < totalCount)"
      plain
      text
      small
      block
      @click="loadMore"
    >
      {{ $t('dashboard.widget.general.load_more') }}
    </v-btn>
  </div>
</template>
