<script>
import reduce from 'lodash/reduce'

export default {
  name: 'KebabMenu',
  props: {
    disabled: Boolean,
    color: {
      type: String,
      default: undefined
    },
    btnClass: {
      type: String,
      default: undefined
    },
    vMenuProps: {
      type: Object,
      default: () => { return { left: true } }
    },
    icon: {
      type: String,
      default: 'mdi-dots-vertical'
    }
  },
  data () {
    return {
      menuOpen: false
    }
  },
  computed: {
    hasSlotItems () {
      return reduce(this.$scopedSlots.items(), (res, item) => {
        return res || !!item.context
      }, false)
    }
  },
  methods: {
    closeMenu () {
      this.menuOpen = false
    }
  }
}
</script>
<template>
  <v-menu
    v-if="hasSlotItems"
    v-model="menuOpen"
    v-bind="vMenuProps"
  >
    <template #activator="{ on }">
      <slot
        name="activator"
        :on="on"
      >
        <v-btn
          :disabled="disabled"
          :color="color"
          icon
          :class="btnClass"
          v-on="on"
        >
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </slot>
    </template>

    <v-list>
      <slot
        name="items"
        :close-menu="closeMenu"
      />
    </v-list>
  </v-menu>
</template>
