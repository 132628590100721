<script>
import Vue from 'vue'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import ActivityHubItem from 'components/activity-hub/activity-hub-item.vue'
import ActivityHubItemLink from 'components/activity-hub/activity-hub-item-link.vue'

export default {
  name: 'NotificationListItem',
  components: {
    ActivityHubItem,
    ActivityHubItemLink
  },
  props: {
    notification: {
      type: Object,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    currentFilterCategory: {
      type: String,
      required: true
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      bookmarkRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (notifications) => {
            this.$emit('request-success-data', notifications)
          }
        }
      }),
      doneRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (notifications) => {
            this.$emit('request-success-data', notifications)
          }
        }
      })
    }
  },
  computed: {
    disabled () {
      return this.bookmarkRequestable.requestableLoading || this.doneRequestable.requestableLoading
    }
  },
  methods: {
    sendBookmarkRequest (id) {
      this.bookmarkRequestable.request({
        method: Request.PATCH,
        url: this.$apiEndpoints.notifications.bookmark(id),
        params: { page: this.currentPage, tab_category: this.currentFilterCategory }
      })
    },
    sendUnBookmarkRequest (id) {
      this.bookmarkRequestable.request({
        method: Request.PATCH,
        url: this.$apiEndpoints.notifications.unbookmark(id),
        params: { page: this.currentPage, tab_category: this.currentFilterCategory }
      })
    },
    sendDoneRequest (id) {
      this.doneRequestable.request({
        method: Request.PATCH,
        url: this.$apiEndpoints.notifications.done(id),
        params: { page: this.currentPage, tab_category: this.currentFilterCategory }
      })
    }
  }
}
</script>
<template>
  <!-- The replaceAll in the router-link :to is a hack and should be generalized/formalized , same in snackbars.vue/standalone-event.vue -->
  <activity-hub-item-link
    :value="notification.event.target"
    :disabled="disabled"
    :style="notification.event.target.noaccess || notification.event.target.deleted ? 'cursor: not-allowed' : ''"
  >
    <activity-hub-item
      :event="notification.event"
      :unread="!notification.read_at"
    >
      <template #actions>
        <v-hover
          v-if="!notification.bookmarked_at"
          v-slot="{ hover }"
        >
          <v-btn
            icon
            title="Merken"
            :disabled="disabled"
            :loading="bookmarkRequestable.requestableLoadingDelayed"
            @click.prevent="sendBookmarkRequest(notification.id)"
          >
            <v-icon color="grey lighten-1">
              {{ hover ? "mdi-bookmark-plus-outline" : "mdi-bookmark-outline" }}
            </v-icon>
          </v-btn>
        </v-hover>
        <v-hover
          v-if="notification.bookmarked_at"
          v-slot="{ hover }"
        >
          <v-btn
            icon
            title="Nicht mehr merken"
            :disabled="disabled"
            :loading="bookmarkRequestable.requestableLoadingDelayed"
            @click.prevent="sendUnBookmarkRequest(notification.id)"
          >
            <v-icon color="blue lighten-1">
              {{ hover ? "mdi-bookmark-minus" : "mdi-bookmark" }}
            </v-icon>
          </v-btn>
        </v-hover>
        <v-btn
          v-if="showClose"
          icon
          title="Erledigt"
          :disabled="disabled"
          :loading="doneRequestable.requestableLoadingDelayed"
          @click.prevent="sendDoneRequest(notification.id)"
        >
          <v-icon color="grey lighten-1">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </activity-hub-item>
  </activity-hub-item-link>
</template>
