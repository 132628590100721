import { stateColors } from 'helpers/definitions'

export default {
  computed: {
    dossierDefinitionBreadcrumbs () {
      return [
        { text: 'Dossiervorlagen', to: { name: 'dossier-definitions' } },
        { text: `${this.dossierDefinition.id}` }
      ]
    },
    dossierDefinitionStateText () {
      return 'Aktualisiert'
    },
    dossierDefinitionStateColor () {
      return stateColors.created
    },
    dossierDefinitionStateUpdatedAtDate () {
      return new Date(this.dossierDefinition.updatedAt)
    }
  }
}
