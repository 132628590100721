export default {
  actions: {
    create: 'Gruppe erstellen'
  },
  detailActions: {
    delete: 'Gruppe löschen'
  },
  createDialog: {
    title: 'Neue Gruppe erstellen',
    fields: {
      name: 'Name'
    },
    successMessage: 'Gruppe "{title}" wurde erfolgreich erstellt'
  },
  deleteDialog: {
    title: 'Gruppe löschen?',
    text: 'Löschen Sie die Gruppe, wenn Sie sicher sind, dass diese Gruppe nicht mehr benöigt wird. Gelöschte Gruppen sind unwiederbringlich verloren.',
    buttons: {
      ok: 'Gruppe löschen'
    }
  }
}
