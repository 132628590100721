<script>
import Menucardable from 'mixins/menucardable'
import FileListItem from 'list-items/file-list-item.vue'

export default {
  name: 'FileDeleteCardMenu',
  components: { FileListItem },
  mixins: [Menucardable],
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  methods: {
    onDelete () {
      this.$emit('delete', this.value)
      this.closeMenuCard()
    },
    onCancel () {
      this.$emit('cancel', this.value)
      this.closeMenuCard()
    },
    onMenuCardOpened () {
      this.$emit('opened')
    },
    onMenuCardClosed () {
      this.$emit('closed')
    }
  }
}
</script>
<template>
  <v-menu
    :attach="attachElement"
    :close-on-click="closeOnClick"
    :close-on-content-click="closeOnContentClick"
    :nudge-bottom="nudgeBottom"
    :value="internalVisible"
    min-width="100%"
    transition="slide-y-transition"
    z-index="1"
    @input="onMenuStateChanged"
  >
    <v-card
      v-if="value"
    >
      <div class="pt-4">
        <file-list-item
          :link="false"
          :value="value"
          class="pb-2 pt-2"
        />
      </div>
      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="onCancel"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="error"
          depressed
          @click="onDelete"
        >
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
