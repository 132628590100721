<script>
import Vue from 'vue'
import Request from 'api/request'
import BaseControl from 'controls/base-control'
import Controlable from 'mixins/controlable'
import Cacheable from 'mixins/cacheable'
import Requestable from 'mixins/requestable'
import DossierFieldDefinitionListItem from 'list-items/dossier-field-definition-list-item.vue'
import KebabMenu from 'components/kebab-menu.vue'
import AddEditDossierFieldDialog from 'dialogs/add-edit-dossier-field-dialog.vue'
import DeleteDossierFieldDefinitionDialog from 'dialogs/delete-dossier-field-definition-dialog.vue'
import { CONTENT_TYPE_NOTE, isContentTypeValueEmpty } from '../helpers/content-types'
import draggable from 'vuedraggable'
import has from 'lodash/has'
import find from 'lodash/find'

export default {
  name: 'DossierDefinitionFieldsControl',
  components: {
    BaseControl,
    DossierFieldDefinitionListItem,
    KebabMenu,
    AddEditDossierFieldDialog,
    DeleteDossierFieldDefinitionDialog,
    draggable
  },
  mixins: [Controlable, Cacheable],
  props: {
    value: {
      type: Array,
      required: true
    },
    dataTransformations: {
      type: Array,
      required: true
    },
    dossierDefinitionId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      moveRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: this.onUpdated
        }
      })
    }
  },
  computed: {
    cacheValueWithDataTransformation () {
      return this.cacheValue.map((field) => {
        return {
          ...field,
          hasDefaultValue: (field.type.name !== CONTENT_TYPE_NOTE) && !isContentTypeValueEmpty(field.type.name, field.default_value),
          dataTransformation: find(this.dataTransformations, { field: { id: field.id } }),
          dossierDefinitionId: this.dossierDefinitionId
        }
      })
    }
  },
  methods: {
    hasRequestParameter () {
      return false
    },
    onUpdated (value) {
      this.$emit('change', value)
    },
    onTransformationCreated (transformation) {
      this.$emit('transformation:created', transformation)
    },
    onPositionChanged (event) {
      if (has(event, 'moved')) {
        this.moveRequestable.request(
          { method: Request.PATCH, url: this.$apiEndpoints.dossierItemDefinitions.move(event.moved.element.id) },
          { index: event.moved.newIndex })
      }
    }
  }
}
</script>

<template>
  <base-control
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :class="controlClass"
    :error-messages="controlErrorMessages"
    :error-count="Number.MAX_VALUE"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
  >
    <v-list
      class="flex-grow-1"
      style="min-width: 0"
    >
      <draggable
        :list="cacheValueWithDataTransformation"
        handle=".handle"
        :force-fallback="true"
        :animation="200"
        @change="onPositionChanged"
      >
        <template v-for="(field, index) in cacheValueWithDataTransformation">
          <dossier-field-definition-list-item
            :key="`${field.id}_item`"
            :value="field"
            :divider="index < cacheValueWithDataTransformation.length - 1"
          >
            <template #icon>
              <v-icon class="handle">
                mdi-drag-horizontal
              </v-icon>
            </template>
            <template #actions>
              <kebab-menu btn-class="ml-4">
                <template #items="{ closeMenu }">
                  <add-edit-dossier-field-dialog
                    :value="field"
                    :dossier-definition-id="dossierDefinitionId"
                    @request-success="onUpdated"
                    @transformation:created="onTransformationCreated"
                    @open="closeMenu"
                  >
                    <template #activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-title>{{ $t('dossierFieldDefinition.actions.edit') }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </add-edit-dossier-field-dialog>

                  <delete-dossier-field-definition-dialog
                    :request-parameter="{ method: 'delete', url: $apiEndpoints.dossierItemDefinitions.destroy(field.id) }"
                    @request-success="onUpdated"
                    @open="closeMenu"
                  >
                    <template #activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-title>{{ $t('dossierFieldDefinition.actions.delete') }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </delete-dossier-field-definition-dialog>
                </template>
              </kebab-menu>
            </template>
          </dossier-field-definition-list-item>
        </template>
      </draggable>
    </v-list>

    <template #actions>
      <kebab-menu
        color="primary"
      >
        <template #activator="{ on }">
          <v-btn
            text
            color="primary"
            v-on="on"
          >
            {{ $t('dossierFieldDefinition.actions.add') }}
          </v-btn>
        </template>
        <template #items="{ closeMenu }">
          <add-edit-dossier-field-dialog
            :dossier-definition-id="dossierDefinitionId"
            @request-success="onUpdated"
            @open="closeMenu"
          >
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-title>{{ $t('dossierFieldDefinition.subActions.add.dataField') }}</v-list-item-title>
              </v-list-item>
            </template>
          </add-edit-dossier-field-dialog>
          <add-edit-dossier-field-dialog
            :dossier-definition-id="dossierDefinitionId"
            note
            @request-success="onUpdated"
            @open="closeMenu"
          >
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-title>{{ $t('dossierFieldDefinition.subActions.add.noteField') }}</v-list-item-title>
              </v-list-item>
            </template>
          </add-edit-dossier-field-dialog>
        </template>
      </kebab-menu>
    </template>
  </base-control>
</template>
