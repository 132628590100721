<script>
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import TextControl from 'controls/text-control.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'

export default {
  name: 'SettingsExternalApiContent',
  components: {
    TextControl,
    CustomDialog
  },
  mixins: [PageContentable, Requestable],
  methods: {
    activateOrReset () {
      this.request({ method: Request.POST, url: this.$apiEndpoints.externalApiAccess.create() })
    },
    deactivate () {
      this.request({ method: Request.DELETE, url: this.$apiEndpoints.externalApiAccess.destroy() })
    },
    onRequestSuccess (data) {
      this.value.api_token = data.api_token
    }
  }
}
</script>
<template>
  <div v-if="value">
    <h2 class="text-h5 font-weight-light mb-4">
      Externe API
    </h2>
    <p>
      Mit diesem Token können Sie die externe API nutzen. Sie finden die Dokumentation zur externen API
      <a
        href="/samarbeid-api-documentation"
        target="_blank"
      >
        -> hier
      </a>
      .
      <b>ACHTUNG: Die externe API ist noch NICHT stabil und kann sich jederzeit verändern.</b>
    </p>

    <p>Warnung: Geben Sie das API-Token nicht an andere Personen weiter!</p>
    <text-control
      label="API token"
      :value="value.api_token"
      :options="{type: 'like-password'}"
      :loading="requestableLoading"
      disabled
    />

    <custom-dialog
      :title="$t('user.externalApiSharing.dialogs.reset.title')"
      :text="$t('user.externalApiSharing.dialogs.reset.text')"
      :ok-btn-text="$t('user.externalApiSharing.dialogs.reset.buttons.ok')"
      @click-ok="activateOrReset"
    >
      <template #activator="{ on }">
        <v-btn
          :color="!value.api_token ? 'primary' : undefined"
          :disabled="requestableLoading || !$config.isCurrentUser(value)"
          depressed
          v-on="value.api_token ? on : undefined"
          @click="!value.api_token ? activateOrReset() : undefined"
        >
          {{ !value.api_token ? 'Aktivieren' : 'Zurücksetzen' }}
        </v-btn>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="value.api_token"
      :title="$t('user.externalApiSharing.dialogs.deactivate.title')"
      :text="$t('user.externalApiSharing.dialogs.deactivate.text')"
      :ok-btn-text="$t('user.externalApiSharing.dialogs.deactivate.buttons.ok')"
      @click-ok="deactivate"
    >
      <template #activator="{ on }">
        <v-btn
          color="primary"
          :disabled="requestableLoading || !$config.isCurrentUser(value)"
          depressed
          v-on="on"
        >
          Deaktivieren
        </v-btn>
      </template>
    </custom-dialog>
  </div>
</template>
