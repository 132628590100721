<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import TextControl from 'controls/text-control.vue'
import TextareaControl from 'controls/textarea-control.vue'

export default {
  name: 'CreateStaticPageDialog',
  components: {
    CustomDialog,
    TextControl,
    TextareaControl
  },
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            if (this.addAnother) {
              this.successMessage = this.$t('staticPage.createDialog.successMessage', { title: data.title })
              this.staticPage = {}
            } else {
              this.dialogOpen = false
              this.$emit('success', data)
            }
          }
        }
      }),
      staticPage: null
    }
  },
  computed: {
    isValid () {
      if (this.staticPage) {
        return this.staticPage.title && this.staticPage.title.trim().length > 0 &&
            this.staticPage.slug && this.staticPage.slug.trim().length > 0
      }

      return false
    }
  },
  methods: {
    onDialogOpened () {
      this.staticPage = {}

      this.createRequestable.resetRequestable()

      this.$emit('open')
    },
    onDialogClosed () {
    },
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0

      this.createRequestable.request(
        { method: Request.POST, url: this.$apiEndpoints.pages.create() },
        null,
        {
          title: this.staticPage.title,
          slug: this.staticPage.slug
        }
      )
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t('staticPage.createDialog.title')"
    fullheight
    :close-on-button-click="false"
    :ok-btn-text="$t('general.buttons.save')"
    :ok-btn-disabled="!isValid"
    :additional-ok-btn-texts="[$t('general.buttons.saveAndCreateAnother')]"
    :loading="createRequestable.requestableLoading"
    :error-message="createRequestable.baseErrorMessage"
    :success-message="successMessage"
    content-class="create-static-page-dialog"
    @click-ok="onOkButtonClicked"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="staticPage">
      <text-control
        v-model="staticPage.title"
        label="Titel"
        :hint="$t('general.field.required')"
        persistent-hint
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('title')"
        :error-count="Number.MAX_VALUE"
        class="mb-5"
      />
      <textarea-control
        v-model="staticPage.slug"
        label="Pfad"
        :hint="$t('general.field.required')"
        persistent-hint
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('slug')"
        :error-count="Number.MAX_VALUE"
      />
    </template>
  </custom-dialog>
</template>
