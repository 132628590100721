<script>
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import PageListHeader from 'components/page-list-header.vue'
import DataListFilter from 'components/data-list/filter.vue'
import SmartList from 'components/smart-list.vue'
import ObjectListItem from 'list-items/object-list-item.vue'
import CreateStaticPageDialog from 'dialogs/create-static-page-dialog.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'StaticPageListContent',
  components: {
    PageListHeader,
    DataListFilter,
    SmartList,
    ObjectListItem,
    CreateStaticPageDialog
  },
  mixins: [PageContentable, ListPageContentable],
  computed: {
    result () {
      return this.value ? this.value.result : undefined
    }
  },
  methods: {
    ...{ routeFor },
    onCreated (staticPage) {
      this.$router.push(routeFor('static_page', staticPage.id))
    }
  }
}
</script>
<template>
  <div>
    <create-static-page-dialog @success="onCreated">
      <template #activator="{ on }">
        <page-list-header
          title="Statische Seiten"
          :primary-action-title="$t('staticPage.actions.create')"
          v-on="on"
        />
      </template>
    </create-static-page-dialog>

    <data-list-filter
      v-model="filters"
      @update:filter="$emit('update:prop', { prop: 'filters', value: $event.value, info: $event.field })"
    />

    <smart-list
      v-slot="{ item }"
      :value="result"
      :loading="pageRequestLoading"
    >
      <object-list-item
        :value="{
          title: item.title,
          subtitleElements: [item.slug]
        }"
        :to="routeFor('static_page', item.id)"
        indent
        :dense="false"
        divider
      />
    </smart-list>

    <v-pagination
      v-if="!pageRequestLoading && result && pages.total() > 1"
      v-model="pages.current"
      :length="pages.total()"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'pages', value: pages })"
    />
  </div>
</template>
