import Vue from 'vue'
import Requestable from './requestable'
import findIndex from 'lodash/findIndex'
import reduce from 'lodash/reduce'
import Request from '../api/request'

export default {
  data () {
    return {
      dataFieldsItems: null,
      dataFieldsLockedItems: [],
      dataFieldsDeferredFunc: undefined,
      dataFieldsErrorDialogOpen: false,
      dataFieldsLoadFieldsRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.dataFieldsItems = data.dataFields
            this.dataFieldsOnFieldsLoaded()
          }
        }
      })
    }
  },
  methods: {
    dataFieldsOnDataFieldBeforeLock (dataField) {
      if (this.dataFieldsFindIndexOfDataField(this.dataFieldsLockedItems, dataField) === -1) this.dataFieldsLockedItems.push(dataField)
    },
    dataFieldsOnDataFieldLocked (dataField, lockVersion) {
      dataField.lock_version = lockVersion
    },
    dataFieldsOnDataFieldUnlocked (dataField, lockVersion) {
      if (lockVersion) dataField.lock_version = lockVersion

      const index = this.dataFieldsFindIndexOfDataField(this.dataFieldsLockedItems, dataField)
      if (index > -1) this.dataFieldsLockedItems.splice(index, 1)
    },
    dataFieldsOnMonitorMessage (data, doUpdateDataFieldRequestFunc) {
      switch (data.action) {
        case 'data_item_locked':
        case 'data_item_unlocked':
        case 'data_item_updated':
          if (this.dataFieldsFindIndexOfDataField(this.dataFieldsLockedItems, { definition: { task_item_id: data.id } }) === -1) {
            doUpdateDataFieldRequestFunc(data.id)
            return true
          }
          break
        default:
          return false
      }
    },
    dataFieldsOnDataFieldUpdated (dataField, data) {
      this.dataFieldsOnDataFieldUnlocked(dataField, null)
      this.dataFieldsUpdateDataField(data.dataField)

      if (this.dataFieldsDeferredFunc) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.dataFieldsExecFuncWithDataErrorCheck(this.dataFieldsDeferredFunc)
          }, 100)
        })
      }
    },
    dataFieldsOnFieldsLoaded () {},
    dataFieldsControlRef (dataField) {
      return this.dataFieldsControlRefPrefix + dataField.definition.task_item_id
    },
    dataFieldsExecFuncWithDataErrorCheck (callback) {
      if (this.dataFieldsItems) {
        if (this.dataFieldsCheckState('controlLoading')) {
          this.dataFieldsDeferredFunc = callback
        } else {
          this.dataFieldsDeferredFunc = undefined
          this.dataFieldsCheckErrors() && callback()
        }
      }
    },
    dataFieldsCheckErrors () {
      if (this.dataFieldsItems && this.dataFieldsCheckState('controlHasError')) {
        this.dataFieldsErrorDialogOpen = true
        return false
      } else {
        return true
      }
    },
    dataFieldsLoadFields (id) {
      this.dataFieldsLoadFieldsRequestable.request(
        { method: Request.GET, url: this.$apiEndpoints.dataItems.index() }, { task_id: id }, null, true
      )
    },
    dataFieldsLoadField (id) {
      const requestable = new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.dataFieldsUpdateDataField(data)
          }
        }
      })
      requestable.request({ method: Request.GET, url: this.$apiEndpoints.dataItems.show(id) }, null, null, true)
    },
    dataFieldsUpdateDataField (dataField) {
      const index = this.dataFieldsFindIndexOfDataField(this.dataFieldsItems, dataField)
      if (index > -1) this.dataFieldsItems.splice(index, 1, dataField)
    },
    dataFieldsFindIndexOfDataField (dataFields, dataField) {
      return findIndex(dataFields, { definition: { task_item_id: dataField.definition.task_item_id } })
    },
    dataFieldsCheckState (stateProperty) {
      return reduce(this.dataFieldsItems, (res, dataField) => {
        const ref = this.dataFieldsControlRef(dataField)
        return res || (!!this.$refs[ref] && !!this.$refs[ref][0] && this.$refs[ref][0][stateProperty])
      }, false)
    }
  },
  computed: {
    dataFieldsControlRefPrefix () {
      return 'data_control_for_field_'
    }
  }
}
