<script>
import { PageContentable } from '../page'
import TextControl from 'controls/text-control.vue'
import RichtextareaControl from 'controls/richtextarea-control.vue'
import SelectionControl from 'controls/selection-control.vue'
import DossierDefinitionFieldsControl from 'controls/dossier-definition-fields-control.vue'
import MonitorSubscription from 'components/monitor-subscription.vue'
import ActivityHub from 'components/activity-hub/index.vue'
import globalIdFor from 'helpers/global-id'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossierDefinitionEditMainContent',
  components: {
    TextControl,
    RichtextareaControl,
    SelectionControl,
    DossierDefinitionFieldsControl,
    MonitorSubscription,
    ActivityHub
  },
  mixins: [PageContentable],
  computed: {
    dossierDefinition () {
      return this.value
    },
    gid () {
      return globalIdFor('DossierDefinition', this.pageProps.id)
    }
  },
  methods: {
    onUpdated (value) {
      this.$emit('input', value)
    },
    onTransformationCreated (transformation) {
      this.value.dataTransformations.push(transformation)
      this.$emit('input', this.value)
      this.$router.push(routeFor('dossier_data_transformation_detail', this.dossierDefinition.id, { params: { dataTransformationId: transformation.id } }))
    }
  }
}
</script>
<template>
  <div>
    <template v-if="value">
      <monitor-subscription
        :key="`monitor-channel-${gid}`"
        :gid="gid"
      />

      <text-control
        v-model="value.name"
        label="Name"
        :request-parameter="{method: 'patch', url: $apiEndpoints.dossierDefinitions.update(value.id), mapping: 'name'}"
        class="mb-5"
        @request-success-data="onUpdated"
      />

      <richtextarea-control
        v-model="value.description"
        label="Beschreibung"
        :request-parameter="{method: 'patch', url: $apiEndpoints.dossierDefinitions.update(value.id), mapping: 'description'}"
        class="mb-5"
        @request-success-data="onUpdated"
      />

      <selection-control
        v-model="value.titleFields"
        label="Datenfelder für den Titel"
        :options="{ items: value.fields, multiple: true }"
        item-text="name"
        item-value="id"
        :deletable-chips="false"
        sortable-chips
        :hint="$t('general.sortableHint')"
        persistent-hint
        :request-parameter="{method: 'patch', url: $apiEndpoints.dossierDefinitions.update(value.id), mapping: 'title_fields'}"
        class="mb-5"
        @request-success-data="onUpdated"
      />

      <selection-control
        v-model="value.subtitleFields"
        label="Datenfelder für den Subtitel"
        :options="{ items: value.fields, multiple: true }"
        item-text="name"
        item-value="id"
        :deletable-chips="false"
        sortable-chips
        :hint="$t('general.sortableHint')"
        persistent-hint
        :request-parameter="{method: 'patch', url: $apiEndpoints.dossierDefinitions.update(value.id), mapping: 'subtitle_fields'}"
        class="mb-5"
        @request-success-data="onUpdated"
      />

      <dossier-definition-fields-control
        v-model="value.fields"
        :data-transformations="value.dataTransformations"
        :dossier-definition-id="value.id"
        label="Datenfelder"
        class="mb-5"
        @change="onUpdated"
        @transformation:created="onTransformationCreated"
      />
    </template>

    <activity-hub
      v-if="gid"
      :key="`activity-hub-${gid}`"
      :gid="gid"
      :groups="(dossierDefinition || {}).groups"
      :editor-hint="$t('dossierDefinition.editorHint')"
      class="mt-12"
      @loaded="pageDataLoaded"
    />
  </div>
</template>
