<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'

export default {
  name: 'SelectControl',
  components: { ResetBtn },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: [String, Number],
      default: undefined
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('select', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>

<template>
  <v-select
    v-model="cacheValue"
    :label="label"
    :items="items"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :readonly="controlReadonly"
    :class="controlClass"
    :error-count="Number.MAX_VALUE"
    :error-messages="controlErrorMessages"
    :hint="hint"
    :persistent-hint="persistentHint"
    :success-messages="controlSuccessMessage"
    @change="controlOnChange"
  >
    <template
      v-if="!hideAppend"
      #append
    >
      <reset-btn
        v-if="cachedValueChanged && controlHasError"
        @click="controlReset"
      />
    </template>
  </v-select>
</template>
