import ListPage from '../list-page'
import ListContent from './list-content.vue'

export default {
  name: 'UserListPage',
  mixins: [ListPage],
  computed: {
    pageTitleParts () {
      return ['Nutzer:in']
    },
    pageContentComponents () {
      return ListContent
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.users.index()
    },
    initPageRequestParams () {
      return {
        page: this.pages.current,
        query: this.filters.values.query,
        order: this.filters.values.order,
        tab_category: this.filters.values.tab_category,
        activation_state: this.filters.values.activation_state,
        confirmation_state: this.filters.values.confirmation_state
      }
    },
    filterTabs () {
      return {
        name: 'tab_category',
        items: [
          { text: 'Alle', value: 'ALL' },
          { text: 'Standardnutzer:innen', value: 'NO_ADMIN' },
          { text: 'Administrator:innen', value: 'ADMIN' }
        ],
        counts: this.valueAttributeOrDefault('tab_categories', {}),
        default: 'ALL'
      }
    },
    filterFields () {
      return [
        [
          {
            name: 'query',
            type: 'text',
            label: 'Name / E-Mail',
            default: ''
          },
          {
            name: 'order',
            type: 'single-select',
            label: 'Sortierung',
            items: [
              { text: 'Name – aufsteigend', value: 'name_asc' },
              { text: 'Name – absteigend', value: 'name_desc' },
              { text: 'Erstellungsdatum – neueste zuerst', value: 'created_at_desc' },
              { text: 'Erstellungsdatum – älteste zuerst', value: 'created_at_asc' }
            ],
            default: 'name_asc',
            cols: 4
          }],
        [
          {
            name: 'activation_state',
            type: 'single-select',
            label: 'Status Aktivierung',
            items: [
              { text: 'Aktiviert', value: 'activated' },
              { text: 'Deaktiviert', value: 'deactivated' },
              { text: 'Alle', value: 'all' }
            ],
            default: 'activated'
          },
          {
            name: 'confirmation_state',
            type: 'single-select',
            label: 'Status Bestätigung',
            items: [
              { text: 'Bestätigt', value: 'confirmed' },
              { text: 'Unbestätigt', value: 'unconfirmed' },
              { text: 'Alle', value: 'all' }
            ],
            default: 'all'
          }
        ]
      ]
    }
  }
}
