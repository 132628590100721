<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'

export default {
  name: 'DeleteDialog',
  components: { CustomDialog },
  mixins: [Requestable],
  props: {
    ...requestablePropFactory().props,
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      dialogOpen: false
    }
  },
  methods: {
    onDialogOpened () {
      this.resetRequestable()

      this.$emit('open')
    },
    startRequest () {
      this.resetRequestable()
      this.request(this.requestParameter)
    },
    onRequestSuccess (data) {
      this.dialogOpen = false

      this.$emit('request-success', data)
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="title"
    :text="text"
    :ok-btn-text="$t('general.buttons.delete')"
    :close-on-button-click="false"
    ok-btn-color="error"
    :loading="requestableLoading"
    :error-message="errorMessage"
    @click-ok="startRequest"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
  </custom-dialog>
</template>
