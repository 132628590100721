import Page from '../page'
import EditHeader from './edit-header.vue'
import EditMainContent from './edit-main-content.vue'
import EditGroupsContent from './edit-groups-content.vue'
import EditReferencesContent from './edit-references-content.vue'
import EditDataTransformationsContent from './edit-data-transformations-content.vue'
import EditDataTransformationDetailContent from './edit-data-transformation-detail-content.vue'
import EditSidebarLeft from './edit-sidebar-left.vue'

export default {
  name: 'DossierDefinitionEditPage',
  mixins: [Page],
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    dataTransformationId: {
      type: Number,
      required: false
    }
  },
  computed: {
    pageTitleParts () {
      return [...(this.value ? [this.value.name] : []), 'Dossiervorlagen']
    },
    pageContentComponents () {
      const components = [EditHeader]

      switch (this.type) {
        case 'main':
          components.push(EditMainContent)
          break
        case 'groups':
          components.push(EditGroupsContent)
          break
        case 'references':
          components.push(EditReferencesContent)
          break
        case 'data-transformations':
          components.push(EditDataTransformationsContent)
          break
        case 'data-transformation-detail':
          components.push(EditDataTransformationDetailContent)
          break
      }

      return components
    },
    pageSidebarLeftComponents () {
      return EditSidebarLeft
    },
    pagePropsToWatch () {
      return this.id
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.dossierDefinitions.show(this.id)
    }
  }
}
