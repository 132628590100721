<script>
import { PageContentable } from '../page'
import UsersControl from 'controls/users-control.vue'
import UsersAndGroupsControl from 'controls/users-and-groups-control.vue'
import SwitchControl from 'controls/switch-control.vue'
import DateControl from 'controls/date-control.vue'
import VisibilitySummary from 'components/visibility-summary.vue'
import TimeTrackingWidget from 'components/time-tracking-widget.vue'
import ShareWidget from 'components/share-widget.vue'
import MentioningHint from 'components/mentioning-hint.vue'
import DesignModeBtn from 'components/design-mode-btn.vue'
import Workflow from 'mixins/models/workflow'
import Task from 'mixins/models/task'
import map from 'lodash/map'

export default {
  name: 'TaskEditSidebarRight',
  components: {
    UsersControl,
    UsersAndGroupsControl,
    SwitchControl,
    DateControl,
    VisibilitySummary,
    TimeTrackingWidget,
    ShareWidget,
    MentioningHint,
    DesignModeBtn
  },
  mixins: [PageContentable, Workflow, Task],
  props: {
    designMode: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    workflow () {
      return this.value ? this.value.workflow : null
    },
    task () {
      return this.value ? this.value.task : null
    },
    group_ids () { return map(this.workflow.groups, 'id') }
  },
  methods: {
    onUpdated (workflow) {
      this.$emit('input', {
        workflow,
        task: this.workflowGetTaskWithIdFor(workflow, this.task.id)
      })
    }
  }
}
</script>
<template>
  <div
    v-if="value && taskIsVariantFull"
    :key="`task-${task.id}`"
  >
    <users-control
      v-model="task.assignee"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.tasks.updateAssignee(task.id), mapping: 'assignee'}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.users.list(), params: {group_ids: group_ids}}"
      label="Verantwortlich"
      success-message=""
      single
      indent
      sidebar
      @request-success-data="onUpdated"
    />
    <v-divider />

    <users-and-groups-control
      v-model="task.contributors"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.tasks.updateContributors(task.id), mapping: 'contributors'}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.usersAndGroups.list(), params: {group_ids: group_ids}}"
      label="Teilnehmend"
      success-message=""
      avatar-list-item
      indent
      sidebar
      @request-success-data="onUpdated"
    />
    <v-divider />

    <date-control
      v-model="task.dueAt"
      :request-parameter="{method: 'patch', url: $apiEndpoints.tasks.updateDueDate(task.id), mapping: 'due_at'}"
      label="Fälligkeitsdatum"
      success-message=""
      sidebar
      @request-success-data="onUpdated"
    />
    <v-divider />

    <date-control
      v-model="task.snoozeUntil"
      :request-parameter="{method: 'patch', url: $apiEndpoints.tasks.snooze(task.id), mapping: 'snooze_until'}"
      label="Zurückstellen bis"
      success-message=""
      sidebar
      @request-success-data="onUpdated"
    />
    <v-divider />

    <visibility-summary :value="workflow.groups" />
    <v-divider />

    <time-tracking-widget
      :value="task.timeTracking"
      :task-id="task.id"
      :task-gid="task.gid"
      @updated="onUpdated"
    />
    <v-divider />

    <share-widget
      :value="task.shares"
      :task-id="task.id"
      @updated="onUpdated"
    />
    <v-divider />

    <switch-control
      v-model="task.marked"
      :request-parameter="{method: 'patch', url: $apiEndpoints.tasks.updateMarked(task.id), mapping: 'marked'}"
      label="Meine Merkliste"
      success-message=""
      sidebar
    />
    <v-divider />

    <mentioning-hint
      :identifier="taskIdentifier"
      path="task.mentioningHint"
    />
    <v-divider />

    <design-mode-btn
      :value="designMode"
      @input="$emit('update:prop', { prop: 'designMode', value: $event })"
    />
  </div>
</template>
