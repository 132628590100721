import Vue from 'vue'
import isNil from 'lodash/isNil'

Vue.mixin({
  beforeCreate () {
    const options = this.$options
    if (options.config) { this.$config = options.config } else if (options.parent && options.parent.$config) { this.$config = options.parent.$config }
  }
})

const configStr = document.getElementById('vue-config')?.textContent
const configJson = !isNil(configStr) ? JSON.parse(configStr) : null
const config = !isNil(configJson)
  ? {
      current_user: configJson.current_user,
      share_token: configJson.share_token,
      menus: configJson.menus,
      globalIdApp: configJson.global_id_app,
      sentry_dsn: configJson.sentry_dsn,
      host: configJson.host,
      release: configJson.release,
      flags: {
        revisionHuman: configJson.flags.revision_human,
        showExtendedVersionInfo: configJson.flags.show_extended_version_info,
        enableExperimentalFeatures: configJson.flags.enable_experimental_features,
        systemProcessDefinitionSingleTaskAccess: configJson.flags.system_process_definition_single_task_access
      },
      isCurrentUser: function (user) {
        return configJson.current_user && user && user.id === configJson.current_user.id
      },
      isAuthenticated: function () {
        return !!configJson.current_user
      }
    }
  : null

Vue.use({
  install: function (Vue, options) {
    Vue.$config = config
  }
})

export default config
