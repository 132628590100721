<script>
export default {
  name: 'AboutContent'
}
</script>
<template>
  <div>
    <h1 class="text-h4 font-weight-light mt-4">
      Über samarbeid
    </h1>
    <p class="mt-4">
      Samarbeid ist ein Open-Source-Werkzeug für die prozessorientierte, flexible Zusammenarbeit in Teams.
    </p>
    <ul v-if="$config.host || $config.release">
      <li v-if="$config.host">
        Instanz: {{ $config.host }}
      </li>
      <li v-if="$config.release">
        Version: {{ $config.release }}
      </li>
    </ul>
    <p class="mt-4">
      Mehr Infos zu samarbeid auf <a
        href="https://www.samarbeid.org"
        target="_blank"
      >samarbeid.org</a>
    </p>
  </div>
</template>
