<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import SelectList from 'components/select-list.vue'
import DossierDefinitionListItem from 'list-items/dossier-definition-list-item.vue'
import DataControl from 'controls/data-control'
import RichtextareaContent from 'components/richtextarea-content.vue'
import { isContentTypeValueEmpty, updateValueForContentType } from 'helpers/content-types'
import reduce from 'lodash/reduce'
import indexOf from 'lodash/indexOf'

export default {
  name: 'CreateDossierDialog',
  components: {
    CustomDialog,
    SelectList,
    DossierDefinitionListItem,
    DataControl,
    RichtextareaContent
  },
  props: {
    definition: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      currentStep: undefined,
      selectedDefinition: undefined,
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            if (this.addAnother) {
              this.$emit('created', data)
              this.successMessage = this.$t('dossier.createDialog.successMessage', { title: data.referenceLabel })
              this.loadNewDossier()
            } else {
              this.dialogOpen = false
              this.$emit('created', data)
              this.$emit('success', data)
            }
          }
        }
      }),
      newDossierRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (dossier) => {
            this.dossier = dossier
          }
        }
      }),
      dossier: null
    }
  },
  computed: {
    title () {
      return this.selectedDefinition?.name || this.$t('dossier.createDialog.title')
    },
    errorMessage () {
      return this.createRequestable.baseErrorMessage || this.newDossierRequestable.baseErrorMessage
    },
    dossierIsValid () {
      if (this.dossier) {
        return reduce(this.dossier.dataFields, function (result, field) {
          if (field.definition.required) result &= !isContentTypeValueEmpty(field.definition.type, field.value)
          return result
        }, true)
      }

      return false
    },
    steps () {
      return (this.definition === undefined) ? [1, 2] : [2]
    },
    nextStep () {
      const index = indexOf(this.steps, this.currentStep)
      return (index > -1 && (this.steps.length > (index + 1))) ? this.steps[index + 1] : undefined
    },
    prevStep () {
      const index = indexOf(this.steps, this.currentStep)
      return (index > -1 && (index > 0)) ? this.steps[index - 1] : undefined
    }
  },
  methods: {
    onDialogOpened () {
      this.currentStep = undefined
      this.selectedDefinition = this.definition
      this.setStep('initial')
      this.$emit('open')
    },
    onDialogClosed () {
      this.$refs.selectList?.cancel()
      this.newDossierRequestable.cancelRequestable()
    },
    setStep (div) {
      let nextStep

      switch (div) {
        case 'initial':
          nextStep = this.steps[0] || this.currentStep
          break
        case 'next':
          nextStep = this.nextStep || this.currentStep
          break
        case 'prev':
          nextStep = this.prevStep || this.currentStep
          break
      }

      if (nextStep !== this.currentStep) {
        this.currentStep = nextStep

        this.$refs.selectList?.reset()
        this.createRequestable.resetRequestable()
        this.newDossierRequestable.resetRequestable()

        this.successMessage = undefined

        if (this.currentStep === 1) {
          this.selectedDefinition = undefined
        } else if (this.currentStep === 2) {
          this.loadNewDossier()
        }
      }
    },
    loadNewDossier () {
      this.newDossierRequestable.request({ method: Request.GET, url: this.$apiEndpoints.dossiers.new() },
        { dossier_definition_id: this.selectedDefinition.id }, null, true)
    },
    selectAndShowDefinitionDetails (definition) {
      this.selectedDefinition = definition

      this.setStep('next')
    },
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0
      /*
      TODO: We use setTimeout here to wait for the dossier-control to be closed and update the dossier value.
            This is kind of a hack and it would be better to have a better solution
       */
      setTimeout(() => {
        this.createRequestable.request(
          { method: Request.POST, url: this.$apiEndpoints.dossiers.create() },
          null,
          {
            dossier_definition_id: this.selectedDefinition.id,
            field_data: reduce(this.dossier.dataFields, function (result, field) {
              result[field.definition.id] = updateValueForContentType(field.definition.type, field.value)
              return result
            }, {})
          }
        )
      }, 50)
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="title"
    fullheight
    :close-on-button-click="false"
    :cancel-btn-text="prevStep === undefined ? undefined : $t('general.buttons.back')"
    :ok-btn-text="nextStep === undefined ? $t('general.buttons.save') : null"
    :ok-btn-disabled="!dossierIsValid"
    :additional-ok-btn-texts="[$t('general.buttons.saveAndCreateAnother')]"
    :loading="createRequestable.requestableLoading"
    :error-message="errorMessage"
    :success-message="successMessage"
    content-class="create-dossier-dialog"
    @click-ok="onOkButtonClicked"
    @click-cancel="prevStep === undefined ? dialogOpen = false : setStep('prev')"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <v-window
      v-model="currentStep"
      style="overflow: visible; height: 100%;"
    >
      <v-window-item
        :value="1"
        style="height: 100%;"
      >
        <select-list
          ref="selectList"
          v-slot="{ item, itemKey }"
          :request-parameter="{ method: 'get', url: $apiEndpoints.dossierDefinitions.list(), params: { filter_by_groups_only: true} }"
          add-only
          class="fill-height"
        >
          <dossier-definition-list-item
            :key="itemKey"
            :value="item"
            @click="selectAndShowDefinitionDetails(item)"
          />
        </select-list>
      </v-window-item>
      <v-window-item :value="2">
        <div v-if="selectedDefinition">
          <richtextarea-content :template="selectedDefinition.description" />
          <div class="mt-6">
            <template v-if="!newDossierRequestable.requestableLoading && dossier">
              <data-control
                v-for="(dataField, index) in dossier.dataFields"
                :key="dataField.definition.id"
                v-model="dataField.value"
                :type="dataField.definition.type"
                :label="dataField.definition.name"
                :required="dataField.definition.required"
                :options="dataField.definition.options"
                :error-messages="createRequestable.validationErrorMessageFor(`field_${dataField.definition.id}`)"
                :class="{'mb-5': index < dossier.dataFields.length - 1}"
                child-of-dialog
              />
            </template>
            <div
              v-else
              class="text-center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
              />
            </div>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </custom-dialog>
</template>
