<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import TextControl from 'controls/text-control.vue'

export default {
  name: 'CreateShareDialog',
  components: {
    CustomDialog,
    TextControl
  },
  props: {
    taskId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      dialogOpen: false,
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.dialogOpen = false
            this.$emit('success', data)
          }
        }
      }),
      share: null
    }
  },
  methods: {
    onDialogOpened () {
      this.share = { name: undefined }

      this.createRequestable.resetRequestable()

      this.$emit('open')
    },
    onDialogClosed () {
    },
    onOkButtonClicked (index) {
      this.createRequestable.request(
        { method: Request.POST, url: this.$apiEndpoints.tasks.createShare(this.taskId) }, null, this.share
      )
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t('task.widgets.share.dialogs.create.title')"
    fullheight
    :close-on-button-click="false"
    :ok-btn-text="$t('task.widgets.share.dialogs.create.buttons.ok')"
    :loading="createRequestable.requestableLoading"
    :error-message="createRequestable.baseErrorMessage"
    content-class="create-share-dialog"
    @click-ok="onOkButtonClicked"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="share">
      <div class="mb-5">
        {{ $t('task.widgets.share.dialogs.create.text') }}
      </div>
      <text-control
        v-model="share.name"
        :label="$t('task.widgets.share.dialogs.create.fields.name.label')"
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('name')"
        :error-count="Number.MAX_VALUE"
      />
    </template>
  </custom-dialog>
</template>
