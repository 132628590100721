<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import TextControl from 'controls/text-control.vue'
import ScheduleControl from 'controls/schedule-control.vue'
import ObjectsControl from 'controls/objects-control/index.vue'
import ProcessDefinitionListItem from 'list-items/process-definition-list-item.vue'
import UsersControl from 'controls/users-control.vue'
import { routeFor } from 'helpers/route'
import pick from 'lodash/pick'

export default {
  name: 'CreateAutomationDialog',
  components: {
    UsersControl,
    ObjectsControl,
    ProcessDefinitionListItem,
    ScheduleControl,
    CustomDialog,
    TextControl
  },
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      validationRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            if (!this.automation) {
              this.automation = data
            } else {
              this.automation.schedule = data.schedule
            }
          }
        }
      }),
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            if (this.addAnother) {
              this.successMessage = this.$t('automation.createDialog.successMessage', { title: data.title })
              this.automation = null
              this.validateAutomation()
            } else {
              this.dialogOpen = false
              this.$emit('success', data)
            }
          }
        }
      }),
      automation: null
    }
  },
  computed: {
    isValid () {
      if (this.automation) {
        return this.automation.title && this.automation.title.trim().length > 0 &&
            this.automation.workflowDefinition?.id &&
            this.automation.schedule && !this.validationRequestable.validationErrorFor('schedule', true)
      }

      return false
    }
  },
  methods: {
    ...{ routeFor },
    validateAutomation () {
      this.validationRequestable.request(
        { method: Request.POST, url: this.$apiEndpoints.automations.validateSchedule() },
        null, { schedule: pick(this.automation?.schedule, ['start', 'interval', 'rule', 'validations']) }, true)
    },
    onDialogOpened () {
      this.validationRequestable.resetRequestable()
      this.createRequestable.resetRequestable()
      this.successMessage = undefined
      this.automation = null

      this.validateAutomation()

      this.$emit('open')
    },
    onDialogClosed () {
    },
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0

      this.createRequestable.request(
        { method: Request.POST, url: this.$apiEndpoints.automations.create() },
        null,
        {
          title: this.automation.title,
          workflow_definition_id: this.automation.workflowDefinition.id,
          candidate_assignee_id: this.automation.candidateAssignee?.id,
          schedule: this.automation.schedule
        }
      )
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t('automation.createDialog.title')"
    fullheight
    :close-on-button-click="false"
    :ok-btn-text="$t('general.buttons.save')"
    :ok-btn-disabled="!isValid"
    :additional-ok-btn-texts="[$t('general.buttons.saveAndCreateAnother')]"
    :loading="createRequestable.requestableLoading"
    :error-message="createRequestable.baseErrorMessage"
    :success-message="successMessage"
    content-class="create-automation-dialog"
    @click-ok="onOkButtonClicked"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="automation">
      <text-control
        v-model="automation.title"
        :label="$t('automation.fields.title')"
        :hint="$t('general.field.required')"
        persistent-hint
        :disabled="createRequestable.requestableLoadingDelayed"
        :error-messages="createRequestable.validationErrorMessageFor('title')"
        :error-count="Number.MAX_VALUE"
        class="mb-5"
      />

      <objects-control
        v-model="automation.workflowDefinition"
        :list-request-parameter="{method: 'get', url: $apiEndpoints.workflowDefinitions.list(), params: {except: 'single_task'}}"
        :label="$t('automation.fields.workflowDefinition')"
        :hint="$t('general.field.required')"
        persistent-hint
        :disabled="createRequestable.requestableLoadingDelayed"
        :error-messages="createRequestable.validationErrorMessageFor('workflow_definition_id')"
        :error-count="Number.MAX_VALUE"
        single
        :allow-empty="false"
        class="mb-5"
      >
        <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
          <process-definition-list-item
            :key="item.id"
            :indent="indent"
            :item-link="itemLink"
            :selectable="selectable"
            :selected="selected"
            :skeleton="item.skeleton"
            :value="item"
            :to="routeFor('process_definition', item.id)"
            v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
          />
        </template>
      </objects-control>

      <users-control
        v-model="automation.candidateAssignee"
        :label="$t('automation.fields.candidateAssignee')"
        :list-request-parameter="{method: 'get', url: $apiEndpoints.users.list()}"
        single
        :disabled="createRequestable.requestableLoadingDelayed"
        :error-messages="createRequestable.validationErrorMessageFor('candidate_assignee_id')"
        :error-count="Number.MAX_VALUE"
        class="mb-5"
      />

      <schedule-control
        v-model="automation.schedule"
        :active="automation.active"
        :label="$t('automation.fields.schedule')"
        :hint="$t('general.field.required')"
        persistent-hint
        :disabled="createRequestable.requestableLoadingDelayed || validationRequestable.requestableLoadingDelayed"
        :error-messages="createRequestable.validationErrorFor('schedule', true) || validationRequestable.validationErrorFor('schedule', true)"
        :error-count="Number.MAX_VALUE"
        @change="validateAutomation"
      />
    </template>
  </custom-dialog>
</template>
