import Page from '../page'
import EditHeader from './edit-header.vue'
import EditContentProcess from './edit-content-process.vue'
import EditContentTask from './edit-content-task.vue'
import EditContentBlock from './edit-content-block.vue'
import EditSidebarLeft from './edit-sidebar-left.vue'

export default {
  name: 'ProcessDefinitionEditPage',
  mixins: [Page],
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    subId: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    pageTitleParts () {
      return [...(this.value ? [this.value.name] : []), 'Prozessvorlagen']
    },
    pageContentComponents () {
      const components = [EditHeader]

      switch (this.type) {
        case 'process-definition':
          components.push(EditContentProcess)
          break
        case 'task-definition':
          components.push(EditContentTask)
          break
        case 'block-definition':
          components.push(EditContentBlock)
      }

      return components
    },
    pageSidebarLeftComponents () {
      return EditSidebarLeft
    },
    pageComponentProps () {
      return { type: this.type, subId: this.subId }
    },
    pagePropsToWatch () {
      return this.id
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.workflowDefinitions.show(this.id)
    }
  }
}
