export default {
  actions: {
    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    delete: 'Löschen'
  },
  subActions: {
    add: {
      newDataField: 'Neues Datenfeld hinzufügen',
      existingDataField: 'Bestehendes Datenfeld hinzufügen',
      noteField: 'Hinweisfeld hinzufügen'
    },
    edit: {
      dataField: 'Datenfeld bearbeiten',
      noteField: 'Hinweisfeld bearbeiten'
    }
  },
  deleteDialog: {
    title: 'Feld aus Prozessvorlage löschen?',
    text: 'Löschen Sie das Feld aus der Prozessvorlage nur, wenn Sie sicher sind, dass dieses Feld nicht mehr benöigt wird. Gelöschte Felder und ihre Werte gehen unwiederbringlich verloren.',
    buttons: {
      ok: 'Feld löschen'
    }
  }
}
