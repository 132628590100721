<script>
import { routeFor } from 'helpers/route'

export default {
  name: 'VisibilitySummary',
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...{ routeFor }
  }
}
</script>
<template>
  <div class="px-4 py-2 text-body-2 text--secondary">
    Sichtbar für
    <template v-if="value.length > 0">
      <span
        v-for="(group, index) in value"
        :key="group.id"
      >
        <router-link
          :to="routeFor('group', group.id)"
          class="object-link text-subtitle-2 text--primary"
        >{{ group.name }}</router-link><template v-if="index === value.length - 2"> und </template><template v-else-if="index < value.length - 2">, </template><template v-else>. </template>
      </span>
    </template>
    <template v-else>
      alle.
    </template>
  </div>
</template>
