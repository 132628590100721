import Vue from 'vue'
import VueI18n from 'vue-i18n'
import generalDE from 'i18n/modules/general.de'
import errorDE from 'i18n/modules/error.de'
import workflowDE from 'i18n/modules/workflow.de'
import taskDE from 'i18n/modules/task.de'
import blockDE from 'i18n/modules/block.de'
import dataFieldDE from 'i18n/modules/data-field.de'
import dossierDE from 'i18n/modules/dossier.de'
import groupDE from 'i18n/modules/group.de'
import userDE from 'i18n/modules/user.de'
import controlDE from 'i18n/modules/control.de'
import componentsDE from 'i18n/modules/components.de'
import processDefinitionDE from 'i18n/modules/process-definition.de'
import taskFieldDefinitionDE from 'i18n/modules/task-field-definition.de'
import dossierDefinitionDE from 'i18n/modules/dossier-definition.de'
import dossierFieldDefinitionDE from 'i18n/modules/dossier-field-definition.de'
import dataTransformationDe from 'i18n/modules/data-transformation.de'
import staticPageDE from 'i18n/modules/static-page.de'
import footerMenuEntryDE from 'i18n/modules/footer-menu-entry.de'
import mainMenuEntryDE from 'i18n/modules/main-menu-entry.de'
import dashboardDE from 'i18n/modules/dashboard.de'
import activityHubDE from 'i18n/modules/activity-hub.de'
import siteHeaderDE from 'i18n/modules/site-header.de'
import timeTrackingDE from 'i18n/modules/time-tracking.de'
import automationDE from 'i18n/modules/automation.de'
import shareDe from 'i18n/modules/share.de'

Vue.use(VueI18n)

const messages = {
  de: {
    general: generalDE,
    error: errorDE,
    workflow: workflowDE,
    task: taskDE,
    block: blockDE,
    dataField: dataFieldDE,
    dossier: dossierDE,
    group: groupDE,
    user: userDE,
    control: controlDE,
    components: componentsDE,
    processDefinition: processDefinitionDE,
    taskFieldDefinition: taskFieldDefinitionDE,
    dossierDefinition: dossierDefinitionDE,
    dossierFieldDefinition: dossierFieldDefinitionDE,
    dataTransformation: dataTransformationDe,
    staticPage: staticPageDE,
    footerMenuEntry: footerMenuEntryDE,
    mainMenuEntry: mainMenuEntryDE,
    dashboard: dashboardDE,
    activityHub: activityHubDE,
    siteHeader: siteHeaderDE,
    timeTracking: timeTrackingDE,
    automation: automationDE,
    share: shareDe
  }
}

const defaultI18n = new VueI18n({
  locale: 'de',
  messages
})

export default defaultI18n
