export default {
  actions: {
    create: 'Dossier erstellen',
    export: 'Aktuelle Liste exportieren',
    delete: 'Dossier löschen',
    editDefinition: 'Vorlage bearbeiten'
  },
  createDialog: {
    title: 'Dossier erstellen',
    successMessage: 'Dossier "{title}" wurde erfolgreich erstellt'
  },
  deleteDialog: {
    title: 'Dossier löschen?',
    text: 'Löschen Sie das Dossier, wenn Sie sicher sind, dass dieses Dossier nicht mehr benöigt wird.',
    buttons: {
      ok: 'Dossier löschen'
    }
  },
  editorHint: 'Alle direkt erwähnten Nutzer:innen und Gruppen werden über deinen Kommentar benachrichtigt. @:control.richtextarea.hint',
  mentioningHint: 'Dieses Dossier mit {identifier} erwähnen.',
  deletedTitle: 'Gelöschtes Dossier'
}
