import Vue from 'vue'
import forOwn from 'lodash/forOwn'
import reduce from 'lodash/reduce'

export default {
  methods: {
    dataFieldOptionsDefault (type) {
      switch (type) {
        case 'boolean': return false
        case 'string': return ''
        case 'richtext': return ''
        case 'dossier-definition': return null
        case 'array': return []
        case 'auto-increment': return null
      }
    },
    dataFieldGenerateObjectWithDefaultValues (definition) {
      const result = {}

      forOwn(definition, (value, key) => {
        Vue.set(result, key, this.dataFieldOptionsDefault(value.type))
      })

      return result
    },
    dataFieldIsOptionValueValid (optionDefinition, value) {
      if (optionDefinition.required) {
        switch (optionDefinition.type) {
          case 'boolean': return true
          case 'auto-increment': return true
          case 'string': return value.trim().length > 0
          case 'richtext':return value?.trim().length > 0
          case 'dossier-definition': return value !== null
          case 'array':
            return value.length >= (optionDefinition.minItems || 1) &&
                reduce(optionDefinition.items, (result, itemDefinition, key) => {
                  return result && reduce(value, (res, val, _) => {
                    return res && this.dataFieldIsOptionValueValid(itemDefinition, val[key])
                  }, true)
                }, true)
        }
      } else {
        return true
      }
    },
    dataFieldAllOptionsValid (optionsDefinition, optionValues) {
      return reduce(optionsDefinition, (result, value, key) => {
        return result && this.dataFieldIsOptionValueValid(value, optionValues[key])
      }, true)
    },
    dataFieldOptionForRequest (optionsDefinition, optionValues, oldOptionValues = null) {
      const optionsForRequest = {}

      forOwn(optionsDefinition, (value, key) => {
        switch (value.type) {
          case 'dossier-definition':
            if (optionValues[key]) optionsForRequest[key] = optionValues[key].id
            break
          case 'array':
            optionsForRequest[key] = optionValues[key]
            forOwn(value.items, (itemDefValue, itemDefKey) => {
              if (itemDefValue.type === 'auto-increment') {
                let maxValue = reduce((oldOptionValues && oldOptionValues[key]) || optionValues[key], (result, val) => {
                  if ((val[itemDefKey] || 0) > result) {
                    return (val[itemDefKey] || 0)
                  } else {
                    return result
                  }
                }, 0)

                forOwn(optionsForRequest[key], (itemValue) => {
                  if (itemValue[itemDefKey] === null) {
                    maxValue++
                    itemValue[itemDefKey] = maxValue
                  }
                })
              }
            })

            break
          default:
            optionsForRequest[key] = optionValues[key]
        }
      })

      return optionsForRequest
    }
  }
}
