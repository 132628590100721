<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import RichtextareaContent from 'components/richtextarea-content.vue'
import UserListItem from 'list-items/user-list-item.vue'
import TimeTracking from 'mixins/models/time-tracking'
import Request from 'api/request'
import { routeFor } from 'helpers/route'
import eventBus from '../helpers/event-bus'

export default {
  name: 'TimeTrackingReportDialog',
  components: { UserListItem, RichtextareaContent, CustomDialog },
  mixins: [Requestable, TimeTracking],
  props: {
    ...requestablePropFactory().props,
    gid: {
      type: String,
      required: true
    },
    timeTrackingValue: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialogOpen: false,
      timeTrackingEvents: []
    }
  },
  methods: {
    ...{ routeFor },
    onDialogOpened () {
      this.resetRequestable()
      this.request(
        { method: Request.GET, url: this.$apiEndpoints.events.timeTracking() },
        { object_gid: this.gid }, null, true, true)
    },
    onDialogClosed () {
      this.cancelRequestable()
    },
    onRequestSuccess (data) {
      this.timeTrackingEvents = data
    },
    linkTo (event) {
      this.dialogOpen = false

      this.$router.push(routeFor(event.target.object_type, event.target.object_id, { hash: event.target.hash }))
      this.$nextTick(() => {
        eventBus.$emit('trigger-scroll')
      })
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t('timeTracking.dialogs.report.title', {timeSpent: timeTrackingSpanTextFor(timeTrackingValue.timeSpentInMinutes), budget: timeTrackingSpanTextFor(timeTrackingValue.timeBudgetInMinutes)})"
    :ok-btn-text="$t('general.buttons.close')"
    :cancel-btn-text="null"
    :loading="requestableLoading"
    :error-message="baseErrorMessage"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t('timeTracking.dialogs.report.columns.date') }}
          </th>
          <th class="text-left">
            {{ $t('timeTracking.dialogs.report.columns.user') }}
          </th>
          <th class="text-left">
            {{ $t('timeTracking.dialogs.report.columns.amount') }}
          </th>
          <th class="text-left">
            {{ $t('timeTracking.dialogs.report.columns.comment') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="event in timeTrackingEvents"
          :key="event.id"
          @click="linkTo(event)"
        >
          <td>{{ timeTrackingSpentAtDateLocalizedFor(event.payload.time_tracking.spentAt) }}</td>
          <td>
            <user-list-item :value="event.subject" />
          </td>
          <td>{{ timeTrackingSpanTextFor(event.payload.time_tracking.timeSpentInMinutes) }}</td>
          <td>
            <richtextarea-content :template="event.payload.message" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </custom-dialog>
</template>
