import Page from '../page'
import SettingsHeader from './settings-header.vue'
import SettingsAccountContent from './settings-account-content.vue'
import SettingsGroupsContent from './settings-groups-content.vue'
import SettingsEmailContent from 'pages/users/settings-email-content.vue'
import SettingsPasswordContent from './settings-password-content.vue'
import SettingsNotificationsContent from './settings-notifications-content.vue'
import SettingsCalendarContent from './settings-calendar-content.vue'
import SettingsExternalApiContent from './settings-external-api-content.vue'
import SettingsSidebarLeft from './settings-sidebar-left.vue'

export default {
  name: 'UserSettingsPage',
  mixins: [Page],
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    pageTitleParts () {
      return [...(this.value ? [this.value.fullname] : []), 'Nutzer:in']
    },
    pageContentComponents () {
      const components = [SettingsHeader]

      switch (this.type) {
        case 'account':
          components.push(SettingsAccountContent)
          break
        case 'groups':
          components.push(SettingsGroupsContent)
          break
        case 'email':
          components.push(SettingsEmailContent)
          break
        case 'password':
          components.push(SettingsPasswordContent)
          break
        case 'notifications':
          components.push(SettingsNotificationsContent)
          break
        case 'calendar':
          components.push(SettingsCalendarContent)
          break
        case 'external-api':
          components.push(SettingsExternalApiContent)
          break
      }

      return components
    },
    pageSidebarLeftComponents () {
      return SettingsSidebarLeft
    },
    pagePropsToWatch () {
      return this.id
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.userSettings.show(this.id)
    }
  }
}
