<script>
export default {
  name: 'FilterSingleSelect',
  props: {
    value: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      required: true
    },
    clearable: Boolean,
    disabled: Boolean
  }
}
</script>
<template>
  <v-select
    :value="value"
    :items="items"
    :label="label"
    :clearable="clearable"
    :disabled="disabled"
    hide-details
    outlined
    @input="$emit('input', $event)"
  />
</template>
