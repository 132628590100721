<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import TextControl from 'controls/text-control.vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'CreateEditSubmenuDialog',
  components: {
    CustomDialog,
    TextControl
  },
  props: {
    value: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      requestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            if (!this.isEdit && this.addAnother) {
              this.successMessage = this.$t('mainMenuEntry.dialogs.createSubMenu.successMessage', { title: this.menuEntry.title })
              this.$emit('request-success', data)
              this.initData()
            } else {
              this.dialogOpen = false
              this.$emit('request-success', data)
            }
          }
        }
      }),
      menuEntry: null
    }
  },
  computed: {
    isEdit () {
      return this.value !== undefined
    },
    title () {
      return this.isEdit ? this.$t('mainMenuEntry.dialogs.editSubMenu.title') : this.$t('mainMenuEntry.dialogs.createSubMenu.title')
    },
    okBtnText () {
      return this.isEdit ? this.$t('general.buttons.save') : this.$t('mainMenuEntry.dialogs.createSubMenu.buttons.ok')
    },
    additionalOkBtnTexts () {
      return this.isEdit ? undefined : [this.$t('mainMenuEntry.dialogs.createSubMenu.buttons.saveAndCreateAnother')]
    },
    contentClass () {
      return this.isEdit ? 'edit-main-menu-entry-dialog' : 'create-main-menu-entry-dialog'
    },
    hasValidChanges () {
      if (this.menuEntry) {
        return this.menuEntry.title && this.menuEntry.title.trim().length > 0 &&
          (!this.isEdit || (this.menuEntry.title !== this.value.title))
      }

      return false
    }
  },
  methods: {
    initData () {
      this.menuEntry = (this.isEdit
        ? cloneDeep(this.value)
        : {
            title: null
          })
    },
    onDialogOpened () {
      this.initData()
      this.requestable.resetRequestable()
      this.$emit('open')
    },
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0

      if (this.isEdit) {
        this.requestable.request(
          { method: Request.PATCH, url: this.$apiEndpoints.mainMenuEntries.update(this.menuEntry.id) },
          null,
          {
            title: this.menuEntry.title
          }
        )
      } else {
        this.requestable.request(
          { method: Request.POST, url: this.$apiEndpoints.mainMenuEntries.create() },
          null,
          {
            title: this.menuEntry.title,
            parent_id: null
          }
        )
      }
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="title"
    :close-on-button-click="false"
    :ok-btn-text="okBtnText"
    :ok-btn-disabled="!hasValidChanges"
    :additional-ok-btn-texts="additionalOkBtnTexts"
    :loading="requestable.requestableLoading"
    :error-message="requestable.baseErrorMessage"
    :success-message="successMessage"
    :content-class="contentClass"
    @click-ok="onOkButtonClicked"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="menuEntry">
      <text-control
        v-model="menuEntry.title"
        label="Titel"
        :hint="$t('general.field.required')"
        persistent-hint
        hide-append
        :disabled="requestable.requestableLoading"
        :error-messages="requestable.validationErrorMessageFor('title')"
      />
    </template>
  </custom-dialog>
</template>
