<script>
import { PageContentable } from '../page'
import MonitorSubscription from 'components/monitor-subscription.vue'
import DataControl from 'controls/data-control'
import ActivityHub from 'components/activity-hub/index.vue'
import globalIdFor from 'helpers/global-id'

export default {
  name: 'DossierEditMainContent',
  components: {
    MonitorSubscription,
    DataControl,
    ActivityHub
  },
  mixins: [PageContentable],
  computed: {
    gid () {
      return globalIdFor('Dossier', this.pageProps.id)
    }
  },
  methods: {
    onUpdated (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<template>
  <div>
    <monitor-subscription
      :key="`monitor-channel-${gid}`"
      :gid="gid"
    />

    <template v-if="value">
      <data-control
        v-for="(dataField, index) in value.dataFields"
        :key="dataField.definition.id"
        :value="dataField.value"
        :acl-object-global-id="value.gid"
        :type="dataField.definition.type"
        :label="dataField.definition.name"
        :options="dataField.definition.options"
        :required="dataField.definition.required"
        :request-parameter="{method: 'patch', url: $apiEndpoints.dossiers.update(value.id), mapping: (data) => { return {'dossier_field_definition_id': dataField.definition.id, 'value': data, 'lock_version': dataField.lock_version}}}"
        :class="{'mb-5': index < value.dataFields.length - 1}"
        @request-success-data="onUpdated"
      />
    </template>

    <activity-hub
      v-if="gid"
      :key="`activity-hub-${gid}`"
      :gid="gid"
      :groups="(value || {}).groups"
      :editor-hint="$t('dossier.editorHint')"
      class="mt-12"
      @loaded="pageDataLoaded"
    />
  </div>
</template>
