<script>
import ListItemTemplate from './list-item-template.vue'

export default {
  name: 'DossierListItem',
  components: { ListItemTemplate },
  props: {
    ...ListItemTemplate.props
  }
}
</script>
<template>
  <div>
    <list-item-template
      skeleton-type="list-item-avatar-two-line"
      two-line
      v-bind="$props"
      v-on="$listeners"
    >
      <v-list-item-content>
        <v-list-item-title :class="{'text--disabled': value.deleted}">
          {{ value.deleted ? $t('dossier.deletedTitle') : value.title }}
        </v-list-item-title>
        <v-list-item-subtitle :class="{'text--disabled': value.deleted}">
          {{ value.deleted ? `*${value.id}` : value.subtitle }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-list-item-action-text
          class="text-right"
          style="width: min-content;"
        >
          {{ value.definition.name }}
        </v-list-item-action-text>
      </v-list-item-action>
    </list-item-template>

    <v-list-item-subtitle
      v-if="$scopedSlots.snippets"
      class="mt-2 ml-4"
    >
      <slot name="snippets" />
    </v-list-item-subtitle>
  </div>
</template>
