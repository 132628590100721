import isNil from 'lodash/isNil'
import defaults from 'lodash/defaults'
import axios from 'axios'
import omit from 'lodash/omit'

export function routeFor (type, id, { params = undefined, query = undefined, hash = undefined } = {}) {
  const routes = {
    ambition: 'left-here-to-keep-tests-happy-until-labels-are-integrated',
    dossier: 'dossier',
    dossier_task_references: 'dossier-task-references',
    dossier_dossier_field_references: 'dossier-dossier-field-references',
    dossier_definition: 'dossier-definition',
    dossier_definition_groups: 'dossier-definition-groups',
    dossier_definition_references: 'dossier-definition-references',
    group: 'group',
    group_users: 'group-users',
    group_process_definitions: 'group-process-definitions',
    group_dossier_definitions: 'group-dossier-definitions',
    task: 'task',
    user: 'user',
    user_settings: 'user-settings',
    user_settings_groups: 'user-settings-groups',
    user_settings_email: 'user-settings-email',
    user_settings_password: 'user-settings-password',
    user_settings_notifications: 'user-settings-notifications',
    user_settings_calendar: 'user-settings-calendar',
    user_settings_external_api: 'user-settings-external-api',
    workflow: 'workflow',
    process_definition: 'process-definition',
    task_definition: 'task-definition',
    block_definition: 'block-definition',
    workflow_definition: 'process-definition', // This needs to be here for backend compatibility
    static_page: 'static-page',
    footer_menu_entry: 'footer-menu-entry',
    about: 'about',
    dossier_data_transformations: 'dossier-data-transformations',
    dossier_data_transformation_detail: 'dossier-data-transformation-detail',
    automation: 'automation',
    tasks_by_definition: 'tasks-by-definition',
    dossiers_by_definition: 'dossiers-by-definition',
    main_menu_entry: 'main-menu-entries'
  }

  const result = { name: routes[type], params: defaults({ id }, params) }
  if (result.name === undefined) {
    console.error('Unknown route type:', type)
    return ''
  }

  if (!isNil(query)) {
    result.query = defaults({}, result.query, query)
  }

  if (!isNil(hash)) {
    result.hash = hash
  }

  return result
}

export function exportUrlFor (path, params) {
  return axios.getUri({ url: path + '.xlsx', params: omit(params, ['page']) })
}
