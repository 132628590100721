<script>
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import { distanceDate } from 'helpers/date'
import { routeFor } from 'helpers/route'

export default {
  name: 'MyLastChangesContent',
  mixins: [Requestable],
  data () {
    return {
      changes: []
    }
  },
  computed: {
    requestParameter () {
      return { method: Request.GET, url: this.$apiEndpoints.dashboard.myLastChanges() }
    }
  },
  beforeMount () {
    this.request(this.requestParameter)
  },
  methods: {
    ...{ distanceDate, routeFor },
    onRequestSuccess (data) {
      this.changes = data
    }
  }
}
</script>
<template>
  <div>
    <h1 class="text-h4 font-weight-light mt-4">
      {{ $t('dashboard.widget.my_last_changes.title') }}
    </h1>
    <v-list>
      <template v-if="!requestableLoading">
        <template v-if="changes && changes.length > 0">
          <v-list-item
            v-for="(value, index) in changes"
            :key="index"
            :to="routeFor(value.object.type, value.object.id)"
          >
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ value.change_name }} • {{ $t(`dashboard.widget.my_last_changes.types.${value.object.type}`) }} • {{ distanceDate(value.last_updated_at) }}
              </v-list-item-subtitle>
              <v-list-item-title>{{ value.object.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
          v-else
          class="px-0"
        >
          {{ $t('dashboard.widget.my_last_changes.emptyMessage') }}
        </v-list-item>
      </template>
      <v-skeleton-loader
        v-if="requestableLoadingDelayed"
        type="list-item-two-line@5"
      />
    </v-list>
    <v-btn
      v-if="!requestableLoading"
      plain
      text
      small
      block
      :to="{name: 'journal', query: {subject_ids: [$config.current_user.id]}}"
    >
      {{ $t('dashboard.widget.general.load_more') }}
    </v-btn>
  </div>
</template>
