<script>
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import PageListHeader from 'components/page-list-header.vue'
import CreateUserDialog from 'dialogs/create-user-dialog.vue'
import DataListFilter from 'components/data-list/filter.vue'
import SmartList from 'components/smart-list.vue'
import UserListItem from 'list-items/user-list-item.vue'
import User from 'mixins/models/user'
import { routeFor } from 'helpers/route'

export default {
  name: 'UserListContent',
  components: {
    PageListHeader,
    CreateUserDialog,
    DataListFilter,
    SmartList,
    UserListItem
  },
  mixins: [PageContentable, ListPageContentable, User],
  computed: {
    result () {
      return this.value ? this.value.result : undefined
    }
  },
  methods: {
    ...{ routeFor },
    onCreated (user) {
      this.$router.push(routeFor('user_settings', user.id))
    }
  }
}
</script>
<template>
  <div>
    <create-user-dialog @success="onCreated">
      <template #activator="{ on }">
        <page-list-header
          title="Nutzer:innen"
          :primary-action-title="$config.current_user.isAdmin ? $t('user.actions.create') : undefined"
          v-on="on"
        />
      </template>
    </create-user-dialog>

    <data-list-filter
      v-model="filters"
      @update:filter="$emit('update:prop', { prop: 'filters', value: $event.value, info: $event.field })"
    />

    <smart-list
      v-slot="{ item }"
      :value="result"
      :loading="pageRequestLoading"
    >
      <user-list-item
        v-if="$config.current_user.isAdmin || $config.isCurrentUser(item)"
        :value="item"
        :to="routeFor('user_settings', item.id)"
        indent
        :dense="false"
        divider
        show-unconfirmed-email
      />
      <user-list-item
        v-else
        :value="item"
        :to="routeFor('user', item.id)"
        indent
        :dense="false"
        divider
      />
    </smart-list>

    <v-pagination
      v-if="!pageRequestLoading && result && pages.total() > 1"
      v-model="pages.current"
      :length="pages.total()"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'pages', value: pages })"
    />
  </div>
</template>
