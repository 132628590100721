import DossierListPage from './list'

export default {
  name: 'DossierListByDefinitionPage',
  mixins: [DossierListPage],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    initPageRequestParams () {
      const params = DossierListPage.methods.initPageRequestParams.call(this)
      delete params.definition_ids
      params.definition_id = this.id
      return params
    },
    filterFields () {
      const filterFields = DossierListPage.methods.filterFields.call(this)
      filterFields.splice(1, 1)
      return filterFields
    }
  }
}
