<script>
import Richtextarea from './richtextarea/richtextarea'
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import ResetBtn from 'components/reset-btn.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import { number2LocaleString } from 'helpers/number'
import pick from 'lodash/pick'

export default {
  name: 'RichtextareaControl',
  components: { Richtextarea, ResetBtn, CopyToClipboardBtn },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    ...pick(new Richtextarea().$options.props, ['hint', 'mentionMenuAttach', 'mentionMenuNudgeBottomDifference', 'mentionDisabled']),
    value: {
      type: String,
      default: undefined
    }
  },
  methods: {
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    characterCount () {
      let count = 0

      if (this.$el) {
        const tmp = this.$el.querySelector('.ProseMirror')
        count = [...tmp.innerText.trim()].length
      }

      return count
    },
    characterCountAsLocaleString () {
      return number2LocaleString(this.characterCount())
    },
    clipboardData () {
      const result = {
        'text/html': this.cacheValue
      }

      if (this.$el) {
        result['text/plain'] = this.$el.querySelector('.ProseMirror').innerText
      }

      return result
    },
    onBlur () {
      if (!document.getElementById('tiptap-vuetify-custom-image__input-file')) {
        this.controlOnBlur()
        this.controlOnChange()
        this.controlOnUnlock()
      }
    }
  }
}
</script>

<template>
  <richtextarea
    v-model="cacheValue"
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :readonly="controlReadonly"
    :class="controlClass"
    :error-count="Number.MAX_VALUE"
    :error-messages="controlErrorMessages"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
    :counter="controlIsFocused"
    :mention-disabled="mentionDisabled"
    :mention-menu-attach="mentionMenuAttach"
    :mention-menu-nudge-bottom-difference="mentionMenuNudgeBottomDifference"
    :content-changed="value !== cacheValue"
    @blur="onBlur"
    @input="controlOnInput"
    @focus="controlOnFocus(); controlOnLock();"
  >
    <template #append>
      <reset-btn
        v-if="cachedValueChanged && controlHasError"
        @click="controlReset"
      />
      <copy-to-clipboard-btn
        v-if="cacheValue"
        :value="clipboardData()"
      />
    </template>
    <template #counter="{ props }">
      <v-counter
        v-bind="props"
        :value="`${characterCountAsLocaleString()} Zeichen`"
      />
    </template>
  </richtextarea>
</template>
<style lang="scss">
.richtextarea.control {
  .tiptap-vuetify-editor {
    .v-card {
      background-color: transparent;
    }

    .ProseMirror {
      margin: 0 !important;
    }

    .tiptap-vuetify-editor__toolbar {
      transform: scaleY(0);
      transform-origin: top;
      max-height: 0;
      transition: all 0.26s ease;

      .v-toolbar {
        margin-top: 4px;
      }
    }
  }

  &.v-input--is-focused {
    .tiptap-vuetify-editor__toolbar {
      transform: scaleY(1);
      max-height: 100px;
    }
  }
}
</style>
