import Page from '../page'
import ShowContent from './show-content.vue'

export default {
  name: 'ShareShowPage',
  mixins: [Page],
  props: {
    token: {
      type: String,
      required: true
    }
  },
  computed: {
    pageTitleParts () {
      return ['Zuarbeit']
    },
    pageContentComponents () {
      return [ShowContent]
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.share.show(this.token)
    }
  }
}
