import Request from 'api/request'
import Requestable from 'mixins/requestable'
import PageContent from './page-content'
import SidebarLeft from './sidebar-left.vue'
import SidebarRight from './sidebar-right.vue'
import eventBus from 'helpers/event-bus'
import kebabCase from 'lodash/kebabCase'
import join from 'lodash/join'
import castArray from 'lodash/castArray'
import forEach from 'lodash/forEach'
import has from 'lodash/has'
import assign from 'lodash/assign'
import defaults from 'lodash/defaults'

export const PageContentable = {
  props: {
    value: {
      type: Object,
      default: null
    },
    pageProps: {
      type: Object,
      required: true
    },
    pageRequestLoading: Boolean,
    pageRequestHasError: Boolean,
    pageRequestParameter: {
      type: Object,
      required: true
    },
    hasLeftSidebar: {
      type: Boolean,
      required: true
    },
    hasRightSidebar: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      pageDataCount: 2
    }
  },
  methods: {
    fetchPageData (pageRequestParameter) {
      this.$emit('fetchPageData', pageRequestParameter)
    },
    pageDataLoaded () {
      this.pageDataCount--

      if (this.pageDataCount === 0) {
        this.$nextTick(() => {
          eventBus.$emit('trigger-scroll')
        })
      }
    }
  },
  watch: {
    value: {
      handler (value, oldValue) {
        if (oldValue === null) this.pageDataLoaded()
      }
    }
  }
}

export default {
  name: 'Page',
  mixins: [Requestable],
  data () {
    return {
      value: null,
      pageRequestParameter: {
        method: null,
        url: null,
        params: null,
        data: null,
        useCancelToken: null
      },
      sidebarLeftOpen: undefined,
      sidebarRightOpen: undefined
    }
  },
  computed: {
    pageTitleParts () {
      return null
    },
    pageContentComponents () {
      return null
    },
    pageSidebarLeftComponents () {
      return null
    },
    pageSidebarRightComponents () {
      return null
    },
    pageSidebarLeftFloating () {
      return false
    },
    pageSidebarRightFloating () {
      return false
    },
    pageComponentProps () {
      return {}
    },
    pagePropsToWatch () {
      return this.$props
    }
  },
  watch: {
    pagePropsToWatch: {
      handler () {
        this.onPropsChanged()
      },
      deep: true,
      immediate: true
    },
    pageTitleParts: {
      handler () {
        this.setPageTitle()
      },
      immediate: true
    }
  },
  methods: {
    onPropsChanged () {
      eventBus.$emit('page-changed')
      this.onBeforePropsChanged()
      this.initPageRequestParameter()
      this.fetchPageData()
    },
    onBeforePropsChanged () {
    },
    onValueChanged () {
    },
    onPageComponentEvent (event) {
    },
    initPageRequestMethod () {
      return Request.GET
    },
    initPageRequestUrl () {
      return null
    },
    initPageRequestParams () {
      return null
    },
    initPageRequestData () {
      return null
    },
    initPageRequestUseCancelToken () {
      return true
    },
    initPageRequestParameter () {
      this.pageRequestParameter.method = this.initPageRequestMethod()
      this.pageRequestParameter.url = this.initPageRequestUrl()
      this.pageRequestParameter.params = this.initPageRequestParams()
      this.pageRequestParameter.data = this.initPageRequestData()
      this.pageRequestParameter.useCancelToken = this.initPageRequestUseCancelToken()
    },
    fetchPageData (pageRequestParameterOverwrite = null) {
      if (this.hasError) this.resetRequestable()

      const actualPageRequestParameter = defaults(pageRequestParameterOverwrite, this.pageRequestParameter)

      if (actualPageRequestParameter.method && actualPageRequestParameter.url) {
        this.$debugLog('fetchPageData:', actualPageRequestParameter)
        this.request(
          { method: actualPageRequestParameter.method, url: actualPageRequestParameter.url },
          actualPageRequestParameter.params,
          actualPageRequestParameter.data,
          actualPageRequestParameter.useCancelToken,
          true)
      }
    },
    onRequestSuccess (data) {
      this.value = data
    },
    genPageElements () {
      const elements = []

      if (this.pageSidebarLeftComponents || this.pageSidebarRightComponents) {
        elements.push(
          this.$createElement(SidebarLeft, {
            props: {
              value: this.sidebarLeftOpen,
              floating: !this.pageSidebarLeftComponents || this.pageSidebarLeftFloating
            },
            on: {
              input: (value) => {
                this.sidebarLeftOpen = value
              }
            }
          }, this.genPageComponents(this.pageSidebarLeftComponents))
        )
      }

      elements.push(
        this.$createElement(PageContent, {}, [
          ...this.genPageComponents(this.pageContentComponents)
        ])
      )

      if (this.pageSidebarLeftComponents || this.pageSidebarRightComponents) {
        elements.push(
          this.$createElement(SidebarRight, {
            props: {
              value: this.sidebarRightOpen,
              floating: !this.pageSidebarRightComponents || this.pageSidebarRightFloating
            },
            on: {
              input: (value) => {
                this.sidebarRightOpen = value
              }
            }
          }, this.genPageComponents(this.pageSidebarRightComponents))
        )
      }

      return elements
    },
    genPageComponents (components) {
      const elements = []

      if (this.error && [403, 404, 409, 410].includes(this.error.code)) return elements

      forEach(castArray(components), (component) => {
        if (component) {
          const props = assign({
            value: this.value,
            pageProps: this.$props,
            pageRequestLoading: this.requestableLoading,
            pageRequestHasError: this.hasError,
            pageRequestParameter: this.pageRequestParameter,
            hasLeftSidebar: !!this.pageSidebarLeftComponents,
            hasRightSidebar: !!this.pageSidebarRightComponents
          }, this.pageComponentProps)

          elements.push(this.$createElement(component, {
            props,
            on: {
              input: (value) => {
                this.value = value
                this.onValueChanged()
              },
              fetchPageData: (value) => {
                if (has(value, 'method')) this.pageRequestParameter.method = value.method
                if (has(value, 'url')) this.pageRequestParameter.url = value.url
                if (has(value, 'params')) this.pageRequestParameter.params = value.params
                if (has(value, 'data')) this.pageRequestParameter.data = value.data
                if (has(value, 'useCancelToken')) this.pageRequestParameter.useCancelToken = value.useCancelToken
                this.fetchPageData()
              },
              'update:prop': ({ prop, value, info }) => {
                this.onPropUpdated(prop, value, info)
              },
              'open-sidebar': (type) => {
                switch (type) {
                  case 'left':
                    this.sidebarLeftOpen = true
                    break
                  case 'right':
                    this.sidebarRightOpen = true
                    break
                }
              },
              'component:event': this.onPageComponentEvent
            }
          }))
        }
      })

      return elements
    },
    setPageTitle () {
      const titleParts = this.pageTitleParts
      if (titleParts && titleParts.length) {
        document.title = `${join(titleParts, ' • ')} • ${this.$t('general.appName')}`
      } else {
        document.title = this.$t('general.appName')
      }
    },
    onPropUpdated (prop, value, info) {
    },
    pageClass () {
      return kebabCase(this.$options.name)
    }
  },
  render: function (createElement) {
    return createElement('div', {
      staticClass: this.pageClass()
    }, [this.genPageElements()])
  }
}
