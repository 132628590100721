<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import { formatTime } from 'helpers/date'

export default {
  name: 'TimeControl',
  components: {
    ResetBtn,
    CopyToClipboardBtn
  },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      menuOpen: false
    }
  },
  computed: {
    localizedTimeValue () {
      if (this.cacheValue) {
        return formatTime(new Date(`2000-01-01 ${this.cacheValue}`))
      } else {
        return ''
      }
    }
  },
  methods: {
    onMenuStateChanged (opened) {
      if (opened) {
        if (this.$refs.picker !== undefined) this.$refs.picker.selectingHour = true
        this.controlOnLock()
      } else {
        this.controlOnInput()
        this.controlOnChange()
        this.controlOnUnlock()
      }
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>
<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menuOpen"
      :close-on-content-click="false"
      :return-value.sync="cacheValue"
      min-width="290px"
      offset-y
      transition="scale-transition"
      @input="onMenuStateChanged"
    >
      <template #activator="{ on }">
        <v-text-field
          :value="localizedTimeValue"
          :label="label"
          :loading="controlLoading"
          :disabled="controlDisabled"
          :class="controlClass"
          :error-messages="controlErrorMessages"
          :error-count="Number.MAX_VALUE"
          :hint="controlHint"
          :persistent-hint="controlPersistentHint"
          :success-messages="controlSuccessMessage"
          readonly
          v-on="on"
        >
          <template #append>
            <reset-btn
              v-if="cachedValueChanged && controlHasError"
              @click="controlReset"
            />
            <copy-to-clipboard-btn
              v-if="cacheValue"
              :value="{'text/plain': localizedTimeValue}"
            />
          </template>
        </v-text-field>
      </template>

      <v-time-picker
        ref="picker"
        v-model="cacheValue"
        format="24hr"
        full-width
        scrollable
      >
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="$refs.menu.save(null)"
        >
          Löschen
        </v-btn>
        <v-btn
          :disabled="!cachedValueChanged"
          color="primary"
          depressed
          @click="$refs.menu.save(cacheValue)"
        >
          Speichern
        </v-btn>
      </v-time-picker>
    </v-menu>
  </div>
</template>
