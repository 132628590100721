<script>
import { PageContentable } from '../page'
import { routeFor } from 'helpers/route'

export default {
  name: 'UserSettingsSidebarLeft',
  mixins: [PageContentable],
  methods: {
    ...{ routeFor }
  }
}
</script>
<template>
  <v-list
    v-if="value"
    class="pa-0"
  >
    <v-list-item
      :to="routeFor('user_settings', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Account
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />
    <v-list-item
      :to="routeFor('user_settings_groups', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Gruppen
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-chip
          pill
          :color="$route.name === 'user-settings-groups' ? 'white': undefined"
        >
          {{ String(value.groupCount) }}
        </v-chip>
      </v-list-item-avatar>
    </v-list-item>

    <template v-if="$config.isCurrentUser(value) || $config.current_user.isAdmin">
      <v-divider />
      <v-list-item
        :to="routeFor('user_settings_email', value.id)"
        exact
      >
        <v-list-item-content>
          <v-list-item-title>
            E-Mail-Adresse
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-if="$config.isCurrentUser(value)">
      <v-divider />
      <v-list-item
        v-if="$config.isCurrentUser(value)"
        :to="routeFor('user_settings_password', value.id)"
        exact
      >
        <v-list-item-content>
          <v-list-item-title>
            Passwort
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider />
    <v-list-item
      :to="routeFor('user_settings_notifications', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Benachrichtigungen
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />
    <v-list-item
      :to="routeFor('user_settings_calendar', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Kalender
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />
    <v-list-item
      :to="routeFor('user_settings_external_api', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          API
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
