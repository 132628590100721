import Page from '../page'
import EditSidebarLeft from './edit-sidebar-left.vue'
import GroupEditHeader from './edit-header.vue'
import GroupEditContentMain from './edit-content-main.vue'
import GroupEditContentUsers from './edit-content-users.vue'
import GroupEditContentProcessDefinitions from './edit-content-process-definitions.vue'
import GroupEditContentDossierDefinitions from './edit-content-dossier-definitions.vue'

export default {
  name: 'GroupEditPage',
  mixins: [Page],
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    pageTitleParts () {
      return [...(this.value ? [this.value.name] : []), 'Gruppen']
    },
    pageContentComponents () {
      const components = [GroupEditHeader]

      switch (this.type) {
        case 'main':
          components.push(GroupEditContentMain)
          break
        case 'users':
          components.push(GroupEditContentUsers)
          break
        case 'process-definitions':
          components.push(GroupEditContentProcessDefinitions)
          break
        case 'dossier-definitions':
          components.push(GroupEditContentDossierDefinitions)
          break
      }

      return components
    },
    pageSidebarLeftComponents () {
      return EditSidebarLeft
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.groups.show(this.id)
    }
  }
}
