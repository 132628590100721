export default {
  deleteDialog: {
    title: {
      singleUsage: 'Datenfeld löschen?',
      multiUsage: 'Datenfeld entfernen?',
      blockUsage: 'Datenfeld kann nicht gelöscht werden'
    },
    text: {
      singleUsage: 'Dieses Datenfeld wir mit allen eingegebenen Daten unwiederbringlich gelöscht.',
      multiUsage: 'Dieses Datenfeld wird noch in einer Aufgabe verwendet. Es wird nur die Anzeige des Datenfelds in der aktuellen Aufgabe entfernt. Das Datenfeld bleibt an der anderen Stelle erhalten. | Dieses Datenfeld wird noch mehrfach in Aufgaben verwendet. Es wird nur die Anzeige des Datenfelds in der aktuellen Aufgabe entfernt. Das Datenfeld bleibt an den anderen Stellen erhalten.',
      blockUsage: 'Dieses Datenfeld wird noch als Bedingung in einem Block genutzt. Es kann daher nicht gelöscht werden. | Dieses Datenfeld wird noch als Bedingung in mehrerern Blöcken genutzt. Es kann daher nicht gelöscht werden.'
    },
    buttons: {
      ok: {
        singleUsage: 'Datenfeld löschen',
        multiUsage: 'Datenfeld entfernen',
        blockUsage: 'OK'
      }
    }
  }
}
