<script>
import { routeFor } from 'helpers/route'

export default {
  name: 'Mention',
  props: {
    mId: {
      type: String,
      required: true
    },
    mType: {
      type: String,
      required: true
    },
    noaccess: Boolean,
    deleted: Boolean
  },
  computed: {
    route () {
      return routeFor(this.mType, this.mId)
    }
  },
  methods: {
    handleClick (event, navigate) {
      if (this.findRichtextArea()?.contentChanged) {
        event.preventDefault()
        window.open(this.$router.resolve(this.route).href, '_blank')
        return
      }

      navigate(event)
    },
    findRichtextArea () {
      let component = null
      let parent = this.$parent
      while (parent && !component) {
        if (parent.$options.name === 'RichtextArea' || parent.$options.name === 'ActivityHubCommentEditor') {
          component = parent
        }
        parent = parent.$parent
      }
      return component
    }
  }
}
</script>
<template>
  <router-link
    v-if="!noaccess && !deleted"
    v-slot="{ navigate }"
    custom
    :to="route"
    class="blue lighten-5 primary--text object-link object-link--chip"
  ><a @click="handleClick($event, navigate)"><slot /></a></router-link>
  <span
    v-else
    class="grey lighten-3 object-link--chip"
  >
    <s v-if="deleted"><slot /></s>
    <slot v-else />
  </span>
</template>
