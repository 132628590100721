<script>
import ListItemTemplate from './list-item-template.vue'
import join from 'lodash/join'

export default {
  name: 'TaskFieldDefinitionListItem',
  components: {
    ListItemTemplate
  },
  props: {
    ...ListItemTemplate.props
  },
  computed: {
    attributes () {
      const res = []
      if (this.value.required) res.push('Erforderlich')
      if (this.value.infobox) res.push('Infobox')
      if (this.value.options.add_to_calendar) res.push('Kalendereintrag')
      if (this.value.hasDefaultValue) res.push('Vorgabewert')
      return join(res, ', ')
    }
  }
}
</script>
<template>
  <list-item-template
    skeleton-type="list-item-avatar-two-line"
    two-line
    v-bind="$props"
  >
    <v-list-item-icon v-if="$scopedSlots.icon">
      <slot name="icon" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ value.name }}</v-list-item-title>
      <v-list-item-subtitle class="d-flex">
        <div class="flex-grow-1">
          {{ value.typeLabel }}
        </div>
        <div
          v-if="attributes.length > 0"
          class="ml-4"
        >
          {{ attributes }}
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <slot name="actions" />
    </v-list-item-action>
  </list-item-template>
</template>
