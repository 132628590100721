<script>
import Vue from 'vue'
import Requestable from 'mixins/requestable'
import DataField from 'mixins/models/data-field'
import CustomDialog from 'dialogs/custom-dialog.vue'
import TextControl from 'controls/text-control.vue'
import SwitchControl from 'controls/switch-control.vue'
import ContentTypeOptionsControl from 'controls/content-type-options-control.vue'
import DataControl from 'controls/data-control'
import { routeFor } from 'helpers/route'
import {
  CONTENT_TYPE_DOSSIER,
  CONTENT_TYPE_NOTE,
  CONTENT_TYPE_RICHTEXT,
  isContentTypeValueEmpty,
  updateValueForContentType,
  contentTypeValuesEqual
} from 'helpers/content-types'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import Request from 'api/request'
import find from 'lodash/find'
import partition from 'lodash/partition'
import merge from 'lodash/merge'

export default {
  name: 'AddEditDossierFieldDialog',
  components: {
    CustomDialog,
    TextControl,
    SwitchControl,
    ContentTypeOptionsControl,
    DataControl
  },
  mixins: [Requestable, DataField],
  props: {
    dossierDefinitionId: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      default: () => {
        return {
          name: '',
          required: false,
          recommended: false,
          unique: false,
          type: {
            name: '',
            label: '',
            options: {}
          },
          default_value: null,
          dataTransformation: undefined
        }
      }
    },
    note: Boolean
  },
  data () {
    return {
      dialogOpen: false,
      internalValue: undefined,
      contentTypes: undefined,
      contentTypesFiltered: undefined,
      contentTypesQueryRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            [this.contentTypesFiltered, this.contentTypes] = partition(data, ['type', CONTENT_TYPE_NOTE])
          }
        }
      })
    }
  },
  computed: {
    isAdd () {
      return this.$options.propsData.value === undefined
    },
    isNote () {
      return this.internalValue?.type.name === CONTENT_TYPE_NOTE
    },
    title () {
      const action = this.isAdd ? 'add' : 'edit'
      const field = this.isNote ? 'noteField' : 'dataField'

      return this.$t(`dossierFieldDefinition.subActions.${action}.${field}`)
    },
    selectedContentType () {
      return this.getContentTypeDefinition(this.internalValue?.type?.name)
    },
    nameValueChanged () {
      return this.internalValue?.name !== this.value.name
    },
    recommendedValueChanged () {
      return this.internalValue?.recommended !== this.value.recommended
    },
    requiredValueChanged () {
      return this.internalValue?.required !== this.value.required
    },
    uniqueValueChanged () {
      return this.internalValue?.unique !== this.value.unique
    },
    typeValueChanged () {
      return this.internalValue?.type?.name !== this.value.type.name || !isEqual(this.internalValue?.type?.options, this.value.type.options)
    },
    anyValueChanged () {
      return this.nameValueChanged || this.recommendedValueChanged ||
      this.requiredValueChanged || this.uniqueValueChanged || this.typeValueChanged
    },
    needsTransformation () {
      return !this.isAdd && (
        (this.requiredValueChanged && this.internalValue?.required) ||
        (this.uniqueValueChanged && this.internalValue?.unique) ||
        this.typeValueChanged
      )
    },
    hasValidChanges () {
      return this.internalValue && (
        this.internalValue.name !== this.value.name ||
        this.internalValue.required !== this.value.required ||
        this.internalValue.recommended !== this.value.recommended ||
        this.internalValue.unique !== this.value.unique ||
        this.internalValue.type.name !== this.value.type.name ||
        !isEqual(this.internalValue.type.options, this.value.type.options) ||
        !contentTypeValuesEqual(this.value.type.name, this.value.default_value, this.internalValue.type.name, this.internalValue.default_value)
      ) && ((this.isNote && this.internalValue.default_value && this.richTextControlCharacterCount() > 0) || (!this.isNote && this.internalValue.name && this.internalValue.name.trim().length > 0)) &&
          this.internalValue.type.name && this.internalValue.type.name.trim().length > 0 &&
          this.dataFieldAllOptionsValid(this.selectedContentType.options_definition, this.internalValue.type.options)
    },
    requestData () {
      const result = {}

      if (this.internalValue && this.contentTypes) {
        if (this.internalValue.name !== this.value.name) result.name = this.internalValue.name
        if (this.internalValue.required !== this.value.required) result.required = this.internalValue.required
        if (this.internalValue.recommended !== this.value.recommended) result.recommended = this.internalValue.recommended
        if (this.internalValue.unique !== this.value.unique) result.unique = this.internalValue.unique
        if (this.internalValue.type.name !== this.value.type.name) result.content_type = this.internalValue.type.name

        if (this.internalValue.default_value !== this.value.default_value) result.default_value = updateValueForContentType(this.internalValue.type.name, this.internalValue.default_value)

        const oldOptionsForRequest = this.dataFieldOptionForRequest(this.getContentTypeDefinition(this.value.type.name)?.options_definition, this.value.type.options)
        const newOptionsForRequest = this.dataFieldOptionForRequest(this.selectedContentType?.options_definition, this.internalValue.type.options, this.value.type.options)
        if (!isEqual(newOptionsForRequest, oldOptionsForRequest)) result.options = newOptionsForRequest
      }

      return result
    },
    showDataControl () {
      return this.internalValue?.type?.name && (this.internalValue.type.name !== CONTENT_TYPE_DOSSIER || this.internalValue.type.options.type !== null)
    },
    hasInternalValue () {
      return !isContentTypeValueEmpty(this.value.type.name, this.value.default_value) || !isContentTypeValueEmpty(this.internalValue.type.name, this.internalValue.default_value)
    }
  },
  mounted () {
    this.contentTypesQueryRequestable.request(
      { method: Request.GET, url: this.$apiEndpoints.contentTypes.index() },
      null, null, true, true)
  },
  methods: {
    routeFor,
    onDialogOpened () {
      this.internalValue = cloneDeep(this.value)
      if (this.note) {
        this.internalValue.type.name = CONTENT_TYPE_NOTE
        this.onContentTypeChanged()
      }
      this.resetRequestable()

      this.$emit('open')
    },
    onRequestSuccess (data) {
      this.dialogOpen = false

      if (!this.isAdd && this.needsTransformation) {
        this.$emit('transformation:created', data)
      } else {
        this.$emit('request-success', data)
      }
    },
    onContentTypeChanged () {
      this.internalValue.type.label = this.selectedContentType?.label
      this.internalValue.type.options_definition = this.selectedContentType?.options_definition
      this.internalValue.type.options = {}

      if (this.selectedContentType?.options_definition) {
        this.internalValue.type.options = this.dataFieldGenerateObjectWithDefaultValues(this.selectedContentType?.options_definition)
      }
    },
    getContentTypeDefinition (type) {
      return find([...this.contentTypes, ...this.contentTypesFiltered], { type })
    },
    onClickOk () {
      setTimeout(() => {
        if (this.isAdd) {
          this.request({
            method: Request.POST,
            url: this.$apiEndpoints.dossierItemDefinitions.create(),
            mapping: (data) => {
              return merge(data, { dossier_definition_id: this.dossierDefinitionId })
            }
          }, null, this.requestData)
        } else {
          if (this.needsTransformation) {
            this.request({
              method: Request.POST,
              url: this.$apiEndpoints.dataTransformations.create(),
              mapping: (data) => {
                return merge(data, { dossier_item_definition_id: this.value.id })
              }
            }, null, this.requestData)
          } else {
            this.request({
              method: 'patch',
              url: this.$apiEndpoints.dossierItemDefinitions.update(this.value.id)
            }, null, this.requestData)
          }
        }
      }, 50)
    },
    dataControlType (type) {
      return type === CONTENT_TYPE_NOTE ? CONTENT_TYPE_RICHTEXT : type
    },
    richTextControlCharacterCount () {
      if (this.dataControlType(this.internalValue.type.name) === CONTENT_TYPE_RICHTEXT) {
        const control = this.$refs?.dataControl
        return control ? control.characterCount() : 0
      } else {
        return 0
      }
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="title"
    fullheight
    :close-on-button-click="false"
    :ok-btn-disabled="!hasValidChanges"
    :loading="requestableLoading"
    :error-message="baseErrorMessage"
    @click-ok="onClickOk"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="internalValue && contentTypes">
      <template v-if="!isNote">
        <text-control
          v-model="internalValue.name"
          label="Name des Datenfeldes"
          :hint="$t('general.field.required')"
          persistent-hint
          :disabled="requestableLoading || needsTransformation"
          :error-messages="validationErrorMessageFor('name')"
          class="mb-5"
        />
        <v-autocomplete
          v-model="internalValue.type.name"
          label="Art des Datenfeldes"
          :items="contentTypes"
          item-value="type"
          item-text="label"
          :disabled="requestableLoading || (!isAdd && anyValueChanged && !typeValueChanged) || !!value.dataTransformation || hasInternalValue"
          :hint="$t('general.field.required')"
          persistent-hint
          :error-messages="validationErrorMessageFor('content_type')"
          :error-count="Number.MAX_VALUE"
          class="mb-5"
          @change="onContentTypeChanged"
        />
        <content-type-options-control
          v-model="internalValue.type.options"
          :content-type="selectedContentType"
          :disabled="requestableLoading || (!isAdd && anyValueChanged && !typeValueChanged) || !!value.dataTransformation || hasInternalValue"
          :error-messages="validationErrorFor('options')"
          :class="{'mb-5': !hasInternalValue}"
        />
        <v-alert
          v-if="hasInternalValue && !value.dataTransformation"
          dense
          text
          type="warning"
          border="left"
          :icon="false"
          class="mt-2 mb-5"
        >
          <h4>Datenfeldkonfiguration gesperrt</h4>
          Um die Art des Datenfeldes zu ändern oder die Konfiguration anzupassen, darf kein Vorgabewert gesetzt sein. Bitte löschen Sie diesen und speichern das Datenfeld.
        </v-alert>
        <switch-control
          v-model="internalValue.required"
          label="Erforderlich beim Erstellen und Aktualisieren eines Dossiers"
          :disabled="requestableLoading || (!isAdd && anyValueChanged && !requiredValueChanged && !internalValue.required) || !!value.dataTransformation || isAdd"
          :error-messages="validationErrorMessageFor('required')"
          :hint="isAdd ? 'Diese Eigenschaft kann nach dem Erstellen über eine Datentransformation geändert werden' : undefined"
          :persistent-hint="isAdd"
          class="mb-5"
          @input="$event ? internalValue.recommended = false : undefined"
        />
        <switch-control
          v-model="internalValue.recommended"
          label="Eingabe beim Erstellen des Dossiers ermöglichen"
          :disabled="requestableLoading || needsTransformation"
          :error-messages="validationErrorMessageFor('recommended')"
          class="mb-5"
          @input="$event ? internalValue.required = false : undefined"
        />
        <switch-control
          v-model="internalValue.unique"
          label="Wert muss für jedes Dossier unterschiedlich sein (Duplikate verbieten)"
          :disabled="requestableLoading || (!isAdd && anyValueChanged && !uniqueValueChanged && !internalValue.unique) || !!value.dataTransformation"
          :error-messages="validationErrorMessageFor('unique')"
        />
      </template>
      <v-alert
        v-if="needsTransformation"
        dense
        text
        type="warning"
        border="left"
        :icon="false"
        class="mt-2"
      >
        <h4>Datentransformation wird angelegt</h4>
        Um diese Änderungen durchzuführen wird eine Datentransformation angelegt. Nachdem diese ausgeführt wurde, wird die hier festgelegte Änderung aktiv.
      </v-alert>
      <v-btn
        v-if="!!value.dataTransformation"
        text
        plain
        small
        color="warning"
        :to="routeFor('dossier_data_transformation_detail', value.dossierDefinitionId, { params: { dataTransformationId: value.dataTransformation.id } })"
      >
        Für dieses Feld existiert eine unausgeführte Datentransformation
      </v-btn>
      <data-control
        v-if="showDataControl"
        ref="dataControl"
        v-model="internalValue.default_value"
        :type="dataControlType(internalValue.type.name)"
        :label="isNote ? $t('dossierFieldDefinition.addEditDialog.noteValue') : $t('dossierFieldDefinition.addEditDialog.defaultValue.label')"
        :options="internalValue.type.options"
        :disabled="!!value.dataTransformation || needsTransformation"
        :hint="isNote ? undefined : $t('dossierFieldDefinition.addEditDialog.defaultValue.hint')"
        persistent-hint
        class="mt-1"
      />
    </template>
    <div
      v-else-if="contentTypesQueryRequestable.requestableLoadingDelayed"
      class="fill-height d-flex justify-center align-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      />
    </div>
  </custom-dialog>
</template>
