<script>
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import DeleteDossierDefinitionDialog from 'dialogs/delete-dossier-definition-dialog.vue'
import DossierDefinition from 'mixins/models/dossier-definition'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossierDefinitionEditHeader',
  components: {
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    DeleteDossierDefinitionDialog
  },
  mixins: [PageContentable, DossierDefinition],
  computed: {
    dossierDefinition () {
      return this.value
    },
    isHidden () {
      return this.dossierDefinition?.groups?.length <= 0
    }
  },
  methods: {
    ...{ routeFor },
    onDeleted () {
      this.$router.replace({ name: 'dossier-definitions' })
    }
  }
}
</script>
<template>
  <div v-if="value">
    <v-alert
      v-if="isHidden"
      dense
      text
      type="error"
    >
      {{ $t('dossierDefinition.hiddenMessage') }}
    </v-alert>

    <breadcrumbs :items="dossierDefinitionBreadcrumbs" />

    <page-detail-header
      :state-text="dossierDefinitionStateText"
      :state-updated-at="dossierDefinitionStateUpdatedAtDate"
      :state-color="dossierDefinitionStateColor.background"
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #default>
        <span>
          <router-link
            :to="routeFor('dossiers_by_definition', dossierDefinition.id)"
            class="text-decoration-none"
          >{{ $tc('general.counts.instances', dossierDefinition.instanceCount) }}</router-link>
        </span>
      </template>
      <template #actions="{loading}">
        <kebab-menu
          :disabled="loading"
          color="primary"
        >
          <template #items="{ closeMenu }">
            <delete-dossier-definition-dialog
              :request-parameter="{ method: 'delete', url: $apiEndpoints.dossierDefinitions.destroy(value.id) }"
              @request-success="onDeleted"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>{{ $t('dossierDefinition.actions.delete') }}</v-list-item-title>
                </v-list-item>
              </template>
            </delete-dossier-definition-dialog>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <h1 class="text-h4 font-weight-light mb-4">
      {{ value.name }}
    </h1>
  </div>
</template>
