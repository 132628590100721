import i18n from 'i18n'
import { de } from 'date-fns/locale'
import { formatDistanceToNow, format, parseISO } from 'date-fns'
import isString from 'lodash/isString'

const locales = { de }
const currentLocaleId = 'de'

export function distanceDate (date) {
  if (isString(date)) date = parseISO(date)
  return formatDistanceToNow(date, { locale: locales[currentLocaleId], addSuffix: true })
}

export function formatDateTime (date) {
  return i18n.t('general.time', { time: format(date, 'P, p', { locale: locales[currentLocaleId] }) })
}

export function formatDate (date, formatStr = 'P') {
  return format(date, formatStr, { locale: locales[currentLocaleId] })
}

export function formatTime (time) {
  return i18n.t('general.time', { time: format(time, 'p', { locale: locales[currentLocaleId] }) })
}

export function iso8601DateObject (datetime) {
  const result = {
    date: null,
    time: null
  }

  if (datetime) {
    const date = new Date(datetime)
    result.date = format(date, 'yyyy-MM-dd')
    result.time = format(date, 'HH:mm:ss')
  }

  return result
}

export function currentIso8601Timestamp () {
  return format(new Date(), "yyy-MM-dd'T'HH:mm:ss")
}

export function currentIso8601Date () {
  return format(new Date(), 'yyy-MM-dd')
}
