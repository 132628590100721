/* eslint no-console: 0 */

import Vue from 'vue'
import router from 'router'
import i18n from 'i18n'
import vuetify from 'plugins/vuetify'
import 'plugins/v-currency-field'
import 'plugins/action-cable'
import config from 'helpers/config'
import eventBus from 'helpers/event-bus'
import Request from 'api/request'
import apiEndpoints from 'api/endpoints'
import AppWrapper from 'components/app-wrapper'
import browserDetect from 'vue-browser-detect-plugin'
import UniqueId from 'vue-unique-id'
import * as Sentry from '@sentry/vue'

// Remove "false &&" to active debug logging
Vue.prototype.$debugLog = (...data) => false && console.debug(...data)
Vue.$debugLog = Vue.prototype.$debugLog

if (config.sentry_dsn?.length) {
  Sentry.init({
    Vue,
    dsn: config.sentry_dsn,
    release: config.release,
    initialScope: {
      tags: { host: config.host },
      ...(config.current_user ? { user: { id: config.current_user.id, email: config.current_user.email } } : {})
    },
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport)
  })
}

Vue.use(browserDetect)
Vue.use(UniqueId)

document.addEventListener('DOMContentLoaded', () => {
  (() => new Vue({
    el: '#vue-app',
    i18n,
    vuetify,
    apiEndpoints,
    config,
    router,
    components: { AppWrapper }
  }))()
})

let newReleaseInfoVisible = false
window.addEventListener('focus', () => {
  if (config.current_user || config.share_token) {
    (new Request()).request(Request.GET, apiEndpoints.users.ping())
      .then(data => {
        Request.setAxiosCSRFToken(data?.form_authenticity_token)

        if (data?.revision && config.release && data?.revision !== config.release) {
          if (!newReleaseInfoVisible) {
            eventBus.$emit('quick-message', i18n.t('general.newReleaseMessage.text'),
              'error', 0, {
                text: i18n.t('general.newReleaseMessage.button'),
                action: () => location.reload()
              }, true
            )
            newReleaseInfoVisible = true
            Sentry.captureMessage('New release warning shown to user due to outdated client.')
          }
        }
      })
      .catch(() => {
        Sentry.captureMessage('Ping request error.')
      })
  }
})
