<script>
import ObjectsControl from 'controls/objects-control/index.vue'
import ObjectListItem from 'list-items/object-list-item.vue'
import UserListItem from 'list-items/user-list-item.vue'
import Avatar from 'components/avatar.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'UsersControl',
  inject: {
    components: {
      default: {
        ObjectsControl,
        ObjectListItem,
        UserListItem,
        Avatar
      }
    },
    helper: {
      default: {
        routeFor
      }
    }
  },
  model: ObjectsControl.model,
  props: {
    ...ObjectsControl.props,
    avatarListItem: Boolean,
    to: {
      type: Function,
      default: (item) => { return routeFor('user', item.id) }
    }
  }
}
</script>
<template functional>
  <component
    :is="injections.components.ObjectsControl"
    :class="[data.class || '', data.staticClass || '']"
    v-bind="props"
    v-on="listeners"
  >
    <template
      v-if="props.avatarListItem"
      #list="{items}"
    >
      <div class="d-flex flex-wrap px-4 py-2">
        <v-btn
          v-for="item in items"
          :key="item.id"
          icon
          :to="injections.helper.routeFor('user', item.id)"
          class="mr-1 mb-2"
        >
          <component
            :is="injections.components.Avatar"
            :skeleton="item.skeleton"
            :text="item.avatar.label"
            :image="item.avatar.url"
            :tooltip-subtitle="item.email"
            :tooltip-title="item.fullname"
          />
        </v-btn>
      </div>
    </template>
    <template #list-item="{item, cssClass, itemClass, indent, itemLink, skeleton, selectable, selected, toggleElement}">
      <component
        :is="injections.components.ObjectListItem"
        v-if="item.type && item.type === 'custom'"
        :key="item.id"
        :value="{
          id: item.id,
          title: item.title,
          subtitleElements: [item.subtitle]
        }"
        :class="cssClass"
        :item-class="itemClass"
        :indent="indent"
        :skeleton="skeleton"
        :item-link="itemLink"
        :selectable="selectable"
        :selected="selected"
        v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
      />
      <component
        :is="injections.components.UserListItem"
        v-else
        :key="item.id"
        :value="item"
        :class="cssClass"
        :item-class="itemClass"
        :indent="indent"
        :skeleton="skeleton"
        :item-link="itemLink"
        :selectable="selectable"
        :selected="selected"
        :to="props.to(item)"
        v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
      />
    </template>
  </component>
</template>
