import Page from '../page'
import ProcessesContent from './processes-content.vue'
import DossiersContent from './dossiers-content.vue'
import EverythingInViewContent from './everything-in-view-content.vue'
import MyLastChangesContent from './my-last-changes-content.vue'
import { VCol, VContainer, VRow } from 'vuetify/lib/components'
import map from 'lodash/map'

function createGrid (rows) {
  return {
    name: 'Grid',
    render: function (createElement) {
      return createElement(VContainer, { class: { 'pa-0': true } }, map(rows, (cols) => {
        return createElement(VRow, {}, map(cols, (col) => {
          return createElement(VCol, { props: col.props }, [createElement(col.component)])
        }))
      }))
    }
  }
}

export default {
  name: 'DashboardPage',
  mixins: [Page],
  computed: {
    pageTitleParts () {
      return ['Dashboard']
    },
    pageContentComponents () {
      return [
        ProcessesContent,
        createGrid([[
          { component: MyLastChangesContent, props: { cols: 12, md: 6 } },
          { component: EverythingInViewContent, props: { cols: 12, md: 6 } }
        ]]),
        DossiersContent
      ]
    }
  }
}
