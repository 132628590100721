<script>
import { PageContentable } from '../page'
import Vue from 'vue'
import Requestable from 'mixins/requestable'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import Request from 'api/request'
import FooterMenuEntry from 'mixins/models/footer-menu-entry'
import TextControl from 'controls/text-control.vue'
import SwitchControl from 'controls/switch-control.vue'
import ObjectsControl from 'controls/objects-control/index.vue'
import ObjectListItem from 'list-items/object-list-item.vue'
import NumericControl from 'controls/numeric-control.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'FooterMenuEntryEditContent',
  components: {
    NumericControl,
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    CustomDialog,
    TextControl,
    SwitchControl,
    ObjectsControl,
    ObjectListItem
  },
  mixins: [PageContentable, FooterMenuEntry],
  inject: {
    helper: {
      default: {
        Request
      }
    }
  },
  data () {
    return {
      tab: 0,
      locations: null,
      locationsRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (value) => { this.locations = value }
        }
      }),
      targetRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (value) => {
            if (this.$refs.control_url) this.$refs.control_url.controlShowSuccessMessage()
            if (this.$refs.control_page) this.$refs.control_page.controlShowSuccessMessage()
            this.onUpdated(value)
          }
        }
      })
    }
  },
  computed: {
    menuEntry () {
      return this.value
    }
  },
  watch: {
    value: {
      handler (value) {
        if (value) this.tab = value.url ? 1 : 0
      },
      immediate: true
    }
  },
  created () {
    this.locationsRequestable.request({ method: 'get', url: this.$apiEndpoints.menuEntries.locations() })
  },
  methods: {
    ...{ routeFor },
    onUpdated (value) {
      this.$emit('input', value)
    },
    onDeleted () {
      this.$router.replace({ name: 'footer-menu-entries' })
    },
    updateTarget ({ page = null, url = null }) {
      this.value.page = page
      this.value.url = url

      this.targetRequestable.request(
        { method: 'patch', url: this.$apiEndpoints.menuEntries.update(this.value.id) }, null,
        { page_id: this.value.page ? this.value.page.id : null, url: this.value.url })
    }
  }
}

</script>
<template>
  <div v-if="value">
    <breadcrumbs :items="footerMenuEntryBreadcrumbs" />

    <page-detail-header
      :state-text="footerMenuEntryStateText"
      :state-updated-at="footerMenuEntryStateUpdatedAtDate"
      :state-color="footerMenuEntryStateColor.background"
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #actions="{actionRequest, loading}">
        <kebab-menu
          :disabled="loading"
          color="primary"
        >
          <template #items="{ closeMenu }">
            <custom-dialog
              :title="$t('footerMenuEntry.deleteDialog.title')"
              :text="$t('footerMenuEntry.deleteDialog.text')"
              :ok-btn-text="$t('footerMenuEntry.deleteDialog.buttons.ok')"
              ok-btn-color="error"
              @click-ok="actionRequest($apiEndpoints.menuEntries.destroy(value.id), onDeleted, helper.Request.DELETE)"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>{{ $t('footerMenuEntry.actions.delete') }}</v-list-item-title>
                </v-list-item>
              </template>
            </custom-dialog>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <h1 class="text-h4 font-weight-lightmb-4">
      {{ value.title }}
    </h1>

    <text-control
      v-model="value.title"
      label="Titel"
      :request-parameter="{method: 'patch', url: $apiEndpoints.menuEntries.update(value.id), mapping: 'title'}"
      :hint="$t('general.field.required')"
      persistent-hint
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <!--    <select-control-->
    <!--      v-model="value.location.value"-->
    <!--      :items="locationItemsFor(locations, value.location)"-->
    <!--      label="Menü"-->
    <!--      :request-parameter="{method: 'patch', url: $apiEndpoints.menuEntries.update(value.id), mapping: 'location'}"-->
    <!--      :hint="$t('general.field.required')"-->
    <!--      persistent-hint-->
    <!--      class="mb-5"-->
    <!--      @request-success-data="onUpdated"-->
    <!--    />-->

    <numeric-control
      v-model="value.position"
      label="Reihenfolge"
      :request-parameter="{method: 'patch', url: $apiEndpoints.menuEntries.update(value.id), mapping: 'position'}"
      :hint="$t('general.field.required')"
      persistent-hint
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <div class="text-caption">
      Verlinkung
    </div>
    <v-tabs v-model="tab">
      <v-tab>Statische Seite</v-tab>
      <v-tab>Url</v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="mb-5"
      style="overflow: visible;"
    >
      <v-tab-item>
        <objects-control
          ref="control_page"
          :value="value.page"
          label="Statische Seite"
          :hint="$t('general.field.required')"
          persistent-hint
          :list-request-parameter="{method: 'get', url: $apiEndpoints.pages.list()}"
          single
          :disabled="targetRequestable.requestableLoading"
          :error-messages="targetRequestable.validationErrorMessageFor('page')"
          @change="updateTarget({ page: $event })"
        >
          <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
            <object-list-item
              :key="item.id"
              :indent="indent"
              :item-link="itemLink"
              :selectable="selectable"
              :selected="selected"
              :skeleton="item.skeleton"
              :value="{
                title: item.title,
                subtitleElements: [item.slug]
              }"
              v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
            />
          </template>
        </objects-control>
      </v-tab-item>
      <v-tab-item>
        <text-control
          ref="control_url"
          :value="value.url"
          :options="{type: 'url'}"
          label="Url"
          :hint="$t('general.field.required')"
          persistent-hint
          :disabled="targetRequestable.requestableLoading"
          :error-messages="targetRequestable.validationErrorMessageFor('url')"
          @change="updateTarget({ url: $event })"
        />
      </v-tab-item>
    </v-tabs-items>

    <switch-control
      v-model="value.new_window"
      label="In neuem Fenster öffnen"
      :request-parameter="{method: 'patch', url: $apiEndpoints.menuEntries.update(value.id), mapping: 'new_window'}"
      @request-success-data="onUpdated"
    />
  </div>
</template>
