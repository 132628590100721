import compact from 'lodash/compact'
import round from 'lodash/round'
import { formatDateTime } from 'helpers/date'

export function createdAtLocalized (file) {
  if (file.createdAt) {
    return formatDateTime(new Date(file.createdAt))
  }

  return null
}

export function subtitleElements (file) {
  return compact([file.name, fileSizeString(file.size), createdAtLocalized(file)])
}

function fileSizeString (sizeInBytes) {
  if (sizeInBytes) {
    if (sizeInBytes >= 1000) {
      if (sizeInBytes > 1000000) {
        return `${round(sizeInBytes / 1000000)} MB`
      } else {
        return `${round(sizeInBytes / 1000)} kB`
      }
    } else {
      return `${sizeInBytes} Byte`
    }
  } else {
    return ''
  }
}
