<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import TaskDefinitionListItem from 'list-items/task-definition-list-item.vue'
import BlockDefinitionListItem from 'list-items/block-definition-list-item.vue'
import Requestable from 'mixins/requestable'
import ProcessDefinition from 'mixins/models/process-definition'
import { routeFor } from 'helpers/route'
import eventBus from 'helpers/event-bus'
import draggable from 'vuedraggable'
import has from 'lodash/has'
import last from 'lodash/last'

export default {
  name: 'ProcessDefinitionEditSidebarLeft',
  components: {
    TaskDefinitionListItem,
    BlockDefinitionListItem,
    draggable
  },
  mixins: [PageContentable, ProcessDefinition],
  props: {
    type: {
      type: String,
      required: true
    },
    subId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      addRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (value) => {
            this.$emit('input', value)

            const addedElement = last(value.structure)
            switch (addedElement.type) {
              case 'task_definition':
                this.$router.push(routeFor('task_definition', value.id, { params: { taskDefinitionId: addedElement.id } }))
                break
              case 'block_definition':
                this.$router.push(routeFor('block_definition', value.id, { params: { blockDefinitionId: addedElement.id } }))
                break
            }
          },
          onRequestError (error) {
            eventBus.$emit('quick-message', error.forAll(), 'error')
          }
        }
      }),
      moveRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (value) => {
            this.$emit('input', value)
          },
          onRequestError (error) {
            eventBus.$emit('quick-message', error.forAll(), 'error')
          }
        }
      })
    }
  },
  computed: {
    processDefinition () {
      return this.value
    }
  },
  methods: {
    ...{ routeFor },
    putInBlock (to, from, dragEl, event) {
      return !dragEl.classList.contains('block-definition-list-item')
    },
    onPositionChanged (parent, event) {
      if (has(event, 'moved')) {
        this.moveItem(event.moved.element.type, event.moved.element.id, event.moved.newIndex, parent.type, parent.id)
      } else if (has(event, 'added')) {
        this.moveItem(event.added.element.type, event.added.element.id, event.added.newIndex, parent.type, parent.id)
      }
    },
    moveItem (type, id, newIndex, parentType, parentId) {
      if (type === 'task_definition') {
        this.moveRequestable.request(
          { method: 'patch', url: this.$apiEndpoints.taskDefinitions.move(id) },
          null,
          { target_type: parentType, target_id: parentId, index: newIndex })
      } else if (type === 'block_definition') {
        this.moveRequestable.request(
          { method: 'patch', url: this.$apiEndpoints.blockDefinitions.move(id) },
          null,
          { target_type: parentType, target_id: parentId, index: newIndex })
      }
    }
  }
}
</script>
<template>
  <v-list
    v-if="value"
    class="pa-0"
  >
    <v-list-item
      :to="routeFor('process_definition', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-subtitle>Prozessvorlage</v-list-item-subtitle>
        <v-list-item-title>{{ processDefinition.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />

    <div class="mt-2 pr-2 d-flex align-center">
      <v-subheader
        class="flex-grow-1 text-uppercase"
        style="letter-spacing: .1em"
      >
        Struktur
      </v-subheader>

      <v-menu
        left
        bottom
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            icon
            :loading="addRequestable.requestableLoadingDelayed"
            :disabled="addRequestable.requestableLoading || isReadonly"
            v-on="on"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="addRequestable.request({ method: 'post', url: $apiEndpoints.taskDefinitions.create() }, null, { workflow_definition_id: processDefinition.id })">
            <v-list-item-content>Aufgabe hinzufügen</v-list-item-content>
          </v-list-item>
          <v-list-item @click="addRequestable.request({ method: 'post', url: $apiEndpoints.blockDefinitions.create() }, null, { workflow_definition_id: processDefinition.id })">
            <v-list-item-content>Block hinzufügen</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <draggable
      :list="value.structure"
      :group="{ name: 'process-structure-group' }"
      handle=".handle"
      :force-fallback="$browserDetect.isChrome"
      :animation="200"
      direction="vertical"
      :empty-insert-threshold="10"
      fallback-on-body
      swap-threshold="0.2"
      invert-swap
      :disabled="isReadonly"
      @change="onPositionChanged({ type: 'process_definition', id: value.id }, $event)"
    >
      <template v-for="item in value.structure">
        <task-definition-list-item
          v-if="item.type === 'task_definition'"
          :key="`${item.type}-${item.id}`"
          :value="item"
          :to="routeFor('task_definition', value.id, { params: { taskDefinitionId: item.id }})"
          :ripple="false"
          :disabled="isReadonly"
          class="task-definition-list-item list-item-bottom-border"
        />

        <block-definition-list-item
          v-else-if="item.type === 'block_definition'"
          :key="`${item.type}-${item.id}`"
          :value="item"
          :to="routeFor('block_definition', value.id, { params: { blockDefinitionId: item.id }})"
          :ripple="false"
          :disabled="isReadonly"
          class="block-definition-list-item list-item-bottom-border"
        >
          <draggable
            :list="item.items"
            :group="{ name: 'process-structure-group', put: putInBlock}"
            handle=".handle"
            :force-fallback="$browserDetect.isChrome"
            :animation="200"
            direction="vertical"
            :empty-insert-threshold="10"
            fallback-on-body
            swap-threshold="0.2"
            invert-swap
            :class="{'group-drop-zone': item.items.length === 0}"
            :disabled="isReadonly"
            @change="onPositionChanged({ type: 'block_definition', id: item.id }, $event)"
          >
            <task-definition-list-item
              v-for="(subTask, indexSub) in item.items"
              :key="`${subTask.type}-${subTask.id}`"
              :value="subTask"
              :to="routeFor('task_definition', value.id, { params: { taskDefinitionId: subTask.id }})"
              :ripple="false"
              :disabled="isReadonly"
              class="task-definition-list-item"
              :class="{'list-item-bottom-border': indexSub + 1 < item.items.length}"
            />
          </draggable>
        </block-definition-list-item>
      </template>
    </draggable>
  </v-list>
</template>

<style lang="scss">
  .list-item-bottom-border {
    border-bottom-color: rgba(0,0,0,.12);
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .block-definition-list-item {
    & > .v-list-item__content {
      padding-bottom: 0;
    }

    &.sortable-chosen .sub-items {
      display: none;
    }

    .task-definition-list-item {
      padding-left: 0;
    }
  }

  .group-drop-zone {
    min-height: 20px;
  }
</style>
