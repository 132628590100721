export default {
  actions: {
    create: 'Nutzer:in erstellen'
  },
  detailActions: {
    deactivate: 'Nutzer:in deaktivieren',
    activate: 'Nutzer:in aktivieren'
  },
  createDialog: {
    title: 'Neue:n Nutzer:in erstellen',
    buttons: {
      saveAndCreateAnother: 'Speichern und weitere:n erstellen'
    },
    fields: {
      email: 'E-Mail',
      firstname: 'Vorname',
      lastname: 'Nachname'
    },
    successMessage: 'Nutzer "{title}" wurde erfolgreich erstellt'
  },
  emailNotificationInterval: {
    message: 'Du erhältst die aktuellste Benachrichtigungen {interval} per Mail. Das kannst Du {link} ändern.',
    linkText: 'hier'
  },
  calendarSharing: {
    dialogs: {
      reset: {
        title: 'Kalenderfreigabe zurücksetzen',
        text: 'Wenn Sie die Kalenderfreigabe zurücksetzen, wird eine neue Privatadresse im iCal-Format für ihren persönlichen Samarbeid-Kalender erzeugt. Alle bestehenden Einbindungen in andere Anwendungen funktionieren nicht mehr und müssen erneuert werden.',
        buttons: {
          ok: 'zurücksetzen'
        }
      },
      deactivate: {
        title: 'Kalenderfreigabe deaktivieren',
        text: 'Wenn Sie die Kalenderfreigabe deaktivieren, wird die Privatadresse ihres persönlichen Samarbeid-Kalender unwiederbringlich gelöscht. Alle bestehenden Einbindungen in andere Anwendungen funktionieren nicht mehr und müssen auch nach erneuter Aktivierung erneuert werden.',
        buttons: {
          ok: 'deaktivieren'
        }
      }
    }
  },
  externalApiSharing: {
    dialogs: {
      reset: {
        title: 'Api-Token zurücksetzen',
        text: 'Wenn Sie das Api-Token zurücksetzen, wird ein neues Token erzeugt. Alle bestehenden Einbindungen in andere Anwendungen funktionieren nicht mehr und müssen erneuert werden.',
        buttons: {
          ok: 'zurücksetzen'
        }
      },
      deactivate: {
        title: 'Api-Token deaktivieren',
        text: 'Wenn Sie das Api-Token deaktivieren, wird Token unwiederbringlich gelöscht. Alle bestehenden Einbindungen in andere Anwendungen funktionieren nicht mehr und müssen auch nach erneuter Aktivierung erneuert werden.',
        buttons: {
          ok: 'deaktivieren'
        }
      }
    }
  }
}
