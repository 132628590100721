<script>
import filter from 'lodash/filter'

export default {
  name: 'FilterMultiSelect',
  props: {
    value: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    removeItem (item) {
      const newValue = filter(this.value, i => i !== item)
      if (this.value.length > newValue.length) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>
<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :label="label"
    multiple
    outlined
    hide-details
    @change="$emit('input', $event)"
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="removeItem(data.item.value)"
      >
        {{ data.item.text }}
      </v-chip>
    </template>
    <template #item="data">
      <v-list-item-content>
        {{ data.item.text }}
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
