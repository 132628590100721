<script>
export default {
  name: 'CustomDialog',
  props: {
    value: Boolean,
    title: { type: String, required: true },
    text: { type: String, default: undefined },
    customBtnText: {
      type: String,
      default: undefined
    },
    customBtnColor: {
      type: String,
      default: 'secondary'
    },
    customBtnDisabled: {
      type: Boolean,
      default: false
    },
    cancelBtnText: {
      type: String,
      default: 'Abbrechen'
    },
    cancelBtnColor: {
      type: String,
      default: 'secondary'
    },
    okBtnText: {
      type: String,
      default: 'OK'
    },
    okBtnColor: {
      type: String,
      default: 'primary'
    },
    okBtnDisabled: {
      type: Boolean,
      default: false
    },
    additionalOkBtnTexts: {
      type: Array,
      default: () => []
    },
    closeOnButtonClick: {
      type: Boolean,
      default: true
    },
    fullheight: Boolean,
    fullwidth: Boolean,
    loading: Boolean,
    errorMessage: {
      type: String,
      default: undefined
    },
    successMessage: {
      type: String,
      default: undefined
    },
    contentClass: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      internalValue: false
    }
  },
  computed: {
    contentClasses () {
      const classes = ['custom-dialog']

      if (this.fullheight) classes.push('custom-dialog--fullheight')
      if (this.fullwidth) classes.push('custom-dialog--fullwidth')
      if (this.contentClass) classes.push(this.contentClass)

      return classes.join(' ')
    },
    onActivator () {
      return {
        click: (event) => {
          this.internalValue = true
        }
      }
    }
  },
  watch: {
    value: {
      handler (val) {
        if (this.internalValue !== val) {
          this.internalValue = val
          this.onVisibilityChangedUpdated(val)
        }
      },
      immediate: true
    },
    internalValue: {
      handler (val) {
        this.onVisibilityChangedUpdated(val)
      }
    }
  },
  methods: {
    onCustomBtnClicked () {
      if (this.closeOnButtonClick) {
        this.internalValue = false
        this.onVisibilityChangedUpdated(false)
      }
      this.$emit('click-custom')
    },
    onCancelBtnClicked () {
      if (this.closeOnButtonClick) {
        this.internalValue = false
        this.onVisibilityChangedUpdated(false)
      }
      this.$emit('click-cancel')
    },
    onOkBtnClicked (index) {
      if (this.closeOnButtonClick) {
        this.internalValue = false
        this.onVisibilityChangedUpdated(false)
      }

      this.$emit('click-ok', index)
    },
    onVisibilityChangedUpdated (open) {
      this.$emit('input', open)
      this.$emit(open ? 'open' : 'close')
    }
  }
}
</script>
<template>
  <v-dialog
    v-model="internalValue"
    :content-class="contentClasses"
    persistent
    max-width="960"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{}"
    >
      <slot
        name="activator"
        :on="onActivator"
      />
    </template>
    <v-card :loading="loading">
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="$scopedSlots.default">
        <slot />
      </v-card-text>
      <v-card-text v-else-if="text">
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <div
          v-if="errorMessage"
          class="error--text"
        >
          {{ errorMessage }}
        </div>
        <div
          v-else-if="successMessage"
          class="success--text"
        >
          {{ successMessage }}
        </div>
        <v-spacer />

        <v-btn
          v-if="customBtnText"
          :color="customBtnColor"
          text
          :disabled="loading || customBtnDisabled"
          class="mr-2"
          @click="onCustomBtnClicked"
        >
          {{ customBtnText }}
        </v-btn>

        <v-btn
          v-if="cancelBtnText"
          :color="cancelBtnColor"
          text
          :disabled="loading"
          @click="onCancelBtnClicked"
        >
          {{ cancelBtnText }}
        </v-btn>

        <v-btn-toggle
          v-if="okBtnText"
          borderless
          dense
          class="ml-2"
          :value-comparator="() => false"
        >
          <v-btn
            :color="okBtnColor"
            depressed
            :disabled="loading || okBtnDisabled"
            @click="onOkBtnClicked(undefined)"
          >
            {{ okBtnText }}
          </v-btn>
          <v-menu
            v-if="additionalOkBtnTexts.length > 0"
            top
            left
            offset-y
            :nudge-top="8"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :color="okBtnColor"
                depressed
                :disabled="loading || okBtnDisabled"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>
            <v-list
              dense
              class="py-0"
            >
              <v-list-item
                v-for="(btnText, index) in additionalOkBtnTexts"
                :key="index"
                class="text-button"
                :class="okBtnColor"
                :style="(index < additionalOkBtnTexts.length -1) ? 'border-bottom: 1px white solid !important;' : ''"
                dark
                @click="onOkBtnClicked(index)"
              >
                {{ btnText }}
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn-toggle>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.custom-dialog--fullheight {
  height: 90%;
  overflow-y: unset;

  & > .v-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > .v-card__text {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}
.custom-dialog--fullheight, .custom-dialog--fullwidth {
  width: 100%;
}
</style>
