<script>
import ObjectsControl from 'controls/objects-control/index.vue'
import DossierListItem from 'list-items/dossier-list-item.vue'
import { requestablePropFactory } from 'mixins/requestable'
import defaults from 'lodash/defaults'
import omit from 'lodash/omit'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossierControl',
  components: {
    DossierListItem,
    ObjectsControl,
    CreateDossierDialog: () => import('dialogs/create-dossier-dialog.vue')
  },
  model: ObjectsControl.model,
  props: {
    ...requestablePropFactory().props,
    ...omit(ObjectsControl.props, ['updateRequestParameter', 'listRequestParameter']),
    options: {
      type: Object,
      default: () => {}
    },
    childOfDialog: Boolean
  },
  computed: {
    internalOptions () {
      return defaults({}, this.options, {
        multiple: false,
        type: undefined
      })
    },
    listRequestParameter () {
      return { method: 'get', url: this.$apiEndpoints.dossiers.list(), params: { definition_id: this.internalOptions.type.id } }
    },
    updateRequestParameter () {
      return this.requestParameter
    },
    singleSelect () {
      return !this.internalOptions.multiple
    }
  },
  methods: {
    ...{ routeFor }
  }
}
</script>
<template>
  <objects-control
    v-bind="{...$props, ...$attrs}"
    :list-request-parameter="listRequestParameter"
    :update-request-parameter="updateRequestParameter"
    :single="singleSelect"
    v-on="$listeners"
  >
    <template #list-item="{item, cssClass, itemClass, indent, itemLink, skeleton, selectable, selected, toggleElement}">
      <dossier-list-item
        :key="item.id"
        :class="cssClass"
        :item-class="itemClass"
        :indent="indent"
        :skeleton="skeleton"
        :value="item"
        :item-link="itemLink"
        :selectable="selectable"
        :selected="selected"
        :to="routeFor('dossier', item.id)"
        v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
      />
    </template>

    <template
      v-if="!childOfDialog"
      #create-action="{onCreated}"
    >
      <create-dossier-dialog
        :definition="internalOptions.type"
        @created="onCreated"
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            icon
            class="mt-n2"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </create-dossier-dialog>
    </template>
  </objects-control>
</template>
