<script>
import PageContent from '../page-content'
import PageTitle from 'mixins/page-title'

export default {
  name: 'ConfirmationPage',
  components: {
    PageContent
  },
  mixins: [PageTitle],
  props: {
    tokenEmail: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      email: '',
      rules: {
        required: value => !!value || 'Erforderlich'
      }
    }
  },
  computed: {
    authenticityToken () {
      return document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' }
    },
    pageTitleParts () {
      return ['Anleitung zur Bestätigung noch mal schicken']
    }
  },
  watch: {
    tokenEmail: {
      handler (value) {
        this.email = value
      },
      immediate: true
    }
  }
}
</script>
<template>
  <div class="lost-password-page">
    <page-content :col-props="{cols: 12, sm: 6}">
      <h4 class="mb-6">
        Anleitung zur Bestätigung noch mal schicken
      </h4>

      <v-form
        :action="$route.path"
        accept-charset="UTF-8"
        method="post"
        class="mb-4"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="authenticityToken.content"
        >
        <v-text-field
          v-model="email"
          type="email"
          name="user[email]"
          label="E-Mail"
          :rules="[rules.required]"
          required
        />
        <v-btn
          type="submit"
          :disabled="email === ''"
          class="mt-2"
        >
          Anleitung zur Bestätigung noch mal schicken
        </v-btn>
      </v-form>
      <a :href="$router.resolve({name: 'sign-in-page'}).href">Zur Anmeldung</a>
    </page-content>
  </div>
</template>
