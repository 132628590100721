<script>
import Vue from 'vue'
import Requestable from 'mixins/requestable'
import ActivityHubItem from '../components/activity-hub/activity-hub-item.vue'
export default {
  name: 'ObsoleteEventBanner',
  components: { ActivityHubItem },
  data () {
    return { eventId: undefined, eventIsObsolete: false, event: undefined }
  },
  watch: {
    '$route.query.event': {
      handler: function (newId) {
        this.eventId = newId
        this.loadAndUpdate()
      },
      immediate: true
    }
  },
  methods: {
    loadAndUpdate: function () {
      if (!this.eventId) {
        this.eventIsObsolete = false
        this.event = undefined
        return
      }
      const requestable = new (Vue.extend(Requestable))()
      requestable.onRequestSuccess = (data) => {
        if (data.obsolete) {
          this.eventIsObsolete = true
          this.event = data.last_superseder
        }
      }
      requestable.request({ method: Request.GET, url: this.$apiEndpoints.events.obsoleteInfo(this.eventId) })
    }
  }
}
</script>
<template>
  <v-banner
    icon="mdi-clock-alert-outline"
    icon-color="warning"
    transition="slide-y-transition"
    :value="eventIsObsolete"
  >
    Diese Benachrichtigung ist nicht mehr aktuell.
    <div
      v-if="event"
    >
      Zwischenzeitlich kam folgende Änderung dazu:
      <v-timeline
        class="mt-4 ml-n5 pr-4"
        dense
      >
        <activity-hub-item
          v-if="event"
          :event="event"
        />
      </v-timeline>
    </div>
    <template #actions="{ dismiss }">
      <v-btn
        text
        color="primary"
        @click="dismiss"
      >
        Alles klar
      </v-btn>
    </template>
  </v-banner>
</template>
