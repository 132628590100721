<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import Avatar from 'components/avatar.vue'
import DataControl from 'controls/data-control'
import MonitorSubscription from 'components/monitor-subscription.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import Requestable from 'mixins/requestable'
import DataFields from 'mixins/data-fields'
import Request from 'api/request'

export default {
  name: 'ShareShowContent',
  components: { CustomDialog, MonitorSubscription, DataControl, Avatar },
  mixins: [PageContentable, DataFields],
  data () {
    return {
      completeDialogOpen: false,
      completeRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.$emit('input', data)
          }
        }
      })
    }
  },
  computed: {
    task () {
      return this.value?.task
    },
    token () {
      return this.pageProps.token
    }
  },
  watch: {
    task: {
      handler (value, oldValue) {
        if (this.task && (!oldValue || this.task.id !== oldValue.id)) {
          this.dataFieldsLoadFields(this.task.id)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    completeShareRequest () {
      this.completeRequestable.resetRequestable()
      this.completeRequestable.request({ method: Request.PATCH, url: this.$apiEndpoints.share.complete(this.token) })
    },
    openCompleteDialog () {
      this.completeDialogOpen = true
    },
    onMonitorMessage (data) {
      if (this.dataFieldsOnMonitorMessage(data, this.dataFieldsLoadField)) return

      switch (data.action) {
        case 'updated':
          this.$emit('fetchPageData')
          break
      }
    }
  }
}
</script>
<template>
  <div
    v-if="!value || (!value.completed && !dataFieldsItems)"
    class="mt-16 d-flex justify-center align-center"
  >
    <v-progress-circular
      color="primary"
      indeterminate
    />
  </div>
  <div
    v-else
    class="mt-4"
  >
    <div
      v-if="value.requester"
      class="d-flex mb-8"
    >
      <avatar
        v-if="value.requester.avatar"
        :text="value.requester.avatar.label"
        :image="value.requester.avatar.url"
        :size="60"
        class="mr-4"
      />
      <div>
        <div class="text-h6">
          {{ value.requester.fullname }}
        </div>
        <div>{{ $t('share.introWithRequesterText') }}</div>
      </div>
    </div>
    <div
      v-else
      class="text-h6 mb-8"
    >
      {{ $t('share.introWithoutRequesterText') }}
    </div>

    <div v-if="task && dataFieldsItems">
      <monitor-subscription
        :key="`monitor-channel-${task.gid}`"
        :gid="task.gid"
        @received="onMonitorMessage"
      />
      <custom-dialog
        v-model="dataFieldsErrorDialogOpen"
        :title="$t('task.taskDataWithErrorsDialog.title')"
        :text="$t('task.taskDataWithErrorsDialog.text')"
        :cancel-btn-text="null"
      />
      <custom-dialog
        v-model="completeDialogOpen"
        :title="$t('share.dialogs.complete.title')"
        :text="$t('share.dialogs.complete.text')"
        :ok-btn-text="$t('share.dialogs.complete.buttons.ok')"
        @click-ok="completeShareRequest"
      />

      <div class="mb-6">
        <div class="text-h5 text-truncate">
          {{ task.name }}
        </div>

        <data-control
          v-for="(dataField, index) in dataFieldsItems"
          :ref="dataFieldsControlRef(dataField)"
          :key="'control_' + dataField.definition.task_item_id"
          v-model="dataField.value"
          :acl-object-global-id="task.gid"
          :type="dataField.definition.type"
          :label="dataField.definition.name"
          :options="dataField.definition.options"
          :required="dataField.definition.required"
          :infobox="dataField.definition.infobox"
          :locked-by="dataField.locked ? {fullname: 'wird'} : null"
          :disabled="task.dataReadonly || completeRequestable.requestableLoadingDelayed"
          :request-parameter="{method: 'patch', url: $apiEndpoints.dataItems.update(dataField.definition.task_item_id), mapping: (data) => { return { value: data, lock_version: dataField.lock_version }}}"
          :lock-request-parameter="{method: 'patch', url: $apiEndpoints.dataItems.lock(dataField.definition.task_item_id), mapping: () => { return { lock_version: dataField.lock_version }}}"
          :unlock-request-parameter="{method: 'patch', url: $apiEndpoints.dataItems.unlock(dataField.definition.task_item_id)}"
          :class="{'mb-5': index < dataFieldsItems.length - 1}"
          :mention-disabled="true"
          @request-success-data="dataFieldsOnDataFieldUpdated(dataField, $event)"
          @before-lock="dataFieldsOnDataFieldBeforeLock(dataField)"
          @lock="dataFieldsOnDataFieldLocked(dataField, $event)"
          @unlock="dataFieldsOnDataFieldUnlocked(dataField, $event)"
        />
      </div>

      <v-alert
        v-if="completeRequestable.hasError"
        class="mb-6"
        dense
        text
        type="error"
      >
        {{ completeRequestable.baseErrorMessage }}
      </v-alert>

      <v-btn
        color="primary"
        :disabled="completeRequestable.requestableLoadingDelayed"
        @click="dataFieldsExecFuncWithDataErrorCheck(openCompleteDialog)"
      >
        {{ $t('share.actions.complete') }}
      </v-btn>

      <div class="mt-2 text-body-2 text--secondary">
        {{ $t('share.completeButtonHint') }}
      </div>
    </div>
    <div
      v-else
      class="text-h5"
    >
      <template v-if="value.completed">
        {{ $t('share.completedText') }}
      </template>
    </div>
  </div>
</template>
