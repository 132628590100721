export default {
  actions: {
    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    delete: 'Löschen'
  },
  subActions: {
    add: {
      dataField: 'Datenfeld hinzufügen',
      noteField: 'Hinweisfeld hinzufügen'
    },
    edit: {
      dataField: 'Datenfeld bearbeiten',
      noteField: 'Hinweisfeld bearbeiten'
    }
  },
  deleteDialog: {
    title: 'Datenfeld aus Dossiervorlage löschen?',
    text: 'Löschen Sie das Datenfeld aus der Dossiervorlage nur, wenn Sie sicher sind, dass dieses Datenfeld nicht mehr benöigt wird. Gelöschte Datenfelder und ihre Werte gehen unwiederbringlich verloren.',
    buttons: {
      ok: 'Datenfeld löschen'
    }
  },
  addEditDialog: {
    noteValue: '@:task.addEditFieldDialog.controls.noteValue',
    defaultValue: {
      label: '@:task.addEditFieldDialog.controls.defaultValue.label',
      hint: 'Feld wird beim Erstellen des Dossiers mit diesem Wert vorbelegt'
    }
  }
}
