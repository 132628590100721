<script>
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import Request from 'api/request'
import Group from 'mixins/models/group'

export default {
  name: 'GroupEditHeader',
  components: {
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    CustomDialog
  },
  mixins: [PageContentable, Group],
  inject: {
    helper: {
      default: {
        Request
      }
    }
  },
  computed: {
    group () {
      return this.value
    }
  },
  methods: {
    onDeleted () {
      this.$router.replace({ name: 'groups' })
    }
  }
}
</script>
<template>
  <div v-if="value">
    <v-alert
      v-if="isSystemGroupAll"
      dense
      text
      type="info"
    >
      Diese Gruppe beinhaltet automatisch alle Nutzer:innen. Sie kann nicht bearbeitet werden.
    </v-alert>

    <breadcrumbs :items="groupBreadcrumbs" />

    <page-detail-header
      :state-text="groupStateText"
      :state-updated-at="groupStateUpdatedAtDate"
      :state-color="groupStateColor.background"
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #default>
        <span v-if="value.groupMember">Du bist Mitglied</span>
      </template>
      <template #actions="{actionRequest, loading}">
        <kebab-menu
          :disabled="loading || !$config.current_user.isAdmin || isReadonly"
          color="primary"
        >
          <template #items="{ closeMenu }">
            <custom-dialog
              :title="$t('group.deleteDialog.title')"
              :text="$t('group.deleteDialog.text')"
              :ok-btn-text="$t('group.deleteDialog.buttons.ok')"
              ok-btn-color="error"
              @click-ok="actionRequest($apiEndpoints.groups.destroy(value.id), onDeleted, helper.Request.DELETE)"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>{{ $t('group.detailActions.delete') }}</v-list-item-title>
                </v-list-item>
              </template>
            </custom-dialog>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <h1 class="text-h4 font-weight-light mb-4">
      {{ value.name }}
    </h1>
  </div>
</template>
