<script>
import eventBus from '../helpers/event-bus'
import VSnackbars from 'v-snackbars'

export default {
  name: 'GlobalSnackbars',
  components: { VSnackbars },
  data () {
    return {
      messageObjects: []
    }
  },
  mounted () {
    eventBus.$on('quick-message', (text, color = 'black', timeout = 5000, button = { text: 'Schließen', action: null }, global = false) => {
      this.messageObjects.push({ message: { text, button }, color, timeout, global })
    })
    eventBus.$on('page-changed', () => {
      for (let idx = this.messageObjects.length - 1; idx >= 0; idx--) {
        if (!this.messageObjects[idx].global) this.messageObjects.splice(idx, 1)
      }
    })
  },
  methods: {
    onCloseBtnClicked (props) {
      if (props.message.button.action) props.message.button.action()
      props.close()
    }
  }
}
</script>
<template>
  <div id="global-snackbars">
    <v-snackbars :objects.sync="messageObjects">
      <template #default="props">
        {{ props.message.text }}
      </template>
      <template #action="props">
        <v-btn
          text
          @click="onCloseBtnClicked(props)"
        >
          {{ props.message.button.text }}
        </v-btn>
      </template>
    </v-snackbars>
  </div>
</template>
