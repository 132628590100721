<script>
import Vue from 'vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import CreateShareDialog from 'dialogs/create-share-dialog.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import { stateColors } from 'helpers/definitions.js'
import Requestable from 'mixins/requestable.js'
import { formatDate } from 'helpers/date.js'
import Request from 'api/request.js'

export default {
  name: 'ShareWidget',
  components: {
    CustomDialog,
    CreateShareDialog,
    CopyToClipboardBtn
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      completeDialogOpen: false,
      completeRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.$emit('updated', data)
            this.completeDialogOpen = false
          }
        }
      })
    }
  },
  computed: {
    hasActiveShare () {
      return this.value[0]?.state === 'active'
    }
  },
  methods: {
    isActive (share) {
      return share.state === 'active'
    },
    hasName (share) {
      return (typeof share.name === 'string' || share.name instanceof String) && share.name.length > 0
    },
    stateColorFor (share) {
      switch (share.state) {
        case 'active':
          return stateColors.started
        case 'completed':
          return stateColors.completed
        default:
          throw new Error('stateColor: Unsupported share state: ' + share.state)
      }
    },
    localizedDateValueFor (share) {
      return formatDate(new Date(share.updatedAt))
    },
    onCompleteDialogOk () {
      this.completeRequestable.request({ method: Request.PATCH, url: this.$apiEndpoints.tasks.completeShare(this.taskId) })
    },
    onCompleteDialogCancel () {
      this.completeDialogOpen = false
    },
    onShareCreated (data) {
      this.$emit('updated', data)
    }
  }
}
</script>
<template>
  <div class="pl-4 pr-2 py-2 text-body-2 text--secondary custom-control">
    <div class="d-flex align-baseline">
      <div class="mr-2">
        {{ $t('task.widgets.share.title') }}
      </div>
      <custom-dialog
        v-if="hasActiveShare"
        v-model="completeDialogOpen"
        :title="$t('task.widgets.share.dialogs.complete.title')"
        :text="$t('task.widgets.share.dialogs.complete.text')"
        :ok-btn-text="$t('task.widgets.share.dialogs.complete.buttons.ok')"
        :close-on-button-click="false"
        :loading="completeRequestable.requestableLoading"
        :error-message="completeRequestable.baseErrorMessage"
        @click-ok="onCompleteDialogOk"
        @click-cancel="onCompleteDialogCancel"
      >
        <template #activator="{ on }">
          <v-btn
            text
            small
            color="primary"
            class="ml-auto"
            v-on="on"
          >
            {{ $t('task.widgets.share.actions.complete') }}
          </v-btn>
        </template>
      </custom-dialog>
      <create-share-dialog
        v-else
        :task-id="taskId"
        @success="onShareCreated"
      >
        <template #activator="{ on }">
          <v-btn
            text
            small
            color="primary"
            class="ml-auto"
            v-on="on"
          >
            {{ $t('task.widgets.share.actions.create') }}
          </v-btn>
        </template>
      </create-share-dialog>
    </div>
    <v-list class="py-0">
      <v-list-item
        v-for="(share, index) in value"
        :key="`share-${index}`"
        class="pl-0 pr-2"
        dense
      >
        <v-list-item-content>
          <v-list-item-title>
            <template v-if="hasName(share)">
              {{ $t('task.widgets.share.listItem.recipient', { name: share.name }) }}
            </template>
            <template v-else>
              {{ $t('task.widgets.share.listItem.noRecipient') }}
            </template>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span :class="stateColorFor(share).text">
              {{ $t(`task.widgets.share.states.${share.state}`) }}
            </span>
            {{ $t('task.widgets.share.listItem.when', { date: localizedDateValueFor(share) }) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="isActive(share)"
            class="d-flex align-center"
          >
            <a
              :href="share.url"
              target="_blank"
              class="text-decoration-none text-truncate mr-2"
            >{{ share.url }}</a>
            <copy-to-clipboard-btn
              :value="{'text/plain': share.url}"
              small
            />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
