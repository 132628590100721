// Styles
import './richtextarea.sass'

// Extensions
import VInput from 'vuetify/lib/components/VInput/VInput'
import VTextField from 'vuetify/lib/components/VTextField/VTextField'
import Mentionable from './mentionable'

// Editor
import {
  TiptapVuetify,
  Bold, Italic, Underline, Strike, Link, Blockquote, OrderedList, BulletList, ListItem, TodoItem, TodoList,
  History, CodeBlock, HardBreak, CustomImage
} from 'tiptap-vuetify'
import { CodeBlockHighlight } from 'tiptap-extensions'
import bash from 'highlight.js/lib/languages/bash'
import css from 'highlight.js/lib/languages/css'
import dockerfile from 'highlight.js/lib/languages/dockerfile'
import erb from 'highlight.js/lib/languages/erb'
import haml from 'highlight.js/lib/languages/haml'
import javascript from 'highlight.js/lib/languages/javascript'
import json from 'highlight.js/lib/languages/json'
import latex from 'highlight.js/lib/languages/latex'
import python from 'highlight.js/lib/languages/python'
import ruby from 'highlight.js/lib/languages/ruby'
import sql from 'highlight.js/lib/languages/sql'
import typescript from 'highlight.js/lib/languages/typescript'
import yaml from 'highlight.js/lib/languages/yaml'

import 'highlight.js/styles/stackoverflow-light.css'

// Utilities
import i18n from 'i18n'
import mixins from 'vuetify/lib/util/mixins'
import remove from 'lodash/remove'
import map from 'lodash/map'
import eventBus from 'helpers/event-bus'
const baseMixins = mixins(VTextField, Mentionable)

/* @vue/component */
export default baseMixins.extend({
  name: 'RichtextArea',
  props: {
    hint: {
      type: String,
      default () {
        return this.$options.propsData?.mentionDisabled === true ? null : i18n.t('control.richtextarea.hint')
      }
    },
    contentChanged: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editor: null,
      extensions: [
        [Bold],
        [Italic],
        [Underline],
        [Strike],
        [BulletList],
        [OrderedList],
        [ListItem],
        [TodoList],
        [TodoItem],
        [Link, { options: { target: '_blank' } }],
        [CustomImage, {
          options: {
            maxFileSize: 20971520,
            filterErrorFunc: this.onImageFileFilterError
          }
        }],
        [Blockquote],
        [CodeBlock],
        [History],
        [HardBreak]
      ]
    }
  },
  computed: {

    classes () {
      return {
        richtextarea: true,
        ...VTextField.options.computed.classes.call(this)
      }
    },
    editorProperties () {
      return {
        disablePasteRules: remove(map(this.extensions, ext => new ext[0]().nativeExtensionInstance.name), ext => ext !== 'link'),
        disableInputRules: true
      }
    }
  },
  methods: {
    blur (e) {
      // https://github.com/vuetifyjs/vuetify/issues/5913
      // Safari tab order gets broken if called synchronous
      window.requestAnimationFrame(() => {
        this.editor && this.editor.blur()
      })
    },

    clearableCallback () {
      this.editor && this.editor.focus()
      this.$nextTick(() => { this.internalValue = null })
    },

    genTextFieldSlot () {
      return this.$createElement('div', {
        staticClass: 'v-text-field__slot'
      }, [this.genLabel(), this.prefix ? this.genAffix('prefix') : null, this.genInput(), this.genMentionMenu(), this.suffix ? this.genAffix('suffix') : null])
    },

    genInput () {
      const listeners = Object.assign({}, this.listeners$)
      delete listeners.change // Change should not be bound externally

      return this.$createElement(TiptapVuetify, {
        props: {
          value: this.internalValue,
          extensions: this.extensions,
          cardProps: { flat: true },
          disabled: this.isDisabled || this.isReadonly,
          nativeExtensions: [this.mentionableExtension, new CodeBlockHighlight({ languages: { bash, css, dockerfile, erb, haml, javascript, json, latex, python, ruby, sql, typescript, yaml } })],
          editorProperties: this.editorProperties
        },
        on: {
          init: this.onEditorInit,
          input: this.onEditorInput,
          focus: this.onEditorFocus,
          blur: this.onEditorBlur
        },
        attrs: {
          ...this.attrs$,
          id: this.computedId
        },
        ref: 'tiptapVuetify'
      })
    },

    onClick (event) {
      if (event.target instanceof HTMLAnchorElement) {
        event.preventDefault()
        return
      }

      if (this.isFocused || this.isDisabled || !this.editor) return

      this.editor.focus()
    },

    onFocus (e) {
      if (!this.editor || this.isDisabled) return

      if (!this.editor.focused) {
        this.editor.focus()
      }

      if (!this.isFocused) {
        this.isFocused = true
        this.$emit('focus', e)
      }
    },

    onMouseDown (e) {
      VInput.options.methods.onMouseDown.call(this, e)
    },

    tryAutofocus () {
      if (!this.autofocus || typeof document === 'undefined' || !this.editor || !this.editor.focused) return false
      this.editor.focus()
      return true
    },

    onEditorInit ({ editor }) {
      this.editor = editor

      this.$nextTick(function () {
        const contentElement = this.$el.querySelector('div.tiptap-vuetify-editor__content')
        contentElement.addEventListener('focusin', this.onEditorContentFocusin)
        contentElement.addEventListener('focusout', this.onEditorContentFocusout)
      })
    },

    onEditorInput (output, info) {
      const json = info.getJSON().content

      if (Array.isArray(json) && json.length === 1 && !Object.prototype.hasOwnProperty.call(json[0], 'content')) {
        this.internalValue = null
      } else {
        this.internalValue = info.getHTML()
      }
    },

    onEditorFocus (event, view) {
      this.onFocus(event)
    },

    onEditorBlur (event, view) {
      if (this.isFocused && !this.$refs.tiptapVuetify.$el.contains(event.relatedTarget)) {
        this.onBlur(event)
      }
    },

    onEditorContentFocusin (e) {
      if (!this.editor || this.isDisabled) return

      if (!this.isFocused) {
        this.isFocused = true
        e && this.$emit('focus', e)
      }
    },

    onEditorContentFocusout (event) {
      if (this.isFocused && !this.$refs.tiptapVuetify.$el.contains(event.relatedTarget)) {
        this.onBlur(event)
      }
    },

    mentionableOnInsert () {
      if (this.editor) this.editor.focus()
    },

    onImageFileFilterError (type, file) {
      type = ['type', 'size'].includes(type) ? type : 'default'

      eventBus.$emit('quick-message', this.$t(`control.richtextarea.image_filter_errors.${type}`, { filename: file.name }), 'error')
    }
  }
})
