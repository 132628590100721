export default {
  actions: {
    create: 'Dossiervorlage erstellen',
    delete: 'Dossiervorlage löschen'
  },
  createDialog: {
    title: 'Dossiervorlage erstellen',
    successMessage: 'Dossiervorlage "{title}" wurde erfolgreich erstellt'
  },
  deleteDialog: {
    title: 'Dossiervorlage löschen?',
    text: 'Löschen Sie die Dossiervorlage, wenn Sie sicher sind, dass diese Dossiervorlage nicht mehr benöigt wird. Gelöschte Dossiervorlagen sind unwiederbringlich verloren.',
    buttons: {
      ok: 'Dossiervorlage löschen'
    }
  },
  editorHint: '@:processDefinition.editorHint',
  hiddenElements: {
    singular: 'Es existiert {count} Dossiervorlage, welche keiner Gruppe zugeordnet ist. Diese Vorlage und die dazugehörigen Instanzen sind für niemanden sichtbar.',
    plural: 'Es existieren {count} Dossiervorlage, welche keiner Gruppe zugeordnet sind. Diese Vorlagen und die dazugehörigen Instanzen sind für niemanden sichtbar.',
    message: '| @:dossierDefinition.hiddenElements.singular | @:dossierDefinition.hiddenElements.plural'
  },
  hiddenMessage: 'Diese Dossiervorlage ist keiner Gruppe zugeordnet. Die Vorlage und alle Instanzen sind für niemanden sichtbar.'
}
