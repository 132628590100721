<script>
import VRuntimeTemplate from 'v-runtime-template'
import Mention from 'components/mention.vue'

export default {
  name: 'RichtextareaContent',
  components: {
    VRuntimeTemplate,
    // eslint-disable-next-line vue/no-unused-components
    Mention
  },
  props: {
    template: {
      type: String,
      default: ''
    },
    parent: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    wrappedTemplate () {
      return `<div>${this.template}</div>`
    }
  }
}
</script>
<template>
  <v-runtime-template
    v-if="template"
    :template="wrappedTemplate"
    :parent="parent"
    class="richtextarea-content tiptap-vuetify-editor__content richtextarea-content-wrapper"
  />
</template>
<style lang="scss">
  .richtextarea-content.tiptap-vuetify-editor__content.richtextarea-content-wrapper {
    p:last-child {
      margin-bottom: 0 !important;
    }
  }
</style>
