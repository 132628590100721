<script>
import keys from 'lodash/keys'
import every from 'lodash/every'
import includes from 'lodash/includes'
import forEach from 'lodash/forEach'
import eventBus from 'helpers/event-bus'

export default {
  name: 'CopyToClipboardBtn',
  props: {
    value: {
      type: Object,
      default: undefined,
      validator: value => {
        return every(keys(value), val => includes(['text/plain', 'text/html'], val))
      }
    }
  },
  data () {
    return {
      onActivator: {
        click: () => {
          this.copyToClipboard()
        }
      }
    }
  },
  methods: {
    showSuccessMessage () {
      eventBus.$emit('quick-message', 'Erfolgreich in die Zwischenablage kopiert.')
    },
    showErrorMessage () {
      eventBus.$emit('quick-message', 'In die Zwischenablage kopieren war nicht möglich.', 'error')
    },
    copyToClipboard () {
      if (this.value && keys(this.value).length) {
        if (navigator.userAgent.toLowerCase().includes('firefox')) {
          this.copyToClipboardAlternative(this.value['text/html'], this.value['text/plain'])
        } else {
          const items = {}
          forEach(this.value, (value, key) => {
            items[key] = new Blob(
              [value],
              { type: key }
            )
          })
          const item = new ClipboardItem(items)

          navigator.clipboard.write([item]).then(
            () => this.showSuccessMessage(),
            () => this.showErrorMessage()
          )
        }
      }
    },
    copyToClipboardAlternative (valueHtml, valuePlain) {
      const input = document.createElement('input')
      input.setAttribute('type', 'text')
      input.style.display = 'none'
      input.value = valueHtml
      document.body.appendChild(input)
      input.select()

      function listener (e) {
        if (valuePlain) e.clipboardData.setData('text/plain', valuePlain)
        if (valueHtml) e.clipboardData.setData('text/html', valueHtml)

        e.preventDefault()
      }
      document.addEventListener('copy', listener)
      document.execCommand('copy')
      document.removeEventListener('copy', listener)

      document.body.removeChild(input)
    }
  }
}
</script>
<template>
  <div class="d-flex align-center">
    <slot
      name="activator"
      :on="onActivator"
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            tabindex="-1"
            style="pointer-events: all;"
            icon
            x-small
            v-bind="attrs"
            v-on="on"
            @click="copyToClipboard"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('components.copyToClipboardBtn.tooltip') }}</span>
      </v-tooltip>
    </slot>
  </div>
</template>
