import { routeFor } from 'helpers/route'

export default {
  computed: {
    dossierBreadcrumbs () {
      return [
        { text: 'Dossiers', to: { name: 'dossiers' } },
        { text: this.dossier.definition.name, to: routeFor('dossiers_by_definition', this.dossier.definition.id) }
      ]
    },
    dossierIdentifier () {
      return `*${this.dossier.id}`
    }
  }
}
