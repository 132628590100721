<script>
export default {
  name: 'SmartList',
  props: {
    value: {
      type: Array,
      default: () => undefined
    },
    loading: Boolean,
    emptyListMessage: {
      type: String,
      default: 'Für diese Filtereinstellungen gibt es keine Ergebnisse.'
    }
  }
}
</script>
<template>
  <div>
    <v-alert
      v-if="!loading && value !== undefined && value.length === 0"
      type="info"
      text
      class="mt-6"
    >
      {{ emptyListMessage }}
    </v-alert>

    <v-list
      v-else-if="loading || (value !== undefined && value.length > 0)"
      class="py-0"
    >
      <v-list-item
        v-if="loading"
        two-line
        class="py-2"
      >
        <v-list-item-content class="text-center">
          <v-progress-circular
            color="primary"
            indeterminate
          />
        </v-list-item-content>
      </v-list-item>
      <template v-else>
        <template v-if="$scopedSlots.default">
          <slot
            v-for="(item, index) in value"
            :item="item"
            :index="index"
          />
        </template>
        <slot
          v-else-if="$scopedSlots.items"
          name="items"
          :items="value"
        />
      </template>
    </v-list>
  </div>
</template>
