<script>
import { PageContentable } from '../page'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossierDefinitionEditSidebarLeft',
  mixins: [PageContentable],
  methods: {
    ...{ routeFor }
  }
}
</script>
<template>
  <v-list
    v-if="value"
    class="pa-0"
  >
    <v-list-item
      :to="routeFor('dossier_definition', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Allgemein
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />
    <v-list-item
      :to="routeFor('dossier_definition_references', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Referenzen in Dossiers
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-chip
          pill
          :color="$route.name === 'dossier-definition-references' ? 'white': undefined"
        >
          {{ String(value.referenceCount) }}
        </v-chip>
      </v-list-item-avatar>
    </v-list-item>

    <template v-if="$config.current_user.isAdmin">
      <v-divider />
      <v-list-item
        :to="routeFor('dossier_definition_groups', value.id)"
        exact
      >
        <v-list-item-content>
          <v-list-item-title>
            Gruppen
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-avatar>
          <v-chip
            pill
            :color="$route.name === 'dossier-definition-groups' ? 'white': undefined"
          >
            {{ String(value.groups.length) }}
          </v-chip>
        </v-list-item-avatar>
      </v-list-item>
    </template>

    <v-list-item
      :to="routeFor('dossier_data_transformations', value.id)"
    >
      <v-list-item-content>
        <v-list-item-title>
          Datentransformationen
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-chip
          pill
          :color="$route.name.startsWith('dossier-data-transformation') ? 'white': undefined"
        >
          {{ String(value.dataTransformations.length) }}
        </v-chip>
      </v-list-item-avatar>
    </v-list-item>
  </v-list>
</template>
