import join from 'lodash/join'

export default {
  methods: {
    setPageTitle () {
      const titleParts = this.pageTitleParts
      if (titleParts && titleParts.length) {
        document.title = `${join(titleParts, ' • ')} • ${this.$t('general.appName')}`
      } else {
        document.title = this.$t('general.appName')
      }
    }
  },
  computed: {
    pageTitleParts () {
      return null
    }
  },
  watch: {
    pageTitleParts: {
      handler () {
        this.setPageTitle()
      },
      immediate: true
    }
  }
}
