import { stateColors } from 'helpers/definitions'

export default {
  methods: {
    userIsActiveFor (user) {
      return !user.deactivatedAt
    },
    userIsConfirmedFor (user) {
      return user.confirmed
    },
    userEmailsFor (user) {
      let res = user.email
      if (user.unconfirmed_email) res += ' / ' + user.unconfirmed_email
      return res
    }
  },
  computed: {
    userBreadcrumbs () {
      return [
        { text: 'Nutzer:innen', to: { name: 'users' } },
        { text: `${this.user.id}` }
      ]
    },
    userIsActive () {
      return this.userIsActiveFor(this.user)
    },
    userIsConfirmed () {
      return this.userIsConfirmedFor(this.user)
    },
    userStateText () {
      if (!this.userIsConfirmed || !this.userIsActive) {
        const parts = []
        if (!this.userIsConfirmed) parts.push('Unbestätigt')
        if (!this.userIsActive) parts.push('Deaktiviert')
        return parts.join('/')
      } else return 'Aktualisiert'
    },
    userStateColor () {
      if (!this.userIsActive) {
        return stateColors.trashed
      } else if (!this.userIsConfirmed) {
        return stateColors.snoozed
      } else return stateColors.created
    },
    userStateUpdatedAtDate () {
      return new Date(this.user.deactivatedAt || this.user.updatedAt)
    }
  }
}
