<script>
import ObjectListItem from './object-list-item.vue'

export default {
  name: 'GroupListItem',
  components: { ObjectListItem },
  props: {
    ...ObjectListItem.props,
    subtitleElements: {
      type: Function,
      default: (value, defaultResult) => {
        return defaultResult
      }
    }
  }
}
</script>
<template>
  <object-list-item
    :value="{
      id: value.id,
      title: value.name,
      subtitleElements: subtitleElements(value,[
        $tc('general.counts.users', value.userCount) + (value.groupMember ? ` (${$t('general.groupMember')})` : ''),
        $tc('general.counts.processDefinitions', value.workflowDefinitionCount),
        $tc('general.counts.dossierDefinitions', value.dossierDefinitionCount)
      ])
    }"
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
  />
</template>
