<script>
import Avatar from 'components/avatar.vue'
import filter from 'lodash/filter'

export default {
  name: 'FilterUserMultiSelect',
  components: { Avatar },
  props: {
    value: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    removeItem (item) {
      const newValue = filter(this.value, i => i !== item)
      if (this.value.length > newValue.length) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>
<template>
  <v-autocomplete
    :value="value"
    :items="items"
    item-value="id"
    item-text="fullname"
    :label="label"
    multiple
    outlined
    hide-details
    @change="$emit('input', $event)"
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="removeItem(data.item.id)"
      >
        <avatar
          v-if="data.item.avatar && data.item.id !== null"
          :text="data.item.avatar.label"
          :image="data.item.avatar.url"
          left
          class="mr-2"
        />
        {{ data.item.fullname }}
      </v-chip>
    </template>
    <template #item="data">
      <avatar
        v-if="data.item.avatar && data.item.id !== null"
        :text="data.item.avatar.label"
        :image="data.item.avatar.url"
        class="mr-2"
      />
      {{ data.item.fullname }}
    </template>
  </v-autocomplete>
</template>
