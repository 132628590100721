<script>
export default {
  name: 'SiteFooter'
}
</script>
<template>
  <v-bottom-navigation
    app
    grow
    absolute
    :height="$scopedSlots.default ? 'auto' : undefined"
  >
    <slot>
      <template v-if="$config.menus.footer_menu">
        <v-btn
          v-for="(entry, index) in $config.menus.footer_menu"
          :key="index"
          :to="entry.slug ? { name: 'static-page-show', params: { slug: entry.slug }} : undefined"
          :href="entry.url ? entry.url : undefined"
          :target="entry.new_window ? '_blank' : undefined"
          text
        >
          {{ entry.title }}
        </v-btn>
      </template>
    </slot>
  </v-bottom-navigation>
</template>
