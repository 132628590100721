import { stateColors } from 'helpers/definitions'

export default {
  computed: {
    staticPageBreadcrumbs () {
      return [
        { text: 'Statische Seiten', to: { name: 'static-pages' } },
        { text: `${this.staticPage.id}` }
      ]
    },
    staticPageStateText () {
      return 'Aktualisiert'
    },
    staticPageStateColor () {
      return stateColors.created
    },
    staticPageStateUpdatedAtDate () {
      return new Date(this.staticPage.updatedAt)
    }
  }
}
