import Vue from 'vue'
import ActionCableVue from 'actioncable-vue'
import config from 'helpers/config'

let connectionUrl = document.head.querySelector("meta[name='action-cable-url']")?.content
if (config.share_token) connectionUrl = `${connectionUrl}?share_token=${config.share_token}`

if (connectionUrl) {
  Vue.use(ActionCableVue, {
    debug: true,
    debugLevel: 'error',
    connectionUrl,
    connectImmediately: true
  })
}
