<script>
import EditTitleDialog from 'dialogs/edit-title-dialog.vue'
import Workflow from 'mixins/models/workflow'
import ProcessDefinition from 'mixins/models/process-definition'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'

export default {
  name: 'HeaderEditContent',
  components: {
    EditTitleDialog
  },
  mixins: [Workflow, ProcessDefinition],
  props: {
    workflow: {
      type: Object,
      required: true
    },
    titleDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showTitleAlert () {
      return !this.workflow.definition?.autoGenerateTitle &&
          (isNil(this.workflow.titleFromDB) || (trim(this.workflow.titleFromDB) === '')) &&
          !this.workflowIsSingleTaskFor(this.workflow)
    }
  }
}
</script>
<template>
  <div v-if="workflow">
    <v-alert
      v-if="showTitleAlert"
      dense
      text
      type="info"
    >
      <v-row align="center">
        <v-col class="grow">
          Bitte legen Sie einen Titel für den Prozess fest.
        </v-col>
        <v-col class="shrink">
          <v-btn
            small
            @click="$emit('update:titleDialogOpen', true)"
          >
            Prozesstitel bearbeiten
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <edit-title-dialog
      :value="titleDialogOpen"
      :request-parameter="{method: 'patch', url: $apiEndpoints.workflows.update(workflow.id), mapping: (data) => { return {'title': data.title }}}"
      :title-value="workflow.titleFromDB"
      attribute="title"
      :title="$t('workflow.editTitleDialog.title')"
      :label="$t('workflow.editTitleDialog.fields.title')"
      @request-success-data="$emit('update:workflow', $event)"
      @input="$emit('update:titleDialogOpen', $event)"
    />
  </div>
</template>
