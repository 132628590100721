import { stateColors } from 'helpers/definitions'
import map from 'lodash/map'

export default {
  methods: {
    locationItemsFor (locations, location = null) {
      if (locations) {
        return map(locations, (value, key) => { return { text: value, value: key } })
      } else if (location && location.value && location.text) {
        return [location]
      } else {
        return []
      }
    }
  },
  computed: {
    footerMenuEntryBreadcrumbs () {
      return [
        { text: 'Fußzeilenmenüeinträge', to: { name: 'footer-menu-entries' } },
        { text: `${this.menuEntry.id}` }
      ]
    },
    footerMenuEntryStateText () {
      return 'Aktualisiert'
    },
    footerMenuEntryStateColor () {
      return stateColors.created
    },
    footerMenuEntryStateUpdatedAtDate () {
      return new Date(this.menuEntry.updatedAt)
    }
  }
}
