<script>
import ConditionalRoot from 'components/conditional-root.js'

export default {
  name: 'InfiniteScrollList',
  components: {
    ConditionalRoot
  },
  props: {
    items: {
      type: Array,
      default: () => undefined
    },
    loadOnNthLastItem: {
      type: Number,
      default: 0
    },
    emptyMessage: {
      type: String,
      default: 'Es gibt keine Ergebnisse.'
    },
    errorMessage: {
      type: String,
      default: 'Etwas ist schief gelaufen...'
    },
    maxListHeight: {
      type: Number,
      default: undefined
    },
    groupProps: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      status: 'ok'
    }
  },
  computed: {
    statusOk () {
      return this.status === 'ok'
    },
    statusLoading () {
      return this.status === 'loading'
    },
    statusError () {
      return this.status === 'error'
    },
    statusEmpty () {
      return this.status === 'empty'
    },
    alertType () {
      if (this.statusError) return 'error'
      if (this.statusEmpty) return 'info'

      return undefined
    },
    alertMessage () {
      if (this.statusError) return this.errorMessage
      if (this.statusEmpty && !this.items?.length) {
        return this.emptyMessage
      }

      return undefined
    }
  },
  watch: {
    items: {
      handler (value, oldValue) {
        if (value === undefined) this.load()
      },
      deep: false,
      immediate: true
    }
  },
  methods: {
    load () {
      this.status = 'loading'
      this.$emit('onLoad', (status) => {
        this.status = status
      })
    },
    onIntersect (entries, observer, isIntersecting) {
      if (this.statusOk && entries[0].isIntersecting && Number(entries[0].target.dataset.index) >= (this.items?.length - 1 - this.loadOnNthLastItem)) {
        this.load()
      }
    }
  }
}
</script>
<template>
  <v-list
    :max-height="maxListHeight"
    class="infinite-scroll-list u-scroll-y"
  >
    <conditional-root :show="groupProps !== undefined">
      <v-list-item-group v-bind="groupProps">
        <div
          v-for="(item, index) in items"
          :key="item.key"
          v-intersect.once="onIntersect"
          :data-index="index"
        >
          <slot
            :item="item"
            :index="index"
          />
        </div>

        <v-list-item
          v-if="statusLoading"
          two-line
          class="py-2"
        >
          <v-list-item-content class="text-center">
            <v-progress-circular
              color="primary"
              indeterminate
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-else-if="!statusOk && alertMessage !== undefined">
          <v-list-item-content>
            <v-alert
              :type="alertType"
              text
            >
              {{ alertMessage }}
            </v-alert>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </conditional-root>
  </v-list>
</template>
