export default {
  request: {
    withResponse: {
      title: {
        default: 'Unerwarteter Fehler',
        code: {
          403: 'Kein Zugriff',
          404: 'Daten existieren nicht',
          409: 'Konflikt beim speichern',
          410: 'Daten gelöscht'
        }
      },
      message: {
        default: 'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten. {0} Bitte versuchen Sie es später erneut.',
        code: {
          403: 'Sie dürfen nicht auf diese Seite zugreifen. {0} Bitte kontaktieren Sie uns, wenn Sie diese Nachricht unerwartet erhalten haben.',
          404: 'Die betroffenen Daten konnten nicht gefunden werden. {0} Bitte kontaktieren Sie uns, wenn Sie diese Nachricht unerwartet erhalten haben.',
          409: 'Während Du dieses Datenfeld bearbeitet hast, hat ein:e andere:r Nutzer:in einen neuen Zwischenstand gespeichert. Deine Änderungen können daher nicht gespeichert werden. Bitte lade die Seite neu.',
          410: 'Die betroffenen Daten wurde gelöscht. {0} Bitte kontaktieren Sie uns, wenn Sie diese Nachricht unerwartet erhalten haben.'
        }
      }
    },
    withoutResponse: {
      title: 'Netzwerkfehler',
      message: 'Die Verbindung zum Server ist fehlgeschlagen oder wurde unterbrochen. {0} Bitte überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.'
    },
    general: {
      title: 'Unerwarteter Fehler',
      message: 'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten. {0} Bitte versuchen Sie es später erneut.'
    }
  }
}
