<script>
import { distanceDate } from 'helpers/date'
import Requestable from 'mixins/requestable'
import Request from 'api/request'

export default {
  name: 'PageDetailHeader',
  mixins: [Requestable],
  props: {
    stateText: {
      type: String,
      default: undefined
    },
    stateColor: {
      type: String,
      default: undefined
    },
    stateUpdatedAt: {
      type: Date,
      default: undefined
    },
    commentsCount: {
      type: Number,
      default: undefined
    },
    hasLeftSidebar: {
      type: Boolean,
      required: true
    },
    hasRightSidebar: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      onSuccessCallback: null
    }
  },
  methods: {
    ...{ distanceDate },
    actionRequest (url, onSuccess = null, method = Request.PATCH, params = null, payload = null, useCancelToken = false) {
      if (!this.requestableLoading && Request.METHODS.indexOf(method) !== -1) {
        this.onSuccessCallback = onSuccess
        this.request({ method, url }, params, payload, useCancelToken)
      }
    },
    onRequestSuccess (data) {
      if (this.onSuccessCallback) this.onSuccessCallback(data)
    }
  }
}
</script>
<template>
  <div class="mb-3 mb-lg-7">
    <div
      style="position: relative;"
      :class="{'error--text': hasError}"
    >
      <v-divider />
      <v-input
        :error-messages="errorMessage"
        :error-count="Number.MAX_VALUE"
        class="page-detail-header align-center"
      >
        <v-chip
          v-if="stateText && stateColor"
          :color="stateColor"
          class="white--text"
          label
        >
          {{ stateText }}
        </v-chip>

        <div class="custom-meta-list text--secondary">
          <span
            v-if="stateUpdatedAt"
            class="ml-2 hidden-xs-only"
          >{{ distanceDate(stateUpdatedAt) }}</span>

          <a
            v-if="commentsCount"
            class="hidden-xs-only u-text-decoration-0"
            @click="$emit('comment-count-click')"
          >{{ commentsCount }} Kommentare</a>

          <slot />
        </div>

        <template #append>
          <slot
            name="actions"
            :action-request="actionRequest"
            :loading="requestableLoading"
          />
        </template>
      </v-input>
      <v-progress-linear
        v-if="requestableLoading"
        height="2"
        indeterminate
      />
      <v-divider
        v-else
        style="padding-bottom: 1px;"
        :class="{'inherit-border-color': hasError}"
      />
    </div>

    <div
      class="d-lg-none mt-2"
      style="height: 28px"
    >
      <v-btn
        v-if="hasLeftSidebar"
        small
        absolute
        style="left: 0; border-top-left-radius: 0; border-bottom-left-radius: 0;"
        @click="$emit('open-sidebar', 'left')"
      >
        <v-icon>mdi-chevron-double-right</v-icon>
      </v-btn>

      <v-btn
        v-if="hasRightSidebar"
        small
        absolute
        style="right: 0; border-top-right-radius: 0; border-bottom-right-radius: 0;"
        @click="$emit('open-sidebar', 'right')"
      >
        <v-icon>mdi-chevron-double-left</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<style>
    .inherit-border-color {
        border-color: inherit !important;
    }
    .page-detail-header > .v-input__control > .v-input__slot {
        margin-top: 8px;
    }
    .page-detail-header:not(.v-input--has-state) > .v-input__control > .v-messages {
        display: none;
    }
</style>
