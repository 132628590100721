<script>
import ObjectListItem from './object-list-item.vue'
import User from 'mixins/models/user'

export default {
  name: 'UserListItem',
  components: { ObjectListItem },
  mixins: [User],
  props: {
    ...ObjectListItem.props,
    showUnconfirmedEmail: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <object-list-item
    :value="{
      id: value.id,
      title: value.fullname,
      subtitleElements: [showUnconfirmedEmail ? userEmailsFor(value) : value.email],
      avatar: {
        text: value.avatar.label,
        image: value.avatar.url
      },
      type: 'User',
      href: ''
    }"
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
  />
</template>
