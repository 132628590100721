export default {
  actions: {
    create: 'Statische Seite erstellen',
    delete: 'Statische Seite löschen'
  },
  createDialog: {
    title: 'Statische Seite erstellen',
    successMessage: 'Statische Seite "{title}" wurde erfolgreich erstellt'
  },
  deleteDialog: {
    title: 'Statische Seite löschen?',
    text: 'Löschen Sie die statische Seite, wenn Sie sicher sind, dass diese nicht mehr benöigt wird. Gelöschte statische Seiten sind unwiederbringlich verloren. Alle mit dieser statischen Seite verknüpften Menüeinträge werden ebenfalls gelöscht!',
    buttons: {
      ok: 'Statische Seite löschen'
    }
  }
}
