<script>
import { PageContentable } from '../page'
import PageListHeader from 'components/page-list-header.vue'
import SmartList from 'components/smart-list.vue'
import WorkflowListItem from 'list-items/workflow-list-item.vue'
import TaskListItem from 'list-items/task-list-item.vue'
import DossierListItem from 'list-items/dossier-list-item.vue'
import Workflow from 'mixins/models/workflow'
import ProcessDefinition from 'mixins/models/process-definition'
import { routeFor } from 'helpers/route'

export default {
  name: 'SearchListContent',
  components: {
    PageListHeader,
    SmartList,
    WorkflowListItem,
    TaskListItem,
    DossierListItem
  },
  mixins: [PageContentable, Workflow, ProcessDefinition],
  props: {
    filter: {
      type: Object,
      default: null
    }
  },
  computed: {
    results () {
      return this.value ? this.value.results : undefined
    },
    queryTerm () {
      return this.value ? this.value.queryTerm : undefined
    },
    totalPages () {
      return this.value ? this.value.total_pages : 1
    }
  },
  methods: {
    ...{ routeFor },
    listItemType (type) {
      switch (type) {
        case 'workflow': return WorkflowListItem
        case 'task': return TaskListItem
        case 'dossier': return DossierListItem
        default: return null
      }
    },
    hideWorkflowDefinition (item) {
      return (item.type === 'task') && (
        this.workflowIsSingleTaskFor(item.object.workflow) ||
        this.processDefinitionIsSystemProcessDefinitionSingleTaskFor(item.object.workflow.definition)
      )
    },
    hideWorkflowTitle (item) {
      return (item.type === 'task') && this.workflowIsSingleTaskFor(item.object.workflow)
    }
  }
}
</script>
<template>
  <div>
    <page-list-header title="Suche" />

    <div v-if="!pageRequestLoading && value">
      <div v-if="(value.searchedWithMisspellingsDueToLowResultCount && results.length > 0) || (value.suggestion)">
        <v-alert
          type="info"
          text
          class="mt-6"
        >
          <p v-if="value.searchedWithMisspellingsDueToLowResultCount && results.length > 0">
            Für Ihre Suche nach
            "{{ queryTerm }}"
            gab es nur wenige exakte Treffer. Es werden daher auch ähnliche Ergebnisse angezeigt.
          </p>
          <p v-if="value.suggestion">
            Meinten Sie:
            <router-link :to="{ name: 'search', params: { query: value.suggestion } }">
              {{ value.suggestion }}
            </router-link>
            ?
          </p>
        </v-alert>
      </div>
    </div>

    <smart-list
      id="search-results"
      v-slot="{ item }"
      :value="results"
      :loading="pageRequestLoading"
      :empty-list-message="`Für Ihre Suche nach \x22${queryTerm}\x22 gibt es leider keine Ergebnisse.`"
    >
      <component
        :is="listItemType(item.type)"
        v-if="listItemType(item.type)"
        :value="item.object"
        :to="routeFor(item.type, item.object.id)"
        :hide-workflow-definition="hideWorkflowDefinition(item)"
        :hide-workflow-title="hideWorkflowTitle(item)"
        ident
        :dense="false"
        divider
      >
        <template
          v-if="item.highlights"
          #snippets
        >
          <div
            v-for="(highlight, h_index) in item.highlights"
            :key="h_index"
            class="search-result-highlights"
          >
            <div class="overline font-weight-bold text&#45;&#45;primary">
              {{ highlight.key }}
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-for="(value, f_index) in highlight.fragments"
              :key="f_index"
              class="text-wrap"
              v-html="value"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </template>
      </component>
    </smart-list>

    <v-pagination
      v-if="!pageRequestLoading && results && totalPages > 1"
      :value="filter.page"
      :length="totalPages"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'filter', value: $event, info: 'page' })"
    />
  </div>
</template>
