<script>
import { PageContentable } from '../page'
import ObjectsControl from 'controls/objects-control/index.vue'
import GroupListItem from 'list-items/group-list-item.vue'

export default {
  name: 'DossierDefinitionEditGroupsContent',
  components: {
    ObjectsControl,
    GroupListItem
  },
  mixins: [PageContentable],
  methods: {
    onUpdated (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<template>
  <div v-if="value">
    <objects-control
      v-model="value.groups"
      label="Gruppen"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.dossierDefinitions.update(value.id), mapping: 'group_ids'}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.groups.list()}"
      @request-success-data="onUpdated"
    >
      <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
        <group-list-item
          :key="item.id"
          :indent="indent"
          :item-link="itemLink"
          :selectable="selectable"
          :selected="selected"
          :skeleton="item.skeleton"
          :value="item"
          v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
        />
      </template>
    </objects-control>
  </div>
</template>
