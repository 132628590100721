<script>
import Vue from 'vue'
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import BaseControl from 'controls/base-control/index'
import FileEditCardMenu from './file-edit-card-menu.vue'
import FileDeleteCardMenu from './file-delete-card-menu.vue'
import FileListItem from 'list-items/file-list-item.vue'
import { updateValueForContentType, CONTENT_TYPE_FILE } from 'helpers/content-types'
import defaults from 'lodash/defaults'
import some from 'lodash/some'
import filter from 'lodash/filter'
import map from 'lodash/map'
import castArray from 'lodash/castArray'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'

export default {
  name: 'FileControl',
  components: {
    BaseControl,
    FileEditCardMenu,
    FileDeleteCardMenu,
    FileListItem
  },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: Array,
      default: () => []
    },
    aclObjectGlobalId: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      uploadRequestables: [],
      showAddWindow: false,
      showEditCardMenu: false,
      showDeleteCardMenu: false,
      currentValue: null
    }
  },
  computed: {
    internalOptions () {
      return defaults({}, this.options, {
        multiple: false
      })
    },
    uploadRequestablesLoading () {
      return some(this.uploadRequestables, item => item.requestableLoading)
    },
    controlLoading () {
      return Controlable.computed.controlLoading.call(this) || this.uploadRequestablesLoading
    },
    controlErrorMessages () {
      const result = castArray(Controlable.computed.controlErrorMessages.call(this)).concat(
        map(this.uploadRequestables, item => item.errorMessage)
      )

      return filter(result, item => item !== null)
    },
    canAddFiles () {
      return this.internalOptions.multiple || this.cacheValue.length === 0
    },
    availableActions () {
      let result = ['download']
      if (!this.controlReadonly) {
        result = result.concat(['delete'])

        if (this.aclObjectGlobalId) {
          result = result.concat(['edit'])
        }
      }

      return result
    },
    cachedValueChanged () {
      return !isEqual(castArray(this.value).filter(v => !isNil(v)), castArray(this.cacheValue).filter(v => !isNil(v)))
    }
  },
  methods: {
    createCacheValue () {
      if (this.value) {
        Cacheable.methods.createCacheValue.call(this)
      } else {
        this.cacheValue = []
      }
    },
    addFocusListener () {
      window.addEventListener('focus', this.onWindowFocused)
    },
    removeFocusListener () {
      window.removeEventListener('focus', this.onWindowFocused)
    },
    onFileAdd () {
      this.showAddWindow = true
      this.addFocusListener()
      this.controlOnLock()

      this.$refs.fileinput.value = null
      this.$refs.fileinput.click()
    },
    onFileAction (action, file) {
      switch (action) {
        case 'edit':
          this.currentValue = file
          this.showEditCardMenu = true
          break
        case 'delete':
          this.currentValue = file
          this.showDeleteCardMenu = true
          break
        default:
      }
    },
    onFileDelete (file) {
      this.cleanupRequestables()
      this.cacheValue = filter(this.cacheValue, item => item !== file)
      this.resetRequestable()
      this.updateControl()
    },
    onFileInputChanged (e) {
      this.showAddWindow = false
      this.removeFocusListener()

      this.cleanupRequestables()

      if (!e.target.files) return

      Array.from(e.target.files).forEach(file => {
        const formData = new FormData()
        formData.append('file', file)

        const requestable = new (Vue.extend(Requestable))({
          methods: {
            onRequestSuccess: (file) => {
              this.uploadRequestables = filter(this.uploadRequestables, item => item !== requestable)
              this.cacheValue.push(file)
              if (!this.uploadRequestablesLoading) this.updateControl()
            },
            onRequestError: () => {
              if (!this.uploadRequestablesLoading) this.updateControl()
            }
          }
        })
        this.uploadRequestables.push(requestable)

        requestable.request({ method: 'post', url: this.$apiEndpoints.uploadedFiles.create() }, null, formData)
      })
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    onRequestSuccessEdit (data) {
      this.currentValue.title = data.title
    },
    updateControl () {
      this.controlOnInput()
      this.controlOnChange(updateValueForContentType(CONTENT_TYPE_FILE, this.cacheValue))

      if (!this.cachedValueChanged) this.controlOnUnlock()
    },
    cleanupRequestables () {
      this.uploadRequestables = []
    },
    onWindowFocused () {
      debounce(() => {
        if (this.showAddWindow) {
          this.showAddWindow = false
          this.removeFocusListener()
          this.controlOnUnlock()
        }
      }, 300)()
    }
  }
}
</script>

<template>
  <base-control
    ref="container"
    :value="cacheValue"
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :class="controlClass"
    :error-messages="controlErrorMessages"
    :error-count="Number.MAX_VALUE"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
    class="p-relative file-control"
  >
    <template #actions>
      <input
        ref="fileinput"
        class="d-none"
        type="file"
        :multiple="internalOptions.multiple"
        @change="onFileInputChanged"
      >
      <v-btn
        :disabled="controlDisabled || controlReadonly || requestableLoading || !canAddFiles"
        color="primary"
        small
        text
        @click="onFileAdd"
      >
        Hinzufügen
      </v-btn>
    </template>

    <v-list
      class="py-0 w-100"
      color="transparent"
    >
      <template v-for="(item, index) in cacheValue">
        <file-list-item
          :key="item.id"
          :class="[{'pt-2': index > 0}, 'px-0']"
          :value="item"
          :actions="availableActions"
          class="pb-2"
          @action="onFileAction"
        />
        <v-divider
          v-if="index < cacheValue.length - 1"
          :key="`list-divider-${index}`"
        />
      </template>
    </v-list>

    <file-edit-card-menu
      v-if="aclObjectGlobalId"
      v-model="showEditCardMenu"
      :attach="$refs.container"
      :nudge-bottom="28"
      :value="currentValue"
      :acl-object-global-id="aclObjectGlobalId"
      @request-success-data="onRequestSuccessEdit"
      @opened="controlOnLock"
      @closed="controlOnUnlock(true);"
    />

    <file-delete-card-menu
      v-model="showDeleteCardMenu"
      :attach="$refs.container"
      :nudge-bottom="28"
      :value="currentValue"
      @delete="onFileDelete"
      @opened="controlOnLock"
      @cancel="controlOnUnlock"
    />
  </base-control>
</template>
<style scoped>
  .w-100 {
    width: 100%;
  }
  .p-relative {
    position: relative;
  }
</style>
