export default {
  state: {
    active: 'Aktiv',
    completed: 'Abgeschlossen'
  },
  tasksDueCount: '{count} fällige Aufgabe | {count} fällige Aufgaben',
  finishedAlert: 'Alle Aufgaben in diesem Prozess sind abgeschlossen oder übersprungen. Dieser Prozess könnte abgeschlossen werden.',
  actions: {
    create: 'Prozess starten',
    complete: 'Prozess abschließen',
    reopen: 'Prozess wiedereröffnen',
    delete: 'Prozess löschen',
    cancel: 'Prozess abbrechen',
    editTitle: 'Titel bearbeiten',
    updateDefinition: 'Vorlage aktualisieren',
    createDefinition: 'Neue Vorlage aus Prozess',
    editDefinition: 'Vorlage bearbeiten',
    addTask: 'Aufgabe hinzufügen',
    addBlock: 'Block hinzufügen',
    export: 'Aktuelle Liste exportieren'
  },
  createDialog: {
    title: 'Prozess starten',
    buttons: {
      ok: {
        process: 'Prozess starten',
        task: 'Aufgabe starten'
      }
    }
  },
  completeDialog: {
    title: 'Prozess abschließen?',
    text: 'In diesem Prozess sind Aufgaben noch nicht abgeschlossen oder übersprungen. Soll dieser Prozess trotzdem abgeschlossen werden?',
    buttons: {
      ok: 'Prozess abschließen'
    }
  },
  cancelDialog: {
    title: 'Prozess abbrechen?',
    text: 'Brechen Sie den Prozess ab, wenn der Prozess nicht erfolgreich abgeschlossen werden kann. Alle noch zu realisierenden Aufgaben werden automatisch übersprungen.',
    buttons: {
      ok: 'Prozess abbrechen'
    }
  },
  deleteDialog: {
    title: 'Prozess löschen?',
    text: 'Löschen Sie den Prozess, wenn Sie sicher sind, dass dieser Prozess nicht mehr benöigt wird. Gelöschte Prozesse sind unwiederbringlich verloren.',
    buttons: {
      ok: 'Prozess löschen'
    }
  },
  updateDefinitionDialog: {
    title: 'Vorlage aktualisieren',
    text: 'Die Vorlage dieses Prozesses wird so aktualisiert, dass zukünftig gestartete Prozesse wie dieser hier aussehen.',
    buttons: {
      ok: 'Vorlage aktualisieren'
    }
  },
  editTitleDialog: {
    title: 'Prozesstitel bearbeiten',
    fields: {
      title: 'Titel des Prozesses'
    }
  },
  mentioningHint: 'Diesen Prozess mit {identifier} erwähnen.'
}
