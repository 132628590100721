export default {
  items: {
    comment: {
      actions: {
        copyToClipboard: 'Link zu diesem Kommentar in die Zwischenablage kopieren',
        edit: 'Kommentar bearbeiten',
        delete: 'Kommentar löschen'
      },
      deleteDialog: {
        title: 'Kommentar löschen?',
        text: 'Der Inhalt gelöschter Kommentare geht unwiederbringlich verloren. Hinweis: In Benachrichtigungen und Mails, die andere Nutzer:innen zu diesem Kommentar bereits erhalten haben, kann der Inhalt dieses Kommentars aber noch nachvolziehbar sein.',
        buttons: {
          ok: 'Kommentar löschen'
        }
      }
    }
  }
}
