<script>
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import PageListHeader from 'components/page-list-header.vue'
import CreateDialog from 'dialogs/create-dialog.vue'
import DataListFilter from 'components/data-list/filter.vue'
import SmartList from 'components/smart-list.vue'
import GroupListItem from 'list-items/group-list-item.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'GroupListContent',
  components: {
    PageListHeader,
    CreateDialog,
    DataListFilter,
    SmartList,
    GroupListItem
  },
  mixins: [PageContentable, ListPageContentable],
  computed: {
    result () {
      return this.value ? this.value.result : undefined
    }
  },
  methods: {
    ...{ routeFor },
    onCreated (group) {
      this.$router.push(routeFor('group', group.id))
    }
  }
}
</script>
<template>
  <div>
    <create-dialog
      :title="$t('group.createDialog.title')"
      :ok-btn-text="$t('general.buttons.save')"
      :add-another-ok-btn-text="$t('general.buttons.saveAndCreateAnother')"
      :success-message-function="(data) => { return $t('group.createDialog.successMessage', { title: data.name })}"
      :fields="{
        name: { type: 'string', required: true, default: '', label: $t('group.createDialog.fields.name') }
      }"
      :request-parameter="{method: 'post', url: $apiEndpoints.groups.create()}"
      content-class="create-group-dialog"
      @success="onCreated"
    >
      <template #activator="{ on }">
        <page-list-header
          title="Gruppen"
          :primary-action-title="$config.current_user.isAdmin ? $t('group.actions.create') : undefined"
          v-on="on"
        />
      </template>
    </create-dialog>

    <data-list-filter
      v-model="filters"
      @update:filter="$emit('update:prop', { prop: 'filters', value: $event.value, info: $event.field })"
    />

    <smart-list
      v-slot="{ item }"
      :value="result"
      :loading="pageRequestLoading"
    >
      <group-list-item
        :value="item"
        :to="routeFor('group', item.id)"
        indent
        :dense="false"
        divider
      />
    </smart-list>

    <v-pagination
      v-if="!pageRequestLoading && result && pages.total() > 1"
      v-model="pages.current"
      :length="pages.total()"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'pages', value: pages })"
    />
  </div>
</template>
