<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'

export default {
  name: 'EditTitleDialog',
  components: { CustomDialog },
  mixins: [Requestable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: Boolean,
      default: false
    },
    titleValue: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogOpen: false,
      internalTitleValue: ''
    }
  },
  computed: {
    hasValidChanges () {
      return this.internalTitleValue?.trim() !== this.titleValue?.trim() && (!this.required || this.internalTitleValue?.trim().length > 0)
    },
    requestDate () {
      return { title: this.internalTitleValue?.trim() }
    }
  },
  watch: {
    value: {
      handler (val) {
        this.dialogOpen = val
      },
      immediate: true
    }
  },
  methods: {
    onDialogOpened () {
      this.internalTitleValue = this.titleValue

      this.resetRequestable()

      this.$emit('input', true)
      this.$emit('open')
    },
    onDialogClosed () {
      this.$emit('input', false)
    },
    onRequestSuccess (data) {
      this.dialogOpen = false

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="title"
    :ok-btn-text="$t('general.buttons.save')"
    :close-on-button-click="false"
    :ok-btn-disabled="!hasValidChanges"
    :loading="requestableLoading"
    :error-message="baseErrorMessage"
    @click-ok="request(requestParameter, null, requestDate)"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <v-text-field
      v-model="internalTitleValue"
      :label="label"
      :hint="required ? $t('general.field.required') : undefined"
      :persistent-hint="required"
      :disabled="requestableLoading"
      :error-messages="validationErrorMessageFor(attribute)"
      :error-count="Number.MAX_VALUE"
    />
  </custom-dialog>
</template>
