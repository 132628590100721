<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import SmartList from 'components/smart-list.vue'
import { routeFor } from 'helpers/route'
import join from 'lodash/join'

export default {
  name: 'DossierDefinitionEditDataTransformationsContent',
  components: {
    SmartList
  },
  mixins: [PageContentable],
  data () {
    return {
      transformations: undefined,
      loadRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.transformations = data
          }
        }
      })
    }
  },
  watch: {
    value: {
      handler () {
        this.transformations = undefined
        if (this.value) {
          this.loadRequestable.request(
            { method: Request.GET, url: this.$apiEndpoints.dataTransformations.index() },
            { object_gid: this.value.gid }, null, true, true
          )
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    routeFor,
    fieldDefinitionDesc (definition) {
      const res = [definition.type.label]
      if (definition.required) res.push('Erforderlich')
      if (definition.unique) res.push('Eindeutig')

      return join(res, ', ')
    }
  }
}
</script>
<template>
  <div>
    <h2 class="text-body-1 text--secondary mb-4">
      Datentransformation
    </h2>

    <smart-list
      v-slot="{ item, index }"
      :value="transformations"
      :loading="loadRequestable.requestableLoading"
      empty-list-message="Zu dieser Dossiervorlage existieren keine Datentransformationen."
    >
      <v-divider
        v-if="index > 0"
        :key="`divider-${item.id}`"
      />
      <v-list-item
        :key="`list-item-${item.id}`"
        :to="routeFor('dossier_data_transformation_detail', value.id, { params: { dataTransformationId: item.id } })"
        class="px-0"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.field.name }}</v-list-item-title>
          <v-list-item-subtitle>
            Von: {{ fieldDefinitionDesc(item.source_definition) }}
            <br>
            Zu: {{ fieldDefinitionDesc(item.target_definition) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </smart-list>
  </div>
</template>
