export default {
  data: {
    confirmed: 'bestätigt {timestamp}',
    locked: '{username} bearbeitet ...'
  },
  richtextarea: {
    hint: 'Für Erwähnungen im Text: @Nutzer:in, @Gruppe, %Prozess, #Aufgabe, *Dossier. Markiere um zu formatieren.',
    image_filter_errors: {
      default: 'Datei "{filename}" kann nicht eingefügt werden.',
      type: '@:control.richtextarea.image_filter_errors.default Nur Bilder vom Typ PNG, JPEG und GIF werden unterstützt.',
      size: '@:control.richtextarea.image_filter_errors.default Nur Bilder mit einer max. Größe von 20MB werden unterstützt.'
    }
  },
  errors: {
    insert: 'Wert konnte nicht eingefügt werden.'
  }
}
