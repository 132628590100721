<script>
import { PageContentable } from '../page'
import { routeFor } from 'helpers/route'

export default {
  name: 'GroupEditSidebarLeft',
  mixins: [PageContentable],
  methods: {
    ...{ routeFor }
  }
}
</script>
<template>
  <v-list
    v-if="value"
    class="pa-0"
  >
    <v-list-item
      :to="routeFor('group', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Allgemein
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />
    <v-list-item
      :to="routeFor('group_users', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Nutzer:innen
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-chip
          pill
          :color="$route.name === 'group-users' ? 'white': undefined"
        >
          {{ String(value.users.length) }}
        </v-chip>
      </v-list-item-avatar>
    </v-list-item>

    <v-divider />
    <v-list-item
      :to="routeFor('group_process_definitions', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Prozessvorlagen
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-chip
          pill
          :color="$route.name === 'group-process-definitions' ? 'white': undefined"
        >
          {{ String(value.workflowDefinitions.length) }}
        </v-chip>
      </v-list-item-avatar>
    </v-list-item>

    <v-divider />
    <v-list-item
      :to="routeFor('group_dossier_definitions', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Dossiervorlagen
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-chip
          pill
          :color="$route.name === 'group-dossier-definitions' ? 'white': undefined"
        >
          {{ String(value.dossierDefinitions.length) }}
        </v-chip>
      </v-list-item-avatar>
    </v-list-item>
  </v-list>
</template>
