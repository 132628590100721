import { stateColors } from 'helpers/definitions'

export default {
  computed: {
    automationBreadcrumbs () {
      return [
        { text: 'Automatisierungen', to: { name: 'automations' } },
        { text: `${this.automation.id}` }
      ]
    },
    automationStateText () {
      return this.$t(`automation.states.${this.automation.active ? 'activated' : 'deactivated'}`)
    },
    automationStateColor () {
      return this.automation.active ? stateColors.active : stateColors.canceled
    },
    automationStateUpdatedAtDate () {
      return new Date(this.automation.updatedAt)
    }
  }
}
