import ApiError from './api-error'

export default class RequestError extends ApiError {
  constructor (_error) {
    super()
  }

  get titleLocalizationKey () {
    return 'error.request.withoutResponse.title'
  }

  get messageLocalizationKey () {
    return 'error.request.withoutResponse.message'
  }
}
