<script>
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import PageListHeader from 'components/page-list-header.vue'
import DataListFilter from 'components/data-list/filter.vue'
import ActivityHubItem from 'components/activity-hub/activity-hub-item.vue'
import ActivityHubItemLink from 'components/activity-hub/activity-hub-item-link.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'JournalListContent',
  components: {
    PageListHeader,
    DataListFilter,
    ActivityHubItem,
    ActivityHubItemLink
  },
  mixins: [PageContentable, ListPageContentable],
  computed: {
    result () {
      return this.value?.result || []
    }
  },
  methods: {
    ...{ routeFor },
    updateFilter (value, field) {
      this.$emit('update:prop', { prop: 'filter', value, info: field })
    }
  }
}
</script>
<template>
  <div>
    <page-list-header title="Journal" />

    <data-list-filter
      v-model="filters"
      @update:filter="$emit('update:prop', { prop: 'filters', value: $event.value, info: $event.field })"
    />

    <v-timeline
      v-if="!pageRequestLoading && !pageRequestHasError && result.length"
      class="ml-n5"
      dense
    >
      <template v-for="event in result">
        <activity-hub-item-link
          :key="event.id"
          :value="event.target"
          :style="event.target.noaccess || event.target.deleted ? 'cursor: not-allowed' : ''"
        >
          <activity-hub-item :event="event" />
        </activity-hub-item-link>
      </template>
    </v-timeline>

    <div
      v-if="pageRequestLoading"
      class="py-6 text-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      />
    </div>

    <v-pagination
      v-if="!pageRequestLoading && result && pages.total() > 1"
      v-model="pages.current"
      :length="pages.total()"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'pages', value: pages })"
    />
  </div>
</template>
