import ListPage from '../list-page'
import ListContent from './list-content.vue'

export default {
  name: 'StaticPageListPage',
  mixins: [ListPage],
  data () {
    return {
      filter: {
        page: 1,
        queryText: null,
        order: 'title_asc'
      }
    }
  },
  computed: {
    pageTitleParts () {
      return ['Statische Seiten']
    },
    pageContentComponents () {
      return ListContent
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.pages.index()
    },
    initPageRequestParams () {
      return {
        page: this.pages.current,
        query: this.filters.values.query,
        order: this.filters.values.order
      }
    },
    filterFields () {
      return [
        [
          {
            name: 'query',
            type: 'text',
            label: 'Titel der statischen Seite',
            default: ''
          },
          {
            name: 'order',
            type: 'single-select',
            label: 'Sortierung',
            items: [
              { text: 'Titel – aufsteigend', value: 'title_asc' },
              { text: 'Titel – absteigend', value: 'title_desc' },
              { text: 'Erstellungsdatum – neueste zuerst', value: 'created_at_desc' },
              { text: 'Erstellungsdatum – älteste zuerst', value: 'created_at_asc' }
            ],
            default: 'title_asc',
            cols: 4
          }]
      ]
    }
  }
}
