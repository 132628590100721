<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import Controlable from 'mixins/controlable'
import Cacheable from 'mixins/cacheable'
import BaseControl from 'controls/base-control/index'
import DateControl from 'controls/date-control.vue'
import SelectControl from 'controls/select-control.vue'
import NumericControl from 'controls/numeric-control.vue'
import RadioControl from 'controls/radio-control.vue'
import { formatDate, formatDateTime } from 'helpers/date'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import castArray from 'lodash/castArray'
import map from 'lodash/map'
import without from 'lodash/without'
import get from 'lodash/get'

export default {
  name: 'ScheduleControl',
  components: {
    RadioControl,
    NumericControl,
    SelectControl,
    DateControl,
    BaseControl
  },
  mixins: [Requestable, Controlable, Cacheable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    controlRequestableErrorMessages () {
      return without(castArray(this.baseErrorMessage).concat(this.validationErrorMessageFor('schedule')), null)
    },
    controlFilteredErrorMessages () {
      return Controlable.computed.controlFilteredErrorMessages.call(this).concat(this.errorsForAttribute('schedule', true))
    },
    ruleItems () {
      return map(this.$t('automation.scheduleControl.rules'), (value, key) => {
        return {
          text: value,
          value: key
        }
      })
    },
    days () {
      return map(Array(7), (value, index) => {
        const idx = (index + 1) % 7
        return {
          text: this.$t(`automation.scheduleControl.validations.daysOfWeek.${idx}`),
          value: idx
        }
      })
    },
    days_of_month () {
      const day = parseInt(formatDate(new Date(this.cacheValue.start), 'd'))

      return map(['start', 'last'], value => {
        return {
          text: this.$t(`automation.scheduleControl.validations.daysOfMonth.${value}`, { day }),
          value
        }
      })
    },
    availableValidations () {
      switch (this.cacheValue?.rule) {
        case 'daily': return []
        case 'weekly': return ['day']
        case 'monthly': return ['day_of_month']
        case 'yearly': return []
        default: return []
      }
    },
    requestValue () {
      return {
        start: this.cacheValue.start,
        rule: this.cacheValue.rule,
        interval: this.cacheValue.interval,
        validations: this.cacheValue.validations
      }
    },
    nextExecutionDateString () {
      return isNil(this.cacheValue.nextExecution) ? null : formatDateTime(new Date(this.cacheValue.nextExecution))
    }
  },
  methods: {
    errorsForAttribute (attribute, fromPropsOnly = false) {
      if (fromPropsOnly || !this.hasControlUpdateRequestParameter) {
        return map(this.errorMessages, value => { return get(value, attribute) })
      } else {
        return this.validationErrorMessageFor(attribute)
      }
    },
    validationsForRule () {
      let result = {}
      let defaultValue = null

      switch (this.cacheValue?.rule) {
        case 'weekly':
          defaultValue = [parseInt(formatDate(new Date(this.cacheValue.start), 'i')) % 7]
          result = { day: this.cacheValue?.validations?.day || defaultValue }
          break
        case 'monthly':
          defaultValue = 'start'
          result = { day_of_month: this.cacheValue?.validations?.day_of_month || defaultValue }
          break
      }
      return result
    },
    onRuleChange (rule) {
      this.cacheValue.rule = rule
      this.cacheValue.validations = this.validationsForRule()
      this.onDataChanged()
    },
    onDataChanged (setter = undefined) {
      if (isFunction(setter)) setter()
      this.controlOnInput()
      this.controlOnChange(this.requestValue)
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>

<template>
  <base-control
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :class="controlClass"
    :error-messages="controlErrorMessages"
    :error-count="Number.MAX_VALUE"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
  >
    <v-container
      style="min-width: 0"
    >
      <date-control
        :value="cacheValue.start"
        :label="$t('automation.scheduleControl.fields.start')"
        select-on-click
        hide-delete-btn
        hide-append
        style="max-width: calc(27rem + 8px);"
        :error-messages="errorsForAttribute('schedule.start')"
        @change="onDataChanged(() => { cacheValue.start = $event })"
      />

      <div class="d-flex">
        <numeric-control
          :value="cacheValue.interval"
          :options="{ allowNegative: false}"
          :label="$t('automation.scheduleControl.fields.interval')"
          hide-append
          class="mr-2"
          style="max-width: 7rem;"
          :error-messages="errorsForAttribute('schedule.interval')"
          @change="onDataChanged(() => { cacheValue.interval = $event })"
        />
        <select-control
          :value="cacheValue.rule"
          :items="ruleItems"
          :label="$t('automation.scheduleControl.fields.rule')"
          hide-append
          style="max-width: 20rem;"
          :error-messages="errorsForAttribute('schedule.rule')"
          @change="onRuleChange"
        />
      </div>

      <div
        v-if="availableValidations.includes('day')"
        style="position: relative"
        class="mt-5 mb-5"
      >
        <label
          class="v-label v-label--active theme--light"
          style="left: 0; right: auto; position: absolute; max-width: 133%; transform: translateX(-14px) translateY(-22px) scale(.75); pointer-events: auto;"
        >{{ $t('automation.scheduleControl.fields.validations.day') }}</label>
        <v-btn-toggle
          :value="cacheValue.validations.day"
          color="primary"
          multiple
          :error-messages="errorsForAttribute('schedule.validations')"
          @change="onDataChanged(() => { cacheValue.validations.day = $event })"
        >
          <v-btn
            v-for="day in days"
            :key="day.value"
            :value="day.value"
          >
            {{ day.text }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <radio-control
        v-if="availableValidations.includes('day_of_month')"
        :value="cacheValue.validations.day_of_month"
        :label="$t('automation.scheduleControl.fields.validations.dayOfMonth')"
        :items="days_of_month"
        :options="{ multiple: true }"
        hide-append
        class="mt-0"
        :error-messages="errorsForAttribute('schedule.validations')"
        @change="onDataChanged(() => { cacheValue.validations.day_of_month = $event })"
      />

      <v-alert
        v-if="cacheValue.description"
        type="info"
        icon="mdi-information-outline"
        text
        dense
      >
        {{ cacheValue.description }}
      </v-alert>

      <v-alert
        v-if="active && nextExecutionDateString"
        type="info"
        icon="mdi-clock-outline"
        text
        dense
      >
        {{ $t('automation.nextExecutionAt', { date: nextExecutionDateString }) }}
      </v-alert>
      <v-alert
        v-else-if="!active"
        type="error"
        icon="mdi-information-outline"
        text
        dense
      >
        {{ $t('automation.deactivated') }}
      </v-alert>
    </v-container>
  </base-control>
</template>
