<script>
import snakeCase from 'lodash/snakeCase'
import * as Sentry from '@sentry/vue'

// Important Info: Use key attribute for all instances of this component because these components should be reused
// because only on destroy the actioncable-vue will unsubscribe from the current (old) channel
export default {
  name: 'MonitorSubscription',
  props: {
    gid: {
      type: String,
      required: true
    },
    channel: {
      type: String,
      default: 'MonitorChannel'
    }
  },
  channels: {
    computed: [
      {
        channelName () {
          return this.channelName
        },
        connected () {
          this.$debugLog(`ActionCable connected to ${this.channelName} via vue`)
        },
        rejected () {
          Sentry.captureMessage('ActionCable rejected')
        },
        received (data) {
          this.$debugLog(`ActionCable channel ${this.channelName} received data:`, data)
          this.$emit('received', data)
        },
        disconnected () {
          this.$debugLog(`ActionCable disconnected from ${this.channelName} via vue`)
        }
      }
    ]
  },
  computed: {
    channelName () {
      return `${snakeCase(this.channel)}_${this.gid}`
    }
  },
  mounted () {
    this.$cable.subscribe({
      channel: this.channel,
      object_gid: this.gid
    }, this.channelName)
  },
  beforeDestroy () {
    this.$debugLog(`beforeDestroy: unsubscribe from ${this.channelName} via vue`)
    this.$cable.unsubscribe(this.channelName)
  }
}
</script>
<template>
  <div />
</template>
