<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import TextControl from 'controls/text-control.vue'
import TaskDefinitionFieldsControl from 'controls/task-definition-fields-control.vue'
import UsersControl from 'controls/users-control.vue'
import UsersAndGroupsControl from 'controls/users-and-groups-control.vue'
import NumericControl from 'controls/numeric-control.vue'
import DeleteDialog from 'dialogs/delete-dialog.vue'
import RequestDialog from 'dialogs/request-dialog.vue'
import ProcessDefinition from 'mixins/models/process-definition'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import map from 'lodash/map'
import { distanceDate } from 'helpers/date'
import { routeFor } from 'helpers/route'

export default {
  name: 'ProcessDefinitionEditContentTask',
  components: {
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    TextControl,
    TaskDefinitionFieldsControl,
    UsersControl,
    UsersAndGroupsControl,
    NumericControl,
    DeleteDialog,
    RequestDialog
  },
  mixins: [PageContentable, ProcessDefinition],
  props: {
    type: {
      type: String,
      required: true
    },
    subId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      contentTypes: undefined,
      contentTypesRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.contentTypes = data
          }
        }
      })
    }
  },
  computed: {
    processDefinition () {
      return this.value
    },
    taskDefinition () {
      return this.type === 'task-definition' ? this.processDefinitionGetTaskDefinitionWithIdFor(this.processDefinition, this.subId) : null
    },
    candidateAssignee () {
      return this.taskDefinition.candidateAssigneeFromProcess ? this.candidateAssigneeFromProcess : this.taskDefinition.candidateAssignee
    },
    group_ids () {
      return map(this.processDefinition.groups, 'id')
    },
    candidateAssigneeFromProcess () {
      return {
        id: 'candidate_assignee_from_process',
        type: 'custom',
        title: 'Verantwortliche:r des Prozesses',
        subtitle: 'Aktuell verantwortliche:r Nutzer:in des Prozesses'
      }
    }
  },
  mounted () {
    this.contentTypesRequestable.request(
      { method: Request.GET, url: this.$apiEndpoints.contentTypes.index() },
      null, null, true, true)
  },
  methods: {
    ...{ map, distanceDate },
    onUpdated (value) {
      this.$emit('input', value)
    },
    onFieldsUpdated (value) {
      this.processDefinition.contentItemDefinitions = value.contentItemDefinitions
      this.taskDefinition.taskItemDefinitions = value.taskItemDefinitions
    },
    onDeleted (value) {
      this.onUpdated(value)
      this.$router.replace(routeFor('process_definition', value.id))
    },
    isCandidateAssigneeFromProcess (item) {
      return item?.type === this.candidateAssigneeFromProcess.type &&
        item?.id === this.candidateAssigneeFromProcess.id
    }
  }
}
</script>
<template>
  <div v-if="taskDefinition">
    <breadcrumbs :items="processDefinitionTaskBreadcrumbs" />

    <page-detail-header
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #default>
        <div>Aktualisiert {{ distanceDate(taskDefinition.updatedAt) }}</div>
      </template>
      <template #actions="{loading}">
        <kebab-menu
          :disabled="loading || isReadonly"
          color="primary"
        >
          <template #items="{ closeMenu }">
            <request-dialog
              :title="$t('processDefinition.dialogs.cloneTask.title')"
              :ok-btn-text="$t('processDefinition.dialogs.cloneTask.buttons.ok')"
              :request-parameter="{ method: 'patch', url: $apiEndpoints.taskDefinitions.clone(taskDefinition.id) }"
              @request-success="onUpdated"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>Aufgabe duplizieren</v-list-item-title>
                </v-list-item>
              </template>
              <i18n path="processDefinition.dialogs.cloneTask.text">
                <template #name>
                  <b>"{{ taskDefinition.name }}"</b>
                </template>
              </i18n>
            </request-dialog>
            <delete-dialog
              :title="$t('processDefinition.dialogs.deleteTask.title')"
              :text="$t('processDefinition.dialogs.deleteTask.text')"
              :request-parameter="{ method: 'delete', url: $apiEndpoints.taskDefinitions.destroy(taskDefinition.id) }"
              @request-success="onDeleted"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>Aufgabe löschen</v-list-item-title>
                </v-list-item>
              </template>
            </delete-dialog>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <h1 class="text-h4 font-weight-light mb-4">
      Aufgabe
    </h1>

    <text-control
      v-model="taskDefinition.name"
      :label="$t('processDefinition.fields.taskDefinitions.title')"
      required
      :request-parameter="{method: 'patch', url: $apiEndpoints.taskDefinitions.update(taskDefinition.id), mapping: 'name'}"
      :readonly="isReadonly"
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <users-control
      :value="candidateAssignee"
      :label="$t('processDefinition.fields.taskDefinitions.candidateAssignee')"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.taskDefinitions.update(taskDefinition.id), mapping: (value) => {
        return {
          'candidate_assignee_from_workflow': isCandidateAssigneeFromProcess(value),
          'candidate_assignee_id': isCandidateAssigneeFromProcess(value) ? null : (value?.id || null)
        }
      }}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.users.list()}"
      :item-identifier="['id', 'type']"
      :additional-items="[candidateAssigneeFromProcess]"
      :readonly="isReadonly"
      single
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <users-and-groups-control
      :value="taskDefinition.candidateContributors"
      :label="$t('processDefinition.fields.taskDefinitions.candidateContributors')"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.taskDefinitions.updateCandidateContributors(taskDefinition.id), mapping: 'candidate_contributors'}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.usersAndGroups.list()}"
      :readonly="isReadonly"
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <numeric-control
      v-model="taskDefinition.dueInDays"
      :label="$t('processDefinition.fields.taskDefinitions.automaticDueInDays')"
      :request-parameter="{method: 'patch', url: $apiEndpoints.taskDefinitions.update(taskDefinition.id), mapping: 'due_in_days'}"
      :options="{ allowNegative: false, min: 0 }"
      :readonly="isReadonly"
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <numeric-control
      v-model="taskDefinition.deferralInDays"
      :label="$t('processDefinition.fields.taskDefinitions.automaticDeferralInDays')"
      :request-parameter="{method: 'patch', url: $apiEndpoints.taskDefinitions.update(taskDefinition.id), mapping: 'deferral_in_days'}"
      :options="{ allowNegative: false, min: 0 }"
      :readonly="isReadonly"
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <task-definition-fields-control
      :value="taskDefinition.taskItemDefinitions"
      :label="$t('processDefinition.fields.taskDefinitions.fields')"
      :content-item-definitions="processDefinition.contentItemDefinitions"
      :content-types="contentTypes"
      :task-definition-id="taskDefinition.id"
      :readonly="isReadonly"
      @change="onFieldsUpdated"
    />
  </div>
</template>
