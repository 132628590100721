<script>
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import TextControl from 'controls/text-control.vue'
import User from 'mixins/models/user'

export default {
  name: 'UserSettingsPasswordContent',
  components: {
    TextControl
  },
  mixins: [PageContentable, Requestable, User],
  data () {
    return {
      current_password: '',
      password: '',
      password_confirmation: '',
      showSuccessConfirmation: false
    }
  },
  computed: {
    valid () {
      return this.password.trim().length > 0 &&
          this.password_confirmation.trim().length > 0 &&
          this.current_password.trim().length > 0
    }
  },
  methods: {
    save () {
      this.request(
        { method: Request.PATCH, url: this.$apiEndpoints.userSettings.updatePassword() },
        null, {
          current_password: this.current_password,
          password: this.password,
          password_confirmation: this.password_confirmation
        }
      )
    },
    onRequestSuccess (data) {
      this.current_password = ''
      this.password = ''
      this.password_confirmation = ''

      this.showSuccessConfirmation = true
    },
    onInput () {
      this.showSuccessConfirmation = false
    }
  }
}
</script>
<template>
  <div v-if="value">
    <form>
      <text-control
        v-model="current_password"
        label="Aktuelles Passwort"
        :options="{type: 'password', autocomplete: 'current-password'}"
        :error-messages="validationErrorMessageFor('current_password')"
        class="mb-5"
        @input="onInput"
      />
      <input
        :value="userEmailsFor(value)"
        type="text"
        name="username"
        autocomplete="username"
        class="d-none"
      >
      <text-control
        v-model="password"
        label="Neues Passwort"
        name="password"
        :options="{type: 'password', autocomplete: 'new-password'}"
        :error-messages="validationErrorMessageFor('password')"
        class="mb-5"
        @input="onInput"
      />
      <text-control
        v-model="password_confirmation"
        label="Neues Passwort wiederholen"
        name="password_confirmation"
        :options="{type: 'password', autocomplete: 'new-password'}"
        :error-messages="validationErrorMessageFor('password_confirmation')"
        class="mb-8"
        @input="onInput"
      />
      <v-btn
        color="primary"
        :disabled="!valid || requestableLoading"
        depressed
        @click="save"
      >
        Passwort ändern
      </v-btn>
    </form>
    <v-alert
      v-if="showSuccessConfirmation"
      type="success"
      class="mt-5"
    >
      Passwort wurde erfolgreich geändert
    </v-alert>
  </div>
</template>
