<script>
import { PageContentable } from '../page'
import { routeFor } from 'helpers/route'
import UsersControl from 'controls/users-control.vue'
import Group from 'mixins/models/group'

export default {
  name: 'GroupEditContentUsers',
  components: {
    UsersControl
  },
  mixins: [PageContentable, Group],
  computed: {
    group () {
      return this.value
    }
  },
  methods: {
    ...{ routeFor },
    onUpdated (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<template>
  <div v-if="value">
    <users-control
      v-model="value.users"
      label="Nutzer:innenauswahl"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.groups.update(value.id), mapping: 'user_ids'}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.users.list()}"
      :to="(item) => { return routeFor($config.current_user.isAdmin ? 'user_settings' : 'user', item.id) }"
      class="mb-5"
      :readonly="!$config.current_user.isAdmin || isReadonly"
      :change-button-tooltip="$config.current_user.isAdmin ? undefined : $t('general.tooltips.onlyAdminsCanChangeSelection')"
      @request-success-data="onUpdated"
    />
  </div>
</template>
