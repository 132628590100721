export const stateColors = {
  created: {
    value: '#546E7A',
    background: 'blue-grey lighten-1',
    text: 'blue-grey--text text--lighten-1'
  },
  snoozed: {
    value: '#FF9800',
    background: 'orange',
    text: 'orange--text'
  },
  active: {
    value: '#4CAF50',
    background: 'green',
    text: 'green--text'
  },
  completed: {
    value: '#01579B',
    background: 'light-blue darken-4',
    text: 'light-blue--text text--darken-4'
  },
  skipped: {
    value: '#BCAAA4',
    background: 'brown lighten-3',
    text: 'brown--text text--lighten-3'
  },
  trashed: {
    value: '#E57373',
    background: 'red lighten-2',
    text: 'red--text text--lighten-2'
  },
  deactivated: {
    value: '#E0E0E0',
    background: 'grey lighten-2',
    text: ''
  },

  // TODO: check if can be removed
  started: {
    value: '#4CAF50',
    background: 'green',
    text: 'green--text'
  },
  canceled: {
    value: '#F44336',
    background: 'red',
    text: 'red--text'
  }
}
