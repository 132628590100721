<script>
export default {
  name: 'SidebarLeft',
  props: {
    value: Boolean,
    floating: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template functional>
  <v-navigation-drawer
    :value="props.value"
    app
    clipped
    left
    width="350"
    height="100%"
    :floating="props.floating"
    @input="listeners.input && listeners.input($event)"
  >
    <v-btn
      class="d-lg-none justify-start py-4"
      style="position: sticky; top: 0; z-index: 1;"
      depressed
      tile
      block
      small
      @click="listeners.input && listeners.input(false)"
    >
      <v-icon>mdi-chevron-double-left</v-icon>
      Seitenleiste schließen
    </v-btn>
    <slot />
  </v-navigation-drawer>
</template>
