<script>
export default {
  name: 'ResetBtn'
}
</script>
<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <v-btn
        tabindex="-1"
        icon
        small
        color="error"
        class="mr-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('click')"
      >
        <v-icon>mdi-undo</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('components.resetBtn.tooltip') }}</span>
  </v-tooltip>
</template>
