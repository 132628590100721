<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import omit from 'lodash/omit'

export default {
  name: 'RequestDialog',
  components: { CustomDialog },
  mixins: [Requestable],
  props: {
    ...requestablePropFactory().props,
    ...omit(CustomDialog.props, ['value', 'errorMessage', 'closeOnButtonClick', 'loading'])
  },
  data () {
    return {
      dialogOpen: false
    }
  },
  methods: {
    omit,
    onDialogOpened () {
      this.resetRequestable()

      this.$emit('open')
    },
    onRequestSuccess (data) {
      this.dialogOpen = false

      if (data) {
        this.$emit('request-success', data)
      }
    }
  }
}
</script>
<template>
  <custom-dialog
    v-bind="{...$props, ...$attrs}"
    v-model="dialogOpen"
    :title="title"
    :close-on-button-click="false"
    :loading="requestableLoading"
    :error-message="baseErrorMessage"
    @click-ok="request(requestParameter, null)"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="$scopedSlots.default">
      <slot />
    </template>
  </custom-dialog>
</template>
