<script>
import Avatar from 'components/avatar.vue'
import { subtitleElements } from 'helpers/file'
import includes from 'lodash/includes'

export default {
  name: 'FileListItem',
  components: { Avatar },
  props: {
    value: {
      type: Object,
      required: true
    },
    actions: {
      type: Array,
      default: () => []
    },
    link: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    subtitleElements () {
      return subtitleElements(this.value)
    }
  },
  methods: {
    openItem () {
      window.open(this.value.url, '_blank')
    },
    showAction (action) {
      return includes(this.actions, action)
    }
  }
}
</script>
<template>
  <v-list-item
    :disabled="disabled"
    v-on="link ? { click: openItem } : {}"
  >
    <avatar
      icon="mdi-file-document-outline"
      class="mr-2"
      list
    />

    <v-list-item-content>
      <v-list-item-title>{{ value.title }}</v-list-item-title>
      <v-list-item-subtitle class="custom-meta-list">
        <span
          v-for="item in subtitleElements"
          :key="item"
        >
          {{ item }}
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action
      v-if="actions && actions.length > 0"
      class="px-4"
    >
      <v-menu
        bottom
        left
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon color="grey lighten-1">
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="showAction('download')"
            :href="value.url"
            download
          >
            Herunterladen
          </v-list-item>
          <v-list-item
            v-if="showAction('edit')"
            @click="$emit('action', 'edit', value)"
          >
            Bearbeiten
          </v-list-item>
          <v-list-item
            v-if="showAction('delete')"
            @click="$emit('action', 'delete', value)"
          >
            Löschen
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>
