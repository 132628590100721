// Styles
import 'vuetify/src/components/VInput/VInput.sass'
import './base-control.sass'

// Extensions
import VInput from 'vuetify/lib/components/VInput'

// Components
import VLabel from 'vuetify/lib/components/VLabel'

// Mixins
import Loadable from 'vuetify/lib/mixins/loadable'

// Types
import mixins from 'vuetify/lib/util/mixins'
const baseMixins = mixins(VInput, Loadable)

export default baseMixins.extend().extend({
  name: 'base-control',
  props: {
    focused: Boolean
  },
  computed: {
    classes () {
      return {
        ...VInput.options.computed.classes.call(this),
        'base-control': true
      }
    },

    labelValue () {
      return !this.isSingle && Boolean(this.isFocused || this.isLabelActive)
    }
  },
  watch: {
    focused: {
      handler (newVal) {
        this.isFocused = newVal
      },
      immediate: true
    }
  },
  methods: {
    genContent () {
      return [this.genHeader(), this.genControl()]
    },

    genDefaultSlot () {
      return [this.$slots.default, this.genProgress()]
    },

    genInputSlot () {
      return this.$createElement('div', this.setBackgroundColor(this.backgroundColor, {
        staticClass: 'v-input__slot',
        ref: 'input-slot'
      }), [this.genDefaultSlot()])
    },

    genHeader () {
      return this.$createElement('div', {
        staticClass: 'base-control__header'
      }, [
        this.genLabel(), this.genActionsSlot()
      ]
      )
    },

    genActionsSlot () {
      return this.$createElement('div', {
        staticClass: 'base-control__actions-slot'
      }, [
        this.$slots.actions
      ]
      )
    },

    genLabel () {
      if (!this.hasLabel) return null
      return this.$createElement(VLabel, {
        props: {
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          focused: this.isFocused || !!this.validationState,
          for: this.computedId,
          light: this.light,
          value: this.labelValue
        }
      }, this.$slots.label || this.label)
    },

    genMessages () {
      if (!this.showDetails) return null
      const messagesNode = VInput.options.methods.genMessages.call(this)
      return this.$createElement('div', {
        staticClass: 'base-control__details'
      }, [messagesNode])
    }
  }
})
