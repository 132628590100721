<script>
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import DeleteDialog from 'dialogs/delete-dialog.vue'
import ProcessDefinition from 'mixins/models/process-definition'
import TextControl from 'controls/text-control.vue'
import RichtextareaControl from 'controls/richtextarea-control.vue'
import SelectionControl from 'controls/selection-control.vue'
import SwitchControl from 'controls/switch-control.vue'
import ObjectsControl from 'controls/objects-control/index.vue'
import GroupListItem from 'list-items/group-list-item.vue'
import MonitorSubscription from 'components/monitor-subscription.vue'
import ActivityHub from 'components/activity-hub/index.vue'
import globalIdFor from 'helpers/global-id'
import { distanceDate } from 'helpers/date'
import { routeFor } from 'helpers/route'
import values from 'lodash/values'
import filter from 'lodash/filter'

export default {
  name: 'ProcessDefinitionEditContentProcess',
  components: {
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    DeleteDialog,
    TextControl,
    RichtextareaControl,
    SelectionControl,
    SwitchControl,
    ObjectsControl,
    GroupListItem,
    MonitorSubscription,
    ActivityHub
  },
  mixins: [PageContentable, ProcessDefinition],
  computed: {
    processDefinition () {
      return this.value
    },
    processContentItemDefinitionsAsArray () {
      return values(this.processDefinition.contentItemDefinitions)
    },
    processContentItemDefinitionWithoutNotesAsArray () {
      return filter(this.processContentItemDefinitionsAsArray, (cid) => cid.type.name !== 'note')
    },
    gid () {
      return globalIdFor('WorkflowDefinition', this.pageProps.id)
    }
  },
  methods: {
    ...{ routeFor, distanceDate },
    onUpdated (value) {
      this.$emit('input', value)
    },
    onDeleted () {
      this.$router.replace({ name: 'process-definitions' })
    }
  }
}
</script>
<template>
  <div>
    <template v-if="value">
      <monitor-subscription
        :key="`monitor-channel-${gid}`"
        :gid="gid"
      />
      <breadcrumbs :items="processDefinitionBreadcrumbs" />

      <page-detail-header
        :state-text="processDefinitionDeactivatedText"
        :state-updated-at="processDefinitionDeactivatedAtDate"
        :state-color="processDefinitionDeactivatedColor && processDefinitionDeactivatedColor.background"
        :has-left-sidebar="hasLeftSidebar"
        :has-right-sidebar="hasRightSidebar"
        @open-sidebar="$emit('open-sidebar', $event)"
      >
        <template #default>
          <div v-if="processDefinitionIsActive">
            Aktualisiert {{ distanceDate(processDefinition.updatedAt) }}
          </div>
          <router-link
            :to="routeFor('tasks_by_definition', isSystemProcessDefinitionSingleTask ? 'none' : processDefinition.id, {query: { tab_category: 'ALL' }})"
            class="text-decoration-none"
          >{{ $tc('general.counts.instances', processDefinition.instanceCount) }}</router-link>
        </template>
        <template #actions="{actionRequest, loading}">
          <kebab-menu
            :disabled="loading || isReadonly"
            color="primary"
          >
            <template #items="{ closeMenu }">
              <v-list-item @click="actionRequest($apiEndpoints.workflowDefinitions.update(value.id), onUpdated, 'patch', null, { active: !processDefinitionIsActive })">
                <v-list-item-title>{{ processDefinitionIsActive ? $t('processDefinition.detailActions.deactivate') : $t('processDefinition.detailActions.activate') }}</v-list-item-title>
              </v-list-item>

              <delete-dialog
                :title="$t('processDefinition.dialogs.deleteProcess.title')"
                :text="$t('processDefinition.dialogs.deleteProcess.text')"
                :request-parameter="{ method: 'delete', url: $apiEndpoints.workflowDefinitions.destroy(value.id) }"
                @request-success="onDeleted"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-title>{{ $t('processDefinition.actions.delete') }}</v-list-item-title>
                  </v-list-item>
                </template>
              </delete-dialog>
            </template>
          </kebab-menu>
        </template>
      </page-detail-header>

      <h1 class="text-h4 font-weight-light mb-4">
        Prozessvorlage
      </h1>

      <text-control
        v-model="value.nameFromDB"
        :label="$t('processDefinition.fields.title')"
        :request-parameter="{method: 'patch', url: $apiEndpoints.workflowDefinitions.update(value.id), mapping: 'name'}"
        :readonly="isReadonly"
        class="mb-5"
        @request-success-data="onUpdated"
      />

      <richtextarea-control
        v-model="value.description"
        :label="$t('processDefinition.fields.description')"
        :request-parameter="{method: 'patch', url: $apiEndpoints.workflowDefinitions.update(value.id), mapping: 'description'}"
        :readonly="isReadonly"
        class="mb-5"
        @request-success-data="onUpdated"
      />

      <selection-control
        v-model="value.startItemDefinitionIds"
        :label="$t('processDefinition.fields.startDialogFields')"
        :options="{ items: processContentItemDefinitionsAsArray, multiple: true }"
        :item-text="item => `${item.label} (${item.type.label})`"
        item-value="id"
        :deletable-chips="false"
        sortable-chips
        :hint="$t('general.sortableHint')"
        persistent-hint
        :request-parameter="{method: 'patch', url: $apiEndpoints.workflowDefinitions.update(value.id), mapping: 'start_item_definition_ids'}"
        :readonly="isReadonly"
        class="mb-5"
        @request-success-data="onUpdated"
      />

      <switch-control
        v-model="value.autoGenerateTitle"
        :label="$t('processDefinition.fields.autoGenerateTitle')"
        :request-parameter="{method: 'patch', url: $apiEndpoints.workflowDefinitions.update(value.id), mapping: 'auto_generate_title'}"
        :readonly="isReadonly"
        class="mt-10"
        @request-success-data="onUpdated"
      />

      <selection-control
        v-if="value.autoGenerateTitle"
        v-model="value.titleItemDefinitionIds"
        :label="$t('processDefinition.fields.autoGenerateTitleFields')"
        :options="{ items: processContentItemDefinitionWithoutNotesAsArray, multiple: true }"
        :item-text="item => `${item.label} (${item.type.label})`"
        item-value="id"
        :deletable-chips="false"
        sortable-chips
        :hint="$t('processDefinition.titleFieldsSelectionHint')"
        persistent-hint
        :request-parameter="{method: 'patch', url: $apiEndpoints.workflowDefinitions.update(value.id), mapping: 'title_item_definition_ids'}"
        :readonly="isReadonly"
        class="mb-5 mt-0"
        @request-success-data="onUpdated"
      />

      <objects-control
        v-model="value.groups"
        :label="$t('processDefinition.fields.groups')"
        :update-request-parameter="{method: 'patch', url: $apiEndpoints.workflowDefinitions.update(value.id), mapping: 'group_ids'}"
        :list-request-parameter="{method: 'get', url: $apiEndpoints.groups.list()}"
        :readonly="!($config.current_user.isAdmin || $config.flags.enableExperimentalFeatures)"
        :change-button-tooltip="($config.current_user.isAdmin || $config.flags.enableExperimentalFeatures) ? undefined : $t('general.tooltips.onlyAdminsCanChangeSelection')"
        @request-success-data="onUpdated"
      >
        <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
          <group-list-item
            :key="item.id"
            :indent="indent"
            :item-link="itemLink"
            :selectable="selectable"
            :selected="selected"
            :skeleton="item.skeleton"
            :value="item"
            v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
          />
        </template>
      </objects-control>
    </template>

    <activity-hub
      v-if="gid"
      :key="`activity-hub-${gid}`"
      :gid="gid"
      :groups="(processDefinition || {}).groups"
      :editor-hint="$t('processDefinition.editorHint')"
      class="mt-12"
      @loaded="pageDataLoaded"
    />
  </div>
</template>
