<script>
import { PageContentable } from '../page'
import VisibilitySummary from 'components/visibility-summary.vue'
import MentioningHint from 'components/mentioning-hint.vue'
import Dossier from 'mixins/models/dossier'

export default {
  name: 'DossierEditSidebarRight',
  components: {
    VisibilitySummary,
    MentioningHint
  },
  mixins: [PageContentable, Dossier],
  computed: {
    dossier () {
      return this.value
    }
  }
}
</script>
<template>
  <div v-if="value">
    <visibility-summary :value="value.groups" />
    <v-divider />

    <mentioning-hint
      :identifier="dossierIdentifier"
      path="dossier.mentioningHint"
    />
    <v-divider />
  </div>
</template>
