<script>
import { PageContentable } from '../page'
import User from 'mixins/models/user'
import Avatar from 'components/avatar.vue'

export default {
  name: 'UserProfileContent',
  components: { Avatar },
  mixins: [PageContentable, User],
  computed: {
    user () {
      return this.value
    }
  }
}
</script>
<template>
  <div
    v-if="value"
    class="d-flex flex-column align-center mt-4"
  >
    <avatar
      :text="value.avatar.label"
      :image="value.avatar.url"
      :size="100"
      class="mb-2"
    />
    <h1 class="text-h4 font-weight-light">
      {{ value.fullname }}
    </h1>
    <div>{{ user.email }}</div>
  </div>
</template>
