<script>
import { PageContentable } from '../page'
import SelectControl from 'controls/select-control.vue'

export default {
  name: 'SettingsNotificationsContent',
  components: {
    SelectControl
  },
  mixins: [PageContentable],
  methods: {
    onUpdated (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<template>
  <div v-if="value">
    <h2 class="text-h5 font-weight-light mb-4">
      Benachrichtigungen per E-Mail
    </h2>
    <select-control
      v-if="$config.current_user.isAdmin || $config.isCurrentUser(value)"
      v-model="value.notifications.interval"
      :items="value.notifications.items"
      label="Neue Benachrichtigungen per E-Mail"
      :request-parameter="{method: 'patch', url: $apiEndpoints.userSettings.updateNotificationSettings(), mapping: 'interval'}"
      class="mb-5"
      :disabled="!$config.isCurrentUser(value)"
      @request-success-data="onUpdated"
    />
  </div>
</template>
