import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import { stateColors } from 'helpers/definitions'

export default {
  methods: {
    processDefinitionGetTaskDefinitionsFor (processDefinition) {
      return flatMap(processDefinition.structure, (item) => {
        switch (item.type) {
          case 'task_definition': return item
          case 'block_definition': return item.items
          default:
            console.error('Unsupported process-definition structure item type:', item.type)
            return []
        }
      })
    },
    processDefinitionGetTaskDefinitionWithIdFor (processDefinition, taskDefinitionId) {
      if (processDefinition) {
        return find(this.processDefinitionGetTaskDefinitionsFor(processDefinition), { id: taskDefinitionId })
      } else {
        return null
      }
    },
    processDefinitionGetBlockDefinitionWithIdFor (processDefinition, blockDefinitionId) {
      if (processDefinition) {
        return find(processDefinition.structure, { type: 'block_definition', id: blockDefinitionId })
      } else {
        return null
      }
    },
    processDefinitionIsSystemProcessDefinitionSingleTaskFor (processDefinition) {
      return processDefinition?.systemIdentifier === 'system_process_definition_single_task'
    },
    processDefinitionIsActiveFor (processDefinition) {
      return !processDefinition.deactivatedAt
    }
  },
  computed: {
    processDefinitionBreadcrumbs () {
      return [
        { text: 'Prozessvorlagen', to: { name: 'process-definitions' } },
        { text: `${this.processDefinition.id}` }
      ]
    },
    processDefinitionTaskBreadcrumbs () {
      return this.processDefinitionBreadcrumbs.concat([
        { text: 'Struktur' },
        { text: 'Aufgabe' }
      ])
    },
    processDefinitionBlockBreadcrumbs () {
      return this.processDefinitionBreadcrumbs.concat([
        { text: 'Struktur' },
        { text: 'Block' }
      ])
    },
    isSystemProcessDefinitionSingleTask () {
      return this.processDefinitionIsSystemProcessDefinitionSingleTaskFor(this.processDefinition)
    },
    isReadonly () {
      return this.isSystemProcessDefinitionSingleTask
    },
    processDefinitionIsActive () {
      return this.processDefinitionIsActiveFor(this.processDefinition)
    },
    processDefinitionDeactivatedText () {
      return !this.processDefinitionIsActive ? 'Deaktiviert' : null
    },
    processDefinitionDeactivatedColor () {
      return !this.processDefinitionIsActive ? stateColors.trashed : null
    },
    processDefinitionDeactivatedAtDate () {
      return !this.processDefinitionIsActive ? new Date(this.processDefinition.deactivatedAt) : null
    }
  }
}
