<script>
import Vue from 'vue'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import Avatar from 'components/avatar.vue'
import ActivityHubItemChunks from './activity-hub-item-chunks.vue'
import RichtextareaContent from 'components/richtextarea-content.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import KebabMenu from 'components/kebab-menu.vue'
import ActivityHubCommentEditor from './activity-hub-comment-editor'
import DeleteCommentDialog from 'dialogs/delete-comment-dialog.vue'
import TimeTracking from 'mixins/models/time-tracking'

export default {
  name: 'ActivityHubItem',
  components: {
    Avatar,
    ActivityHubItemChunks,
    RichtextareaContent,
    CopyToClipboardBtn,
    KebabMenu,
    ActivityHubCommentEditor,
    DeleteCommentDialog
  },
  mixins: [TimeTracking],
  props: {
    event: {
      type: Object,
      required: true
    },
    unread: {
      type: Boolean,
      required: false
    },
    showActions: {
      type: Boolean,
      default: false
    },
    groups: {
      type: Array,
      default: () => []
    },
    editorHint: {
      type: String,
      default: undefined
    },
    timeTracking: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isInEditMode: false,
      currentComment: null,
      currentTimeTracking: null,
      updateCommentRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.cancelEditMode()
            this.$emit('comment-updated', this.event)
          }
        }
      })
    }
  },
  computed: {
    isCommentEvent () {
      return this.event.action === 'commented' || this.event.action === 'feature_released'
    },
    timelineItemClass () {
      return this.event.action === 'commented' ? 'activity-hub-item-comment' : 'activity-hub-item-event'
    },
    timelineItemId () {
      return `timeline-item-${this.event.action}-${this.event.id}`
    },
    timelineItemUrl () {
      return window.location.protocol + '//' + window.location.host + window.location.pathname + '#' + this.timelineItemId
    },
    hasValidChanges () {
      return (this.currentComment !== null && this.currentComment !== this.event.payload.message) ||
          this.currentTimeTracking !== this.event.payload.time_tracking
    },
    eventSubjectId () {
      return this.event.subject?.id
    }
  },
  methods: {
    startEditMode () {
      this.currentComment = this.event.payload.message
      this.currentTimeTracking = this.event.payload.time_tracking
      this.isInEditMode = true
    },
    cancelEditMode () {
      this.isInEditMode = false
      this.currentComment = null
      this.currentTimeTracking = null
    },
    updateComment () {
      this.updateCommentRequestable.request(
        { method: Request.PATCH, url: this.$apiEndpoints.events.updateComment(this.event.id) },
        null, {
          message: this.currentComment,
          ...(this.timeTracking
            ? {
                time_spent_at: this.currentTimeTracking?.spentAt,
                time_spent_in_minutes: this.currentTimeTracking?.timeSpentInMinutes
              }
            : {})
        }
      )
    },
    onCommentDeleted (data) {
      this.$emit('comment-deleted', this.event)
    }
  }
}
</script>
<template>
  <v-timeline-item
    :id="timelineItemId"
    class="d-flex align-center"
    :class="timelineItemClass"
    color="grey lighten-4"
    fill-dot
  >
    <template #icon>
      <v-icon
        v-if="event.avatar.icon"
        color="grey lighten-1"
      >
        {{ event.avatar.icon }}
      </v-icon>
      <avatar
        v-else
        :text="event.avatar.label"
        :image="event.avatar.url"
      />
    </template>

    <div class="d-flex align-center flex-grow-1">
      <div
        v-if="unread"
        style="position: absolute; margin-left: -20px; width: 6px; height: 6px; border-radius: 3px; background-color: #ee8450; box-shadow: 0 0 2px 1px #ee8450; z-index: 1"
        title="Ungelesen"
      />
      <v-card
        v-if="isCommentEvent"
        class="elevation-1 flex-grow-1"
      >
        <v-card-title class="text-body-2 text--secondary flex-nowrap align-start">
          <activity-hub-item-chunks
            :value="event.chunks"
            class="flex-grow-1"
          />
          <kebab-menu
            v-if="showActions"
            btn-class="ml-2"
          >
            <template #items="{ closeMenu }">
              <copy-to-clipboard-btn :value="{'text/plain': timelineItemUrl}">
                <template #activator="{ on }">
                  <v-list-item
                    v-on="on"
                  >
                    {{ $t('activityHub.items.comment.actions.copyToClipboard') }}
                  </v-list-item>
                </template>
              </copy-to-clipboard-btn>
              <v-list-item
                v-if="$config.isCurrentUser({ id: eventSubjectId })"
                @click="startEditMode"
              >
                {{ $t('activityHub.items.comment.actions.edit') }}
              </v-list-item>
              <delete-comment-dialog
                :request-parameter="{ method: 'delete', url: $apiEndpoints.events.deleteComment(event.id) }"
                @request-success="onCommentDeleted"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item
                    v-if="$config.isCurrentUser({ id: eventSubjectId })"
                    class="error--text"
                    v-on="on"
                  >
                    <v-list-item-title>{{ $t('activityHub.items.comment.actions.delete') }}</v-list-item-title>
                  </v-list-item>
                </template>
              </delete-comment-dialog>
            </template>
          </kebab-menu>
        </v-card-title>

        <v-card-text class="text--primary">
          <richtextarea-content
            v-if="!isInEditMode"
            :template="timeTrackingCommentContentFor(event.payload.time_tracking, event.payload.message)"
          />
          <div v-else>
            <activity-hub-comment-editor
              v-model="currentComment"
              placeholder="Schreib einen Kommentar"
              :loading="updateCommentRequestable.requestableLoading"
              :disabled="updateCommentRequestable.requestableLoading"
              :error-messages="updateCommentRequestable.errorMessage"
              :groups="groups"
              :hint="editorHint"
              :time-tracking="timeTracking"
              :time-tracking-value.sync="currentTimeTracking"
              content-changed
              class="mb-3"
            />
            <v-btn
              color="primary"
              depressed
              :disabled="updateCommentRequestable.requestableLoading || !hasValidChanges"
              @click="updateComment"
            >
              Kommentar speichern
            </v-btn>
            <v-btn
              color="secondary"
              text
              :disabled="updateCommentRequestable.requestableLoading"
              @click="cancelEditMode"
            >
              Abbrechen
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <div
        v-else
        class="text-body-2 text--secondary flex-grow-1"
      >
        <activity-hub-item-chunks :value="event.chunks" />
      </div>

      <div
        v-if="$scopedSlots.actions"
        class="ml-2"
      >
        <slot name="actions" />
      </div>
    </div>
  </v-timeline-item>
</template>
<style>
.activity-hub-item-comment .v-timeline-item__body {
  display: flex;
  align-items: center;
}
</style>
