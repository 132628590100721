export default {
  appName: 'samarbeid',
  title: 'Titel',
  description: 'Beschreibung',
  buttons: {
    cancel: 'Abbrechen',
    save: 'Speichern',
    saveAndCreateAnother: 'Speichern und weitere erstellen',
    change: 'Ändern',
    retry: 'Wiederholen',
    back: 'Zurück',
    ok: 'OK',
    delete: 'Löschen',
    close: 'Schließen',
    create: 'Erstellen'
  },
  field: {
    required: 'erforderlich'
  },
  editorHelpText: 'Verweise im Text: @Nutzer:in, %Prozess, #Aufgabe, *Dossier',
  time: '{time} Uhr',
  sortableHint: 'Reihenfolge per Drag&Drop änderbar.',
  counts: {
    instances: 'keine Instanzen | {count} Instanz | {count} Instanzen',
    groups: 'keine Gruppen | {count} Gruppe | {count} Gruppen',
    users: 'keine Nutzer:innen | {count} Nutzer:in | {count} Nutzer:innen',
    processDefinitions: 'keine Prozessvorlagen | {count} Prozessvorlage | {count} Prozessvorlagen',
    dossierDefinitions: 'keine Dossiervorlagen | {count} Dossiervorlage | {count} Dossiervorlagen',
    comments: '| {count} Kommentar | {count} Kommentare'
  },
  groupMember: 'Du bist Mitglied',
  newReleaseMessage: {
    text: 'Es wurde eine neue Version von samarbeid veröffentlicht. Bitte laden Sie die Seite in ihrem Browser neu.',
    button: 'Neu laden'
  },
  tooltips: {
    onlyAdminsCanChangeSelection: 'Nur Admins dürfen Zuordung ändern'
  }
}
