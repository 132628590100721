<script>
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import SmartList from '../../components/smart-list.vue'
import DossierListItem from 'list-items/dossier-list-item.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossierEditDossierFieldReferencesContent',
  components: {
    SmartList,
    DossierListItem
  },
  mixins: [PageContentable, Requestable],
  data () {
    return {
      references: undefined
    }
  },
  watch: {
    value: {
      handler () {
        this.loadDossier()
      },
      deep: true,
      immediate: true
    },
    'pageProps.fieldId': {
      handler () {
        this.loadDossier()
      }
    }
  },
  methods: {
    ...{ routeFor },
    loadDossier () {
      this.references = undefined
      if (this.value && this.pageProps.fieldId) {
        this.request(
          { method: Request.GET, url: this.$apiEndpoints.dossiers.showDossierFieldReferences(this.value.id) },
          { dossier_field_definition_id: this.pageProps.fieldId }, null, true, true
        )
      }
    },
    onRequestSuccess (data) {
      this.references = data
    },
    onRequestError (error) {
      console.log('get data error:')
      console.log(error)
    }
  }
}
</script>
<template>
  <smart-list
    v-slot="{ item }"
    :value="references"
    :loading="requestableLoading"
    empty-list-message="Dieses Dossier wird, für das ausgewählte Feld, in keinem andere Dossier genutzt."
  >
    <dossier-list-item
      :value="item"
      :to="routeFor('dossier', item.id)"
      indent
      :dense="false"
      divider
    />
  </smart-list>
</template>
