<script>
import { routeFor } from 'helpers/route'

export default {
  name: 'ActivityHubItemLink',
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...{ routeFor }
  }
}
</script>
<template functional>
  <a v-if="props.disabled">
    <slot />
  </a>
  <router-link
    v-else-if="props.value.type === 'reference' && $options.methods.routeFor(props.value.object_type, props.value.object_id, { query: { event: props.value.event }, hash: props.value.hash })"
    :to="$options.methods.routeFor(props.value.object_type, props.value.object_id, { query: { event: props.value.event }, hash: props.value.hash })"
    :title="props.value.noaccess || props.value.deleted ? 'gelöscht/kein Zugriff' : 'Öffnen'"
    :class="[data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
  >
    <slot />
  </router-link>
  <a v-else-if="props.value.type === 'reference'">
    <slot />
  </a>
  <a
    v-else-if="props.value.type === 'link'"
    :href="props.value.url"
    target="_blank"
    :class="[data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
  >
    <slot />
  </a>
</template>
