export default {
  actions: {
    createEntry: 'Menüeintrag hinzufügen',
    createSubMenu: 'Untermenü hinzufügen',
    delete: 'Löschen'
  },
  dialogs: {
    createSubMenu: {
      title: 'Untermenü hinzufügen',
      successMessage: 'Untermenü "{title}" wurde erfolgreich hinzugefügt',
      buttons: {
        ok: 'Hinzufügen',
        saveAndCreateAnother: 'Hinzufügen und weiteres erstellen'
      }
    },
    editSubMenu: {
      title: 'Untermenü bearbeiten'
    },
    addMenuItem: {
      title: 'Menüeintrag hinzufügen',
      buttons: {
        ok: 'Hinzufügen'
      }
    },
    deleteMenuEntry: {
      title: 'Menüeintrag löschen?'
    },
    deleteSubMenu: {
      title: 'Untermenü löschen?',
      text: 'Beim Löschen des Untermenüs werden alle Untermenüeinträge mitgelöscht.'
    }
  },
  itemTypes: {
    submenu: 'Untermenü',
    workflow_definition: 'Prozessvorlage',
    dossier_definition: 'Dossiervorlage'
  }
}
