<script>
export default {
  name: 'SidebarRight',
  props: {
    value: Boolean,
    floating: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template functional>
  <v-navigation-drawer
    :value="props.value"
    app
    clipped
    :color="!props.floating ? 'grey lighten-4' : undefined"
    right
    width="320"
    height="100%"
    :floating="props.floating"
    @input="listeners.input && listeners.input($event)"
  >
    <v-btn
      class="d-lg-none justify-end py-4"
      depressed
      tile
      block
      small
      @click="listeners.input && listeners.input(false)"
    >
      Seitenleiste schließen
      <v-icon>mdi-chevron-double-right</v-icon>
    </v-btn>
    <v-divider />
    <slot />
  </v-navigation-drawer>
</template>
<style>
    .v-navigation-drawer.v-navigation-drawer--right > .v-navigation-drawer__content {
        padding-left: 1px;
    }
</style>
