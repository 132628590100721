<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import defaults from 'lodash/defaults'

export default {
  name: 'RadioControl',
  components: { ResetBtn },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: [String, Number],
      default: undefined
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    internalOptions () {
      return defaults(this.options, {
        column: true
      })
    }
  },
  methods: {
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('select', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>

<template>
  <v-radio-group
    v-model="cacheValue"
    :column="internalOptions.column"
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :readonly="controlReadonly"
    :class="controlClass"
    :error-count="Number.MAX_VALUE"
    :error-messages="controlErrorMessages"
    :hint="hint"
    :persistent-hint="persistentHint"
    :success-messages="controlSuccessMessage"
    @change="controlOnChange"
  >
    <v-radio
      v-for="(item, index) in items"
      :key="`radio-${index}`"
      :label="item.text"
      :value="item.value"
    />
    <template
      v-if="!hideAppend"
      #append
    >
      <reset-btn
        v-if="cachedValueChanged && controlHasError"
        @click="controlReset"
      />
    </template>
  </v-radio-group>
</template>
