<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'EditDossierReferenceDialog',
  components: {
    CustomDialog
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialogOpen: false,
      internalValue: undefined,
      updateRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.dialogOpen = false

            if (data) {
              this.$emit('request-success-data', data)
            }
          }
        }
      })
    }
  },
  computed: {
    hasValidChanges () {
      if (!this.internalValue) return false

      return this.internalValue.referenceDefinition.title.trim() !== this.value.referenceDefinition.title ||
          this.internalValue.referenceDefinition.showEmpty !== this.value.referenceDefinition.showEmpty
    }
  },
  methods: {
    onDialogOpened () {
      this.internalValue = cloneDeep(this.value)
      this.updateRequestable.resetRequestable()

      this.$emit('open')
    },
    updateReference () {
      this.updateRequestable.request(
        { method: Request.PATCH, url: this.$apiEndpoints.dossierDefinitions.updateReference() },
        { dossier_field_definition_id: this.value.id },
        { reference_definition: this.internalValue.referenceDefinition }
      )
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    title="Referenz bearbeiten"
    fullheight
    :close-on-button-click="false"
    :ok-btn-disabled="!hasValidChanges"
    :loading="updateRequestable.requestableLoading"
    :error-message="updateRequestable.errorMessagesFullExceptValidationFor(['title', 'showEmpty'])"
    @click-ok="updateReference"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <v-text-field
      v-if="internalValue"
      v-model="internalValue.referenceDefinition.title"
      :label="$t('general.title')"
      :disabled="updateRequestable.requestableLoading"
      :error-messages="updateRequestable.validationErrorMessageFor('title')"
      :error-count="Number.MAX_VALUE"
      class="mb-5"
    />
    <v-switch
      v-if="internalValue"
      v-model="internalValue.referenceDefinition.showEmpty"
      label="Referenz auch anzeigen, wenn keine Dossiers verknüpft sind"
      :disabled="updateRequestable.requestableLoading"
      :error-messages="updateRequestable.validationErrorMessageFor('showEmpty')"
      :error-count="Number.MAX_VALUE"
    />
  </custom-dialog>
</template>
