<script>
import BaseControl from 'controls/base-control/index'

export default {
  name: 'ListControl',
  components: { BaseControl },
  props: {
    value: {
      type: Array,
      required: true
    },
    items: {
      type: Object,
      required: true
    },
    defaultValueItem: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    hint: {
      type: String,
      default: undefined
    },
    persistentHint: Boolean,
    errorMessages: {
      type: [String, Array],
      default: () => []
    }
  },
  data () {
    return {
      internalValue: []
    }
  },
  watch: {
    value: {
      handler (val) {
        this.internalValue = val
      },
      immediate: true
    }
  },
  methods: {
    onAddNewValueItem () {
      this.internalValue.push(Object.assign({}, this.defaultValueItem))
      this.$emit('change', this.internalValue)
    },
    onDeleteValueItem (index) {
      this.internalValue.splice(index, 1)
      this.$emit('change', this.internalValue)
    }
  }
}
</script>

<template>
  <base-control
    :label="label"
    :disabled="disabled"
    :hint="hint"
    :persistent-hint="persistentHint"
    :error-messages="errorMessages"
    :error-count="Number.MAX_VALUE"
  >
    <v-container>
      <v-row
        v-for="(valueItem, index) in internalValue"
        :key="index"
      >
        <v-col
          v-for="(item, name) in items"
          :key="name"
        >
          <slot
            name="field"
            :item-name="name"
            :item-def="item"
            :item-value="valueItem"
          />
        </v-col>
        <v-col class="flex-grow-0 align-self-center">
          <v-btn
            icon
            @click="onDeleteValueItem(index)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <template #actions>
      <v-btn
        text
        color="primary"
        @click="onAddNewValueItem"
      >
        Hinzufügen
      </v-btn>
    </template>
  </base-control>
</template>
