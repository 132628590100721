import ListPage from '../list-page'
import ListContent from './list-content.vue'

export default {
  name: 'MainMenuEntryListPage',
  mixins: [ListPage],
  data () {
    return {
      filter: {
        page: 1,
        queryText: null,
        order: 'position'
      }
    }
  },
  computed: {
    pageTitleParts () {
      return ['Hauptmenü konfigurieren']
    },
    pageContentComponents () {
      return ListContent
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.mainMenuEntries.index()
    }
  }
}
