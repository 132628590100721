<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import PageListHeader from 'components/page-list-header.vue'
import SmartList from 'components/smart-list.vue'
import CreateEditSubmenuDialog from 'dialogs/main-menu-entry/create-edit-submenu-dialog.vue'
import AddMainMenuEntryDialog from 'dialogs/main-menu-entry/add-dialog.vue'
import MainMenuListItem from 'list-items/main-menu-list-item.vue'
import draggable from 'vuedraggable'
import map from 'lodash/map'
import flatten from 'lodash/flatten'
import has from 'lodash/has'

export default {
  name: 'MainMenuEntryListContent',
  components: {
    PageListHeader,
    SmartList,
    MainMenuListItem,
    CreateEditSubmenuDialog,
    AddMainMenuEntryDialog,
    draggable
  },
  mixins: [PageContentable, ListPageContentable],
  data () {
    return {
      parentIdToAdd: null,
      moveRequestable: new (Vue.extend(Requestable))()
    }
  },
  computed: {
    result () {
      return this.value ? this.value.result : undefined
    },
    selectedDefinitions () {
      return flatten(map(this.result ? this.result : [], item => { return item.type === 'submenu' ? item.childEntries : item }))
    }
  },
  methods: {
    updateEntries (menuEntries) {
      this.$emit('input', menuEntries)
    },
    start (method) {
      setTimeout(() => method())
    },
    addMenuEntry (parentId) {
      this.parentIdToAdd = parentId
      this.$refs.addMenuEntryDialog.open()
    },
    onPositionChanged (targetParentId, event) {
      if (has(event, 'moved')) {
        this.moveItem(event.moved.element.id, event.moved.newIndex, targetParentId)
      } else if (has(event, 'added')) {
        this.moveItem(event.added.element.id, event.added.newIndex, targetParentId)
      }
    },
    moveItem (id, newIndex, parentId) {
      this.moveRequestable.request(
        { method: Request.PATCH, url: this.$apiEndpoints.mainMenuEntries.move(id) },
        { index: newIndex, parent_id: parentId })
    },
    putInSubMenu (to, from, dragEl, event) {
      return !dragEl.classList.contains('sub-menu')
    }
  }
}
</script>
<template>
  <div>
    <add-main-menu-entry-dialog
      ref="addMenuEntryDialog"
      :selected-items="selectedDefinitions"
      :parent-id="parentIdToAdd"
      @request-success="updateEntries"
    />

    <page-list-header
      title="Hauptmenü konfigurieren"
      secondary-actions-icon="mdi-plus"
    >
      <template #secondary-items>
        <create-edit-submenu-dialog @request-success="updateEntries">
          <template #activator="{ on }">
            <v-list-item @click="start(on.click)">
              <v-list-item-title>
                {{ $t('mainMenuEntry.actions.createSubMenu') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </create-edit-submenu-dialog>

        <v-list-item @click="addMenuEntry(null)">
          <v-list-item-title>{{ $t('mainMenuEntry.actions.createEntry') }}</v-list-item-title>
        </v-list-item>
      </template>
    </page-list-header>

    <smart-list
      :value="result"
      :loading="pageRequestLoading"
      empty-list-message="Keine Einträge vorhanden."
      class="mt-6 main-menu-items-list"
    >
      <template #items="{items: listItems}">
        <draggable
          :list="result"
          :group="{ name: 'main-menu-items-group' }"
          handle=".handle"
          :force-fallback="true"
          :animation="200"
          direction="vertical"
          class="menu-items"
          @change="onPositionChanged(null, $event)"
        >
          <main-menu-list-item
            v-for="item in listItems"
            v-slot="{items: subItems, isSubMenu}"
            :key="`main-menu-list-item-${item.id}`"
            :value="item"
            @update:entries="updateEntries"
            @add-menu-entry="addMenuEntry"
          >
            <draggable
              v-if="isSubMenu"
              :list="subItems"
              :group="{ name: 'main-menu-items-group', put: putInSubMenu }"
              handle=".handle"
              :force-fallback="true"
              :animation="200"
              direction="vertical"
              class="submenu-items pl-9"
              @change="onPositionChanged(item.id, $event)"
            >
              <div
                v-for="subItem in subItems"
                :key="`main-menu-entry-${value.id}-subentry-${subItem.id}`"
              >
                <v-divider />
                <main-menu-list-item
                  :value="subItem"
                  :divider="false"
                  @update:entries="updateEntries"
                />
              </div>
            </draggable>
          </main-menu-list-item>
        </draggable>
      </template>
    </smart-list>
  </div>
</template>
<style lang="scss" scoped>
.submenu-items {
  transition: all 0.26s ease;
  transform-origin: top;
  max-height: 99999px;
  min-height: 20px;
}
.sortable-chosen > .submenu-items {
  transform: scaleY(0);
  max-height: 0;
  min-height: 0;
}
.sortable-drag {
  height: auto !important;
}
.menu-items {
  padding-bottom: 60px;
}
</style>
