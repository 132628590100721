<script>
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import PageListHeader from 'components/page-list-header.vue'
import DataListFilter from 'components/data-list/filter.vue'
import SmartList from 'components/smart-list.vue'
import ObjectListItem from 'list-items/object-list-item.vue'
import CreateAutomationDialog from 'dialogs/create-automation-dialog.vue'
import { routeFor } from 'helpers/route'
import { formatDateTime } from 'helpers/date'

export default {
  name: 'AutomationListContent',
  components: {
    PageListHeader,
    DataListFilter,
    SmartList,
    ObjectListItem,
    CreateAutomationDialog
  },
  mixins: [PageContentable, ListPageContentable],
  computed: {
    result () {
      return this.value ? this.value.result : undefined
    }
  },
  methods: {
    ...{ routeFor, formatDateTime },
    onCreated (staticPage) {
      this.$router.push(routeFor('automation', staticPage.id))
    }
  }
}
</script>
<template>
  <div>
    <create-automation-dialog @success="onCreated">
      <template #activator="{ on }">
        <page-list-header
          title="Automatisierungen"
          :primary-action-title="$t('automation.actions.create')"
          v-on="on"
        />
      </template>
    </create-automation-dialog>

    <data-list-filter
      v-model="filters"
      @update:filter="$emit('update:prop', { prop: 'filters', value: $event.value, info: $event.field })"
    />

    <smart-list
      v-slot="{ item }"
      :value="result"
      :loading="pageRequestLoading"
    >
      <object-list-item
        :value="{
          title: item.title,
          subtitleElements: [{text: $t(`automation.states.${item.active ? 'activated' : 'deactivated'}`), class: item.active ? 'success--text' : 'error--text'}, {text: item.workflowDefinition.name, class: 'text-truncate mw-18-rem'}, item.schedule.description, $t('automation.nextExecutionAt', { date: formatDateTime(new Date(item.schedule.nextExecution)) })]
        }"
        :to="routeFor('automation', item.id)"
        indent
        :dense="false"
        divider
      />
    </smart-list>

    <v-pagination
      v-if="!pageRequestLoading && result && pages.total() > 1"
      v-model="pages.current"
      :length="pages.total()"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'pages', value: pages })"
    />
  </div>
</template>
<style>
.mw-18-rem {
  max-width: 18rem;
}
</style>
