<script>
import PageDetailHeaderBase from './page-detail-header-base.vue'
import Task from 'mixins/models/task'
import Workflow from 'mixins/models/workflow'
import ProcessDefinition from 'mixins/models/process-definition'
import ProcessTitle from 'components/process-title.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'PageDetailHeaderProcess',
  components: {
    PageDetailHeaderBase,
    ProcessTitle
  },
  mixins: [Task, Workflow, ProcessDefinition],
  props: {
    ...PageDetailHeaderBase.props,
    workflow: {
      type: Object,
      required: true
    }
  },
  computed: {
    showDefinitionTitle () {
      return this.workflow.definition && !this.processDefinitionIsSystemProcessDefinitionSingleTaskFor(this.workflow.definition)
    }
  },
  methods: {
    ...{ routeFor }
  }
}
</script>
<template>
  <page-detail-header-base
    :has-right-sidebar="hasRightSidebar"
    :has-left-sidebar="hasLeftSidebar"
    @click="$emit('open-sidebar', 'left')"
    @open-sidebar="$emit('open-sidebar', $event)"
  >
    <template #default="{actionRequest, requestable}">
      <div class="d-flex align-start">
        <div
          class="flex-grow-1 flex-shrink-1"
          style="min-width: 0;"
        >
          <router-link
            v-if="showDefinitionTitle"
            :to="routeFor('tasks_by_definition', workflow.definition.id)"
            class="text-decoration-none text-body-2 text--secondary text-truncate d-block mb-md-1"
          >
            {{ workflow.definition.name }}
          </router-link>

          <div
            v-if="!workflowIsSingleTaskFor(workflow)"
            class="text-body-1 text--primary text-truncate"
            :class="{'mt-6': !showDefinitionTitle}"
          >
            <process-title :workflow="workflow" />
          </div>
        </div>
        <div
          v-if="$scopedSlots.actions"
          class="d-flex ml-2"
        >
          <slot
            name="actions"
            :action-request="actionRequest"
            :loading="requestable.requestableLoading"
          />
        </div>
      </div>
      <slot
        :action-request="actionRequest"
        :requestable="requestable"
      />
    </template>
  </page-detail-header-base>
</template>
