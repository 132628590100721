import Page from '../page'
import Content from './content.vue'

export default {
  name: 'AboutPage',
  mixins: [Page],
  computed: {
    pageTitleParts () {
      return ['Über samarbeid']
    },
    pageContentComponents () {
      return [Content]
    }
  }
}
