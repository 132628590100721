<script>
export default {
  name: 'CalendarSyncIcon'
}
</script>
<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-icon
        size="20"
        class="ml-2"
        v-on="on"
      >
        mdi-calendar-sync-outline
      </v-icon>
    </template>
    <span>{{ $t('components.calendarSyncIcon.tooltip') }}</span>
  </v-tooltip>
</template>
