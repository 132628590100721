import Page from '../page'
import ListContent from './list-content.vue'

export default {
  name: 'SearchListPage',
  mixins: [Page],
  props: {
    query: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        page: 1
      }
    }
  },
  computed: {
    pageTitleParts () {
      return ['Suche']
    },
    pageContentComponents () {
      return ListContent
    },
    pageComponentProps () {
      return { filter: this.filter }
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.search.fulltext()
    },
    initPageRequestParams () {
      this.filter.page = 1
      return {
        page: this.filter.page,
        query: this.query
      }
    },
    onPropUpdated (prop, value, info) {
      if (prop === 'filter') {
        this.filter[info] = value

        this.pageRequestParameter.params = {
          page: this.filter.page,
          query: this.query
        }
        this.fetchPageData()
      }
    }
  }
}
