<script>
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import Cacheable from 'mixins/cacheable'
import Menucardable from 'mixins/menucardable'

export default {
  name: 'FileEditCardMenu',
  mixins: [Requestable, Cacheable, Menucardable],
  props: {
    value: {
      type: Object,
      default: null
    },
    aclObjectGlobalId: {
      type: String,
      required: true
    }
  },
  computed: {
    cachedValueChanged () {
      return this.value.title !== this.cacheValue.title
    },
    requestParameter () {
      return {
        method: Request.PATCH,
        url: this.$apiEndpoints.uploadedFiles.update(this.value.id),
        mapping: file => { return { title: file.title, acl_object_global_id: this.aclObjectGlobalId } }
      }
    }
  },
  methods: {
    onRequestSuccess (data) {
      this.closeMenuCard()

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    onMenuCardOpened () {
      this.createCacheValue()

      this.$emit('opened')
    },
    onMenuCardClosed () {
      this.resetRequestable()

      this.$emit('closed')
    },
    cancel () {
      this.closeMenuCard()
    }
  }
}
</script>
<template>
  <v-menu
    :attach="attachElement"
    :close-on-click="closeOnClick"
    :close-on-content-click="closeOnContentClick"
    :nudge-bottom="nudgeBottom"
    :value="internalVisible"
    min-width="100%"
    transition="slide-y-transition"
    z-index="1"
    @input="onMenuStateChanged"
  >
    <v-card
      v-if="cacheValue"
      :loading="requestableLoading"
    >
      <div class="pt-4 px-4">
        <v-text-field
          v-model="cacheValue.title"
          :disabled="requestableLoading"
          :error-messages="validationErrorMessageFor('title')"
          error-count="5"
          label="Titel"
        />
      </div>
      <v-card-actions class="px-4">
        <div
          v-if="baseErrorMessage"
          class="error--text"
        >
          {{ baseErrorMessage }}
        </div>
        <v-spacer />
        <v-btn
          :disabled="requestableLoading"
          color="secondary"
          text
          @click="cancel"
        >
          Abbrechen
        </v-btn>
        <v-btn
          :disabled="!cachedValueChanged || requestableLoading"
          color="primary"
          depressed
          @click="cachedValueChanged && request(requestParameter, null, cacheValue)"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
