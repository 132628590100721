import Vue from 'vue'
import { formatDateTime, distanceDate } from 'helpers/date'
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import debounce from 'lodash/debounce'
import castArray from 'lodash/castArray'
import filter from 'lodash/filter'
import isObject from 'lodash/isObject'
import without from 'lodash/without'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    loading: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    shortErrorMessage: {
      type: Boolean,
      default: false
    },
    successMessage: {
      type: [String, Array],
      default: () => 'Erfolgreich gespeichert'
    },
    hint: {
      type: String,
      default: undefined
    },
    persistentHint: Boolean,
    confirmedAt: {
      type: String,
      required: false
    },
    lastUpdated: {
      type: Object,
      default: () => { return { at: null, by: null } }
    },
    lockedBy: {
      type: Object,
      required: false
    },
    options: {
      type: Object,
      default: () => {}
    },
    hideAppend: {
      type: Boolean,
      default: false
    },
    required: Boolean,
    rules: {
      type: Array,
      default: () => []
    },
    ...requestablePropFactory('lockRequestParameter').props,
    ...requestablePropFactory('unlockRequestParameter').props
  },
  data () {
    return {
      controlSuccessMessage: undefined,
      successMessageTimeoutId: undefined,
      controlErrorMessage: undefined,
      errorMessageTimeoutId: undefined,
      controlIsFocused: false,
      lockRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.$debugLog('controlable: lockRequestable success')
            this.$emit('lock', data)
          },
          onRequestError: () => {
            this.$debugLog('controlable: lockRequestable error')
          },
          onRequestErrorDialogCancel: () => {
            location.reload()
          }
        }
      }),
      unlockRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.$debugLog('controlable: unlockRequestable success')
            this.$emit('unlock', data)
          }
        }
      }),
      rm: new RequestManager((requestType, requestValue) => {
        switch (requestType) {
          case RequestManager.REQUEST_TYPE_LOCK:
            this.$emit('before-lock')
            return this.lockRequestable.request(this.lockRequestParameter)
          case RequestManager.REQUEST_TYPE_UNLOCK:
            return this.unlockRequestable.request(this.unlockRequestParameter)
          case RequestManager.REQUEST_TYPE_UPDATE:
          case RequestManager.REQUEST_TYPE_UPDATE_WITHOUT_LOCK:
            return this.request(this.controlUpdateRequestParameter, null, requestValue)
          default:
            return new Promise((resolve, reject) => { reject(new Error(`Unsupported requestType: ${requestType}`)) })
        }
      })
    }
  },
  beforeDestroy () {
    this.rm.push(RequestManager.REQUEST_TYPE_UNLOCK)
  },
  computed: {
    controlUpdateRequestParameter () {
      return this.requestParameter
    },
    hasControlUpdateRequestParameter () {
      return !isNil(this.controlUpdateRequestParameter)
    },
    controlLoading () {
      return this.hasControlUpdateRequestParameter ? this.requestableLoading : this.loading
    },
    controlReadonly () {
      return this.readonly || this.controlDisabled
    },
    controlDisabled () {
      return this.disabled || this.controlLoading || !!this.lockedBy
    },
    controlClass () {
      return ['control', ...this.controlReadonly ? ['control--is-readonly'] : []]
    },
    controlRequestableErrorMessages () {
      return this.shortErrorMessage ? this.errorMessageShort : this.errorMessage
    },
    controlFilteredErrorMessages () {
      return without(filter(castArray(this.errorMessages), value => !isObject(value)), null)
    },
    controlErrorMessages () {
      let errors = null

      if (this.hasControlUpdateRequestParameter) {
        errors = this.controlRequestableErrorMessages
      } else {
        errors = this.controlFilteredErrorMessages
      }

      errors = castArray(errors)
      errors.push(this.controlErrorMessage)
      errors = filter(errors, e => !isNil(e))
      return errors
    },
    controlHasError () {
      return this.hasControlUpdateRequestParameter ? this.hasError : (!isNil(this.errorMessages) && (this.errorMessages.length > 0))
    },
    controlHint () {
      const hintParts = []

      if (this.lockedBy) {
        hintParts.push(this.$t('control.data.locked', { username: this.lockedBy.fullname }))
      } else {
        if (this.required && !this.controlReadonly) {
          hintParts.push(this.$t('general.field.required'))
        }

        if (this.lastUpdated?.at && this.lastUpdated?.by) {
          hintParts.push(`${this.lastUpdated.by.fullname} ${distanceDate(new Date(this.lastUpdated.at))}`)
        }

        if (this.confirmedAt) {
          hintParts.push(this.$t('control.data.confirmed', { timestamp: formatDateTime(new Date(this.confirmedAt)) }))
        }

        if (this.hint) {
          if (this.persistentHint || this.controlIsFocused) {
            hintParts.push(this.hint)
          }
        }
      }

      return hintParts.length > 0 ? join(hintParts, ' • ') : undefined
    },
    controlPersistentHint () {
      return !!this.lastUpdated?.at || !!this.confirmedAt || this.persistentHint || (this.required && !this.controlReadonly) || !!this.lockedBy
    },
    controlHasLockingSupport () {
      return true
    }
  },
  methods: {
    controlOnFocus () {
      this.controlIsFocused = true
    },
    controlOnBlur () {
      this.controlIsFocused = false
    },
    controlOnChange (requestValue = this.cacheValue, emitValue = this.cacheValue) {
      if (this.cachedValueChanged) {
        if (this.hasControlUpdateRequestParameter) {
          this.rm.push(
            this.hasRequestParameter('lockRequestParameter') && this.controlHasLockingSupport
              ? RequestManager.REQUEST_TYPE_UPDATE
              : RequestManager.REQUEST_TYPE_UPDATE_WITHOUT_LOCK,
            requestValue
          )
        } else {
          this.$emit('change', emitValue)
        }
      }

      if (!this.hasControlUpdateRequestParameter) this.$emit('update-data:changed', requestValue)
    },
    controlOnInput () {
      if (this.hasControlUpdateRequestParameter) {
        if (this.controlHasError) this.resetRequestable()
      } else {
        this.$emit('input', this.cacheValue)
      }
    },
    controlReset () {
      this.createCacheValue()
      this.controlOnInput()
    },
    controlShowSuccessMessage () {
      this.controlSuccessMessage = this.successMessage
      clearTimeout(this.successMessageTimeoutId)
      this.successMessageTimeoutId = setTimeout(() => { this.controlSuccessMessage = undefined }, 2000)
    },
    controlShowErrorMessage (errorMessage) {
      this.controlErrorMessage = errorMessage
      clearTimeout(this.errorMessageTimeoutId)
      this.errorMessageTimeoutId = setTimeout(() => { this.controlErrorMessage = undefined }, 2000)
    },
    controlOnLock () {
      if (this.hasRequestParameter('lockRequestParameter')) {
        this.rm.push(RequestManager.REQUEST_TYPE_LOCK)
      }
    },
    controlOnUnlock (force = false) {
      if (force || !this.cachedValueChanged) {
        if (this.hasRequestParameter('unlockRequestParameter')) {
          this.rm.push(RequestManager.REQUEST_TYPE_UNLOCK)
        }
      }
    }
  }
}

class RequestManager {
  static get REQUEST_TYPE_LOCK () { return 'LOCK' }
  static get REQUEST_TYPE_UNLOCK () { return 'UNLOCK' }
  static get REQUEST_TYPE_UPDATE () { return 'UPDATE' }
  static get REQUEST_TYPE_UPDATE_WITHOUT_LOCK () { return 'UPDATE_WITHOUT_LOCK' }

  #locked = false
  #loading = false
  #active = false
  #lockRefreshTimeoutId = null

  constructor (requestCallback) {
    this.requests = []
    this.requestCallback = requestCallback
  }

  push (requestType, requestValue = null) {
    this.requests.push({ type: requestType, value: requestValue })

    if (!this.#active && !this.#loading) this.#executeNextRequest()
  }

  get #hasRequests () {
    return Array.isArray(this.requests) && this.requests.length
  }

  get #nextRequestDefinition () {
    if (this.#hasRequests) {
      const requestDefinition = this.requests.pop()

      if (this.#locked && requestDefinition.type === this.constructor.REQUEST_TYPE_LOCK) {
        Vue.$debugLog('Cannot lock control because it is already locked')
      } else if (!this.#locked && requestDefinition.type === this.constructor.REQUEST_TYPE_UNLOCK) {
        Vue.$debugLog('Cannot unlock control because it is not locked')
      } else {
        // (!this.#locked && requestDefinition.type === this.constructor.REQUEST_TYPE_UPDATE)
        // we used to prevent updating of unlocked controls but it seems better to allow this as the user wouldn't
        // recognize the missing update request

        return requestDefinition
      }
    }

    return null
  }

  #scheduleLockRefresh () {
    this.#lockRefreshTimeoutId = setTimeout(() => {
      this.#executeRequest(this.constructor.REQUEST_TYPE_LOCK, null).finally(() => {
        if (!this.#active && this.#hasRequests) {
          debounce(() => { this.#executeNextRequest() }, 300)()
        } else {
          this.#scheduleLockRefresh()
        }
      })
    }, 28000)
  }

  #cancelLockRefresh () {
    if (this.#lockRefreshTimeoutId) clearTimeout(this.#lockRefreshTimeoutId)
    this.#lockRefreshTimeoutId = null
  }

  #executeNextRequest () {
    this.#active = true
    const requestDefinition = this.#nextRequestDefinition
    if (requestDefinition) {
      const { type: requestType, value: requestValue } = requestDefinition

      this.#cancelLockRefresh()
      this.#executeRequest(requestType, requestValue).finally(() => {
        if (this.#locked) this.#scheduleLockRefresh()
        if (this.#hasRequests) {
          debounce(() => { this.#executeNextRequest() }, 300)()
        } else {
          this.#active = false
        }
      })
    } else {
      if (this.#hasRequests) {
        this.#executeNextRequest()
      } else {
        this.#active = false
      }
    }
  }

  #executeRequest (requestType, requestValue) {
    return new Promise((resolve, reject) => {
      this.#loading = true
      this.requestCallback(requestType, requestValue)
        .then(() => {
          this.#loading = false
          this.#locked = requestType === this.constructor.REQUEST_TYPE_LOCK
          resolve()
        })
        .catch(() => {
          this.#loading = false
          resolve()
        })
    })
  }
}
