<script>
import ActivityHubItemReference from './activity-hub-item-reference.vue'

export default {
  name: 'ActivityHubItemChunks',
  components: { ActivityHubItemReference },
  props: {
    value: { type: Array, required: true }
  }
}
</script>
<template>
  <div>
    <template v-for="(chunk, index) in value">
      <activity-hub-item-reference
        v-if="chunk.type === 'reference'"
        :key="index"
        :value="chunk"
      />
      <span
        v-else-if="chunk.type === 'text-value' || chunk.type === 'link'"
        :key="index"
        class="text--primary"
      >
        {{ chunk.text }}
      </span>
      <template v-else-if="chunk.type === 'text'">
        <v-tooltip
          v-if="chunk.tooltip"
          :key="index"
          top
        >
          <template #activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >{{ chunk.text }}</span>
          </template>
          <span>{{ chunk.tooltip }}</span>
        </v-tooltip>
        <span
          v-else
          :key="index"
        >
          {{ chunk.text }}
        </span>
      </template>
    </template>
  </div>
</template>
