<script>
import debounce from 'lodash/debounce'

export default {
  name: 'FilterText',
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: undefined
    },
    disabled: Boolean
  },
  methods: {
    onValueChanged: debounce(function (newValue) {
      this.$emit('input', newValue === null ? '' : newValue)
    }, 500)
  }
}
</script>
<template>
  <v-text-field
    :value="value"
    :label="label"
    :disabled="disabled"
    hide-details
    outlined
    clearable
    @input="onValueChanged"
  />
</template>
