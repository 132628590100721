<script>
import { PageContentable } from '../page'
import ProcessDefinition from 'mixins/models/process-definition'

export default {
  name: 'ProcessDefinitionEditHeader',
  mixins: [PageContentable, ProcessDefinition],
  computed: {
    processDefinition () {
      return this.value
    },
    isHidden () {
      return (this.processDefinition?.groups?.length <= 0) && this.processDefinitionIsActive
    }
  }
}
</script>
<template>
  <v-alert
    v-if="value"
    dense
    text
    :type="isHidden ? 'error': 'info'"
  >
    <template v-if="isHidden">
      {{ $t('processDefinition.hiddenMessage') }}
    </template>
    <template v-else-if="isSystemProcessDefinitionSingleTask">
      Diese Vorlage ermöglicht das Starten einer einzelnen Aufgabe. Sie kann nicht bearbeitet werden.
    </template>
    <template v-else>
      Änderungen werden nur in neu gestarteten Prozessen wirksam.
    </template>
  </v-alert>
</template>
