<script>
import FilterTabs from './filter-tabs.vue'
import FilterText from './filter-text.vue'
import FilterSingleSelect from './filter-single-select.vue'
import FilterUserMultiSelect from './filter-user-multi-select.vue'
import FilterMultiSelect from './filter-multi-select.vue'
import isFunction from 'lodash/isFunction'

export default {
  name: 'DataListFilter',
  components: {
    FilterTabs,
    FilterText,
    FilterSingleSelect,
    FilterMultiSelect,
    FilterUserMultiSelect
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    valueFromFunction (value) {
      return isFunction(value) ? value() : value
    },
    updateFilter (value, field) {
      this.$emit('update:filter', { value, field })
    }
  }
}
</script>
<template>
  <v-card
    class="mt-4"
    elevation="0"
    outlined
  >
    <filter-tabs
      v-if="value.tabs"
      :key="value.tabs.name"
      :value="value.values[value.tabs.name]"
      :items="value.tabs.items"
      :counts="valueFromFunction(value.tabs.counts)"
      @input="updateFilter($event, value.tabs.name)"
    />

    <v-container
      v-if="value.fields && value.fields.length"
      class="my-3 px-4"
    >
      <v-row
        v-for="(rows, rowIndex) in value.fields"
        :key="rowIndex"
      >
        <v-col
          v-for="(field, colIndex) in rows"
          :key="colIndex"
          class="pa-2"
          :cols="field.cols"
        >
          <filter-text
            v-if="field.type === 'text'"
            :key="field.name"
            :value="value.values[field.name]"
            :label="field.label"
            :disabled="valueFromFunction(field.disabled)"
            @input="updateFilter($event, field.name)"
          />
          <filter-single-select
            v-else-if="field.type === 'single-select'"
            :key="field.name"
            :value="value.values[field.name]"
            :label="field.label"
            :items="valueFromFunction(field.items)"
            :clearable="field.clearable"
            :disabled="valueFromFunction(field.disabled)"
            @input="updateFilter($event, field.name)"
          />
          <filter-multi-select
            v-else-if="field.type === 'multi-select'"
            :key="field.name"
            :value="value.values[field.name]"
            :label="field.label"
            :items="valueFromFunction(field.items)"
            @input="updateFilter($event, field.name)"
          />
          <filter-user-multi-select
            v-else-if="field.type === 'user-multi-select'"
            :key="field.name"
            :value="value.values[field.name]"
            :label="field.label"
            :items="valueFromFunction(field.items)"
            @input="updateFilter($event, field.name)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
