export default {
  name: 'ConditionalRoot',
  functional: true,
  props: {
    show: Boolean
  },
  render: function (createElement, context) {
    const children = context.children.filter(vnode => vnode.tag) // remove unnecessary text nodes
    if (children.length !== 1) {
      console.warn('this component accepts only one root node in its slot')
    }
    if (context.props.show) {
      return children[0]
    } else {
      return context.children[0].componentOptions.children
    }
  }
}
