<script>
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import Request from 'api/request'
import Automation from 'mixins/models/automation'
import TextControl from 'controls/text-control.vue'
import ScheduleControl from 'controls/schedule-control.vue'
import ObjectsControl from 'controls/objects-control/index.vue'
import ProcessDefinitionListItem from 'list-items/process-definition-list-item.vue'
import UsersControl from 'controls/users-control.vue'
import ActivityHub from 'components/activity-hub/index.vue'
import MonitorSubscription from 'components/monitor-subscription.vue'
import { routeFor } from 'helpers/route'
import globalIdFor from 'helpers/global-id'

export default {
  name: 'AutomationEditContent',
  components: {
    MonitorSubscription,
    ActivityHub,
    UsersControl,
    ProcessDefinitionListItem,
    ObjectsControl,
    ScheduleControl,
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    CustomDialog,
    TextControl
  },
  mixins: [PageContentable, Automation],
  inject: {
    helper: {
      default: {
        Request
      }
    }
  },
  computed: {
    automation () {
      return this.value
    },
    gid () {
      return globalIdFor('Automation', this.pageProps.id)
    }
  },
  methods: {
    ...{ routeFor },
    onUpdated (value) {
      this.$emit('input', value)
    },
    onDeleted () {
      this.$router.replace({ name: 'automations' })
    }
  }
}
</script>
<template>
  <div v-if="value">
    <monitor-subscription
      :key="`monitor-channel-${gid}`"
      :gid="gid"
    />

    <breadcrumbs :items="automationBreadcrumbs" />

    <page-detail-header
      :state-text="automationStateText"
      :state-updated-at="automationStateUpdatedAtDate"
      :state-color="automationStateColor.background"
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #actions="{actionRequest, loading}">
        <kebab-menu
          :disabled="loading"
          color="primary"
        >
          <template #items="{ closeMenu }">
            <v-list-item @click="actionRequest($apiEndpoints.automations.update(value.id), onUpdated, 'patch', null, { active: !value.active })">
              <v-list-item-title>{{ value.active ? $t('automation.actions.deactivate') : $t('automation.actions.activate') }}</v-list-item-title>
            </v-list-item>

            <custom-dialog
              :title="$t('automation.deleteDialog.title')"
              :text="$t('automation.deleteDialog.text')"
              :ok-btn-text="$t('automation.deleteDialog.buttons.ok')"
              ok-btn-color="error"
              @click-ok="actionRequest($apiEndpoints.automations.destroy(value.id), onDeleted, helper.Request.DELETE)"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>{{ $t('automation.actions.delete') }}</v-list-item-title>
                </v-list-item>
              </template>
            </custom-dialog>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <div class="mb-4">
      <h1 class="text-h4 font-weight-light">
        {{ value.title }}
      </h1>
    </div>

    <text-control
      v-model="value.title"
      :title="$t('automation.createDialog.title')"
      :request-parameter="{method: 'patch', url: $apiEndpoints.automations.update(value.id), mapping: 'title'}"
      :hint="$t('general.field.required')"
      persistent-hint
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <objects-control
      v-model="value.workflowDefinition"
      :label="$t('automation.fields.workflowDefinition')"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.automations.update(value.id), mapping: 'workflow_definition_id'}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.workflowDefinitions.list(), params: {except: 'single_task'}}"
      single
      :allow-empty="false"
      @request-success-data="onUpdated"
    >
      <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
        <process-definition-list-item
          :key="item.id"
          :indent="indent"
          :item-link="itemLink"
          :selectable="selectable"
          :selected="selected"
          :skeleton="item.skeleton"
          :value="item"
          :to="routeFor('process_definition', item.id)"
          v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
        />
      </template>
    </objects-control>

    <users-control
      v-model="value.candidateAssignee"
      :label="$t('automation.fields.candidateAssignee')"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.automations.update(value.id), mapping: 'candidate_assignee_id'}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.users.list()}"
      single
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <schedule-control
      v-model="value.schedule"
      :active="value.active"
      :label="$t('automation.fields.schedule')"
      :request-parameter="{method: 'patch', url: $apiEndpoints.automations.update(value.id), mapping: 'schedule'}"
      @request-success-data="onUpdated"
    />

    <activity-hub
      v-if="gid"
      :key="`activity-hub-${gid}`"
      :gid="gid"
      hide-comments
      class="mt-12"
      @loaded="pageDataLoaded"
    />
  </div>
</template>
^
