export default {
  actions: {
    delete: 'Datentransformation löschen'
  },
  dialogs: {
    delete: {
      title: 'Datentransformation löschen?',
      text: 'Löschen Sie die Datentransformation, wenn Sie diese nicht mehr benöigen. Die originalen Felddaten gehen dabei nicht verloren.'
    }
  }
}
