<script>
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import pickBy from 'lodash/pickBy'
import defaults from 'lodash/defaults'
import isNil from 'lodash/isNil'
import reduce from 'lodash/reduce'

export default {
  name: 'EverythingInViewContent',
  mixins: [Requestable],
  data () {
    return {
      values: {},
      itemsDefaults: {
        unassigned_tasks_count_of_my_active_workflows: {
          to: { name: 'tasks', query: { assignee_ids: [null], workflow_assignee_ids: [this.$config.current_user.id] } }
        },
        unassigned_active_processes_count: {
          to: { name: 'tasks', query: { workflow_assignee_ids: [null], workflow_definition_ids: ['none', 'any'] } }
        },
        // unassigned_active_workflows_count: {
        //   to: { name: 'workflows', query: { assignee_ids: [null] } }
        // },
        hidden_process_definition_count: {
          to: { name: 'process-definitions' }
        },
        hidden_dossier_definition_count: {
          to: { name: 'dossier-definitions' }
        },
        unconfigured_main_menu: {
          to: { name: 'main-menu-entries' },
          avatarColor: 'error',
          avatarText: null,
          icon: 'mdi-exclamation'
        }
      }
    }
  },
  computed: {
    requestParameter () {
      return { method: Request.GET, url: this.$apiEndpoints.dashboard.everythingInView() }
    },
    items () {
      return reduce(pickBy(this.itemsDefaults, (_, key) => this.values[key] !== undefined), (result, value, key) => {
        return { ...result, [key]: this.item(this.values[key], value) }
      }, {})
    }
  },
  beforeMount () {
    this.request(this.requestParameter)
  },
  methods: {
    isNil,
    onRequestSuccess (data) {
      this.values = data
    },
    item (value, defaultValues) {
      return defaults({}, defaultValues, {
        avatarColor: value === 0 ? 'success' : 'primary',
        avatarText: value > 0 ? value : undefined,
        icon: 'mdi-check',
        value
      })
    }
  }
}
</script>
<template>
  <div>
    <h1 class="text-h4 font-weight-light mt-4">
      {{ $t('dashboard.widget.everything_in_view.title') }}
    </h1>
    <v-list>
      <template v-if="!requestableLoading">
        <v-list-item
          v-for="(value, key) in items"
          :key="key"
          :to="value.to"
        >
          <v-list-item-avatar
            :color="value.avatarColor"
            class="white--text"
          >
            <template v-if="!isNil(value.avatarText)">
              {{ value.avatarText }}
            </template>
            <v-icon
              v-else
              class="white--text"
            >
              {{ value.icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            {{ $tc(`dashboard.widget.everything_in_view.${key}`, value.value) }}
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-skeleton-loader
        v-if="requestableLoadingDelayed"
        type="list-item-avatar@3"
      />
    </v-list>
  </div>
</template>
