<script>
import Vue from 'vue'
import BaseControl from 'controls/base-control/index'
import Controlable from 'mixins/controlable'
import Cacheable from 'mixins/cacheable'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import TaskFieldDefinitionListItem from 'list-items/task-field-definition-list-item.vue'
import KebabMenu from 'components/kebab-menu.vue'
import AddEditFieldDialog from 'dialogs/add-edit-field-dialog.vue'
import DeleteDataFieldDialog from 'dialogs/delete-data-field-dialog.vue'
import eventBus from 'helpers/event-bus'
import { CONTENT_TYPE_NOTE, isContentTypeValueEmpty } from 'helpers/content-types'
import draggable from 'vuedraggable'
import has from 'lodash/has'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import values from 'lodash/values'
import filter from 'lodash/filter'
import defaults from 'lodash/defaults'

export default {
  name: 'TaskDefinitionFieldsControl',
  components: {
    BaseControl,
    TaskFieldDefinitionListItem,
    KebabMenu,
    AddEditFieldDialog,
    DeleteDataFieldDialog,
    draggable
  },
  mixins: [Controlable, Cacheable],
  props: {
    value: {
      type: Array,
      required: true
    },
    contentItemDefinitions: {
      type: Object,
      required: true
    },
    contentTypes: {
      type: Array,
      default: () => undefined
    },
    taskDefinitionId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      moveRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: this.onUpdated,
          onRequestError (error) {
            eventBus.$emit('quick-message', error.forAll(), 'error')
          }
        }
      })
    }
  },
  computed: {
    createRequestParameter () {
      return {
        method: Request.POST,
        url: this.$apiEndpoints.dataItemDefinitions.create(),
        mapping: (data) => {
          return {
            task_definition_id: this.taskDefinitionId,
            content_item_definition: data.content_item,
            task_item_definition: data.task_item
          }
        }
      }
    },
    contentItemDefinitionsInternal () {
      return filter(values(this.contentItemDefinitions), (value) => value.type.name !== CONTENT_TYPE_NOTE)
    }
  },
  methods: {
    createCacheValue () {
      this.cacheValue = cloneDeep(map(this.value, (value) => {
        const contentItemDefinition = this.contentItemDefinitions[value.contentItemDefinitionId]
        return {
          id: value.id,
          required: value.required,
          infobox: value.infobox,
          name: contentItemDefinition.label,
          type: contentItemDefinition.type.name,
          typeLabel: contentItemDefinition.type.label,
          options: defaults({ add_to_calendar: value.add_to_calendar }, contentItemDefinition.options),
          value: contentItemDefinition.default_value,
          hasDefaultValue: (contentItemDefinition.type.name !== CONTENT_TYPE_NOTE) && !isContentTypeValueEmpty(contentItemDefinition.type.name, contentItemDefinition.default_value),
          counts: {
            taskItemDefinitions: contentItemDefinition.taskItemDefinitionCount,
            blockDefinitions: contentItemDefinition.blockDefinitionCount
          }
        }
      }))
    },
    updateRequestParameter (id) {
      return {
        method: Request.PATCH,
        url: this.$apiEndpoints.dataItemDefinitions.update(id),
        mapping: (data) => {
          return {
            content_item_definition: data.content_item,
            task_item_definition: data.task_item
          }
        }
      }
    },
    hasRequestParameter () {
      return false
    },
    onUpdated (value) {
      this.$emit('change', value)
    },
    onPositionChanged (event) {
      if (has(event, 'moved')) {
        this.moveRequestable.request(
          { method: Request.PATCH, url: this.$apiEndpoints.dataItemDefinitions.move(event.moved.element.id) },
          { index: event.moved.newIndex })
      }
    }
  }
}
</script>

<template>
  <base-control
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :readonly="controlReadonly"
    :class="controlClass"
    :error-messages="controlErrorMessages"
    :error-count="Number.MAX_VALUE"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
  >
    <v-list
      class="flex-grow-1 overflow-hidden"
      style="min-width: 0"
    >
      <draggable
        :list="cacheValue"
        handle=".handle"
        :force-fallback="true"
        :animation="200"
        :disabled="controlDisabled"
        @change="onPositionChanged"
      >
        <template v-for="(field, index) in cacheValue">
          <task-field-definition-list-item
            :key="`${field.id}_item`"
            :value="field"
            :divider="index < cacheValue.length - 1"
          >
            <template #icon>
              <v-icon
                class="handle"
                :class="{ 'handle-disabled': controlDisabled}"
              >
                mdi-drag-horizontal
              </v-icon>
            </template>
            <template #actions>
              <kebab-menu :disabled="controlDisabled">
                <template #items="{ closeMenu }">
                  <add-edit-field-dialog
                    :value="field"
                    :content-items="contentItemDefinitionsInternal"
                    :content-types="contentTypes"
                    :request-parameter="updateRequestParameter(field.id)"
                    is-definition
                    @request-success-data="onUpdated"
                    @open="closeMenu"
                  >
                    <template #activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-title>{{ $t('taskFieldDefinition.actions.edit') }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </add-edit-field-dialog>

                  <delete-data-field-dialog
                    :request-parameter="{ method: 'delete', url: $apiEndpoints.dataItemDefinitions.destroy(field.id) }"
                    :task-usage-count="field.counts.taskItemDefinitions"
                    :block-usage-count="field.counts.blockDefinitions"
                    @request-success-data="onUpdated"
                    @open="closeMenu"
                  >
                    <template #activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-title>{{ $t('taskFieldDefinition.actions.delete') }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </delete-data-field-dialog>
                </template>
              </kebab-menu>
            </template>
          </task-field-definition-list-item>
        </template>
      </draggable>
    </v-list>

    <template #actions>
      <kebab-menu
        color="primary"
      >
        <template #activator="{ on }">
          <v-btn
            text
            color="primary"
            :disabled="controlDisabled"
            v-on="on"
          >
            {{ $t('taskFieldDefinition.actions.add') }}
          </v-btn>
        </template>
        <template #items="{ closeMenu }">
          <add-edit-field-dialog
            :request-parameter="createRequestParameter"
            :content-items="contentItemDefinitionsInternal"
            :content-types="contentTypes"
            is-definition
            preselected-field-type="newField"
            @request-success-data="onUpdated"
            @open="closeMenu"
          >
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-title>{{ $t('taskFieldDefinition.subActions.add.newDataField') }}</v-list-item-title>
              </v-list-item>
            </template>
          </add-edit-field-dialog>

          <add-edit-field-dialog
            :request-parameter="createRequestParameter"
            :content-items="contentItemDefinitionsInternal"
            :content-types="contentTypes"
            is-definition
            preselected-field-type="existingField"
            @request-success-data="onUpdated"
            @open="closeMenu"
          >
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-title>{{ $t('taskFieldDefinition.subActions.add.existingDataField') }}</v-list-item-title>
              </v-list-item>
            </template>
          </add-edit-field-dialog>

          <add-edit-field-dialog
            :request-parameter="createRequestParameter"
            :content-items="contentItemDefinitionsInternal"
            :content-types="contentTypes"
            is-definition
            preselected-field-type="noteField"
            @request-success-data="onUpdated"
            @open="closeMenu"
          >
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-title>{{ $t('taskFieldDefinition.subActions.add.noteField') }}</v-list-item-title>
              </v-list-item>
            </template>
          </add-edit-field-dialog>
        </template>
      </kebab-menu>
    </template>
  </base-control>
</template>
