<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import UsersControl from 'controls/users-control.vue'
import map from 'lodash/map'

export default {
  name: 'AddTaskDialog',
  components: {
    CustomDialog,
    UsersControl
  },
  props: {
    workflow: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialogOpen: false,
      name: '',
      assignee: null,
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.dialogOpen = false

            if (data) {
              this.$emit('request-success-data', data)
            }
          }
        }
      })
    }
  },
  computed: {
    workflowGroupIds () { return map(this.workflow.groups, 'id') },
    hasValidChanges () {
      return this.name.trim().length > 0 && this.assignee !== null
    }
  },
  methods: {
    onDialogOpened () {
      this.name = ''
      this.assignee = this.$config.current_user
      this.createRequestable.resetRequestable()

      this.$emit('open')
    },
    createTask () {
      this.createRequestable.request(
        { method: Request.POST, url: this.$apiEndpoints.workflows.addTask(this.workflow.id) },
        null,
        { task: { name: this.name, assignee_id: this.assignee.id } }
      )
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    title="Aufgabe hinzufügen"
    fullheight
    :close-on-button-click="false"
    :ok-btn-disabled="!hasValidChanges"
    :loading="createRequestable.requestableLoading"
    :error-message="createRequestable.baseErrorMessage"
    @click-ok="createTask"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <v-text-field
      v-model="name"
      :label="$t('task.addDialog.fields.title')"
      :hint="$t('general.field.required')"
      persistent-hint
      :disabled="createRequestable.requestableLoading"
      :error-messages="createRequestable.validationErrorMessageFor('name')"
      :error-count="Number.MAX_VALUE"
      class="mb-5"
    />
    <users-control
      v-model="assignee"
      :label="$t('task.addDialog.fields.assignee')"
      single
      :allow-empty="false"
      :hint="$t('general.field.required')"
      persistent-hint
      :disabled="createRequestable.requestableLoading"
      ::error-messages="createRequestable.validationErrorMessageFor('assignee')"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.users.list(), params: {group_ids: workflowGroupIds}}"
      :to="() => undefined"
    />
  </custom-dialog>
</template>
