<script>
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import TextControl from 'controls/text-control.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'

export default {
  name: 'SettingsCalendarContent',
  components: {
    TextControl,
    CustomDialog
  },
  mixins: [PageContentable, Requestable],
  methods: {
    activateOrReset () {
      this.request({ method: Request.POST, url: this.$apiEndpoints.calendar.create() })
    },
    deactivate () {
      this.request({ method: Request.DELETE, url: this.$apiEndpoints.calendar.destroy() })
    },
    onRequestSuccess (data) {
      this.value.calendar_url = data.calendar_url
    }
  }
}
</script>
<template>
  <div v-if="value">
    <h2 class="text-h5 font-weight-light mb-4">
      Kalenderfreigabe
    </h2>
    <p>
      Mit dieser Adresse können Sie von anderen Anwendungen (z.B. Outlook, Thunderbird) aus auf ihren persönlichen Samarbeid-Kalender zugreifen.
      Der Kalender beinhaltet alle fälligen Aufgaben an denen Sie teilnehmen.
    </p>
    <p>Warnung: Geben Sie die Privatadresse nicht an andere Personen weiter.</p>
    <text-control
      label="Privatadresse im iCal-Format"
      :value="value.calendar_url"
      :options="{type: 'like-password'}"
      :loading="requestableLoading"
      disabled
    />
    <p>
      Sie können diese Adresse zurücksetzen und damit die aktuelle ungültig machen.
    </p>

    <custom-dialog
      :title="$t('user.calendarSharing.dialogs.reset.title')"
      :text="$t('user.calendarSharing.dialogs.reset.text')"
      :ok-btn-text="$t('user.calendarSharing.dialogs.reset.buttons.ok')"
      @click-ok="activateOrReset"
    >
      <template #activator="{ on }">
        <v-btn
          :color="!value.calendar_url ? 'primary' : undefined"
          :disabled="requestableLoading || !$config.isCurrentUser(value)"
          depressed
          v-on="value.calendar_url ? on : undefined"
          @click="!value.calendar_url ? activateOrReset() : undefined"
        >
          {{ !value.calendar_url ? 'Aktivieren' : 'Zurücksetzen' }}
        </v-btn>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="value.calendar_url"
      :title="$t('user.calendarSharing.dialogs.deactivate.title')"
      :text="$t('user.calendarSharing.dialogs.deactivate.text')"
      :ok-btn-text="$t('user.calendarSharing.dialogs.deactivate.buttons.ok')"
      @click-ok="deactivate"
    >
      <template #activator="{ on }">
        <v-btn
          color="primary"
          :disabled="requestableLoading || !$config.isCurrentUser(value)"
          depressed
          v-on="on"
        >
          Deaktivieren
        </v-btn>
      </template>
    </custom-dialog>
  </div>
</template>
