<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import TextControl from 'controls/text-control.vue'
import TextareaControl from 'controls/textarea-control.vue'
import ObjectsControl from 'controls/objects-control/index.vue'
import GroupListItem from 'list-items/group-list-item.vue'
import map from 'lodash/map'

export default {
  name: 'CreateDossierDefinitionDialog',
  components: {
    CustomDialog,
    TextControl,
    TextareaControl,
    ObjectsControl,
    GroupListItem
  },
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            if (this.addAnother) {
              this.successMessage = this.$t('dossierDefinition.createDialog.successMessage', { title: data.name })
              this.dossierDefinition = {}
            } else {
              this.dialogOpen = false
              this.$emit('success', data)
            }
          }
        }
      }),
      dossierDefinition: null
    }
  },
  computed: {
    isValid () {
      if (this.dossierDefinition) {
        return this.dossierDefinition.name && this.dossierDefinition.name.trim().length > 0
      }

      return false
    }
  },
  methods: {
    onDialogOpened () {
      this.dossierDefinition = {}

      this.createRequestable.resetRequestable()

      this.$emit('open')
    },
    onDialogClosed () {
    },
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0

      /*
      TODO: We use setTimeout here to wait for the objects-control to be closed and update their value.
            This is kind of a hack and it would be better to have a better solution
      */
      setTimeout(() => {
        this.createRequestable.request(
          { method: Request.POST, url: this.$apiEndpoints.dossierDefinitions.create() },
          null,
          {
            name: this.dossierDefinition.name,
            description: this.dossierDefinition.description,
            group_ids: map(this.dossierDefinition.groups, 'id')
          }
        )
      }, 50)
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t('dossierDefinition.createDialog.title')"
    fullheight
    :close-on-button-click="false"
    :ok-btn-text="$t('general.buttons.save')"
    :ok-btn-disabled="!isValid"
    :additional-ok-btn-texts="[$t('general.buttons.saveAndCreateAnother')]"
    :loading="createRequestable.requestableLoading"
    :error-message="createRequestable.baseErrorMessage"
    :success-message="successMessage"
    content-class="create-dossier-definition-dialog"
    @click-ok="onOkButtonClicked"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="dossierDefinition">
      <text-control
        v-model="dossierDefinition.name"
        label="Name"
        :hint="$t('general.field.required')"
        persistent-hint
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('name')"
        :error-count="Number.MAX_VALUE"
        class="mb-5"
      />
      <textarea-control
        v-model="dossierDefinition.description"
        label="Beschreibung"
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('description')"
        :error-count="Number.MAX_VALUE"
        class="mb-5"
      />
      <objects-control
        v-if="$config.current_user.isAdmin"
        v-model="dossierDefinition.groups"
        label="Gruppen"
        :hint="$t('general.field.required')"
        persistent-hint
        :list-request-parameter="{method: 'get', url: $apiEndpoints.groups.list()}"
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('groups')"
        :error-count="Number.MAX_VALUE"
      >
        <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
          <group-list-item
            :key="item.id"
            :indent="indent"
            :item-link="itemLink"
            :selectable="selectable"
            :selected="selected"
            :skeleton="item.skeleton"
            :value="item"
            v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
          />
        </template>
      </objects-control>
    </template>
  </custom-dialog>
</template>
