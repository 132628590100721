<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import Cacheable from 'mixins/cacheable'
import InfiniteScrollList from './infinite-scroll-list.vue'
import Itemable from 'mixins/itemable'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import castArray from 'lodash/castArray'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import union from 'lodash/union'

export default {
  name: 'SelectList',
  components: {
    InfiniteScrollList
  },
  mixins: [Requestable, Cacheable, Itemable],
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    ...requestablePropFactory().props,
    value: {
      type: Array,
      default: () => []
    },
    additionalItems: {
      type: Array,
      default: () => []
    },
    addOnly: Boolean,
    single: Boolean,
    allowEmpty: {
      type: Boolean,
      default: true
    },
    maxListHeight: {
      type: Number,
      default: undefined
    },
    groupProps: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      pageLoaded: 0,
      queryText: null,
      items: undefined,
      topItems: [],
      loadedItems: [],
      addedItems: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.itemValidateValues(this.value, 'value')
      }
    },
    additionalItems: {
      immediate: true,
      handler () {
        this.itemValidateValues(this.additionalItems, 'additionalItems')
      }
    },
    items: {
      immediate: true,
      handler () {
        this.$emit('items:changed', map(filter(this.items, item => item.type !== 'divider'), item => item.value))
      }
    }
  },
  methods: {
    reset () {
      this.resetRequestable()

      this.loadedItems = []
      this.addedItems = []
      this.queryText = null
      this.pageLoaded = 0
      this.items = undefined
    },
    cancel () {
      this.cancelRequestable()
      this.$nextTick(() => {
        this.items = []
      })
    },
    addNewItem (item) {
      this.cacheValue.push(item)
      if (!this.addOnly) this.$emit('update', this.cacheValue)

      if (this.updateTopItems()) {
        this.items = []
        this.items.push(...map(this.topItems, item => { return { type: 'item', value: item } }))
        if (this.items.length > 0 && this.loadedItems.length > 0) this.items.push({ type: 'divider' })
        this.items.push(...map(this.loadedItems, item => { return { type: 'item', value: item } }))
      }
    },
    updateTopItems () {
      if (!this.queryText && !this.addOnly) {
        this.topItems = cloneDeep(this.cacheValue)
        return true
      } else {
        this.topItems = []
        return false
      }
    },
    load (doneCallback) {
      this.pageLoaded = this.pageLoaded + 1

      const defaultExcept = filter(castArray(this.requestParameter?.params?.except), value => !isNil(value))

      this.updateTopItems()

      const exceptValues = this.addOnly || !this.queryText
        ? union(defaultExcept, filter(this.itemGetValues(this.value), filterItem => {
          return find(this.itemGetValues(this.additionalItems), findItem => this.itemIsEqual(findItem, filterItem)) === undefined
        }))
        : defaultExcept

      this.request(this.requestParameter, {
        query: this.queryText, page: this.pageLoaded, per_page: 10, except: exceptValues
      }, null, true, true, (data) => {
        if (this.items === undefined) {
          this.items = []
          this.loadedItems = []

          const unselectedAdditionalItems = filter(this.additionalItems, item => !this.isSelected(item))

          this.items.push(...map(this.topItems, item => { return { type: 'item', value: item } }))
          if (this.items.length > 0 && (unselectedAdditionalItems.length + data.length) > 0) this.items.push({ type: 'divider' })

          this.items.push(...map(unselectedAdditionalItems, item => { return { type: 'item', value: item } }))
          this.loadedItems.push(...unselectedAdditionalItems)
        }

        this.items.push(...map(data, item => { return { type: 'item', value: item } }))
        this.loadedItems.push(...data)

        if (data?.length === 10) {
          doneCallback('ok')
        } else {
          doneCallback('empty')
        }
      }, () => {
        doneCallback('error')
      })
    },
    queryTextChanged: debounce(function (val) {
      this.queryText = val
      this.pageLoaded = 0
      this.items = undefined
    }, 500),
    isSelected (item) {
      return this.itemFindIndex(this.cacheValue, item) !== -1
    },
    toggleItem (item) {
      const index = this.itemFindIndex(this.cacheValue, item)
      const indexAddedItems = this.itemFindIndex(this.addedItems, item)

      if (index === -1) {
        if (this.single) this.cacheValue.shift()
        this.cacheValue = this.cacheValue.concat([item])
      } else {
        if (this.cacheValue.length > 1 || this.allowEmpty) {
          this.cacheValue = this.cacheValue.slice(0, index).concat(this.cacheValue.slice(index + 1))
        }
      }

      if (indexAddedItems === -1) {
        this.addedItems = this.addedItems.concat([item])
      } else {
        this.addedItems = this.addedItems.slice(0, indexAddedItems).concat(this.addedItems.slice(indexAddedItems + 1))
      }

      if (!this.addOnly) this.$emit('update', this.cacheValue)
      this.$emit('change', this.addedItems)
    }
  }
}
</script>
<template>
  <div class="d-flex flex-column">
    <slot
      name="filter"
      :query-text="queryText"
      :query-text-changed="queryTextChanged"
    >
      <v-text-field
        :value="queryText"
        label="Suche"
        clearable
        outlined
        class="mt-4 flex-grow-0 hide-details"
        @input="queryTextChanged"
      />
    </slot>

    <infinite-scroll-list
      ref="infiniteScrollList"
      v-slot="{ item, index }"
      :items="items"
      :load-on-nth-last-item="1"
      :max-list-height="maxListHeight"
      :group-props="groupProps"
      @onLoad="load"
    >
      <slot
        v-if="item.type === 'item'"
        :item="item.value"
        :item-key="itemKey(item.value)"
        :index="index"
        :selected="isSelected(item.value)"
        :toggle="() => toggleItem(item.value)"
      />
      <v-divider v-else-if="item.type === 'divider'" />
    </infinite-scroll-list>
  </div>
</template>
<style scoped>
.v-text-field.hide-details :deep(.v-text-field__details) {
  display: none;
}
</style>
