import Vue from 'vue'
import Page from '../page'
import TaskEditContent from './edit-content-task.vue'
import ProcessEditContent from './edit-content-process.vue'
import TaskEditSidebarLeft from './edit-sidebar-left.vue'
import TaskEditSidebarRight from './edit-sidebar-right-task.vue'
import ProcessEditSidebarRight from './edit-sidebar-right-process.vue'
import Workflow from 'mixins/models/workflow'
import Requestable from 'mixins/requestable'
import { routeFor } from 'helpers/route'
import Request from 'api/request'
import isNil from 'lodash/isNil'

export default {
  name: 'TaskOrProcessEditPage',
  mixins: [Page, Workflow],
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      designMode: false,
      contentItems: undefined,
      contentItemsRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.contentItems = data
          }
        }
      })
    }
  },
  computed: {
    pageTitleParts () {
      if (this.type === 'workflow') {
        return [...(this.value && this.value.workflow ? [this.value.workflow.referenceLabel] : []), 'Prozesse']
      } else {
        return [...(this.value && this.workflowGetTaskWithIdFor(this.value.workflow, this.id) ? [this.workflowGetTaskWithIdFor(this.value.workflow, this.id).referenceLabel] : []), 'Aufgaben']
      }
    },
    pageContentComponents () {
      return (this.type === 'workflow') ? ProcessEditContent : TaskEditContent
    },
    pageSidebarLeftComponents () {
      return TaskEditSidebarLeft
    },
    pageSidebarRightComponents () {
      return (this.type === 'workflow') ? ProcessEditSidebarRight : TaskEditSidebarRight
    },
    pageSidebarLeftFloating () {
      return true
    },
    pageComponentProps () {
      return { designMode: this.designMode, contentItems: this.contentItems }
    }
  },
  methods: {
    pageClass () {
      return `${this.type}-edit-page`
    },
    onPropsChanged () {
      if (this.value && this.value.workflow) {
        if (this.type === 'workflow' && this.value.workflow.id === this.id) {
          this.value.task = null
          return
        } else if (this.type === 'task') {
          this.value.task = this.workflowGetTaskWithIdFor(this.value.workflow, this.id)
          if (isNil(this.value.task)) this.value = null
          this.initPageRequestParameter()
          this.fetchPageData()
          return
        }
      }

      this.value = null
      this.initPageRequestParameter()
      this.fetchPageData()
    },
    onRequestSuccess (data) {
      if (data.type === 'task') {
        this.value.task = data
      } else {
        this.value = {
          workflow: data,
          task: (this.type === 'workflow') ? null : this.workflowGetTaskWithIdFor(data, this.id)
        }
      }

      if (!this.workflowHasAvailableActionFor(this.value.workflow, 'design')) {
        this.designMode = false
      }

      // if task doesn't exist, try nextTaskId or go to task list
      if (this.type === 'task' && isNil(this.value.task)) {
        if (this.value.workflow.nextTaskId) {
          this.$router.push(routeFor('task', this.value.workflow.nextTaskId))
          this.$emit('component:event', 'load:content-items')
        } else {
          this.$router.push({ name: 'tasks' })
        }
      }
    },
    initPageRequestUrl () {
      return (this.type === 'task' && this.value) ? this.$apiEndpoints.tasks.show(this.id) : this.$apiEndpoints.workflows.show(this.id)
    },
    initPageRequestParams () {
      return (this.type === 'task' && !this.value) ? { from_task_id: true } : null
    },
    onPropUpdated (prop, value, info) {
      if (prop === 'designMode') {
        this.designMode = value
      }
    },
    onValueChanged () {
      if (!this.workflowHasAvailableActionFor(this.value.workflow, 'design')) {
        this.designMode = false
      }
    },
    onPageComponentEvent (event) {
      if (event === 'load:content-items') {
        this.contentItemsRequestable.cancelRequestable()
        this.contentItemsRequestable.request(
          { method: Request.GET, url: this.$apiEndpoints.workflows.contentItems(this.value.workflow.id) },
          null, null, true, true)
      } else if (event === 'reload:process') {
        this.initPageRequestParameter()
        this.fetchPageData({
          url: this.$apiEndpoints.workflows.show(this.value.workflow.id),
          params: (this.value.task) ? { with_task: this.value.task.id } : null
        })
      }
    }
  }
}
