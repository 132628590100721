import SiteHeader from './site-header/index.vue'
import GlobalSnackbars from './global-snackbars.vue'
import SiteFooter from './site-footer.vue'
import router from 'router'

export default {
  name: 'AppWrapper',
  functional: true,
  render: (createElement, context) => {
    const footerContent = (router.currentRoute.meta.hasFooter && router.currentRoute.meta.hasFooter !== true)
      ? [createElement(router.currentRoute.meta.hasFooter)]
      : undefined

    return [
      ...(router.currentRoute.meta.hasHeader ? [createElement(SiteHeader)] : []),
      ...context.scopedSlots.default(),
      createElement(GlobalSnackbars),
      ...(router.currentRoute.meta.hasFooter ? [createElement(SiteFooter, {}, footerContent)] : [])
    ]
  }
}
