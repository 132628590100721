<script>
import ActivityHubItemChunks from '../activity-hub/activity-hub-item-chunks.vue'
import ActivityHubItemLink from '../activity-hub/activity-hub-item-link.vue'
import RichtextareaContent from 'components/richtextarea-content.vue'
import Avatar from 'components/avatar.vue'
import { routeFor } from 'helpers/route'
import TimeTracking from 'mixins/models/time-tracking'

export default {
  name: 'StandaloneEvent',
  components: {
    Avatar,
    ActivityHubItemChunks,
    RichtextareaContent,
    ActivityHubItemLink
  },
  mixins: [TimeTracking],
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    isCommentEvent () {
      return this.event.action === 'commented'
    }
  },
  methods: {
    ...{ routeFor }
  }
}
</script>
<template>
  <div class="d-flex align-center flex-grow-1">
    <activity-hub-item-link
      style="text-decoration: none;"
      class="d-flex align-center flex-grow-1"
      :value="event.target"
    >
      <div class="mr-4">
        <v-icon
          v-if="event.avatar.icon"
          color="grey lighten-1"
        >
          {{ event.avatar.icon }}
        </v-icon>
        <avatar
          v-else
          :text="event.avatar.label"
          :image="event.avatar.url"
        />
      </div>
      <v-card
        v-if="isCommentEvent"
        class="elevation-1 flex-grow-1"
      >
        <v-card-title class="d-block text-body-2 text--secondary">
          <activity-hub-item-chunks :value="event.chunks" />
        </v-card-title>

        <v-card-text class="text--primary">
          <richtextarea-content :template="timeTrackingCommentContentFor(event.payload.time_tracking, event.payload.message)" />
        </v-card-text>
      </v-card>

      <div
        v-else
        class="text-body-2 text--secondary flex-grow-1"
      >
        <activity-hub-item-chunks :value="event.chunks" />
      </div>
    </activity-hub-item-link>

    <div
      v-if="$scopedSlots.actions"
      class="ml-2"
    >
      <slot name="actions" />
    </div>
  </div>
</template>
