<script>
import logo from 'assets/images/ms-icon-310x310.png'

export default {
  name: 'ShareShowContentFooter',
  data () {
    return {
      logo
    }
  }
}
</script>
<template>
  <div class="pa-2">
    <div class="d-flex align-center mb-2">
      <v-img
        :src="logo"
        :max-height="28"
        :max-width="28"
        contain
      />
      <div class="text-h5 ml-2">
        {{ $t('general.appName') }}
      </div>
    </div>
    Diese Zuarbeit basiert technisch auf <a href="https://www.samarbeid.org/">samarbeid.org</a> – Zusammenarbeit einfach machen.<br>
    Für den Inhalt dieser Zuarbeit ist die Person zuständig, die dir den Link zu dieser Seite gesendet hat
  </div>
</template>
