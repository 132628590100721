<script>
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import PageListHeader from 'components/page-list-header.vue'
import DataListFilter from 'components/data-list/filter.vue'
import SmartList from 'components/smart-list.vue'
import ProcessDefinitionListItem from 'list-items/process-definition-list-item.vue'
import CreateProcessDefinitionDialog from 'dialogs/create-process-definition-dialog.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'ProcessDefinitionListContent',
  components: {
    PageListHeader,
    DataListFilter,
    SmartList,
    ProcessDefinitionListItem,
    CreateProcessDefinitionDialog
  },
  mixins: [PageContentable, ListPageContentable],
  computed: {
    result () {
      return this.value ? this.value.result : undefined
    },
    hiddenElementsCount () {
      return this.value?.hiddenElementsCount || 0
    }
  },
  methods: {
    ...{ routeFor },
    onCreated (definition) {
      this.$router.push(routeFor('process_definition', definition.id))
    }
  }
}
</script>
<template>
  <div>
    <v-alert
      v-if="$config.current_user.isAdmin && hiddenElementsCount > 0"
      dense
      text
      type="error"
    >
      {{ $tc('processDefinition.hiddenElements.message', hiddenElementsCount) }}
    </v-alert>

    <create-process-definition-dialog @success="onCreated">
      <template #activator="{ on }">
        <page-list-header
          title="Prozessvorlagen"
          :primary-action-title="$t('processDefinition.actions.create')"
          v-on="on"
        />
      </template>
    </create-process-definition-dialog>

    <data-list-filter
      v-model="filters"
      @update:filter="$emit('update:prop', { prop: 'filters', value: $event.value, info: $event.field })"
    />

    <smart-list
      v-slot="{ item }"
      :value="result"
      :loading="pageRequestLoading"
    >
      <process-definition-list-item
        :value="item"
        :to="routeFor('process_definition', item.id)"
        show-counts
      />
      <v-divider />
    </smart-list>

    <v-pagination
      v-if="!pageRequestLoading && result && pages.total() > 1"
      v-model="pages.current"
      :length="pages.total()"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'pages', value: pages })"
    />
  </div>
</template>
