<script>
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import Request from 'api/request'
import StaticPage from 'mixins/models/static-page'
import TextControl from 'controls/text-control.vue'
import RichtextareaControl from 'controls/richtextarea-control.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'StaticPageEditContent',
  components: {
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    CustomDialog,
    TextControl,
    RichtextareaControl
  },
  mixins: [PageContentable, StaticPage],
  inject: {
    helper: {
      default: {
        Request
      }
    }
  },
  computed: {
    staticPage () {
      return this.value
    }
  },
  methods: {
    ...{ routeFor },
    onUpdated (value) {
      this.$emit('input', value)
    },
    onDeleted () {
      this.$router.replace({ name: 'static-pages' })
    }
  }
}
</script>
<template>
  <div v-if="value">
    <breadcrumbs :items="staticPageBreadcrumbs" />

    <page-detail-header
      :state-text="staticPageStateText"
      :state-updated-at="staticPageStateUpdatedAtDate"
      :state-color="staticPageStateColor.background"
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #actions="{actionRequest, loading}">
        <kebab-menu
          :disabled="loading"
          color="primary"
        >
          <template #items="{ closeMenu }">
            <custom-dialog
              :title="$t('staticPage.deleteDialog.title')"
              :text="$t('staticPage.deleteDialog.text')"
              :ok-btn-text="$t('staticPage.deleteDialog.buttons.ok')"
              ok-btn-color="error"
              @click-ok="actionRequest($apiEndpoints.pages.destroy(value.id), onDeleted, helper.Request.DELETE)"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>{{ $t('staticPage.actions.delete') }}</v-list-item-title>
                </v-list-item>
              </template>
            </custom-dialog>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <div class="mb-4">
      <h1 class="text-h4 font-weight-light">
        {{ value.title }}
      </h1>
      <router-link
        :to="{ name: 'static-page-show', params: { slug: value.slug } }"
      >
        {{ $router.resolve({ name: 'static-page-show', params: { slug: value.slug } }).href }}
      </router-link>
    </div>

    <text-control
      v-model="value.title"
      label="Titel"
      :request-parameter="{method: 'patch', url: $apiEndpoints.pages.update(value.id), mapping: 'title'}"
      :hint="$t('general.field.required')"
      persistent-hint
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <text-control
      v-model="value.slug"
      label="Pfad"
      :request-parameter="{method: 'patch', url: $apiEndpoints.pages.update(value.id), mapping: 'slug'}"
      :hint="$t('general.field.required')"
      persistent-hint
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <richtextarea-control
      v-model="value.content"
      label="Inhalt"
      :request-parameter="{method: 'patch', url: $apiEndpoints.pages.update(value.id), mapping: 'content'}"
      @request-success-data="onUpdated"
    />
  </div>
</template>
