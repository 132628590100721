<script>
import { routeFor } from 'helpers/route'

export default {
  name: 'ActivityHubItemReference',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    route () {
      return routeFor(this.value.object_type, this.value.object_id)
    },
    linkClass () {
      if (this.value.position === 'subject') {
        return 'text-subtitle-2 text--primary'
      } else {
        return 'blue lighten-5 primary--text object-link--chip'
      }
    },
    spanClass () {
      if (this.value.position === 'subject') {
        return 'text-subtitle-2 text--primary'
      } else {
        return 'grey lighten-3 object-link--chip'
      }
    }
  }
}
</script>
<template>
  <router-link
    v-if="!value.noaccess && !value.deleted"
    :to="route"
    class="object-link"
    :class="linkClass"
  >{{ value.text }}</router-link>
  <span
    v-else
    :class="spanClass"
  >
    <s v-if="value.deleted">{{ value.text }}</s>
    <template v-else>{{ value.text }}</template>
  </span>
</template>
