export default {
  actions: {
    entry: {
      create: 'Zeitaufwand hinzufügen',
      edit: 'Zeitaufwand ändern'
    },
    budget: {
      create: 'Budget hinzufügen',
      edit: 'Budget ändern'
    }
  },
  message: 'Zeiterfassung: {timeSpent} für {spentAt}',
  time: {
    hours: ' | {count}h | {count}h',
    minutes: ' | {count}min | {count}min'
  },
  dialogs: {
    edit: {
      fields: {
        spentAt: 'Für wann',
        spentHours: 'Stunden',
        spentMinutes: 'Minuten'
      },
      errors: {
        rangeMinutes: 'Wert muss zwischen 0 und 59 sein'
      }
    },
    report: {
      title: 'Bericht Zeiterfassung (Gebucht: {timeSpent} / Budget: {budget})',
      columns: {
        date: 'Datum',
        user: 'Nutzer:in',
        amount: 'Zeitaufwand',
        comment: 'Vermerk'
      }
    }
  }
}
