import Page, { PageContentable } from '../page'

export default {
  name: 'StaticPagesShowPage',
  mixins: [Page],
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  computed: {
    pageTitleParts () {
      return [...(this.value ? [this.value.title] : [])]
    },
    pageContentComponents () {
      return {
        mixins: [PageContentable],
        template: `<div v-if="value">
                     <h1 class="text-h4 font-weight-light pt-5 mb-4">
                       {{ value.title }}
                     </h1>
                     <div v-html="value.content" />
                   </div>
                  `
      }
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.pages.show(this.slug)
    }
  }
}
