import VAlert from 'vuetify/lib/components/VAlert'
import { componentForContentType, componentOptionsForContentType } from 'helpers/content-types'
import defaults from 'lodash/defaults'

export default {
  name: 'DataControl',
  functional: true,
  props: {
    type: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => {}
    },
    infobox: Boolean,
    childOfDialog: Boolean
  },
  render: function (createElement, context) {
    function internalOptions (props) {
      return defaults({}, props.options, componentOptionsForContentType(props.type))
    }

    function genControl (createElement, component, context, classData, infobox) {
      const props = {
        options: internalOptions(context.props),
        childOfDialog: context.props.childOfDialog,
        shortErrorMessage: true
      }
      if (infobox) {
        props.disabled = true
        delete context.data.attrs.disabled
      }

      return createElement(component, {
        ...classData,
        ...context.data,
        props
      })
    }

    function genAlert (createElement, context, classData) {
      return createElement(VAlert, {
        ...classData,
        props: {
          dense: true,
          text: true,
          type: 'error'
        }
      }, `Der Datentyp "${context.props.type}" wird nicht unterstützt`)
    }

    function genInfobox (createElement, classData, children) {
      return createElement('div', {
        staticClass: 'support-infobox pa-3' + (classData.staticClass === undefined ? '' : ' ' + classData.staticClass),
        class: classData.class
      }, children)
    }

    const classData = {
      staticClass: context.data.staticClass,
      class: context.data.class
    }

    const component = componentForContentType(context.props.type)
    const element = (component !== null)
      ? genControl(createElement, component, context, !context.props.infobox ? classData : null, context.props.infobox)
      : genAlert(createElement, context, !context.props.infobox ? classData : null)

    return (context.props.infobox) ? genInfobox(createElement, classData, [element]) : element
  }
}
