<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'

export default {
  name: 'DeleteDataFieldDialog',
  components: { CustomDialog },
  mixins: [Requestable],
  props: {
    ...requestablePropFactory().props,
    taskUsageCount: {
      type: Number,
      required: true
    },
    blockUsageCount: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      dialogOpen: false
    }
  },
  computed: {
    blockUsage () {
      return this.blockUsageCount > 0
    },
    translationSuffix () {
      if (this.blockUsage) {
        return 'blockUsage'
      } else if (this.taskUsageCount > 1) {
        return 'multiUsage'
      } else {
        return 'singleUsage'
      }
    },
    text () {
      return this.$tc(`dataField.deleteDialog.text.${this.translationSuffix}`, this.blockUsageCount || (this.taskUsageCount - 1))
    }
  },
  methods: {
    onDialogOpened () {
      this.resetRequestable()

      this.$emit('open')
    },
    onRequestSuccess (data) {
      this.dialogOpen = false

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t(`dataField.deleteDialog.title.${translationSuffix}`)"
    :text="text"
    :ok-btn-text="$t(`dataField.deleteDialog.buttons.ok.${translationSuffix}`)"
    :cancel-btn-text="blockUsage ? null : undefined"
    :close-on-button-click="false"
    :ok-btn-color="blockUsage ? undefined : 'error'"
    :loading="requestableLoading"
    :error-message="errorMessage"
    @click-ok="blockUsage ? dialogOpen = false : request(requestParameter)"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
  </custom-dialog>
</template>
