<script>
import { PageContentable } from '../page'
import TextControl from 'controls/text-control.vue'
import RichtextareaControl from 'controls/richtextarea-control.vue'
import Group from 'mixins/models/group'
import MonitorSubscription from 'components/monitor-subscription.vue'
import ActivityHub from '../../components/activity-hub/index.vue'
import globalIdFor from '../../helpers/global-id'

export default {
  name: 'GroupEditContentMain',
  components: {
    MonitorSubscription,
    ActivityHub,
    TextControl,
    RichtextareaControl
  },
  mixins: [PageContentable, Group],
  computed: {
    group () {
      return this.value
    },
    gid () {
      return globalIdFor('Group', this.pageProps.id)
    }
  },
  methods: {
    onUpdated (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<template>
  <div v-if="value">
    <monitor-subscription
      :key="`monitor-channel-${gid}`"
      :gid="gid"
    />

    <text-control
      v-model="value.name"
      label="Name"
      :request-parameter="{method: 'patch', url: $apiEndpoints.groups.update(value.id), mapping: 'name'}"
      class="mb-5"
      :readonly="!$config.current_user.isAdmin || isReadonly"
      @request-success-data="onUpdated"
    />

    <richtextarea-control
      v-model="value.description"
      label="Beschreibung"
      :request-parameter="{method: 'patch', url: $apiEndpoints.groups.update(value.id), mapping: 'description'}"
      class="mb-5"
      :readonly="!$config.current_user.isAdmin || isReadonly"
      @request-success-data="onUpdated"
    />

    <activity-hub
      v-if="gid"
      :key="`activity-hub-${gid}`"
      :gid="gid"
      hide-comments
      class="mt-12"
      @loaded="pageDataLoaded"
    />
  </div>
</template>
