<script>
import ListItemTemplate from './list-item-template.vue'
import ProcessDefinition from 'mixins/models/process-definition'

export default {
  name: 'ProcessDefinitionListItem',
  components: { ListItemTemplate },
  mixins: [ProcessDefinition],
  props: {
    ...ListItemTemplate.props,
    value: {
      type: Object,
      required: true
    },
    showCounts: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <list-item-template
    :skeleton-type="showCounts ? 'list-item-three-line' : 'list-item-avatar-two-line'"
    v-bind="$props"
    v-on="$listeners"
  >
    <v-list-item-content>
      <v-list-item-title>{{ value.name }}</v-list-item-title>
      <v-list-item-subtitle>{{ value.descriptionText }}</v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="showCounts"
        class="custom-meta-list mt-1"
        :class="{'error--text': processDefinitionIsActiveFor(value) && value.groupCount === 0}"
      >
        <span class="d-flex">{{ $tc('general.counts.instances', value.instanceCount) }}</span>
        <span class="d-flex">{{ $tc('general.counts.groups', value.groupCount) }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </list-item-template>
</template>
