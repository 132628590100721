<script>
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import castArray from 'lodash/castArray'

export default {
  name: 'PageDetailHeaderBase',
  mixins: [Requestable],
  props: {
    hasLeftSidebar: {
      type: Boolean,
      required: true
    },
    hasRightSidebar: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      onSuccessCallback: null
    }
  },
  methods: {
    ...{ castArray },
    actionRequest (url, onSuccess = null, method = Request.PATCH, params = null, payload = null, useCancelToken = false) {
      if (!this.requestableLoading && Request.METHODS.indexOf(method) !== -1) {
        this.onSuccessCallback = onSuccess
        this.request({ method, url }, params, payload, useCancelToken)
      }
    },
    onRequestSuccess (data) {
      if (this.onSuccessCallback) this.onSuccessCallback(data)
    }
  }
}
</script>
<template>
  <div class="mb-3 mb-lg-7 d-inline">
    <v-card
      flat
      class="mt-n3 pt-4"
      :style="!$vuetify.breakpoint.smAndDown ? 'position: sticky; z-index: 3; top: 64px;' : ''"
    >
      <slot
        :action-request="actionRequest"
        :requestable="this"
      />

      <v-messages
        v-if="hasError"
        :value="castArray(errorMessage)"
        color="error"
        class="mt-2 mb-1"
      />
      <v-progress-linear
        v-if="requestableLoading"
        class="mt-4"
        height="2"
        indeterminate
      />
      <v-divider
        v-else
        class="mt-2"
        :class="{'error--text': hasError}"
        style="border-bottom: 1px solid white;"
        :style="hasError ? 'border-color: currentColor !important;' : undefined"
      />
    </v-card>

    <div
      class="d-lg-none mt-2"
      style="height: 28px"
    >
      <v-btn
        v-if="hasLeftSidebar"
        small
        absolute
        style="left: 0; border-top-left-radius: 0; border-bottom-left-radius: 0;"
        @click="$emit('open-sidebar', 'left')"
      >
        <v-icon>mdi-chevron-double-right</v-icon>
      </v-btn>

      <v-btn
        v-if="hasRightSidebar"
        small
        absolute
        style="right: 0; border-top-right-radius: 0; border-bottom-right-radius: 0;"
        @click="$emit('open-sidebar', 'right')"
      >
        <v-icon>mdi-chevron-double-left</v-icon>
      </v-btn>
    </div>
  </div>
</template>
