<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import DataControl from 'controls/data-control'
import keys from 'lodash/keys'
import reduce from 'lodash/reduce'

export default {
  name: 'CreateDialog',
  components: {
    CustomDialog,
    DataControl
  },
  mixins: [Requestable],
  props: {
    ...requestablePropFactory().props,
    title: {
      type: String,
      required: true
    },
    okBtnText: {
      type: String,
      required: true
    },
    addAnotherOkBtnText: {
      type: String,
      default: undefined
    },
    successMessageFunction: {
      type: Function,
      default: undefined
    },
    fields: {
      type: Object,
      required: true
    },
    contentClass: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      value: {}
    }
  },
  computed: {
    isValid () {
      return reduce(this.fields, (result, field, key) => {
        if (field.required === true) {
          switch (field.type) {
            case 'string':
            case 'password':
            case 'text':
            case 'richtext':
              if (this.value[key] === '') return result && false
              break
            default:
              if (this.value[key] === null) return result && false
          }
        }
        return result
      }, true)
    },
    fieldCount () {
      return keys(this.fields).length
    }
  },
  methods: {
    onDialogOpened () {
      this.resetRequestable()
      this.value = reduce(this.fields, (result, field, key) => {
        return {
          ...result, [key]: field.default
        }
      }, {})
    },
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0

      this.request(this.requestParameter, null, this.value)
    },
    onRequestSuccess (data) {
      if (this.addAnother) {
        if (this.successMessageFunction) this.successMessage = this.successMessageFunction(data)
        this.onDialogOpened()
      } else {
        this.dialogOpen = false
        this.$emit('success', data)
      }
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="title"
    fullheight
    :close-on-button-click="false"
    :ok-btn-text="okBtnText"
    :ok-btn-disabled="!isValid"
    :additional-ok-btn-texts="addAnotherOkBtnText ? [addAnotherOkBtnText] : []"
    :loading="requestableLoading"
    :error-message="baseErrorMessage"
    :success-message="successMessage"
    :content-class="contentClass"
    @click-ok="onOkButtonClicked"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <data-control
      v-for="(field, name, index) in fields"
      :key="name"
      v-model="value[name]"
      :type="field.type"
      :label="field.label"
      :required="field.required"
      :disabled="requestableLoading"
      :error-messages="validationErrorMessageFor(name)"
      :class="{'mb-5': index < fieldCount - 1}"
    />
  </custom-dialog>
</template>
