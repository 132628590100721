<script>
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import SmartList from '../../components/smart-list.vue'
import WorkflowListItem from 'list-items/workflow-list-item.vue'
import TaskListItem from 'list-items/task-list-item.vue'
import DossierReferenceItem from 'components/dossier_reference_item.vue'
import Workflow from 'mixins/models/workflow'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossierEditTaskReferencesContent',
  components: {
    SmartList,
    WorkflowListItem,
    TaskListItem,
    DossierReferenceItem
  },
  mixins: [PageContentable, Requestable, Workflow],
  data () {
    return {
      references: undefined
    }
  },
  watch: {
    value: {
      handler () {
        this.references = undefined
        if (this.value) {
          this.request(
            { method: Request.GET, url: this.$apiEndpoints.dossiers.showTaskReferences(this.value.id) },
            null, null, true, true
          )
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...{ routeFor },
    onRequestSuccess (data) {
      this.references = data
    },
    onRequestError (error) {
      console.log('get data error:')
      console.log(error)
    }
  }
}
</script>
<template>
  <smart-list
    v-slot="{ item }"
    :value="references"
    :loading="requestableLoading"
    empty-list-message="Dieses Dossier wird in keinem Prozess genutzt."
  >
    <div v-if="!workflowIsSingleTaskFor(item.workflow)">
      <workflow-list-item
        :value="item.workflow"
        indent
        :dense="false"
      />
      <div class="ml-6">
        <task-list-item
          v-for="task in item.tasks"
          :key="task.id"
          :value="{...task, workflow: item.workflow}"
          :to="routeFor('task', task.id)"
          indent
          :dense="true"
          item-class="pb-2"
          hide-workflow-title
          hide-workflow-definition
        >
          <template #references>
            <dossier-reference-item
              v-for="field in task.dossierFields"
              :key="field.id"
              :active-dossier-id="value.id"
              :item="field"
            />
          </template>
        </task-list-item>
      </div>
      <v-divider />
    </div>
    <div v-else>
      <task-list-item
        :value="{...item.tasks[0], workflow: item.workflow}"
        :to="routeFor('task', item.tasks[0].id)"
        indent
        :dense="false"
        divider
        hide-workflow-title
        hide-workflow-definition
      >
        <template #references>
          <dossier-reference-item
            v-for="field in item.tasks[0].dossierFields"
            :key="field.id"
            :active-dossier-id="value.id"
            :item="field"
          />
        </template>
      </task-list-item>
    </div>
  </smart-list>
</template>
