<script>
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import SmartList from '../../components/smart-list.vue'
import GroupListItem from 'list-items/group-list-item.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'UserSettingsGroupsContent',
  components: {
    SmartList,
    GroupListItem
  },
  mixins: [PageContentable, Requestable],
  data () {
    return {
      groups: undefined
    }
  },
  watch: {
    value: {
      handler () {
        this.groups = undefined
        if (this.value) {
          this.request(
            { method: Request.GET, url: this.$apiEndpoints.userSettings.groups(this.value.id) },
            null, null, true, true
          )
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...{ routeFor },
    onRequestSuccess (data) {
      this.groups = data
    },
    onRequestError (error) {
      console.log('get data error:')
      console.log(error)
    }
  }
}
</script>
<template>
  <smart-list
    v-slot="{ item }"
    :value="groups"
    :loading="requestableLoading"
    empty-list-message="Diese:r Nutzer:in ist keiner Gruppe zugeordnet."
  >
    <group-list-item
      :value="item"
      :to="routeFor('group', item.id)"
      indent
      :dense="false"
      divider
    />
  </smart-list>
</template>
