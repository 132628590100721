<script>
import RichtextareaContent from 'components/richtextarea-content.vue'

export default {
  name: 'NoteControl',
  components: {
    RichtextareaContent
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    controlLoading () {
      return false
    },
    controlHasError () {
      return false
    }
  }
}
</script>

<template>
  <div class="secondary--text text--lighten-2 d-flex align-start pb-2 mt-7">
    <richtextarea-content :template="value" />
  </div>
</template>
