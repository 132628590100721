<script>
import Vue from 'vue'
import DataControl from 'controls/data-control'
import Requestable from 'mixins/requestable'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import Request from 'api/request'
import { routeFor } from 'helpers/route'

export default {
  name: 'DataTransformationListItem',
  components: { DataControl },
  props: {
    dataTransformationId: {
      type: Number,
      required: true
    },
    sourceDefinition: {
      type: Object,
      required: true
    },
    targetDefinition: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      internalValue: undefined,
      internalErrorMessage: undefined,
      updateData: undefined,
      updateRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.$emit('update:item', data.item)
            this.$emit('update:counts', data.type_counts)
            this.editMode = false
          }
        }
      })
    }
  },
  computed: {
    internalValueChanged () {
      return !isEqual(this.item.target_item.value, this.internalValue)
    },
    state () {
      if (this.item.error_messages === null) {
        if (this.item.confirmed) {
          return 'READY'
        } else {
          return 'UNCONFIRMED'
        }
      } else {
        return 'INVALID'
      }
    },
    isStateUnconfirmed () {
      return this.state === 'UNCONFIRMED'
    },
    isStateReady () {
      return this.state === 'READY'
    },
    itemIcon () {
      switch (this.state) {
        case 'READY':
          return 'mdi-check-circle-outline'
        case 'UNCONFIRMED':
          return 'mdi-help-circle-outline'
        case 'INVALID':
          return 'mdi-alert-circle-outline'
      }

      return undefined
    },
    itemColor () {
      switch (this.state) {
        case 'READY':
          return 'success'
        case 'UNCONFIRMED':
          return 'warning'
        case 'INVALID':
          return 'error'
      }

      return undefined
    }
  },
  watch: {
    item: {
      handler () {
        this.createInternalValue()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    routeFor,
    createInternalValue () {
      this.internalValue = cloneDeep(this.item.target_item.value)
      this.internalErrorMessage = this.item.error_messages
    },
    resetItem () {
      this.createInternalValue()
      this.updateRequestable.resetRequestable()
    },
    updateItem (payload) {
      this.internalErrorMessage = undefined
      this.updateRequestable.request(
        {
          method: Request.PATCH,
          url: this.$apiEndpoints.dataTransformations.update(
            this.dataTransformationId
          )
        },
        { item_id: this.item.id },
        payload
      )
    },
    updateItemValueAndConfirm () {
      this.updateItem({
        value: this.updateData,
        confirmed: true
      })
    },
    confirmItem () {
      this.updateItem({
        confirmed: true
      })
    },
    unconfirmItem () {
      this.updateItem({
        confirmed: false
      })
    },
    onUpdateDataChanged (data) {
      this.updateData = data
    }
  }
}
</script>
<template>
  <v-list-item :id="$id('data-transformation-list-item')">
    <v-list-item-icon class="mr-4">
      <v-icon :color="itemColor">
        {{ itemIcon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <div
        class="d-flex"
        style="max-width: 100%;"
      >
        <div style="min-width: 0;">
          <v-list-item-title>{{ item.parent.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.parent.subtitle }}</v-list-item-subtitle>
        </div>
        <v-btn
          icon
          class="ml-4"
          target="_blank"
          :to="routeFor('dossier', item.parent.id)"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </div>
      <v-container>
        <v-row>
          <v-col>
            <data-control
              :value="item.source_item.value"
              :acl-object-global-id="item.parent.gid"
              :type="sourceDefinition.type.name"
              :options="sourceDefinition.type.options"
              :required="sourceDefinition.required"
              disabled
            />
          </v-col>
          <v-col>
            <v-card>
              <v-card-text>
                <data-control
                  v-model="internalValue"
                  :acl-object-global-id="item.parent.gid"
                  :type="targetDefinition.type.name"
                  :options="targetDefinition.type.options"
                  :required="targetDefinition.required"
                  :loading="updateRequestable.requestableLoading"
                  :disabled="updateRequestable.requestableLoading"
                  :error-messages="internalErrorMessage || updateRequestable.errorMessageShort"
                  :select-card-menu-attach="$idRef('data-transformation-list-item')"
                  :mention-menu-attach="$idRef('data-transformation-list-item')"
                  @update-data:changed="onUpdateDataChanged"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  plain
                  small
                  color="secondary"
                  :disabled="!internalValueChanged || updateRequestable.requestableLoading"
                  @click="resetItem"
                >
                  Zurücksetzen
                </v-btn>
                <v-btn
                  text
                  small
                  color="primary"
                  :disabled="!internalValueChanged || updateRequestable.requestableLoading"
                  @click="updateItemValueAndConfirm"
                >
                  Speichern
                </v-btn>
                <v-btn
                  v-if="isStateUnconfirmed"
                  text
                  small
                  color="primary"
                  :disabled="internalValueChanged || updateRequestable.requestableLoading"
                  @click="confirmItem"
                >
                  Bestätigen
                </v-btn>
                <v-btn
                  v-else-if="isStateReady"
                  text
                  small
                  color="primary"
                  :disabled="internalValueChanged || updateRequestable.requestableLoading"
                  @click="unconfirmItem"
                >
                  Bestätigung entfernen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-list-item-content>
  </v-list-item>
</template>
