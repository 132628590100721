import ApiError from './api-error'
import includes from 'lodash/includes'

export default class ResponseError extends ApiError {
  constructor (error) {
    super()

    if (error) {
      // rails exception html
      if (includes(error.response.headers['content-type'], 'text/html')) {
        this.data = error.response.data
      }
      this.code = error.response.status
      this.message = error.response.statusText
    }
  }

  get titleLocalizationKey () {
    switch (this.code) {
      case 403:
      case 404:
      case 409:
        return `error.request.withResponse.title.code.${this.code}`
      case 410:
        return `error.request.withResponse.title.code.${this.code}`
      default:
        return 'error.request.withResponse.title.default'
    }
  }

  get messageLocalizationKey () {
    switch (this.code) {
      case 403:
      case 404:
      case 409:
        return `error.request.withResponse.message.code.${this.code}`
      case 410:
        return `error.request.withResponse.message.code.${this.code}`
      default:
        return 'error.request.withResponse.message.default'
    }
  }
}
