<script>

import { routeFor } from 'helpers/route'
export default {
  name: 'DossierReferenceItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    activeDossierId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...{ routeFor }
  }
}
</script>
<template>
  <div class="mb-4">
    <div class="font-weight-medium mb-2">
      {{ item.label }}
    </div>
    <div
      class="d-inline-flex flex-wrap"
      style="gap: 8px;"
    >
      <template v-for="(referencedDossier, indexValue) in item.value">
        <router-link
          v-if="referencedDossier.id !== activeDossierId"
          :key="`${indexValue}__element`"
          :to="routeFor('dossier_task_references', referencedDossier.id)"
          class="blue lighten-5 primary--text object-link object-link--chip mr-2"
        >{{ referencedDossier.title }}</router-link>
        <router-link
          v-else
          :key="`${indexValue}__element`"
          :to="routeFor('dossier_task_references', referencedDossier.id)"
          class="primary--text object-link--chip object-link--outline mr-2"
          style="text-decoration: none;"
        >{{ referencedDossier.title }}</router-link>
      </template>
    </div>
  </div>
</template>
