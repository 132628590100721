export default {
  resetBtn: {
    tooltip: 'Zurücksetzen'
  },
  copyToClipboardBtn: {
    tooltip: 'In Zwischenablage kopieren'
  },
  calendarSyncIcon: {
    tooltip: 'Wird zum Kalender hinzugefügt'
  }
}
