<script>
import PageDetailHeaderProcess from './page-detail-header-process.vue'
import Task from 'mixins/models/task'
import Workflow from 'mixins/models/workflow'
import ProcessDefinition from 'mixins/models/process-definition'

export default {
  name: 'PageDetailHeaderTask',
  components: {
    PageDetailHeaderProcess
  },
  mixins: [Task, Workflow, ProcessDefinition],
  props: {
    ...PageDetailHeaderProcess.props,
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
<template>
  <page-detail-header-process
    :workflow="workflow"
    :has-right-sidebar="hasRightSidebar"
    :has-left-sidebar="hasLeftSidebar"
    @open-sidebar="$emit('open-sidebar', $event)"
  >
    <template #default="{actionRequest, requestable}">
      <div class="d-flex flex-wrap flex-md-nowrap align-center mt-1 mt-md-4">
        <v-chip
          :color="taskStateColorFor(task, workflowIsActive).background"
          class="white--text mr-2 order-md-0 flex-shrink-0"
          label
        >
          {{ taskStateText }}
        </v-chip>
        <div
          class="text-h6 text-md-h5 font-weight-light text-truncate order-first order-md-1 flex-grow-1 flex-shrink-1"
          :style="$vuetify.breakpoint.smAndDown ? 'width: 100%;' : ''"
        >
          {{ task.name }}
        </div>
        <div
          v-if="$scopedSlots.actions"
          class="order-md-2 d-flex ml-auto ml-md-2"
        >
          <slot
            name="actions"
            :action-request="actionRequest"
            :loading="requestable.requestableLoading"
          />
        </div>
      </div>
    </template>
  </page-detail-header-process>
</template>
