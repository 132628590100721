<script>
export default {
  name: 'ProcessTitle',
  props: {
    workflow: {
      type: Object,
      required: true
    }
  }
}
</script>
<template>
  <v-tooltip
    bottom
    :disabled="!workflow.titleTooltip"
  >
    <template #activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
      >{{ workflow.title }}</span>
    </template>
    <span style="white-space: pre-line">{{ workflow.titleTooltip }}</span>
  </v-tooltip>
</template>
