<script>
export default {
  name: 'BlockDefinitionListItem',
  props: {
    value: {
      type: Object,
      required: true
    },
    to: {
      type: [String, Object],
      default: undefined
    },
    ripple: {
      type: [Boolean, Object],
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <v-list-item
    :to="to"
    :ripple="ripple"
  >
    <v-list-item-icon
      class="mr-4 handle"
      :class="{ 'handle-disabled': disabled}"
    >
      <v-icon>
        mdi-drag-horizontal
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-subtitle>Block</v-list-item-subtitle>
      <v-list-item-title>{{ value.title }}</v-list-item-title>
      <v-list
        v-if="$scopedSlots.default"
        class="overflow-hidden sub-items"
      >
        <slot />
      </v-list>
    </v-list-item-content>
  </v-list-item>
</template>
