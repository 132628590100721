import TaskListPage from './list'

export default {
  name: 'TaskListByDefinitionPage',
  mixins: [TaskListPage],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    initPageRequestParams () {
      const params = TaskListPage.methods.initPageRequestParams.call(this)
      delete params.workflow_definition_ids
      params.workflow_definition_id = this.id
      return params
    },
    filterFields () {
      const filterFields = TaskListPage.methods.filterFields.call(this)
      filterFields[2] = filterFields[2].filter((element) => element.name !== 'workflow_definition_ids')
      return filterFields
    }
  }
}
