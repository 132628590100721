import { VCol, VContainer, VMain, VRow } from 'vuetify/lib/components'
import ErrorDialog from 'components/error-dialog.vue'
import ObsoleteEventBanner from 'components/obsolete-event-banner.vue'
import Messages from 'components/messages.vue'

export default {
  name: 'PageContent',
  functional: true,
  props: {
    colProps: Object
  },
  render: function (createElement, context) {
    function genContent (createElement, context) {
      let content = [
        createElement(ObsoleteEventBanner),
        createElement(ErrorDialog),
        createElement(Messages),
        context.children
      ]
      if (context.props.colProps) {
        content = [createElement(VRow, { class: 'd-flex justify-center' }, [
          createElement(VCol, { props: context.props.colProps }, content)
        ])]
      }
      return [createElement(VContainer, {
        style: 'max-width: 960px;',
        class: 'pb-8'
      }, content)]
    }

    return createElement(VMain, {
      staticClass: context.data.staticClass,
      class: context.data.class
    }, genContent(createElement, context))
  }
}
