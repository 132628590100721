<script>
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import CreateDossierDialog from 'dialogs/create-dossier-dialog.vue'
import { stateColors } from 'helpers/definitions'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossiersContent',
  components: { CreateDossierDialog },
  mixins: [Requestable],
  data () {
    return {
      dossierDefinitions: [],
      totalCount: 0
    }
  },
  computed: {
    requestParameter () {
      return { method: Request.GET, url: this.$apiEndpoints.dashboard.dossierDefinitions() }
    },
    badgeColor () {
      return stateColors.active.background
    }
  },
  beforeMount () {
    this.dossierDefinitions = []
    this.request(this.requestParameter, { offset: this.dossierDefinitions.length, limit: 4 })
  },
  methods: {
    routeFor,
    onRequestSuccess (data) {
      this.totalCount = data.total_count
      this.dossierDefinitions.splice(this.dossierDefinitions.length, 0, ...data.result)
    },
    loadMore () {
      this.request(this.requestParameter, { offset: this.dossierDefinitions.length })
    },
    onCreated (dossier) {
      this.$router.push(routeFor('dossier', dossier.id))
    }
  }
}
</script>
<template>
  <div>
    <h1 class="text-h4 font-weight-light mt-4">
      {{ $t('dashboard.widget.dossiers_definitions.title') }}
    </h1>
    <v-container>
      <v-row v-if="dossierDefinitions && dossierDefinitions.length > 0">
        <v-col
          v-for="dd in dossierDefinitions"
          :key="dd.id"
          cols="12"
          sm="6"
          md="3"
          class="mb-5 d-flex"
        >
          <v-badge
            :value="dd.instance_count > 0"
            :color="badgeColor"
            :content="dd.instance_count"
            class="d-flex flex-grow-1"
            overlap
          >
            <v-card
              :to="routeFor('dossiers_by_definition', dd.id)"
              class="flex-grow-1"
            >
              <v-card-title
                class="text-subtitle-1"
                style="word-break: unset; line-height: 1.375rem;"
              >
                {{ dd.name }}
              </v-card-title>
              <v-card-subtitle> {{ dd.description }} </v-card-subtitle>
              <v-card-text class="pb-6">
                <create-dossier-dialog
                  :definition="dd"
                  @success="onCreated"
                >
                  <template #activator="{ on }">
                    <v-btn
                      color="grey lighten-5"
                      style="left: 0; right: 0; margin: 0 auto;"
                      small
                      absolute
                      bottom
                      fab
                      v-on="on"
                      @click.prevent
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </create-dossier-dialog>
              </v-card-text>
            </v-card>
          </v-badge>
        </v-col>
      </v-row>
      <v-row v-else-if="!requestableLoading">
        <v-col class="px-0">
          {{ $t('dashboard.widget.dossiers_definitions.emptyMessage') }}
        </v-col>
      </v-row>
      <v-row v-if="requestableLoadingDelayed">
        <v-col
          v-for="i in 4"
          :key="`skeleton-loader-${i}`"
          cols="6"
          md="3"
        >
          <v-skeleton-loader
            type="card-heading, text@2, table-tfoot"
            :types="{ 'table-tfoot': 'avatar@4' }"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      v-if="!requestableLoading && (dossierDefinitions.length < totalCount)"
      plain
      text
      small
      block
      @click="loadMore"
    >
      {{ $t('dashboard.widget.general.load_more') }}
    </v-btn>
  </div>
</template>
