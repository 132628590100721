<script>
import PageContent from '../page-content'
import PageTitle from 'mixins/page-title'

export default {
  name: 'SignInPage',
  components: {
    PageContent
  },
  mixins: [PageTitle],
  data () {
    return {
      emailAutofilled: true,
      passwordAutofilled: true,
      email: '',
      password: '',
      showPassword: false,
      rules: {
        required: value => !!value || 'Erforderlich'
      }
    }
  },
  computed: {
    authenticityToken () {
      return document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' }
    },
    pageTitleParts () {
      return ['Anmelden']
    }
  },
  watch: {
    email (newValue, oldValue) {
      this.emailAutofilled = false
    },
    password (newValue, oldValue) {
      this.passwordAutofilled = false
    }
  },
  mounted () {
    // Workaround for browser bug (vuetify doesnt recognize autofill on chrome)
    let times = 0
    const interval = setInterval(() => {
      times += 1
      if (times === 100) {
        clearInterval(interval)
      }

      this.emailAutofilled = !!document.querySelector('input[name="user[email]"]:-webkit-autofill')
      this.passwordAutofilled = !!document.querySelector('input[name="user[password]"]:-webkit-autofill')

      if (this.emailAutofilled && this.passwordAutofilled) {
        clearInterval(interval)
      }
    }, 100)
  }
}
</script>
<template>
  <div class="sign-in-page">
    <page-content :col-props="{cols: 12, sm: 6}">
      <h4 class="mb-6">
        Anmelden
      </h4>

      <v-form
        :action="$route.path"
        accept-charset="UTF-8"
        method="post"
        class="mb-4"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="authenticityToken.content"
        >
        <v-text-field
          v-model="email"
          type="email"
          name="user[email]"
          label="E-Mail"
          :placeholder="emailAutofilled ? ' ' : undefined "
          persistent-placeholder
          :rules="[rules.required]"
          required
        />
        <v-text-field
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          name="user[password]"
          label="Passwort"
          :placeholder="passwordAutofilled ? ' ' : undefined"
          persistent-placeholder
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          required
          :rules="[rules.required]"
          @click:append="showPassword = !showPassword"
        />
        <input
          type="hidden"
          name="user[remember_me]"
          value="0"
        >
        <v-checkbox
          name="user[remember_me]"
          value="1"
          label="Angemeldet bleiben"
        />

        <v-btn
          type="submit"
          :disabled="(email === '' && !emailAutofilled)|| (password === '' && !passwordAutofilled)"
        >
          Anmelden
        </v-btn>
      </v-form>
      <a :href="$router.resolve({name: 'lost-password-page'}).href">Passwort vergessen?</a>
    </page-content>
  </div>
</template>
