<script>
import ListItemTemplate from './list-item-template.vue'
import join from 'lodash/join'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossierFieldDefinitionListItem',
  components: {
    ListItemTemplate
  },
  props: {
    ...ListItemTemplate.props
  },
  computed: {
    attributes () {
      const res = []
      if (this.value.required) res.push('Erforderlich')
      if (this.value.recommended) res.push('Empfohlen')
      if (this.value.unique) res.push('Eindeutig')
      if (this.value.hasDefaultValue) res.push('Vorgabewert')

      return join(res, ', ')
    }
  },
  methods: { routeFor }
}
</script>
<template>
  <list-item-template
    skeleton-type="list-item-avatar-two-line"
    two-line
    v-bind="$props"
  >
    <v-list-item-icon v-if="$scopedSlots.icon">
      <slot name="icon" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ value.name }}</v-list-item-title>
      <v-list-item-subtitle>
        {{ value.type.label }}
        <v-btn
          v-if="value.dataTransformation"
          text
          plain
          small
          color="warning"
          :to="routeFor('dossier_data_transformation_detail', value.dossierDefinitionId, { params: { dataTransformationId: value.dataTransformation.id } })"
        >
          (Für dieses Feld existiert eine unausgeführte Datentransformation)
        </v-btn>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-list-item-action-text>
        <span>{{ attributes }}</span>
        <slot name="actions" />
      </v-list-item-action-text>
    </v-list-item-action>
  </list-item-template>
</template>
