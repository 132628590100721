export default {
  widget: {
    processes: {
      title: 'Aufgaben',
      buttons: {
        start: 'Starten'
      },
      emptyMessage: 'Sobald Du die erforderlichen Rechte hast, siehst Du hier Aufgaben.'
    },
    dossiers_definitions: {
      title: 'Dossiers',
      emptyMessage: 'Sobald Du die erforderlichen Rechte hast, siehst Du hier Dossiers.'
    },
    everything_in_view: {
      title: 'Alles im Blick',
      unassigned_tasks_count_of_my_active_workflows_none: 'Alle Aufgaben in deinen Prozessen haben eine:n Verantwortliche:n',
      unassigned_tasks_count_of_my_active_workflows_single: 'Aufgabe in deinen Prozessen fehlt Verantwortliche:r',
      unassigned_tasks_count_of_my_active_workflows_multiple: 'Aufgaben in deinen Prozessen fehlt Verantwortliche:r',
      unassigned_tasks_count_of_my_active_workflows: '@:dashboard.widget.everything_in_view.unassigned_tasks_count_of_my_active_workflows_none | @:dashboard.widget.everything_in_view.unassigned_tasks_count_of_my_active_workflows_single | @:dashboard.widget.everything_in_view.unassigned_tasks_count_of_my_active_workflows_multiple',

      unassigned_active_processes_count_none: 'Alle Deine Prozesse haben eine:n Verantwortliche:n',
      unassigned_active_processes_count_single: 'Deiner Prozesse fehlt Verantwortliche:r',
      unassigned_active_processes_count_multiple: 'Deiner Prozesse fehlt Verantwortliche:r',
      unassigned_active_processes_count: '@:dashboard.widget.everything_in_view.unassigned_active_processes_count_none | @:dashboard.widget.everything_in_view.unassigned_active_processes_count_single | @:dashboard.widget.everything_in_view.unassigned_active_processes_count_multiple',

      unassigned_active_workflows_count_none: 'Alle aktiven Prozesse haben eine:n Verantwortliche:n',
      unassigned_active_workflows_count_single: 'Aktiven Prozess fehlt Verantwortliche:r',
      unassigned_active_workflows_count_multiple: 'Aktiven Prozessen fehlt Verantwortliche:r',
      unassigned_active_workflows_count: '@:dashboard.widget.everything_in_view.unassigned_active_workflows_count_none | @:dashboard.widget.everything_in_view.unassigned_active_workflows_count_single | @:dashboard.widget.everything_in_view.unassigned_active_workflows_count_multiple',

      hidden_process_definition_count_none: 'Alle aktiven Prozessvorlagen sind Gruppen zugeordnet',
      hidden_process_definition_count_single: 'Aktive Prozessvorlage ist keiner Gruppe zugeordnet',
      hidden_process_definition_count_multiple: 'Aktive Prozessvorlagen sind keiner Gruppen zugeordnet',
      hidden_process_definition_count: '@:dashboard.widget.everything_in_view.hidden_process_definition_count_none | @:dashboard.widget.everything_in_view.hidden_process_definition_count_single | @:dashboard.widget.everything_in_view.hidden_process_definition_count_multiple',

      hidden_dossier_definition_count_none: 'Alle aktiven Dossiervorlagen sind Gruppen zugeordnet',
      hidden_dossier_definition_count_single: 'Aktive Dossiervorlage ist keiner Gruppe zugeordnet',
      hidden_dossier_definition_count_multiple: 'Aktive Dossiervorlagen sind keiner Gruppen zugeordnet',
      hidden_dossier_definition_count: '@:dashboard.widget.everything_in_view.hidden_dossier_definition_count_none | @:dashboard.widget.everything_in_view.hidden_dossier_definition_count_single | @:dashboard.widget.everything_in_view.hidden_dossier_definition_count_multiple',

      unconfigured_main_menu: 'Konfiguriere das Hauptmenü'
    },
    my_last_changes: {
      title: 'Deine letzten Änderungen',
      emptyMessage: 'Sobald Du in samarbeid etwas gemacht hast, siehst Du hier deine letzten Änderungen.',
      types: {
        workflow: 'Prozess',
        task: 'Aufgabe',
        dossier: 'Dossier',
        workflow_definition: 'Prozessvorlage',
        dossier_definition: 'Dossiervorlage',
        user: 'Nutzer:in',
        group: 'Gruppe',
        main_menu_entry: 'Menüeintrag'
      }
    },
    general: {
      load_more: 'mehr anzeigen'
    }
  }
}
