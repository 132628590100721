import Page from '../page'
import EditContent from './edit-content.vue'

export default {
  name: 'FooterMenuEntryEditPage',
  mixins: [Page],
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    pageTitleParts () {
      return [...(this.value ? [this.value.title] : []), 'Fußzeilenmenüeinträge']
    },
    pageContentComponents () {
      return EditContent
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.menuEntries.show(this.id)
    }
  }
}
