import ListPage from '../list-page'
import ListContent from './list-content.vue'
import map from 'lodash/map'
import parseInt from 'lodash/parseInt'

export default {
  name: 'JournalListPage',
  mixins: [ListPage],
  computed: {
    pageTitleParts () {
      return ['Journal']
    },
    pageContentComponents () {
      return ListContent
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.events.journal()
    },
    initPageRequestParams () {
      return {
        page: this.pages.current,
        types: this.filters.values.types,
        subject_ids: this.filters.values.subject_ids
      }
    },
    filterFields () {
      return [
        [
          {
            name: 'types',
            type: 'multi-select',
            label: 'Art des Events',
            items: this.valueAttributeOrDefault('event_types'),
            default: [],
            cast: (value) => [value].flat()
          }
        ],
        [
          {
            name: 'subject_ids',
            type: 'user-multi-select',
            label: 'Auslöser:in des Events',
            items: this.addNoneUser(this.valueAttributeOrDefault('users')),
            default: [],
            cast: (value) => map([value].flat(), (value) => { return value === null ? null : parseInt(value) })
          }
        ]
      ]
    }
  }
}
