import i18n from 'i18n'
import { formatDate } from 'helpers/date'
import isNil from 'lodash/isNil'

export default {
  computed: {
    timeTrackingHasValue () {
      return this.timeTrackingHasValueFor(this.timeTrackingValue?.timeSpentInMinutes, this.timeTrackingValue?.spentAt)
    },
    timeTrackingMessage () {
      return this.timeTrackingMessageFor(this.timeTrackingValue?.timeSpentInMinutes, this.timeTrackingValue?.spentAt)
    }
  },
  methods: {
    ...{ formatDate },
    timeTrackingHasValueFor (timeSpentInMinutes, spentAt) {
      return !isNil(timeSpentInMinutes) && !isNil(spentAt)
    },
    timeTrackingHoursFor (timeInMinutes) {
      return Math.floor((timeInMinutes || 0) / 60)
    },
    timeTrackingMinutesFor (timeInMinutes) {
      return (timeInMinutes || 0) % 60
    },
    timeTrackingSpanTextFor (timeInMinutes) {
      const hours = this.timeTrackingHoursFor(timeInMinutes)
      const minutes = this.timeTrackingMinutesFor(timeInMinutes)

      const values = []
      if (hours > 0) values.push(this.$tc('timeTracking.time.hours', hours))
      if (minutes > 0) values.push(this.$tc('timeTracking.time.minutes', minutes))
      return values.join(' ')
    },
    timeTrackingSpentAtDateFor (spentAt) {
      return new Date(spentAt)
    },
    timeTrackingSpentAtDateLocalizedFor (spentAt) {
      return this.formatDate(this.timeTrackingSpentAtDateFor(spentAt))
    },
    timeTrackingMessageFor (timeInMinutes, spentAt) {
      return `${i18n.t('timeTracking.message', { timeSpent: `<span class="font-weight-bold">${this.timeTrackingSpanTextFor(timeInMinutes)}</span>`, spentAt: this.timeTrackingSpentAtDateLocalizedFor(spentAt) })}`
    },
    timeTrackingCommentContentFor (timeTrackingVal, commentVal) {
      let result = commentVal
      if (this.timeTrackingHasValueFor(timeTrackingVal?.timeSpentInMinutes, timeTrackingVal?.spentAt)) {
        result += `<p>--<br />${this.timeTrackingMessageFor(timeTrackingVal?.timeSpentInMinutes, timeTrackingVal?.spentAt)}</p>`
      }
      return result
    }
  }
}
