<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import SmartList from 'components/smart-list.vue'
import KebabMenu from 'components/kebab-menu.vue'
import EditDossierReferenceDialog from 'dialogs/edit-dossier-reference-dialog.vue'

export default {
  name: 'DossierDefinitionEditReferencesContent',
  components: {
    SmartList,
    KebabMenu,
    EditDossierReferenceDialog
  },
  mixins: [PageContentable],
  data () {
    return {
      references: undefined,
      loadRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.references = data
          }
        }
      })
    }
  },
  watch: {
    value: {
      handler () {
        this.references = undefined
        if (this.value) {
          this.loadRequestable.request(
            { method: Request.GET, url: this.$apiEndpoints.dossierDefinitions.showReferences(this.value.id) },
            null, null, true, true
          )
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onUpdated (data) {
      this.references = data
    }
  }
}
</script>
<template>
  <smart-list
    v-slot="{ item, index }"
    :value="references"
    :loading="loadRequestable.requestableLoading"
    empty-list-message="Zu dieser Dossiervorlage existieren keine Referenzen."
  >
    <v-divider
      v-if="index > 0"
      :key="`divider-${item.id}`"
    />
    <v-list-item
      :key="`list-item-${item.id}`"
      class="px-0"
    >
      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        <v-list-item-subtitle>Vorlage: {{ item.source.definition }}, Feld: {{ item.source.field }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-list-item-action-text>
          <span v-if="item.referenceDefinition.showEmpty">immer anzeigen</span>
          <kebab-menu btn-class="ml-4">
            <template #items="{ closeMenu }">
              <edit-dossier-reference-dialog
                :value="item"
                @request-success-data="onUpdated"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-title>Bearbeiten</v-list-item-title>
                  </v-list-item>
                </template>
              </edit-dossier-reference-dialog>
            </template>
          </kebab-menu>
        </v-list-item-action-text>
      </v-list-item-action>
    </v-list-item>
  </smart-list>
</template>
