<script>
import ListItemTemplate from './list-item-template.vue'
import Avatar from 'components/avatar.vue'
import isObject from 'lodash/isObject'

export default {
  name: 'ObjectListItem',
  components: { ListItemTemplate, Avatar },
  props: {
    ...ListItemTemplate.props,
    value: {
      type: Object,
      required: true
    },
    showType: Boolean,
    actionLink: Boolean
  },
  methods: {
    subTitleElementText (element) {
      return isObject(element) ? element.text : element
    },
    subTitleElementClass (element) {
      return isObject(element) ? element.class : undefined
    }
  }
}
</script>
<template>
  <list-item-template
    skeleton-type="list-item-avatar-two-line"
    v-bind="$props"
    v-on="$listeners"
  >
    <avatar
      v-if="value.avatar"
      :text="value.avatar.text"
      :image="value.avatar.image"
      :icon="value.avatar.icon"
      class="mr-2"
      list
    />

    <v-list-item-content>
      <v-list-item-title class="d-flex align-baseline">
        <span>{{ value.title }}</span>
      </v-list-item-title>
      <v-list-item-subtitle
        v-if="value.subtitleElements && value.subtitleElements.length > 0"
        class="custom-meta-list"
      >
        <span
          v-for="(element, index) in value.subtitleElements"
          :key="index"
          :class="subTitleElementClass(element)"
        >
          {{ subTitleElementText(element) }}
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="(showType && value.type) || (actionLink && value.href)">
      <v-list-item-action-text v-if="showType && value.type">
        {{ value.type }}
      </v-list-item-action-text>
      <v-btn
        v-if="actionLink && value.href"
        :href="value.href"
        color="primary"
        icon
        target="_blank"
        x-small
      >
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-list-item-action>
  </list-item-template>
</template>
