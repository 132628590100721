<script>
export default {
  name: 'MentioningHint',
  props: {
    path: {
      type: String,
      required: true
    },
    identifier: {
      type: String,
      required: true
    }
  }
}
</script>
<template>
  <i18n
    :path="path"
    tag="div"
    class="px-4 py-2 text-truncate text-body-2 text--secondary"
  >
    <template #identifier>
      <b>{{ identifier }}</b>
    </template>
  </i18n>
</template>
