import Page from '../page'
import EditHeader from './edit-header.vue'
import EditMainContent from './edit-main-content.vue'
import DossierEditTaskReferencesContent from './edit-task-references-content.vue'
import DossierEditDossierFieldReferencesContent from './edit-dossier-field-references-content.vue'
import EditSidebarLeft from './edit-sidebar-left.vue'
import EditSidebarRight from './edit-sidebar-right.vue'

export default {
  name: 'DossierEditPage',
  mixins: [Page],
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    fieldId: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    pageTitleParts () {
      return [...(this.value ? [this.value.referenceLabel] : []), 'Dossiers']
    },
    pageContentComponents () {
      const components = [EditHeader]

      switch (this.type) {
        case 'main':
          components.push(EditMainContent)
          break
        case 'task-references':
          components.push(DossierEditTaskReferencesContent)
          break
        case 'dossier-field-references':
          components.push(DossierEditDossierFieldReferencesContent)
          break
      }

      return components
    },
    pageSidebarLeftComponents () {
      return EditSidebarLeft
    },
    pageSidebarRightComponents () {
      return EditSidebarRight
    },
    pagePropsToWatch () {
      return this.id
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.dossiers.show(this.id)
    }
  }
}
