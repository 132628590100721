import { stateColors } from 'helpers/definitions'

export default {
  computed: {
    groupBreadcrumbs () {
      return [
        { text: 'Gruppen', to: { name: 'groups' } },
        { text: `${this.group.id}` }
      ]
    },
    groupStateText () {
      return 'Aktualisiert'
    },
    groupStateColor () {
      return stateColors.created
    },
    groupStateUpdatedAtDate () {
      return new Date(this.group.updatedAt)
    },
    isSystemGroupAll () {
      return this.group.systemIdentifier === 'system_group_all'
    },
    isReadonly () {
      return this.isSystemGroupAll
    }
  }
}
