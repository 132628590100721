<script>
import { PageContentable } from '../page'
import { routeFor } from 'helpers/route'
import filter from 'lodash/filter'

export default {
  name: 'DossierEditSidebarLeft',
  mixins: [PageContentable],
  data () {
    return {
      showAll: false
    }
  },
  computed: {
    references () {
      return this.showAll ? this.value.dossierFieldReferences : filter(this.value.dossierFieldReferences, (r) => r.count > 0 || r.showEmpty)
    }
  },
  methods: {
    ...{ routeFor },
    isCurrentRoute (name, id, fieldId = undefined) {
      const res = this.$route.name === name && String(this.$route.params.id) === String(id) &&
          (fieldId === undefined || String(this.$route.params.fieldId) === String(fieldId))
      return res
    }
  }
}
</script>
<template>
  <v-list
    v-if="value"
    class="pa-0"
  >
    <v-list-item
      :to="routeFor('dossier', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Stammdaten
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list-item
      :to="routeFor('dossier_task_references', value.id)"
      exact
    >
      <v-list-item-content>
        <v-list-item-title>
          Referenzen in Aufgaben
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-chip
          pill
          :color="isCurrentRoute('dossier-task-references', value.id) ? 'white': undefined"
        >
          {{ String(value.taskReferenceCount) }}
        </v-chip>
      </v-list-item-avatar>
    </v-list-item>

    <template v-if="value.dossierFieldReferences.length > 0">
      <v-divider />
      <v-subheader>
        <div class="flex-grow-1">
          Referenzen in Dossiers
        </div>
        <v-btn
          v-if="!showAll && value.dossierFieldReferences.length > references.length"
          plain
          x-small
          color="primary"
          @click="showAll = true"
        >
          Alle anzeigen
        </v-btn>
      </v-subheader>
      <v-list-item
        v-for="field in references"
        :key="`dossier_field_reference_${field.id}`"
        :to="routeFor('dossier_dossier_field_references', value.id, { params: { fieldId: field.id } })"
        exact
      >
        <v-list-item-content>
          <v-list-item-title>{{ field.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-avatar>
          <v-chip
            pill
            :color="isCurrentRoute('dossier-dossier-field-references', value.id, field.id) ? 'white': undefined"
          >
            {{ String(field.count) }}
          </v-chip>
        </v-list-item-avatar>
      </v-list-item>
    </template>
  </v-list>
</template>
