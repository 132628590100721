export default {
  deleteDialog: {
    title: 'Block löschen?',
    text: 'Löschen Sie den Block, wenn Sie sicher sind, dass dieser nicht mehr benöigt wird. Gelöschte Blöcke sind unwiederbringlich verloren.',
    buttons: {
      ok: 'Block löschen'
    }
  },
  tooltips: {
    notDeletable: 'Nur leere Blöcke können gelöscht werden'
  }
}
