export default {
  actions: {
    create: 'Menüeintrag erstellen',
    delete: 'Menüeintrag löschen'
  },
  createDialog: {
    title: 'Fußzeilenmenüeintrag erstellen',
    successMessage: 'Menüeintrag "{title}" wurde erfolgreich erstellt'
  },
  deleteDialog: {
    title: 'Fußzeilenmenüeintrag löschen?',
    text: 'Löschen Sie den Menüeintrag, wenn Sie sicher sind, dass dieser nicht mehr benöigt wird. Gelöschte Menüeinträge sind unwiederbringlich verloren.',
    buttons: {
      ok: 'Menüeintrag löschen'
    }
  }
}
