import CustomMention from 'lib/tiptap-extensions/custom-mention'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import isFunction from 'lodash/isFunction'

import VMenu from 'vuetify/lib/components/VMenu/VMenu'

import SelectList from 'components/select-list.vue'
import UserListItem from 'list-items/user-list-item.vue'
import GroupListItem from 'list-items/group-list-item.vue'
import WorkflowListItem from 'list-items/workflow-list-item.vue'
import TaskListItem from 'list-items/task-list-item.vue'
import DossierListItem from 'list-items/dossier-list-item.vue'

export default {
  props: {
    groups: {
      type: Array,
      default: () => []
    },
    mentionMenuAttach: {
      type: [Element, Object, String, Boolean],
      default: false
    },
    mentionMenuNudgeBottomDifference: {
      type: Number,
      default: 0
    },
    mentionDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mentionableExtension:
        new CustomMention({
          items: () => [],
          // is called when a suggestion starts
          onEnter: ({ matcherChar, query, range, command, virtualNode }) => {
            if (!this.mentionDisabled) this.mentionableOnSuggestion(true, matcherChar, query, range, command, virtualNode)
          },
          // is called when a suggestion has changed
          onChange: ({ matcherChar, query, range, command, virtualNode }) => {
            if (this.mentionableDebouncedQueryCall) this.mentionableDebouncedQueryCall.cancel()
            this.mentionableDebouncedQueryCall = debounce(() => this.mentionableOnSuggestion(false, matcherChar, query, range, command, virtualNode), 500)
            this.mentionableDebouncedQueryCall()
          },
          // is called when a suggestion is cancelled
          onExit: () => {
            // reset all saved values
            if (this.mentionableDebouncedQueryCall) this.mentionableDebouncedQueryCall.cancel() // prevent opening suggestions from last debounced onChange
            this.mentionableObjectType = undefined
            this.mentionableSuggestionRange = null
          },
          // is called on every keyDown event while a suggestion is active
          onKeyDown: ({ event }) => {
            return this.mentionableOnEditorKeyDown(event)
          }
        }),
      mentionableObjectType: undefined,
      mentionableFilterTextChanged: undefined,
      mentionableItems: undefined,
      mentionableMenuNudgeBottom: 0,
      mentionableMenuWidth: 0,
      mentionableDebouncedQueryCall: null,
      mentionableSuggestionRange: null,
      mentionableInsertMention: () => {
      },
      mentionableSelectedListItemIndex: undefined
    }
  },
  computed: {
    mentionableHasResults () {
      return this.mentionableItemsCount > 0
    },
    mentionableShowSuggestions () {
      return !!this.mentionableObjectType
    },
    mentionableRequestParameter () {
      const requestUrl = this.mentionableEndpointForType(this.mentionableObjectType)
      if (requestUrl) return { method: 'get', url: requestUrl, params: { group_ids: map(this.groups, 'id') } }

      return undefined
    },
    mentionableItemsCount () {
      return this.mentionableItems?.length || 0
    }
  },
  methods: {
    mentionableTypeForMatcherChar (matcherChar) {
      switch (matcherChar) {
        case '@': return 'user_or_group'
        case '%': return 'workflow'
        case '#': return 'task'
        case '*': return 'dossier'
      }
      return undefined
    },
    mentionableEndpointForType (type) {
      switch (type) {
        case 'user_or_group': return this.$apiEndpoints.usersAndGroups.list()
        case 'workflow': return this.$apiEndpoints.workflows.list()
        case 'task': return this.$apiEndpoints.tasks.list()
        case 'dossier': return this.$apiEndpoints.dossiers.list()
      }
    },
    mentionableOnSuggestion (isOnEnter, matcherChar, query, range, command, virtualNode) {
      const rectEditor = this.$refs.tiptapVuetify.$el.getBoundingClientRect()

      if (isOnEnter) this.mentionableMenuWidth = rectEditor.width - 50

      const rectVN = virtualNode.getBoundingClientRect()
      this.mentionableMenuNudgeBottom = rectVN.top + rectVN.height - rectEditor.top + 8

      this.mentionableObjectType = this.mentionableTypeForMatcherChar(matcherChar)
      if (isFunction(this.mentionableFilterTextChanged)) this.mentionableFilterTextChanged(query)

      this.mentionableSuggestionRange = range
      // we save the command for inserting a selected mention
      // this allows us to call it inside of our custom popup
      // via keyboard navigation and on click
      if (isOnEnter) this.mentionableInsertMention = command
    },
    mentionableOnEditorKeyDown (event) {
      switch (event.keyCode) {
        case 38: // up
          if (this.mentionableSelectedListItemIndex > 0) {
            this.mentionableSelectedListItemIndex--
            this.$refs.selectList.$el.querySelector(`.v-list div[data-index="${this.mentionableSelectedListItemIndex}"] .v-list-item`).scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
          }
          break
        case 40: // down
          if (this.mentionableSelectedListItemIndex < this.mentionableItemsCount - 1) {
            this.mentionableSelectedListItemIndex++
            this.$refs.selectList.$el.querySelector(`.v-list div[data-index="${this.mentionableSelectedListItemIndex}"] .v-list-item`).scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
          }
          break
        case 9: // tab
        case 13: // enter
          if (this.mentionableHasResults) {
            this.mentionableSelectObject(this.mentionableItems[this.mentionableSelectedListItemIndex], this.mentionableObjectType)
          }
          break
        case 27: // esc
          this.mentionableResetRequest()
          this.$refs.suggestionMenu.onKeyDown(event)
          break
        default:
          return false
      }

      return true
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    mentionableSelectObject (object, type) {
      this.mentionableResetRequest()

      this.mentionableInsertMention({
        range: this.mentionableSuggestionRange,
        attrs: {
          mId: object.id,
          mType: type === 'user_or_group' ? object.type : type,
          mNoAccess: false,
          mDeleted: false,
          mLabel: object.mentionLabel
        }
      })
      this.mentionableOnInsert()
    },

    mentionableOnInsert () {
    },

    mentionableResetRequest () {
      this.mentionableObjectType = undefined
      this.mentionableSelectedListItemIndex = undefined
      this.mentionableItems = undefined
      this.$refs.selectList?.cancel()

      if (this.mentionableDebouncedQueryCall) this.mentionableDebouncedQueryCall.cancel()
    },

    mentionableSetItems (items) {
      this.mentionableItems = items
      if (this.mentionableItems.length === 0) {
        this.mentionableSelectedListItemIndex = undefined
      } else {
        this.$nextTick(() => {
          if (this.mentionableSelectedListItemIndex === undefined) this.mentionableSelectedListItemIndex = 0
        })
      }
    },

    genResultListItem (item, itemKey) {
      let elementComponent
      const customProps = {}
      switch (this.mentionableObjectType) {
        case 'user_or_group':
          if (item.type === 'user') {
            elementComponent = UserListItem
          } else if (item.type === 'group') {
            elementComponent = GroupListItem
            customProps.subtitleElements = (value, defaultResult) => {
              return [`Gruppe mit ${defaultResult[0]}`]
            }
          }
          break
        case 'workflow':
          elementComponent = WorkflowListItem
          break
        case 'task':
          elementComponent = TaskListItem
          break
        case 'dossier':
          elementComponent = DossierListItem
          break
      }

      if (elementComponent) {
        return this.$createElement(elementComponent, {
          props: {
            key: itemKey,
            value: item,
            indent: true,
            dense: false,
            ...customProps
          },
          on: {
            click: () => this.mentionableSelectObject(item, this.mentionableObjectType)
          }
        })
      } else {
        return null
      }
    },

    genFilter (queryText, queryTextChanged) {
      this.mentionableFilterTextChanged = queryTextChanged
      return this.$createElement('div', { style: { display: 'none' } })
    },

    genMentionMenu () {
      return this.$createElement(VMenu, {
        props: {
          value: this.mentionableShowSuggestions,
          attach: this.mentionMenuAttach ? this.mentionMenuAttach : (this.$refs.tiptapVuetify ? this.$refs.tiptapVuetify.$el : null),
          nudgeBottom: this.mentionableMenuNudgeBottom + this.mentionMenuNudgeBottomDifference,
          nudgeRight: 25,
          minWidth: this.mentionableMenuWidth,
          maxWidth: this.mentionableMenuWidth,
          transition: 'slide-y-transition',
          contentClass: 'mention-suggestions-menu'
        },
        ref: 'suggestionMenu'
      }, this.mentionableShowSuggestions
        ? [this.$createElement(SelectList, {
            ref: 'selectList',
            props: {
              requestParameter: this.mentionableRequestParameter,
              addOnly: true,
              maxListHeight: 400,
              groupProps: {
                value: this.mentionableSelectedListItemIndex
              }
            },
            on: {
              'items:changed': items => this.mentionableSetItems(items)
            },
            scopedSlots: {
              default: ({ item, itemKey }) => this.genResultListItem(item, itemKey),
              filter: ({ queryText, queryTextChanged }) => this.genFilter(queryText, queryTextChanged)
            }
          })]
        : undefined)
    }
  }
}
