<script>
import PageContent from '../page-content'
import PageTitle from 'mixins/page-title'

export default {
  name: 'ChangePasswordPage',
  components: {
    PageContent
  },
  mixins: [PageTitle],
  props: {
    resetPasswordToken: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data () {
    return {
      password: '',
      password_confirmation: '',
      rules: {
        required: value => !!value || 'Erforderlich'
      }
    }
  },
  computed: {
    authenticityToken () {
      return document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' }
    },
    pageTitleParts () {
      return ['Passwort festlegen']
    }
  }
}
</script>
<template>
  <div class="change-password-page">
    <page-content :col-props="{cols: 12, sm: 6}">
      <h4 class="mb-6">
        Mein Passwort festlegen
      </h4>

      <v-form
        :action="$route.path"
        accept-charset="UTF-8"
        method="post"
        class="mb-4"
      >
        <input
          type="hidden"
          name="_method"
          value="put"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="authenticityToken.content"
        >
        <input
          type="hidden"
          name="user[reset_password_token]"
          :value="resetPasswordToken"
        >
        <input
          type="text"
          name="username"
          :value="email"
          autocomplete="username"
          class="d-none"
        >
        <v-text-field
          v-model="password"
          type="password"
          name="user[password]"
          label="Neues Passwort"
          autocomplete="new-password"
          :rules="[rules.required]"
          required
        />
        <v-text-field
          v-model="password_confirmation"
          type="password"
          name="user[password_confirmation]"
          label="Neues Passwort wiederholen"
          autocomplete="new-password"
          :rules="[rules.required]"
          required
        />
        <v-btn
          type="submit"
          :disabled="password === '' || password_confirmation === ''"
          class="mt-2"
        >
          Passwort speichern
        </v-btn>
      </v-form>
      <a :href="$router.resolve({name: 'sign-in-page'}).href">Zur Anmeldung</a>
    </page-content>
  </div>
</template>
