export default {
  actions: {
    complete: 'Zuarbeit abschließen'
  },
  dialogs: {
    complete: {
      title: 'Zuarbeit abschließen',
      text: 'Nach dem Abschließnen der Zuarbeit können keine Daten mehr geändert werden. Bitte prüfen Sie vor dem Abschließen ob Sie alle Daten korrekt eingegeben haben.',
      buttons: {
        ok: 'Zuarbeit abschließen'
      }
    }
  },
  introWithRequesterText: 'bittet dich um die folgende Zuarbeit.',
  introWithoutRequesterText: 'Wir bitten dich um die folgende Zuarbeit.',
  completedText: 'Vielen Dank! Die Zuarbeit wurde abgeschlossen.',
  completeButtonHint: 'Deine Eingaben werden automatisch zwischengespeichert. Nach dem Abschließen deiner Zuarbeit kannst du die eingegebenen Daten nicht mehr ändern.'
}
