import Page from '../page'

export default {
  name: 'Error404Page',
  mixins: [Page],
  computed: {
    pageTitleParts () {
      return ['404 Fehler']
    },
    pageContentComponents () {
      return {
        template: `<div>
                     <h1 class="text-h4 font-weight-light pt-5 mb-4">
                       404 - Seite nicht gefunden
                     </h1>
                     <p>
                       Die betroffenen Seite konnten nicht gefunden werden.<br>
                       Bitte kontaktieren Sie uns, wenn Sie diese Nachricht unerwartet erhalten haben.
                     </p>
                   </div>
                  `
      }
    }
  }
}
