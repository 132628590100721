<script>
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import PageListHeader from 'components/page-list-header.vue'
import CreateWorkflowDialog from 'dialogs/create-workflow-dialog.vue'
import DataListFilter from 'components/data-list/filter.vue'
import SmartList from 'components/smart-list.vue'
import ProcessTitle from 'components/process-title.vue'
import RichtextareaContent from 'components/richtextarea-content.vue'
import TaskListItem from 'list-items/task-list-item.vue'
import Workflow from 'mixins/models/workflow'
import Task from 'mixins/models/task'
import ProcessDefinition from 'mixins/models/process-definition'
import { routeFor, exportUrlFor } from 'helpers/route'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'TaskListContent',
  components: {
    RichtextareaContent,
    PageListHeader,
    CreateWorkflowDialog,
    DataListFilter,
    SmartList,
    ProcessTitle,
    TaskListItem
  },
  mixins: [PageContentable, ListPageContentable, Workflow, Task, ProcessDefinition],
  data () {
    return {
      createWorkflowDialogDefinitionProp: undefined
    }
  },
  computed: {
    result () {
      return this.value ? this.value.result : undefined
    },
    isSingleDefinitionList () {
      return !!this.pageProps.id
    },
    title () {
      return (this.isSingleDefinitionList ? (this.value?.workflow_definition?.name || '') : 'Aufgaben')
    },
    description () {
      return (this.isSingleDefinitionList ? (this.value?.workflow_definition?.description || '') : undefined)
    }
  },
  methods: {
    ...{ routeFor, exportUrlFor, isEmpty },
    onWorkflowCreated (workflow) {
      if (workflow.nextTaskId) {
        this.$router.push(routeFor('task', workflow.nextTaskId))
      } else {
        this.$emit('fetchPageData')
      }
    },
    configureCreateWorkflowDialog (singleTask, on) {
      this.createWorkflowDialogDefinitionProp = (singleTask ? 'system_process_definition_single_task' : undefined)
      return on
    },
    openCreateWorkflowDialog (singleTask, on) {
      this.createWorkflowDialogDefinitionProp = (singleTask ? 'system_process_definition_single_task' : undefined)
      on.click()
    },
    onCreateAction (on) {
      if (this.isSingleDefinitionList) {
        this.createWorkflowDialogDefinitionProp = this.value?.workflow_definition
        return on
      } else {
        return this.$config.flags.systemProcessDefinitionSingleTaskAccess ? undefined : this.configureCreateWorkflowDialog(false, on)
      }
    }
  }
}
</script>
<template>
  <div>
    <create-workflow-dialog
      :definition="createWorkflowDialogDefinitionProp"
      @success="onWorkflowCreated"
    >
      <template #activator="{ on: onDialog }">
        <page-list-header
          :title="title"
          :primary-action-title="$t('task.listActions.create')"
          v-on="onCreateAction(onDialog)"
        >
          <template #primary-action>
            <template v-if="!isSingleDefinitionList && $config.flags.systemProcessDefinitionSingleTaskAccess">
              <v-menu
                bottom
                left
                offset-y
                :nudge-bottom="8"
              >
                <template #activator="{ on: onMenu, attrs }">
                  <v-btn
                    color="primary"
                    :text="!$vuetify.breakpoint.xs"
                    :icon="$vuetify.breakpoint.xs"
                    v-bind="attrs"
                    v-on="onMenu"
                  >
                    <v-icon v-if="$vuetify.breakpoint.xs">
                      mdi-plus
                    </v-icon>
                    <template v-else>
                      {{ $t('task.listActions.create') }}
                      <v-icon color="primary">
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-btn>
                </template>
                <v-list
                  dense
                >
                  <v-list-item
                    class="text-button"
                    @click="openCreateWorkflowDialog(true, onDialog)"
                  >
                    {{ $t('task.listActions.createSingleTask') }}
                  </v-list-item>
                  <v-list-item
                    class="text-button"
                    @click="openCreateWorkflowDialog(false, onDialog)"
                  >
                    {{ $t('task.listActions.createFromDefinition') }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </template>
          <template #secondary-items>
            <v-list-item :href="exportUrlFor(pageRequestParameter.url, pageRequestParameter.params)">
              <v-list-item-title>{{ $t('workflow.actions.export') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isSingleDefinitionList && value && value.workflow_definition"
              :to="routeFor('process_definition', value.workflow_definition.id)"
            >
              <v-list-item-title>{{ $t('workflow.actions.editDefinition') }}</v-list-item-title>
            </v-list-item>
          </template>
        </page-list-header>
      </template>
    </create-workflow-dialog>

    <richtextarea-content
      v-if="description"
      :template="description"
      class="mt-2"
    />

    <data-list-filter
      v-model="filters"
      @update:filter="$emit('update:prop', { prop: 'filters', value: $event.value, info: $event.field })"
    />

    <smart-list
      v-slot="{ item }"
      :value="result"
      :loading="pageRequestLoading"
    >
      <div v-if="!workflowIsSingleTaskFor(item.workflow)">
        <v-list-item>
          <v-list-item-content>
            <div
              class="d-flex flex-wrap-reverse align-end"
              style="min-width: 0"
            >
              <div
                class="flex-grow-1"
                style="min-width: 0"
              >
                <v-list-item-subtitle
                  v-if="!processDefinitionIsSystemProcessDefinitionSingleTaskFor(item.workflow.definition)"
                  class="text-truncate mb-2"
                >
                  {{ item.workflow.definition.name }}
                </v-list-item-subtitle>
                <v-list-item-title class="text-truncate">
                  <process-title :workflow="item.workflow" />
                </v-list-item-title>
              </div>
              <div class="ml-auto pl-1 mr-1 mb-1 d-flex flex-wrap justify-end">
                <span
                  v-for="task in item.workflow.visible_tasks"
                  :key="task.id"
                  :class="taskStateColorFor({state: task.state}, true).text"
                  style="font-size: small"
                >⬤&nbsp;</span>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <div class="ml-6">
          <task-list-item
            v-for="task in item.tasks"
            :key="task.id"
            :value="{...task, workflow: item.workflow}"
            :to="routeFor('task', task.id)"
            indent
            :dense="true"
            item-class="pb-2"
            hide-workflow-title
            hide-workflow-definition
          />
        </div>
        <v-divider />
      </div>
      <div v-else>
        <task-list-item
          :value="{...item.tasks[0], workflow: item.workflow}"
          :to="routeFor('task', item.tasks[0].id)"
          indent
          :dense="false"
          divider
          hide-workflow-title
          hide-workflow-definition
        />
      </div>
    </smart-list>

    <v-pagination
      v-if="!pageRequestLoading && result && pages.total() > 1"
      v-model="pages.current"
      :length="pages.total()"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'pages', value: pages })"
    />
  </div>
</template>
