<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import DataListFilter from 'components/data-list/filter.vue'
import DataTransformationListItem from 'list-items/data-transformation-list-item.vue'
import KebabMenu from 'components/kebab-menu.vue'
import DeleteDialog from 'dialogs/delete-dialog.vue'
import join from 'lodash/join'
import SmartList from 'components/smart-list.vue'
export default {
  name: 'DossierDefinitionEditDataTransformationDetailContent',
  components: {
    DataListFilter,
    DataTransformationListItem,
    KebabMenu,
    DeleteDialog,
    SmartList
  },
  mixins: [PageContentable],
  data () {
    return {
      transformation: undefined,
      loadRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.transformation = data
          }
        }
      }),
      confirmAllRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: () => {
            this.loadTransformation()
          }
        }
      }),
      applyRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.reloadDossierAndGotoDataTransformations()
          }
        }
      }),
      filters: {
        tabs: {
          name: 'type',
          items: [
            { text: 'Fehlerhaft', value: 'INVALID' },
            { text: 'Unbestätigt', value: 'UNCONFIRMED' },
            { text: 'Bereit', value: 'READY' }
          ],
          counts: () => {
            return this.transformation ? this.transformation.type_counts : {}
          },
          default: 'INVALID'
        },
        values: {
          type: 'INVALID'
        }
      },
      pages: {
        current: 1,
        total: 1
      }
    }
  },
  computed: {
    countInvalid () {
      return this.transformation?.type_counts?.INVALID
    },
    countUnconfirmed () {
      return this.transformation?.type_counts?.UNCONFIRMED
    }
  },
  watch: {
    value: {
      handler () {
        this.loadTransformation()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateFilter ({ field, value }) {
      this.filters.values[field] = value
      this.loadTransformation()
    },
    loadTransformation () {
      this.transformation = undefined
      if (this.value && this.pageProps.dataTransformationId) {
        this.loadRequestable.request(
          { method: Request.GET, url: this.$apiEndpoints.dataTransformations.show(this.pageProps.dataTransformationId) },
          { object_gid: this.value.gid, type: this.filters.values.type }, null, true, true
        )
      }
    },
    confirmAll () {
      this.confirmAllRequestable.request({ method: Request.PATCH, url: this.$apiEndpoints.dataTransformations.confirmAll(this.pageProps.dataTransformationId) })
    },
    applyTransformation () {
      this.applyRequestable.request({ method: Request.PATCH, url: this.$apiEndpoints.dataTransformations.apply(this.pageProps.dataTransformationId) })
    },
    reloadDossierAndGotoDataTransformations () {
      this.$emit('fetchPageData')
      this.$router.replace({ name: 'dossier-data-transformations' })
    },
    onDeleted () {
      this.reloadDossierAndGotoDataTransformations()
    },
    fieldDefinitionDesc (field) {
      const res = [field.type.label]
      if (field.required) res.push('Erforderlich')
      if (field.unique) res.push('Eindeutig')

      return join(res, ', ')
    }
  }
}
</script>
<template>
  <div>
    <template v-if="transformation">
      <div class="d-flex align-center">
        <h2 class="text-body-1 text--secondary flex-grow-1">
          Datentransformation
        </h2>
        <kebab-menu btn-class="ml-4">
          <template #items="{ closeMenu }">
            <delete-dialog
              :title="$t('dataTransformation.dialogs.delete.title')"
              :text="$t('dataTransformation.dialogs.delete.text')"
              :request-parameter="{ method: 'delete', url: $apiEndpoints.dataTransformations.destroy(transformation.id) }"
              @request-success="onDeleted"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title class="error--text">
                    {{ $t('dataTransformation.actions.delete') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </delete-dialog>
          </template>
        </kebab-menu>
      </div>
      <v-list>
        <v-list-item class="px-0">
          <v-list-item-content class="pt-0">
            <v-list-item-title>{{ transformation.field.name }}</v-list-item-title>
            <v-list-item-subtitle>
              Von: {{ fieldDefinitionDesc(transformation.source_definition) }}
              <br>
              Zu: {{ fieldDefinitionDesc(transformation.target_definition) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-if="countInvalid > 0 || countUnconfirmed > 0">
        <v-alert
          v-if="countInvalid > 0"
          type="error"
          prominent
        >
          <v-row align="center">
            <v-col class="grow">
              {{ countInvalid }} Elemente konnten nicht automatisch transformiert werden. Diese müssen manuell bearbeitet werden, bevor die Datentransformation abgeschlossen werden kann.
            </v-col>
          </v-row>
        </v-alert>
        <v-alert
          v-if="countUnconfirmed > 0"
          type="warning"
          prominent
        >
          <v-row align="center">
            <v-col class="grow">
              {{ countUnconfirmed }} Elemente müssen bestätigt werden, bevor die Datentransformation abgeschlossen werden kann.
            </v-col>
            <v-col class="shrink">
              <v-btn
                v-if="filters.values.type === 'UNCONFIRMED'"
                class="ml-2"
                :disabled="confirmAllRequestable.requestableLoading"
                :loading="confirmAllRequestable.requestableLoading"
                @click="confirmAll"
              >
                Alle bestätigen
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </template>
      <v-alert
        v-else
        type="success"
        prominent
      >
        <v-row align="center">
          <v-col class="grow">
            Alle Elemente haben einen validen Wert. Sie können die Transformation nun ausführen.
          </v-col>
          <v-col class="shrink">
            <v-btn
              :disabled="applyRequestable.requestableLoading"
              :loading="applyRequestable.requestableLoading"
              @click="applyTransformation"
            >
              Ausführen
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert
        v-if="applyRequestable.hasError"
        type="error"
      >
        {{ applyRequestable.errorMessage }}
      </v-alert>
    </template>

    <data-list-filter
      v-model="filters"
      @update:filter="updateFilter"
    />

    <smart-list
      v-slot="{ item, index }"
      :value="transformation && transformation.items"
      :loading="loadRequestable.requestableLoadingDelayed"
    >
      <data-transformation-list-item
        :item="item"
        :target-definition="transformation.target_definition"
        :source-definition="transformation.source_definition"
        :data-transformation-id="transformation.id"
        @update:item="$set(transformation.items, index, $event)"
        @update:counts="transformation.type_counts = $event"
      />
      <v-divider />
    </smart-list>
  </div>
</template>
