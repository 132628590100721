<script>
import Vue from 'vue'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import ObjectsControl from 'controls/objects-control/index.vue'
import WorkflowListItem from 'list-items/workflow-list-item.vue'

export default {
  name: 'MoveTaskToProcessDialog',
  components: {
    CustomDialog,
    ObjectsControl,
    WorkflowListItem
  },
  mixins: [Requestable],
  props: {
    taskId: {
      type: Number,
      required: true
    },
    processId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      dialogOpen: false,
      isMoveable: false,
      showProcessDeletionInfo: false,
      confirmProcessDeletion: false,
      testRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.isMoveable = true
            this.showProcessDeletionInfo = data.origin_workflow_deletion
          }
        }
      }),
      targetProcess: null
    }
  },
  computed: {
    hasValidData () {
      return !!this.targetProcess && (!this.showProcessDeletionInfo || this.confirmProcessDeletion)
    }
  },
  methods: {
    executeMoveRequest () {
      this.request(
        { method: 'patch', url: this.$apiEndpoints.tasks.moveToProcess(this.taskId) },
        null,
        { workflow_id: this.targetProcess.id }
      )
    },
    onDialogOpened () {
      this.isMoveable = false
      this.targetProcess = null
      this.showProcessDeletionInfo = false
      this.confirmProcessDeletion = false

      this.testRequestable.resetRequestable()
      this.resetRequestable()

      this.testRequestable.request({ method: 'get', url: this.$apiEndpoints.tasks.testMoveToProcess(this.taskId) }, null, null, true)

      this.$emit('open')
    },
    onRequestSuccess (data) {
      this.dialogOpen = false

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    fullheight
    :title="$t('task.moveDialog.title')"
    :ok-btn-text="$t('task.moveDialog.buttons.ok')"
    :ok-btn-disabled="!isMoveable || !hasValidData"
    :close-on-button-click="false"
    :loading="requestableLoading || testRequestable.requestableLoading"
    :error-message="baseErrorMessage"
    @click-ok="executeMoveRequest"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>

    <template v-if="!testRequestable.requestableLoading">
      <template v-if="isMoveable">
        <objects-control
          v-model="targetProcess"
          :label="$t('task.moveDialog.fields.targetProcess')"
          :hint="$t('general.field.required')"
          persistent-hint
          single
          :list-request-parameter="{method: 'get', url: $apiEndpoints.workflows.list(), params: { except: [processId]}}"
        >
          <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
            <workflow-list-item
              :key="item.id"
              :indent="indent"
              :item-link="itemLink"
              :selectable="selectable"
              :selected="selected"
              :skeleton="item.skeleton"
              :value="item"
              v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
            />
          </template>
        </objects-control>
        <v-checkbox
          v-if="showProcessDeletionInfo"
          v-model="confirmProcessDeletion"
          :label="$t('task.moveDialog.fields.confirmationText')"
        />
      </template>
      <v-alert
        v-else
        type="error"
        text
        dense
      >
        <p>Diese Aufgabe kann aus folgendem Grund nicht verschoben werden:</p>
        {{ testRequestable.baseErrorMessage }}
      </v-alert>
    </template>
  </custom-dialog>
</template>
