export default class ApiError {
  constructor (error) {
    this.code = null
    this.message = error ? error.message : null
  }

  forAttribute (attribute, short = true, asString = true) {
    return null
  }

  forNestedAttribute (attribute, short = true) {
    return []
  }

  forOther () {
    return this.message
  }

  forAll (except = [], short = false) {
    return this.forOther()
  }

  get titleLocalizationKey () {
    return 'error.request.general.title'
  }

  get messageLocalizationKey () {
    return 'error.request.general.message'
  }
}
