<script>
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import TextControl from 'controls/text-control.vue'

export default {
  name: 'UserSettingsEmailContent',
  components: {
    TextControl
  },
  mixins: [PageContentable, Requestable],
  data () {
    return {
      email: '',
      email_confirmation: '',
      showSuccessConfirmation: false
    }
  },
  computed: {
    valid () {
      return this.email.trim().length > 0 && this.email_confirmation.trim().length > 0
    }
  },
  methods: {
    save () {
      this.request(
        { method: Request.PATCH, url: this.$apiEndpoints.userSettings.updateEmail(this.value.id) },
        null, {
          email: this.email,
          email_confirmation: this.email_confirmation
        }
      )
    },
    onRequestSuccess (data) {
      this.email = ''
      this.email_confirmation = ''

      this.showSuccessConfirmation = true

      this.$emit('input', data)
    },
    onInput () {
      this.showSuccessConfirmation = false
    }
  }
}
</script>
<template>
  <div v-if="value">
    <text-control
      v-model="email"
      label="Neue E-Mail"
      :error-messages="validationErrorMessageFor('email')"
      class="mb-5"
      @input="onInput"
    />
    <text-control
      v-model="email_confirmation"
      label="Neue E-Mail wiederholen"
      :error-messages="validationErrorMessageFor('email_confirmation')"
      class="mb-8"
      @input="onInput"
    />
    <v-btn
      color="primary"
      :disabled="!valid || requestableLoading"
      depressed
      @click="save"
    >
      E-Mail ändern
    </v-btn>
    <v-alert
      v-if="showSuccessConfirmation"
      type="success"
      class="mt-5"
    >
      Ihre Daten wurden aktualisiert, aber Sie müssen Ihre neue E-Mail-Adresse bestätigen. Sie erhalten in wenigen Minuten eine E-Mail an Ihre neue Adresse, mit der Sie Ihre Änderung abschließen können.
    </v-alert>
  </div>
</template>
