<script>
import Vue from 'vue'
import TimeTracking from 'mixins/models/time-tracking'
import CustomDialog from 'dialogs/custom-dialog.vue'
import TimeTrackingReportDialog from 'dialogs/time-tracking-report-dialog.vue'
import NumericControl from 'controls/numeric-control.vue'
import Requestable from 'mixins/requestable'
import Request from '../api/request'
export default {
  name: 'TimeTrackingWidget',
  components: { NumericControl, CustomDialog, TimeTrackingReportDialog },
  mixins: [TimeTracking],
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          timeSpentInMinutes: 0,
          timeBudgetInMinutes: 0
        }
      }
    },
    taskId: {
      type: Number,
      required: true
    },
    taskGid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      budgetDialogOpen: false,
      internalBudgetValue: {
        hours: null,
        minutes: null
      },
      updateBudgetRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.$emit('updated', data)
            this.budgetDialogOpen = false
          }
        }
      })
    }
  },
  computed: {
    progressValue () {
      if (this.value?.timeBudgetInMinutes > 0) {
        return Math.ceil(100 * this.value?.timeSpentInMinutes / this.value?.timeBudgetInMinutes)
      } else {
        return this.value?.timeSpentInMinutes > 0 ? 100 : 0
      }
    },
    internalBudgetHoursValue () {
      return this.internalBudgetValue?.hours || 0
    },
    internalBudgetMinutesValue () {
      return this.internalBudgetValue?.minutes || 0
    },
    hasValidBudgetDialogValues () {
      return this.internalBudgetMinutesValue < 60
    },
    hasBudget () {
      return (this.internalBudgetHoursValue > 0) || (this.internalBudgetMinutesValue > 0)
    }
  },
  watch: {
    value: {
      handler: 'setInternalBudgetValue',
      deep: true,
      immediate: true
    }
  },
  methods: {
    setInternalBudgetValue () {
      this.internalBudgetValue.hours = this.timeTrackingHoursFor(this.value?.timeBudgetInMinutes) || null
      this.internalBudgetValue.minutes = this.timeTrackingMinutesFor(this.value?.timeBudgetInMinutes) || null
    },
    clickBudgetDialogOk () {
      this.updateBudgetRequestable.request(
        { method: Request.PATCH, url: this.$apiEndpoints.tasks.updateTimeTrackingBudget(this.taskId) },
        null, {
          budget_in_minutes: (this.internalBudgetHoursValue * 60) + this.internalBudgetMinutesValue
        }
      )
    },
    clickBudgetDialogCancel () {
      this.budgetDialogOpen = false
      this.setInternalBudgetValue()
    }
  }
}
</script>
<template>
  <div class="pl-4 pr-2 py-2 text-body-2 text--secondary custom-control">
    <div class="d-flex align-baseline">
      <div class="mr-2">
        Zeiterfassung
      </div>
      <custom-dialog
        v-model="budgetDialogOpen"
        :title="hasBudget ? $t('timeTracking.actions.budget.edit') : $t('timeTracking.actions.budget.create')"
        :close-on-button-click="false"
        :ok-btn-disabled="!hasValidBudgetDialogValues"
        :loading="updateBudgetRequestable.requestableLoading"
        :error-message="updateBudgetRequestable.baseErrorMessage"
        @click-ok="clickBudgetDialogOk"
        @click-cancel="clickBudgetDialogCancel"
      >
        <template #activator="{ on }">
          <v-btn
            text
            small
            color="primary"
            class="ml-auto"
            v-on="on"
          >
            {{ hasBudget ? $t('timeTracking.actions.budget.edit') : $t('timeTracking.actions.budget.create') }}
          </v-btn>
        </template>
        <v-container>
          <v-row class="mx-n6">
            <v-col>
              <numeric-control
                v-model="internalBudgetValue.hours"
                :label="$t('timeTracking.dialogs.edit.fields.spentHours')"
                :options="{ allowNegative: false }"
                hide-append
              />
            </v-col>
            <v-col>
              <numeric-control
                v-model="internalBudgetValue.minutes"
                :label="$t('timeTracking.dialogs.edit.fields.spentMinutes')"
                :options="{ allowNegative: false }"
                :rules="[value => value <= 59 || $t('timeTracking.dialogs.edit.errors.rangeMinutes')]"
                hide-append
              />
            </v-col>
          </v-row>
        </v-container>
      </custom-dialog>
    </div>
    <div class="mr-2">
      <v-progress-linear
        :value="progressValue"
        :color="progressValue > 100 ? 'error' : undefined"
        class="mt-1 mb-2"
      />
    </div>
    <div class="d-flex mr-2 mb-1">
      <div
        v-if="value && value.timeSpentInMinutes"
        class="mr-2"
      >
        Gebucht: <span class="font-weight-bold">{{ timeTrackingSpanTextFor(value.timeSpentInMinutes) }}</span>
      </div>
      <div
        v-if="value && value.timeBudgetInMinutes"
        class="ml-auto"
      >
        Budget: <span class="font-weight-bold">{{ timeTrackingSpanTextFor(value.timeBudgetInMinutes) }}</span>
      </div>
    </div>

    <time-tracking-report-dialog
      v-if="value && value.timeSpentInMinutes"
      :gid="taskGid"
      :time-tracking-value="value"
    >
      <template #activator="{ on }">
        <v-btn
          plain
          small
          class="ml-n3"
          v-on="on"
        >
          Report anzeigen
        </v-btn>
      </template>
    </time-tracking-report-dialog>
  </div>
</template>
