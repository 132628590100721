<script>
import { requestablePropFactory } from 'mixins/requestable'
import Cacheable from 'mixins/cacheable'
import Menucardable from 'mixins/menucardable'
import Itemable from 'mixins/itemable'
import SelectList from 'components/select-list.vue'
import ObjectListItem from 'list-items/object-list-item.vue'
import xorWith from 'lodash/xorWith'
import head from 'lodash/head'

export default {
  name: 'ObjectsSelectCardMenu',
  components: { SelectList, ObjectListItem },
  mixins: [Cacheable, Menucardable, Itemable],
  props: {
    ...requestablePropFactory().props,
    ...Itemable.props,
    value: {
      type: Array,
      required: true
    },
    single: Boolean,
    allowEmpty: {
      type: Boolean,
      default: true
    },
    additionalItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    cachedValueChanged () {
      return (this.value.length !== this.cacheValue.length) || xorWith(this.value, this.cacheValue, this.itemIsEqual).length > 0
    }
  },
  methods: {
    onCreated (data) {
      this.$refs.selectList?.addNewItem(data)
    },
    onMenuCardOpened () {
      this.$refs.selectList?.reset()
      this.createCacheValue()

      this.$emit('opened')
    },
    onMenuCardClosed () {
      this.$refs.selectList?.cancel()

      if (this.cachedValueChanged) {
        this.$emit('change', this.cacheValue)
      } else {
        this.$emit('cancel')
      }

      this.$emit('closed')
    },
    updateSelection (items) {
      const close = this.single && !this.itemIsEqual(head(this.cacheValue), head(items))
      this.cacheValue = items

      if (close) this.closeMenuCard()
    }
  }
}
</script>
<template>
  <v-menu
    :attach="attachElement"
    :close-on-click="closeOnClick"
    :close-on-content-click="closeOnContentClick"
    :nudge-bottom="nudgeBottom"
    :value="internalVisible"
    transition="slide-y-transition"
    min-width="100%"
    z-index="3"
    @input="onMenuStateChanged"
  >
    <v-card>
      <div class="pt-4">
        <select-list
          ref="selectList"
          :value="cacheValue"
          :additional-items="additionalItems"
          :request-parameter="requestParameter"
          :item-identifier="itemIdentifier"
          :allow-empty="allowEmpty"
          :single="single"
          :max-list-height="400"
          @update="updateSelection"
        >
          <template #filter="{queryText, queryTextChanged}">
            <v-text-field
              :value="queryText"
              autofocus
              class="mx-4 hide-details"
              clearable
              label="Suche"
              outlined
              persistent-hint
              @input="queryTextChanged"
            >
              <template
                v-if="$scopedSlots['create-action']"
                #append-outer
              >
                <slot
                  name="create-action"
                  :on-created="onCreated"
                />
              </template>
            </v-text-field>
            <v-divider />
          </template>

          <template #default="{ item, itemKey, selected, toggle }">
            <slot
              name="list-item"
              :_key="itemKey"
              :_item="item"
              _indent
              _selectable
              :_selected="selected"
              :_toggle-element="toggle"
            >
              <object-list-item
                :key="itemKey"
                :value="item"
                indent
                selectable
                :selected="selected"
                @click="toggle"
              />
            </slot>
          </template>
        </select-list>
      </div>
    </v-card>
  </v-menu>
</template>
