import Request from 'api/request'
import ValidationError from 'api/error/validation-error'
import eventBus from 'helpers/event-bus'
import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isPlainObject from 'lodash/isPlainObject'
import has from 'lodash/has'
import defaults from 'lodash/defaults'

export function requestablePropFactory (propName = 'requestParameter') {
  return {
    props: {
      [propName]: {
        type: Object,
        default: undefined,
        validator: (prop) => {
          return isNil(prop) || (
            has(prop, 'method') && isString(prop.method) && Request.METHODS.indexOf(prop.method) !== -1 &&
              has(prop, 'url') && isString(prop.url)
          )
        }
      }
    }
  }
}

export default {
  data () {
    return {
      requestObject: new Request()
    }
  },
  methods: {
    hasRequestParameter (propName = 'requestParameter') {
      return !isNil(this[propName])
    },
    request (requestParameter, params = null, data = null, useCancelToken = false, emitError = true, onRequestSuccess = null, onRequestError = null) {
      let payload = data

      if (!isNil(requestParameter.mapping)) {
        if (isFunction(requestParameter.mapping)) {
          payload = requestParameter.mapping(data)
        } else {
          payload = { [requestParameter.mapping]: data }
        }
      }

      if (!isNil(requestParameter.params) && isPlainObject(requestParameter.params)) {
        params = defaults(params, requestParameter.params)
      }

      const requestPromise = this.requestObject.request(requestParameter.method, requestParameter.url, params, payload, useCancelToken)
      requestPromise
        .then(data => {
          if (onRequestSuccess) onRequestSuccess(data)
          this.onRequestSuccess(data)
        })
        .catch(error => {
          if (onRequestError) onRequestError(data)
          this.onRequestError(error)

          if (emitError && !(error instanceof ValidationError)) {
            eventBus.$emit('requestable-error', error, () => {
              this.resetRequestable()
              this.request(requestParameter, params, data, useCancelToken, emitError)
            }, () => { this.onRequestErrorDialogCancel() })
          }
        })

      return requestPromise
    },
    cancelRequestable () {
      this.requestObject.cancel()
    },
    resetRequestable () {
      this.requestObject.reset()
    },
    // eslint-disable-next-line n/handle-callback-err
    onRequestError (error) {},
    onRequestSuccess (data) {},
    onRequestErrorDialogCancel () {},
    validationErrorFor (attribute, nested = false) {
      if (!this.requestObject.error) return null

      if (nested) {
        return this.requestObject.error.forNestedAttribute(attribute, true)
      } else {
        return this.requestObject.error.forAttribute(attribute, true, false)
      }
    },
    validationErrorMessageFor (attribute, short = true) {
      return this.requestObject.error ? this.requestObject.error.forAttribute(attribute, short) : null
    },
    errorMessagesFullExceptValidationFor (attributes = []) {
      return this.requestObject.error ? this.requestObject.error.forAll(attributes) : null
    }
  },
  computed: {
    requestableLoading () {
      return this.requestObject.loading
    },
    requestableLoadingDelayed () {
      return this.requestObject.loadingDelayed
    },
    hasError () {
      return this.requestObject.error !== null
    },
    errorMessage () {
      return (this.requestObject.error) ? this.requestObject.error.forAll() : null
    },
    errorMessageShort () {
      return (this.requestObject.error) ? this.requestObject.error.forAll([], true) : null
    },
    baseErrorMessage () {
      return (this.requestObject.error) ? this.requestObject.error.forOther() : null
    },
    error () {
      return this.requestObject.error
    }
  }
}
