export default {
  actions: {
    create: 'Automatisierung erstellen',
    delete: 'Automatisierung löschen',
    deactivate: 'Automatisierung deaktivieren',
    activate: 'Automatisierung aktivieren'
  },
  createDialog: {
    title: 'Automatisierung erstellen',
    successMessage: 'Automatisierung "{title}" wurde erfolgreich erstellt'
  },
  deleteDialog: {
    title: 'Automatisierung löschen?',
    text: 'Löschen Sie die Automatisierung, wenn Sie sicher sind, dass diese nicht mehr benöigt wird. Gelöschte Automatisierungen sind unwiederbringlich verloren.',
    buttons: {
      ok: 'Automatisierung löschen'
    }
  },
  fields: {
    title: 'Titel',
    workflowDefinition: 'Prozessvorlage',
    candidateAssignee: 'Automatisch Verantwortlich',
    schedule: 'Wiederholung'
  },
  scheduleControl: {
    fields: {
      start: 'Start',
      interval: 'Wiederholen alle',
      rule: '',
      validations: {
        day: 'Wiederholen am',
        dayOfMonth: ''
      }
    },
    rules: {
      daily: 'Tage',
      weekly: 'Woche',
      monthly: 'Monate',
      yearly: 'Jahre'
    },
    validations: {
      daysOfWeek: {
        1: 'Mo',
        2: 'Di',
        3: 'Mi',
        4: 'Do',
        5: 'Fr',
        6: 'Sa',
        0: 'So'
      },
      daysOfMonth: {
        start: 'Am {day}. Tag',
        last: 'Am letzten Tag'
      }
    }
  },
  nextExecutionAt: 'Nächste Ausführung am: {date}',
  deactivated: 'Automatisierung deaktiviert',
  states: {
    activated: 'Aktiviert',
    deactivated: 'Deaktiviert'
  }
}
