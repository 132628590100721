<script>
import eventBus from 'helpers/event-bus'
import * as Sentry from '@sentry/vue'

export default {
  name: 'ErrorDialog',
  data () {
    return {
      error: null,
      retryCallback: null
    }
  },
  mounted () {
    eventBus.$on('requestable-error', (error, retryCallback, cancelCallback) => {
      this.error = error
      this.retryCallback = retryCallback
      this.cancelCallback = cancelCallback

      let sentryMessage = `${this.$t(this.error.titleLocalizationKey)}: ${this.$t(this.error.messageLocalizationKey, [''])}`
      if (this.error.message) sentryMessage += ` (${this.error.message})`
      Sentry.captureMessage(sentryMessage)
    })
  },
  methods: {
    retry () {
      const cb = this.retryCallback
      this.error = null
      this.retryCallback = null

      if (cb) cb()
    },
    close () {
      const cb = this.cancelCallback
      this.error = null
      this.retryCallback = null

      if (cb) cb()
    }
  }
}
</script>
<template>
  <v-dialog
    :value="error"
    persistent
    max-width="1100"
  >
    <v-card v-if="error">
      <div
        class="v-alert__border v-alert__border--left error v-alert__border--has-color"
        style="border-radius: 0"
      />

      <v-card-title class="error--text">
        <v-icon
          color="error"
          class="mr-2"
        >
          mdi-alert-circle-outline
        </v-icon>
        {{ $t(error.titleLocalizationKey) }}
      </v-card-title>
      <v-card-text class="pl-14">
        <i18n :path="error.messageLocalizationKey">
          <br>
        </i18n>
        <div
          v-if="error.message"
          class="body-2 mt-2"
        >
          ({{ error.message }})
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          {{ $t('general.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="retry"
        >
          {{ $t('general.buttons.retry') }}
        </v-btn>
      </v-card-actions>

      <v-card-text v-if="error.data">
        <v-divider class="mb-4" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="error.data" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
