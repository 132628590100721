<script>
import DeleteDialog from 'dialogs/delete-dialog.vue'
import CreateEditSubmenuDialog from 'dialogs/main-menu-entry/create-edit-submenu-dialog.vue'

export default {
  name: 'MainMenuListItem',
  components: {
    CreateEditSubmenuDialog,
    DeleteDialog
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    itemClass: {
      type: String,
      default: undefined
    },
    divider: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isSubmenu () {
      return this.value.type === 'submenu'
    }
  },
  methods: {
    updateMenuEntries (menuEntries) {
      this.$emit('update:entries', menuEntries)
    }
  }
}
</script>
<template>
  <div :class="{'sub-menu': isSubmenu}">
    <v-hover v-slot="{ hover }">
      <v-list-item
        two-line
        :class="[{ 'grey lighten-5' : hover }, itemClass]"
      >
        <v-icon
          class="mr-2 handle"
          :color="hover ? undefined : 'grey lighten-2'"
        >
          mdi-drag-horizontal
        </v-icon>
        <v-list-item-content>
          <v-list-item-title :class="{'font-weight-medium': isSubmenu}">
            {{ value.title }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="!isSubmenu">
            {{ $t(`mainMenuEntry.itemTypes.${value.type}`) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>
            <template v-if="isSubmenu">
              <v-btn
                icon
                :color="hover ? 'primary' : undefined"
                @click="$emit('add-menu-entry', value.id)"
              >
                <v-icon :color="!hover ? 'grey lighten-2' : undefined ">
                  mdi-plus
                </v-icon>
              </v-btn>

              <create-edit-submenu-dialog
                :value="value"
                @request-success="updateMenuEntries"
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    :color="hover ? 'primary' : undefined"
                    v-on="on"
                  >
                    <v-icon :color="!hover ? 'grey lighten-2' : undefined ">
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                </template>
              </create-edit-submenu-dialog>
            </template>

            <delete-dialog
              :title="isSubmenu ? $t('mainMenuEntry.dialogs.deleteSubMenu.title') : $t('mainMenuEntry.dialogs.deleteMenuEntry.title')"
              :text="isSubmenu ? $t('mainMenuEntry.dialogs.deleteSubMenu.text'): undefined"
              :request-parameter="{ method: 'delete', url: $apiEndpoints.mainMenuEntries.destroy(value.id) }"
              @request-success="updateMenuEntries"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  :color="hover ? 'error' : undefined"
                  v-on="on"
                >
                  <v-icon :color="!hover ? 'grey lighten-2' : undefined ">
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </template>
            </delete-dialog>
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
    </v-hover>

    <slot
      :items="value.childEntries"
      :is-sub-menu="isSubmenu"
    >
      <template v-if="isSubmenu && value.childEntries.length > 0">
        <div
          v-for="subItem in value.childEntries"
          :key="`main-menu-entry-${value.id}-subentry-${subItem.id}`"
        >
          <v-divider class="ml-9" />
          <main-menu-list-item
            :value="subItem"
            item-class="pl-9"
            :divider="false"
            @update:entries="$emit('update:entries', $event)"
            @add-menu-entry="$emit('add-menu-entry', $event)"
          />
        </div>
      </template>
    </slot>

    <v-divider v-if="divider" />
  </div>
</template>
