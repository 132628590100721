<script>
import { PageContentable } from '../page'
import TextControl from 'controls/text-control.vue'
import SwitchControl from 'controls/switch-control.vue'
import AvatarControl from 'controls/avatar-control.vue'
import MonitorSubscription from 'components/monitor-subscription.vue'
import ActivityHub from 'components/activity-hub/index.vue'
import globalIdFor from 'helpers/global-id'

export default {
  name: 'UserSettingsAccountContent',
  components: {
    TextControl,
    SwitchControl,
    AvatarControl,
    MonitorSubscription,
    ActivityHub
  },
  mixins: [PageContentable],
  computed: {
    gid () {
      return globalIdFor('User', this.pageProps.id)
    }
  },
  methods: {
    onUpdated (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<template>
  <div v-if="value">
    <monitor-subscription
      :key="`monitor-channel-${gid}`"
      :gid="gid"
    />

    <switch-control
      v-if="$config.current_user.isAdmin"
      v-model="value.isAdmin"
      :disabled="$config.isCurrentUser(value)"
      label="Admin"
      :request-parameter="{method: 'patch', url: $apiEndpoints.userSettings.updateAdminStatus(value.id), mapping: 'admin'}"
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <text-control
      v-model="value.firstname"
      label="Vorname"
      :request-parameter="{method: 'patch', url: $apiEndpoints.userSettings.update(), mapping: 'firstname'}"
      class="mb-5"
      :disabled="!$config.isCurrentUser(value)"
      @request-success-data="onUpdated"
    />

    <text-control
      v-model="value.lastname"
      label="Nachname"
      :request-parameter="{method: 'patch', url: $apiEndpoints.userSettings.update(), mapping: 'lastname'}"
      class="mb-5"
      :disabled="!$config.isCurrentUser(value)"
      @request-success-data="onUpdated"
    />

    <avatar-control
      :value="value.avatar"
      label="Avatar"
      :request-parameter="{method: 'patch', url: $apiEndpoints.userSettings.update(), mapping: 'avatar'}"
      :disabled="!$config.isCurrentUser(value)"
      @request-success-data="onUpdated"
    />

    <activity-hub
      v-if="gid"
      :key="`activity-hub-${gid}`"
      :gid="gid"
      hide-comments
      class="mt-12"
      @loaded="pageDataLoaded"
    />
  </div>
</template>
