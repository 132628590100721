<script>
import isNil from 'lodash/isNil'

export default {
  name: 'Messages',
  data () {
    return {
      internalMessages: []
    }
  },
  beforeMount () {
    const messageStr = document.getElementById('vue-messages')?.textContent
    const messageJson = !isNil(messageStr) ? JSON.parse(messageStr) : null
    if (!isNil(messageJson)) {
      this.internalMessages = messageJson.splice(0)
    }
  },
  methods: {
    alertType (messageType) {
      switch (messageType) {
        case 'notice':
          return 'info'
        case 'alert':
        case 'error':
          return 'error'
        default:
          return null
      }
    }
  }
}
</script>
<template>
  <div>
    <template v-for="(message, index) in internalMessages">
      <v-alert
        v-if="alertType(message.type)"
        :key="index"
        :type="alertType(message.type)"
        dismissible
      >
        {{ message.text }}
      </v-alert>
    </template>
  </div>
</template>
