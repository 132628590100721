<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'

export default {
  name: 'BoolControl',
  components: { ResetBtn },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: Boolean,
      default: undefined
    }
  },
  data () {
    return {
      trueClicked: false,
      falseClicked: false
    }
  },
  computed: {
    controlHasLockingSupport () {
      return false
    }
  },
  methods: {
    btnClicked (val) {
      this.trueClicked = val === true
      this.falseClicked = val === false

      this.cacheValue = val === this.cacheValue ? null : val
      this.controlOnInput()
      this.controlOnChange()
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>

<template>
  <v-input
    :label="label"
    :disabled="controlDisabled"
    :class="controlClass"
    :error-messages="controlErrorMessages"
    :error-count="Number.MAX_VALUE"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
    class="custom-input-control"
  >
    <v-btn-toggle
      :value="cacheValue"
      color="primary"
      dense
    >
      <v-btn
        :disabled="controlDisabled"
        :loading="controlLoading && trueClicked"
        :value="true"
        text
        @click="btnClicked(true)"
      >
        Ja
      </v-btn>

      <v-btn
        :disabled="controlDisabled"
        :loading="controlLoading && falseClicked"
        :value="false"
        text
        @click="btnClicked(false)"
      >
        Nein
      </v-btn>
    </v-btn-toggle>

    <template #append>
      <reset-btn
        v-if="cachedValueChanged && controlHasError"
        @click="controlReset"
      />
    </template>
  </v-input>
</template>
