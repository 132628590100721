<script>
import CustomDialog from 'dialogs/custom-dialog.vue'
import DateControl from 'controls/date-control.vue'
import NumericControl from 'controls/numeric-control.vue'
import { currentIso8601Timestamp } from 'helpers/date'
import isNil from 'lodash/isNil'
import VRuntimeTemplate from 'v-runtime-template'
import TimeTracking from 'mixins/models/time-tracking'

export default {
  name: 'ActivityHubTimeTrackingWidget',
  components: {
    NumericControl,
    CustomDialog,
    DateControl,
    VRuntimeTemplate
  },
  mixins: [TimeTracking],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          spentAt: null,
          timeSpentInMinutes: null
        }
      }
    }
  },
  data () {
    return {
      dialogOpen: false,
      internalValue: {
        spentAt: null,
        timeSpentHours: null,
        timeSpentMinutes: null
      }
    }
  },
  computed: {
    timeTrackingValue () {
      return this.value
    },
    internalTimeSpentHoursValue () {
      return this.internalValue?.timeSpentHours || 0
    },
    internalTimeSpentMinutesValue () {
      return this.internalValue?.timeSpentMinutes || 0
    },
    hasValidDialogValues () {
      return !isNil(this.internalValue.spentAt) &&
          (this.internalTimeSpentHoursValue > 0 || this.internalTimeSpentMinutesValue > 0) &&
          this.internalTimeSpentMinutesValue < 60
    }
  },
  watch: {
    value: {
      handler: 'setInternalValue',
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...{ currentIso8601Timestamp },
    setInternalValue () {
      this.internalValue.spentAt = isNil(this.value?.spentAt) ? this.currentIso8601Timestamp() : this.value.spentAt

      this.internalValue.timeSpentHours = this.timeTrackingHoursFor(this.value?.timeSpentInMinutes) || null
      this.internalValue.timeSpentMinutes = this.timeTrackingMinutesFor(this.value?.timeSpentInMinutes) || null
    },
    onClickOk () {
      this.$emit('change', { spentAt: this.internalValue.spentAt, timeSpentInMinutes: (this.internalTimeSpentHoursValue * 60) + this.internalTimeSpentMinutesValue })
    },
    onClickCancel () {
      this.setInternalValue()
    },
    onClickDelete () {
      this.$emit('change', null)
    }
  }
}
</script>
<template>
  <div class="d-flex align-center mb-1">
    <v-runtime-template
      v-if="timeTrackingHasValue"
      :template="`<div>${timeTrackingMessage}</div>`"
      class="text-subtitle-2 font-weight-regular"
    />
    <custom-dialog
      v-model="dialogOpen"
      :title="timeTrackingHasValue ? $t('timeTracking.actions.entry.edit') : $t('timeTracking.actions.entry.create')"
      :custom-btn-text="$t('general.buttons.delete')"
      custom-btn-color="error"
      :custom-btn-disabled="!timeTrackingHasValue"
      :ok-btn-disabled="!hasValidDialogValues"
      @click-ok="onClickOk"
      @click-cancel="onClickCancel"
      @click-custom="onClickDelete"
    >
      <template #activator="{ on }">
        <v-btn
          text
          small
          color="primary"
          class="ml-auto"
          v-on="on"
        >
          {{ timeTrackingHasValue ? $t('timeTracking.actions.entry.edit') : $t('timeTracking.actions.entry.create') }}
        </v-btn>
      </template>
      <date-control
        v-model="internalValue.spentAt"
        :label="$t('timeTracking.dialogs.edit.fields.spentAt')"
        hide-delete-btn
        hide-append
      />
      <v-container>
        <v-row class="mx-n6">
          <v-col>
            <numeric-control
              v-model="internalValue.timeSpentHours"
              :label="$t('timeTracking.dialogs.edit.fields.spentHours')"
              :options="{ allowNegative: false }"
              hide-append
            />
          </v-col>
          <v-col>
            <numeric-control
              v-model="internalValue.timeSpentMinutes"
              :label="$t('timeTracking.dialogs.edit.fields.spentMinutes')"
              :options="{ allowNegative: false }"
              :rules="[value => value <= 59 || $t('timeTracking.dialogs.edit.errors.rangeMinutes')]"
              hide-append
            />
          </v-col>
        </v-row>
      </v-container>
    </custom-dialog>
  </div>
</template>
