import { stateColors } from 'helpers/definitions'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import includes from 'lodash/includes'

const WORKFLOW_STATE_ACTIVE = 'active'
const WORKFLOW_STATE_COMPLETED = 'completed'

export default {
  methods: {
    workflowIdentifierFor (workflow) {
      return `%${workflow.id}`
    },
    workflowGetTasksFor (workflow) {
      return flatMap(workflow.structure, (item) => {
        switch (item.type) {
          case 'task': return item
          case 'block': return item.tasks
          default:
            console.error('Unsupported workflow structure item type:', item.type)
            return []
        }
      })
    },
    workflowGetTaskWithIdFor (workflow, taskId) {
      if (workflow) {
        return find(this.workflowGetTasksFor(workflow), { id: taskId })
      } else {
        return null
      }
    },
    workflowCheckStateForUndefined (workflow) {
      if (workflow.state === undefined) console.error('Unsupported workflow state: ' + workflow.state)
    },
    workflowIsActiveFor (workflow) {
      this.workflowCheckStateForUndefined(workflow)
      return workflow.state === WORKFLOW_STATE_ACTIVE
    },
    workflowNoAccessFor (workflow) {
      return workflow.noAccess === true
    },
    workflowHasAvailableActionFor (workflow, action) {
      return includes(workflow.availableActions, action)
    },
    workflowIsSingleTaskFor (workflow) {
      return workflow?.systemIdentifier === 'system_process_single_task'
    }
  },
  computed: {
    workflowStateText () {
      this.workflowCheckStateForUndefined(this.workflow)
      return this.$t('workflow.state.' + this.workflow.state)
    },
    workflowStateColor () {
      switch (this.workflow.state) {
        case WORKFLOW_STATE_ACTIVE:
          return stateColors.active
        case WORKFLOW_STATE_COMPLETED:
          return stateColors.completed
        default:
          console.error('Unsupported workflow state: ' + this.workflow.state)
      }
    },
    workflowStateUpdatedAtDate () {
      return this.workflow.stateUpdatedAt ? new Date(this.workflow.stateUpdatedAt) : null
    },
    workflowIdentifier () {
      return this.workflowIdentifierFor(this.workflow)
    },
    workflowIsActive () {
      return this.workflowIsActiveFor(this.workflow)
    },
    workflowNoAccess () {
      return this.workflowNoAccessFor(this.workflow)
    },
    workflowIsSingleTask () {
      return this.workflowIsSingleTaskFor(this.workflow)
    }
  }
}
